import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from "./services/redux/store";
import InitialScreen from './presentations/containers/main';
import './ressources/App.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InitialScreen />
      </PersistGate>
    </Provider>
  );
}

export default App;

import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import ResultatRechercheController, { reduxConnect } from './controller';
import Headers from "./../../../components/headers/headers";
import Footer from "./../../../components/footer/footer";

class ResultatRecherche extends ResultatRechercheController {

	render() {
		return (
			<div>
				<LoadingOverlay
					active={this.state.isLoading}
					spinner
					text='Chargement'
				>
					<Headers/>
					<div className="page">
					</div>
					<Footer/>
				</LoadingOverlay>
			</div>
		)
	}
}

export default reduxConnect(ResultatRecherche);

export const ORDER_STATUS = {
    pending: 'En attente',
    processing: 'Confirmée',
    complete: 'Réalisée',
    canceled: 'Annulée',
};

export const ORDER_STATUS_LABEL = {
    PENDING: {value: 'pending', label: 'En attente'},
    CONFIRMED: {value: 'processing',label:'Confirmée'},
    COMPLETE: {value: 'complete',label:'Réalisée'},
    CANCELED: {value: 'canceled',label:'Annulée'},
};
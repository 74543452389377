import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import { withRouter } from 'react-router-dom';

class MenuMobile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false
      }
      this._menuToggle = this._menuToggle.bind(this);
      this._handleDocumentClick = this._handleDocumentClick.bind(this);
    }
    componentDidMount() {
      document.addEventListener('click', this._handleDocumentClick, false);
    }
    componentWillUnmount() {
      document.removeEventListener('click', this._handleDocumentClick, false);
    }
    _handleDocumentClick(e) {
      if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
        this.setState({
            isOpen: false
        });
      }
    }
    _menuToggle(e) {
      e.stopPropagation();
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
    render() {
      let menuStatus = this.state.isOpen ? 'is-open' : '';
      const { infoDashboardAll, infoDashboard } = this.props.dashboard;
  
      return (
        <div ref="root">
            <div className="hamburger" onClick={ this._menuToggle }><span className="material-icons">menu</span></div>
            <div id="overlayburger" onClick={ this._menuToggle } className={ menuStatus }></div>
            <div className={ menuStatus } id='menu-sidebar'>
                <div className="text-right">
                    <span className="material-icons" onClick={ this._menuToggle }>cancel</span>
                </div>
                <ul className="menu-mobile">
                    <li className="menuMobile-item"><Link to="/">Tableau de bord</Link></li>
                    <li className="menuMobile-item"><Link to="/mon-agenda">Mon agenda</Link></li>
                    <li className="menuMobile-item"><Link to="/etablissement-institut/Etablissement">Mon établissement</Link></li>
                    <li className="menuMobile-item">
                      <Link to="/hotel/hotelPartenaires">
                        Les hôtels 
                        <span className="nbr notif-nbr">
                        {(infoDashboard && infoDashboard.hotel && parseInt(infoDashboard.hotel)) || 0}
                        </span>
                      </Link>
                    </li>
                    <li className="menuMobile-item">
                      <Link to="/commande">
                        Gestion des commandes 
                        <span className="nbr notif-nbr">
                          {(infoDashboardAll && infoDashboardAll.nbOrderPending && parseInt(infoDashboardAll.nbOrderPending)) || 0}
                        </span>
                      </Link>
                    </li>
                </ul>
            </div>
        </div>
      )
    }
  }

  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuMobile));
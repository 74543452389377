import React from 'react';
import 'react-dropdown/style.css';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import LoadingOverlay from 'react-loading-overlay';
import Input from 'react-phone-number-input/input';
import Select, { components } from "react-select";
import Modal from 'react-awesome-modal';
import ParametreCompteController, { reduxConnect } from './controller'
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import PopupHandler from "./../../components/popup/popupHandler";

const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

const options = getCountries().map((country) => ({
  label: en[country] + ' +' + getCountryCallingCode(country),
  chipLabel: '+' + getCountryCallingCode(country),
  value: country
}));

class ParametreCompte extends ParametreCompteController {
  render() {
    const { showPassword, showMailAdress, showEditEmailPassword } = this.state;
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="page">
            <div className="bandeaux">
              <div className="wrapper clr">
                <h1>Paramètres du compte</h1>
              </div>
            </div>
            <div>
              {this.state.updateInfoError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
              {this.state.updateInfoError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
            </div>
            <div className="banner"></div>
            <div className="main monHotel">
              <div className="wrapper clr">
                <form className="" onSubmit={this.handleSubmit}>
                  <div className="row mainBody">
                    <div className="col col-6 line-input">
                      <label>Nom*</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-nom"
                          value={this.state.firstName ? this.state.firstName : ""}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="firstName"
                        />
                      </div>
                      {
                        this.state.errors.firstName.length > 0 &&
                        <span className='error'>{this.state.errors.firstName}</span>
                      }
                    </div>
                    <div className="col col-6 line-input">
                      <label>Prénom*</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-prenom"
                          value={this.state.lastName ? this.state.lastName : ""}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="lastName"
                        />
                      </div>
                      {
                        this.state.errors.lastName.length > 0 &&
                        <span className='error'>{this.state.errors.lastName}</span>
                      }
                    </div>
                    <div className="col col-6 line-input">
                      <label>Adresse e-mail*</label>
                      <div className="cnt-input">
                        <input
                          type="email"
                          className="input-nom"
                          value={this.state.email ? this.state.email : ""}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="email"
                          disabled
                        />
                        <span className="edit clickable" onClick={() => this.showHideComponent("showHideMailAdress")}>
                          <i className="material-icons icon-edit">edit</i>
                        </span>
                      </div>
                    </div>
                    <div className="col col-6 line-input">
                      <label>Mot de passe*</label>
                      <div className="cnt-input">
                        <input
                          type="password"
                          className="input-prenom"
                          value="password"
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="userPassword"
                          disabled
                        />
                        <span className="edit clickable" onClick={() => this.showHideComponent("showHidePassword")}>
                          <i className="material-icons icon-edit">edit</i>
                        </span>
                      </div>
                    </div>
                    {showEditEmailPassword && 
                      <div className="col col-12 blc-show-hide">
                          <div className="remove-btn"><button className="arrow" type="button" onClick={() => this.showHideComponent("showHideEditEmailPassword")}><i className="material-icons icons-cancel">cancel</i></button></div>
                          <div className="row">
                              <div className="col col-7 line-input">
                                  <label>Mot de passe actuel*</label>
                                  <div className="cnt-input with-icon">
                                      <input
                                          type={this.state.showCurrentPassword ? "text" : "password"}
                                          className={this.state.errorsPass.currentPassword ? "hasError" : ""} 
                                          value={this.state.currentPassword}
                                          onChange={this.handlePasswordEmailInput}
                                          name="currentPassword"
                                      />
                                      <span
                                          className={`edit clickable`}
                                          onClick={() => this.togglePasswordVisibility("currentPassword")}
                                          name='currentPassword'
                                      >
                                          <i className="material-icons">{!this.state.showCurrentPassword ? 'lock' : 'lock_open'}</i>
                                      </span>
                                  </div>
                                  {
                                    this.state.errorsPass.currentPassword &&
                                    <span className='error'>{this.state.errorsPass.currentPassword}</span>
                                  }
                              </div>
                              {showPassword && (
                                  <React.Fragment>
                                      <div className="col col-7 line-input">
                                          <label>Nouveau mot de passe*</label>
                                          <div className="cnt-input with-icon">
                                          <input
                                              type={this.state.showNewPassword ? "text" : "password"}
                                              className={this.state.errorsPass.newPassword ? "hasError" : ""} 
                                              value={this.state.newPassword}
                                              onChange={this.handlePasswordEmailInput}
                                              name="newPassword"
                                          />
                                          <span
                                              className={`edit clickable`}
                                              onClick={() => this.togglePasswordVisibility("newPassword")}
                                              name='newPassword'
                                          >
                                              <i className="material-icons">{!this.state.showNewPassword ? 'lock' : 'lock_open'}</i>
                                          </span>
                                          </div>
                                          {
                                            this.state.errorsPass.newPassword &&
                                            <span className='error'>{this.state.errorsPass.newPassword}</span>
                                          }
                                      </div>
                                      <div className="col col-7 line-input">
                                          <label>Confirmer nouveau mot de passe*</label>
                                          <div className="cnt-input with-icon">
                                              <input
                                                  type={this.state.showConfirmNewPassword ? "text" : "password"}
                                                  className={this.state.errorsPass.confirmNewPassword ? "hasError" : ""} 
                                                  value={this.state.confirmNewPassword}
                                                  onChange={this.handlePasswordEmailInput}
                                                  name="confirmNewPassword"
                                              />
                                              <span
                                                  className={`edit clickable`}
                                                  onClick={() => this.togglePasswordVisibility("confirmNewPassword")}
                                                  name='confirmNewPassword'
                                              >
                                                  <i className="material-icons">{!this.state.showConfirmNewPassword ? 'lock' : 'lock_open'}</i>
                                              </span>
                                          </div>
                                          {
                                            this.state.errorsPass.confirmNewPassword &&
                                            <span className='error'>{this.state.errorsPass.confirmNewPassword}</span>
                                          }
                                      </div>
                                  </React.Fragment>
                              )}
                              {showMailAdress && (
                                  <div className="col col-7 line-input">
                                      <label>Adresse e-mail*</label>
                                      <div className="cnt-input">
                                          <input
                                              className={this.state.errorsPass.newEmail ? "hasError" : ""} 
                                              type="email"
                                              value={this.state.newEmail} 
                                              onChange={this.handlePasswordEmailInput}
                                              name="newEmail"
                                          />
                                      </div>
                                      {
                                        this.state.errorsPass.newEmail &&
                                        <span className='error'>{this.state.errorsPass.newEmail}</span>
                                      }
                                  </div>
                              )}
                          </div>   
                      </div>
                    }
                    <div className="col col-6 line-input">
                      <label>Numéro de téléphone du contact</label>
                      <div className="PhoneInput">
                        <Select
                          onChange={(value) => this.handleUserSelect(value)}
                          options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                          components={{ SingleValue }}
                          value={options.find(op => { return op.value === (this.state.currentCountry ? this.state.currentCountry : "") })}
                        />

                        <Input
                          country={this.state.currentCountry}
                          international
                          value={this.state.phone ? this.state.phone : ""}
                          onChange={((value) => this.updateStateNumber(value))}
                          onBlur={(event) => this.handleNumberInput(event)}
                          name='phone'
                        />
                        {
                          this.state.errors.currentUserNumberError.length > 0 &&
                          <span className='error'>{this.state.errors.currentUserNumberError}</span>
                        }
                      </div>
                    </div>
                    {/* <div className="col col-12 line-input">
                    <label>Autoriser les notifications par SMS ? </label>
                    <div className="btn-check">
                      <label className="check">
                        <input
                          type="radio"
                          checked={this.state.smsAuthorization === true}
                          name="smsAuthorization"
                          onChange={(event) => this.updateChecked(event)}
                          value={true}
                        />
                        <span className="radiobtn"></span> Oui
                      </label>
                      <label className="check">
                        <input
                          type="radio"
                          checked={this.state.smsAuthorization === false}
                          name="smsAuthorization"
                          onChange={(event) => this.updateChecked(event)}
                          value={false}
                        />
                        <span className="radiobtn"></span> Non
                      </label>
                    </div>
                  </div> */}
                    <div className="cnt-Btn cnt-btn-inscription">
                      <input
                        className={`button mch-button ${this.isFormValid() ? '' : 'buttonIsInvalid'}`}
                        type="submit"
                        value="Enregistrer"
                      />
                      <div className="clr"></div>
                    </div>
                  </div>
                  <div className="clr"></div>
                </form>
              </div>
              {/* 
              <div className="wrapper-modal">
                <Modal
                  open={open} 
                  onClose={this.onCloseModal}
                  center={true}
                  >
                    <br/>
                      <label> {this.state.textReturn}</label>
                  {/* <h2>{this.state.title}</h2>
                  <p>
                    {this.state.textReturn}
                  </p> 
                </Modal>
              </div>
              */}
            </div>
          </div>
          <Footer />
          <div className="wrapper-modal">
            <Modal
              visible={this.state.visible}
              width="400"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="content-modal">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                  <h1>{this.state.titleShow}</h1>
                </div>
                <div className="main-modal">
                  <p>{this.state.messageShow}</p>
                  <div className="cnt-Btn align-right">
                    {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                    <button className="button" onClick={() => this.state.messageShow.includes("Succès de la modification du compte") ? this.redirectToLogin() : this.closeModal()}>Ok</button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(ParametreCompte);
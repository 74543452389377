import { get, put, post, remove} from '.';
import { url } from '../redux/constants/etablissement'

const testResponse = (response) => {
    if (!response || !response.data) return false;
    return response.data;
}

const testVoidResponse = (response) => {
    const data  = (response && response.status) ? response : (response && response.response) || null;
    return data;
  }

const testResponseData = (response) => {
    if (!response || !response.data || !response.data.data) return false;
    return response.data.data;
}

export default {
    getIdentityEtablissement : async () => {
        const response = await get(url.etablissementIdentité);
        return testResponse(response)
    },

    updateIdentityEtablissement : async (data) => {
        const response = await put(url.etablissementIdentité,data)
        return testVoidResponse(response)
    },

    getInfoEtablissement : async () => {
        const response = await get(url.etablissement);
        return testResponse(response)
    },
    
    updateInfoEtablissement : async (data) => {
        const response = await put(url.etablissement,data);
        return testVoidResponse(response)
    },

    createEtablissement : async (data) => {
        const response = await post(url.etablissement,data)
        return testResponse(response)
    },

    getProgressionEtablissement : async () => {
        const response = await get(url.progressionEtablissement);
        return testResponse(response)
    },

    getLegalInformation : async () => {
        const response = await get(url.legalInformation);
        return testResponse(response)
    },

    sendLegalInformation : async (data) => {
        const response = await put(url.legalInformation,data)
        return testVoidResponse(response);
    },

    getOpeningTime : async (id) => {
        const response = await get(`${url.openingTime}/${id}?page=1&itemPerPage=5`);
        return testResponseData(response)
    },

    createOpeningTime : async (data,id) => {
        const response = await post(url.openingTime,data,null,id)
        return testVoidResponse(response)
    },

    updateOpeningTime : async (data,id) => {
        const response = await put(`${url.openingTime}/${data.id}`,data,null,id)
        return testVoidResponse(response)
    },

    removeOpeningTime: async (id,idSeller) =>{
        const response = await remove(`${url.openingTime}/${id}`,null,idSeller)
        return testVoidResponse(response);
    }

}
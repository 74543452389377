import { get, post, remove } from '.';
import { url } from '../redux/constants/agenda'


const testResponse = (response) => {
  if (response.Error) {
    return response
  }
  else if (!response || !response.data) {
    return false
  }
  else {
    return response.data;
  }
}

export default {

  getAllEvent: async (sellerId) => {
    const header = sellerId ? {SELLER: sellerId} : null;
    const response = await get(url.agendaEvent, null, header);
    return testResponse(response)
  },

  addEvent: async (data) => {
    const response = await post(url.agendaEvent, data);
    return response
  },

  searchEvent: async (name, sellerId) => {
    const header = sellerId ? {SELLER: sellerId} : null;
    const response = await get(`${url.agendaEvent}?filter[title]=${name}&itemPerPage=5`, null, header);
    return testResponse(response)
  },

  removeEvent : async (idEvent) => {
    const response = await remove(`${url.agendaEvent}/${idEvent}?`);
    return response
  },

  getGoogleAccessToken : async (data) => {
    if (data && data.urlRedirect){
      const response = await get(`${url.getGoogleAccessToken}`);
      return response
    }
    else{
      const response = await get(`${url.getGoogleAccessToken}?code=${data.code}`);
      return response
    }
  },

   
  syncCalendar: async (idCalendar) => {
    const response = await post(`${url.syncToCalendar}/${idCalendar}`);
    return response
  },

  unSyncCalendar: async () => {
    const response = await post(url.unsyncToCalendar);
    return response
  },

  getStateSync: async () => {
    const response = await get(url.stateSync);
    return response
  },

  exportEvent : async (data) => {
    const response = await get(`${url.exportEvent}?start=${data.start}&end=${data.end}`)
    return response
  },

}
import { TOKEN, EXPIRES_AT, WS_CALL_TIME, REFRESH_TOKEN } from '../services/redux/constants/commons';
import store from "../services/redux/store";

export default class Auth {
  logout = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem("idSeller");
    localStorage.removeItem("type_compte");
    localStorage.removeItem(WS_CALL_TIME);
    localStorage.removeItem(REFRESH_TOKEN);
  };

  isAuthenticated = () => {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      store.dispatch({ type: 'LOGOUT' })
      this.logout();
      return false;
    }
    return true;
  };
}


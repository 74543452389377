import { get, put } from '.';
import { url } from '../redux/constants/bank'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;
}

const testVoidResponse = (response) => {
  const data  = (response && response.status) ? response : (response && response.response) || null;
  return data;
}

export default {

  getBankInfo: async () => {
    const response = await get(url.bankInfo);
    return testResponse(response)
  },

  sendBankInfo: async (data) => {
    const response = await put(url.bankInfo, data)
    return testVoidResponse(response);
  },

  uploadBankFile: async (data) => {
    const response = await put(url.bankUpload, data)
    return response;
  },

  verifyBankFile: async () => {
    const response = await get(url.bankVerifyFile)
    return testResponse(response)
  }
}
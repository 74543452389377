export const GET_SALE_ORDER = 'GET_SALE_ORDER';
export const GET_NEXT_SALE_ORDER = 'GET_NEXT_SALE_ORDER';
export const GET_SALE_ORDER_EXPORT = 'GET_SALE_ORDER_EXPORT';
export const LOGOUT = 'LOGOUT';

export const url = {
  saleOrder: '/seller/sale-order',
  saleOrderExport: '/sale-order/export',
  nextSaleOrder: '/sale-order/next',
  acceptSaleOrder: '/sale-order/accept',
  denySaleOrder: '/sale-order/deny',
  cancelSaleOrder:'/sale-order/cancel',
  saleOrderDetails: '/sale-order/details',
  orderBill:'/sale-order/order-bill',
  downloadOrderBill:'/sale-order/invoice',
  refundOrder:'/sale-order/refund'
};

export default {
  GET_SALE_ORDER,
  GET_NEXT_SALE_ORDER,
  GET_SALE_ORDER_EXPORT,
  LOGOUT,
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input'

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";


class ContactHotelController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        streetAddress: "",
        city: "",
        country: "",
        zipCode: ""
      },
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      currentCountry: "FR",
      errors: {
        address: 'default',
        firstName: 'default',
        lastName: 'default',
        email: 'default',
        currentUserNumberError: "default",
        streetAddress: "default",
        city: "default",
        country: "default",
        zipCode: "default",
      },
      currentUserNumber: "",
      isLoading: false,
      birthdayContact : null,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  callBack = async (data,type) => {
    const {steps} =this.props.dashboard;
    const fromHeadBand= (this.props.location.state && this.props.location.state.fromHeadBand) || false;
    const { infoLogin } = this.props.commons;
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    if(type && type==="update"){
      const updateError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        updateError,
      })
      if (data && data.data && (data.data.code === 500 || data.data.code === 400) && data.data.message) {
        showPopupExtra(<PopupError title="Erreur" message={data.data.message}/>)
      }
      if (!updateError) {
        if (!steps.includes("1b")) {
          await this.props.addSteps({steps: ["1b"]},this.callBack);
        }else if ((isIndependant || steps.includes("1a")) && fromHeadBand) {
          this.props.setLoadingFalse()
          this.props.history.push("/dashboard");
        }else{
          await this.props.getProgressionEtablissement(this.callBack)
        }
      }else{
        this.props.setLoadingFalse()
      }
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      const stepsResponse = (data && data.data && data.data && data.data.steps) || [];
      this.setState({
        addStepError,
      })
      this.props.setLoadingFalse()
      if(!addStepError && (isIndependant || stepsResponse.includes("1a")) && fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }else{
      this.props.setLoadingFalse()
    }

  }
  async componentDidMount() {
    const { infoContactSeller } = this.props.sellers;
    if (infoContactSeller) {
      this.setState({
        ...infoContactSeller,
        currentCountry: isValidPhoneNumber(infoContactSeller.phone) ? parsePhoneNumber(infoContactSeller.phone).country : "FR",
        address: infoContactSeller && infoContactSeller.address && infoContactSeller.address !== null ? infoContactSeller.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
      });
      for (let [key, value] of Object.entries(infoContactSeller)) {
        if (key === "address" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            key1 && value1 && this.testErrors(key1, value1)
          }
        }
        else {
          key && value && this.testErrors(key, value)
        }
      }
    }
  }
  
  updateStateAddress = (value) => {
    let name = value.target.name;
    let address = this.state.address
    switch (name) {
      case 'streetAddress':
        address.streetAddress = value.target.value
        break;
      case 'city':
        address.city = value.target.value
        break;
      case 'country':
        address.country = value.target.value
        break;
      case 'zipCode':
        address.zipCode = value.target.value
        break;
      default:
        break;
    }
    this.handleUserInput(value);
    this.setState({ address })
  }
  updateDayPick = (date) => {
    let errors = this.state.errors;
    errors.birthdayContact = this.props.errors.notEmptyInput(date);
    this.setState({ errors, birthdayContact:date });
  }

  isNotVoid = () => {
    const { infoLogin } = this.props.commons;
    const {
      streetAddress,
      city,
      country,
      zipCode,
      email,
      firstName,
      lastName,
      currentUserNumberError,
    } = this.state.errors;
    if (infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT')){
      return streetAddress === "" && city === "" && country === "" && zipCode === "" && email === "" && firstName === "" && lastName === "" && currentUserNumberError === "" && this.state.birthdayContact !== null
    }
    else{
      return streetAddress === "" && city === "" && country === "" && zipCode === "" && email === "" && firstName === "" && lastName === "" && currentUserNumberError === ""
    }
  }

  componentDidUpdate(prevProps) {
    const prevContactSeller = prevProps.sellers.infoContactSeller;
    const propsContactSeller = this.props.sellers.infoContactSeller;

    if (!prevContactSeller) {
      return;
    }
    if (prevContactSeller !== propsContactSeller) {
      this.setState({
        ...propsContactSeller,
        address: propsContactSeller && propsContactSeller.address !== null ? propsContactSeller.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
      });
      for (let [key, value] of Object.entries(propsContactSeller)) {
        if (key === "address" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            key1 && value1 && this.testErrors(key1, value1)
          }
        }
        else {
          key && value && this.testErrors(key, value)
        }

      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { infoLogin } = this.props.commons;

    const data = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      address: this.state.address,
    }
    if (infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT')){
      data.birthdayContact = this.state.birthdayContact
    }
    this.props.setLoadingTrue()
    this.props.updateSellerContact(data, this.callBack);
  }
  testErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case 'lastName':
        errors.lastName = this.props.errors.nameErrors(value);
        break;
      case 'firstName':
        errors.firstName = this.props.errors.nameErrors(value);
        break;
      case 'email':
        errors.email = this.props.errors.emailErrors(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      case 'phone':
        errors.currentUserNumberError = this.numberErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'lastName':
        errors.lastName = this.props.errors.nameErrors(value);
        break;
      case 'firstName':
        errors.firstName = this.props.errors.nameErrors(value);
        break;
      case 'email':
        errors.email = this.props.errors.emailErrors(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  numberErrors = (value) => {
    if (typeof value === "string") {
      if (isValidPhoneNumber(value)) {
        if (this.state.currentCountry === "FR" && (value.charAt(3)==="0")) {
          return "0 apres 33 est invalide";
        }
        else {
          return "";
        }
      } 
      else {
        return "Numero invalide";
      }
    }
    else {
      return "Numero invalide";
    }

  }
  // numberErrors() {
  //   if (isValidPhoneNumber(this.state.currentUserNumber)) {
  //     this.setState({ disabled: false });
  //     return "";
  //   } else {
  //     this.setState({ disabled: true });
  //     return "Numero invalide";
  //   }
  // }

  handleUserSelect(value) {
    this.setState({ currentCountry: value.value });
  }

  updateStateNumber(value) {
    this.handleNumberInput(value);

    this.setState({ phone: value });
  }
  handleNumberInput = (value) => {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.currentUserNumberError = this.numberErrors(value)
      this.setState({ errors });
    }
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event);
    this.setState({ [name]: event.target.value, });
  }

}

export default ContactHotelController;

ContactHotelController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';

class AttenteReponseController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      accept: 'Accepter',
      idSelection: null,
      percent: '',
      hotel: [
        {
          name: 'Carlton',
          address: 'Anosy',
          email: 'hotel@gmail.com',
          phone: '+325564846984',
          distance: '1.3',
          commission: '15',
          message: 'Ceci est un message'
        }
      ],
      selectedValue: null,
      prestation: [],
      messageRefused: "",
      isLoading: false,
      query: {
        itemPerPage: 5,
        page: 1,
        status: 5,
      },
      data: [],
    }
  }
  componentDidMount() {
    this.getAllData();
  }
  getAllData = async () => {
    this.props.setLoadingTrue()
    await this.props.getRequetePartenariat(this.state.query, this.callBack);
    this.props.setLoadingFalse()
  }

  handlePaginationChange = (page) => {
    let query = this.state.query;
    query.page = parseInt(page)
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getRequetePartenariat(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let query = this.state.query;
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getRequetePartenariat(this.state.query, this.callBack);
  };

  callBack = (data, type) => {
    this.props.setLoadingFalse()
    this.closeModal()
    if (type === "acceptPartenership" || type === "endPartenership" || type === "denyPartenership") {
      let requestError = false;
      if (type === "denyPartenership") {
        requestError =  data && data.status && (data.status === 200 || data.status === 201) ? false : true;
      }else{
        requestError =  data && data.id ? false : true
      }
      this.setState({
        requestError,
        isLoading: false
      });
      if (!requestError) {
        this.props.setLoadingTrue();
        this.props.getAllData();
      }
    }else{
      this.setState({
        data: (data && data.data) || null
      })
    }
  }


  onChangeListPrestation = async (item, id, isChecked) => {
    let { prestation } = this.state;
    prestation = item.target.checked ? [...prestation, id] : prestation.filter(i => i !== id)
    this.setState({
      prestation,
    });
  }

  openModal = (value, index) => {
    this.setState({
      idSelection: index,
      selectedValue: value,
      visible: true,
      prestation: [],
      messageRefused: "",
      accept: 'Accepter',
    })
  }
  isAcceptedsubmit = () => {
    const { prestation } = this.state
    return Array.isArray(prestation) && prestation.length
  }

  isRefusedSubmit = () => {
    const { messageRefused } = this.state;
    return messageRefused !== ""
  }

  closeModal() {
    this.setState({
      visible: false,
      selectedValue: null,
      idSelection: null,
    });
  }

  updateStateSimple = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleOptionsChange(value) {
    this.setState({ accept: value.value });
  }

  handleOptionsPercentChange(value) {
    this.setState({ percent: value.value });
  }

  sendRequest = (event) => {
    const type = this.state.accept;
    if (type === 'Accepter') {
      const data = { prestations: this.state.prestation };
      this.props.setLoadingTrue()
      this.props.acceptPartenership(data, this.state.selectedValue.id, this.callBack)
    }
    else {
      this.props.setLoadingTrue()
      this.props.denyPartenership(this.state.selectedValue.id, this.callBack)
    }
    this.closeModal();
  }

}

export default AttenteReponseController;

AttenteReponseController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class AvisCommandeController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      id: this.props.match.params.id,
    }
  }

  componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    if (!this.props.notice || !this.props.notice.productReview ) {
      this.setState({
        isLoading: true
      });
      this.props.getOrderReview(this.state.id,this.callback);
    }
  }

  callback = () => {
    this.setState({
        isLoading: false
    });
  }
}

export default AvisCommandeController;

AvisCommandeController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
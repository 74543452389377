import { get, post, put } from '.';
import { url } from '../redux/constants/notice'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;
}

export default {

  getOrderNotice: async (id) => {
    const response = await get(`${url.orderNotice}/` + id);
    return testResponse(response)
  },
  
  getReviews: async (saleOrder, productId, query) => {
    let filter = query && query.page  ? `?page=${query.page}` : '';
    filter = query && query.itemPerPage  ? `${filter}${filter ? '&': '?'}itemPerPage=${query.itemPerPage}` : filter;
    filter = query && parseInt(query.status)  ? `${filter}${filter ? '&': '?'}filter[status]=${parseInt(query.status)}` : filter;
    filter = query && query.reported !== null  ? `${filter}${filter ? '&': '?'}filter[reported]=${query.reported}` : filter;
    filter = saleOrder ? `${filter}${filter ? '&': '?'}filter[idOrder]=${saleOrder}`: filter;
    filter = productId ? `${filter}${filter ? '&': '?'}filter[product]=${productId}` : filter;
    
    const response = await get(`${url.reviews}${filter}`);
    return testResponse(response)
  },

  reportReview: async (data) => {
    const response = await post(`${url.reportReview}`, data);
    return response
  },

  validateReview: async (id) => {
    const response = await put(`${url.validateReview}/${id}`);
    return response
  },
}
import constants from '../constants/prestation';
import serviceApp from '../../applicatif/prestation';

export const createPrestation = (data, id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.createPrestation(data, id);
    callBack && callBack(payload, "create");
    if (payload) {
      return dispatch({
        type: constants.CREATE_PRESTATION,
        payload,
      })
    }
  }
}

export const updateFullPrestation = (data, idSSeller, id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateFullPrestation(data, idSSeller, id);
    callBack && callBack(payload, "update")
    if (payload) {
      return dispatch({
        type: constants.UPDATE_FULL_PRESTATION,
        payload,
      })
    }
  }
}


export const updatePrestation = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updatePrestation(data);
    callBack && callBack(payload, "update")
    if (payload) {
      return dispatch({
        type: constants.UPDATE_PRESTATION,
        payload: data,
      })
    }
  }
}

export const getPrestation = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getPrestation(data);
    callBack && callBack(payload, "getPrestation")
    if (payload) {
      if (payload && payload.status && payload.status === 200) {
        return dispatch({
          type: constants.GET_PRESTATION,
          payload: payload.data,
        })
      }
    }
  }
}

export const removePrestation = (id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.removePrestation(id);
    if (payload) {
      callBack && callBack(payload, "remove")
      if ((payload && payload.status === 201) || (payload && payload.status === 200)) {
        return dispatch({
          type: constants.REMOVE_PRESTATION,
          payload: id,
        })
      }
    }
  }
}

export const getPrestationIndependant = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getPrestationIndependant();
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_PRESTATION_INDEPENDANT,
        payload,
      })
    }
  }
}

export const addPrestationIndependant = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.addPrestationIndependant(data);
    if (payload) {
      callBack && callBack(payload, "add");
      return dispatch({
        type: constants.ADD_INDEPENDANT_PRESTATION,
        payload,
      })
    }
  }
}
import { get, put, remove, postWithID } from '.';
import { url } from '../redux/constants/prestation'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;

}
const testResponseData = (response) => {
  if (!response || !response.data || !response.data.data) return false;
  return response.data.data;
}

export default {
  createPrestation: async (data, id) => {
    const response = await postWithID(url.prestation, data, id);
    return testResponse(response);
  },

  updatePrestation: async (data, id) => {
    const response = await put(`${url.update}${data.id}`, data);
    return response.status
  },

  updateFullPrestation: async (data, idSeller, idPrestation) => {
    const response = await put(`${url.prestation}/${idPrestation}`, data);
    return testResponse(response)
  },
  getPrestation: async (data) => {
    const response = await get(`${url.prestation}?itemPerPage=${data && data.itemPerPage? data.itemPerPage : 200}&page=${data && data.page? data.page : 1}${data && data.filter ? `&filter[name]=${data.filter}` : "" }`);
    return response;
  },
  removePrestation: async (id) => {
    const response = await remove(`${url.prestation}/${id}`)
    return response
  },
  getPrestationIndependant: async () => {
    const response = await get(`${url.prestationIndependant}?page=1&itemPerPage=200`);
    return testResponseData(response)
  },
  addPrestationIndependant: async (data) => {
    const response = await put(url.addIndependantPrestation,data);
    return response
  },
}



import constants from '../constants/notice';
import serviceApp from '../../applicatif/notice';

export const getOrderNotice = (id,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getOrderNotice(id);
        callBack && callBack(payload);
        if (payload) {
            return dispatch({ 
                type: constants.GET_ORDER_NOTICE,
                payload,
            }) 
        }
    }
}
export const getReviews = (saleOrder, productId, query, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getReviews(saleOrder, productId, query);
        callBack && callBack(payload, "getReviews");
        if (payload) {
            return dispatch({ 
                type: constants.GET_REVIEWS,
                payload,
            }) 
        }
    }
}

export const getOrderReview = (saleOrder, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getReviews(saleOrder, null);
        callBack && callBack(payload, "getOrderReview");
        if (payload) {
            return dispatch({ 
                type: constants.GET_ORDER_REVIEW,
                payload,
            }) 
        }
    }
}

export const getProductReview = (productId, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getReviews(null, productId);
        callBack && callBack(payload, "getProductReview");
        if (payload) {
            return dispatch({ 
                type: constants.GET_PRODUCT_REVIEW,
                payload,
            }) 
        }
    }
}

export const reportReview = (data, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.reportReview(data);
        callBack && callBack(payload, "reportReview");
    }
}

export const validateReview = (id, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.validateReview(id);
        callBack && callBack(payload, "validateReview");
    }
}
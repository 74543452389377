import { get, post, put } from '.';
import { url } from '../redux/constants/hotels'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;
}

const testVoidResponse = (response) => {
  const data  = (response && response.status) ? response : (response && response.response) || null;
  return data;
}

export default {

  getHotelPartner: async (data) => {
    const response = await get(`${url.hotelPartener}?itemPerPage=${data && data.itemPerPage? data.itemPerPage : 5} ${data.page? `&page=${data.page}` : "" }${data && data.filter ? `&filter[search]=${data.filter}` : "" }`);
    return response
  },
  getRequetePartenariat: async (data) => {
    const response = await get(`${url.requetePartenariat}?itemPerPage=${data.itemPerPage}&page=${data.page}&filter[status]=${data.status}`);
    return response
  },

  getContactHotel: async (data) => {
    const response = await post(url.contactHotel, data);
    return testResponse(response);
  },

  UpdateContactHotel: async (data) => {
    const response = await put(`${url.contactSeller}${data.id}`, data);
    return testResponse(response)
  },

  getAllHotel: async (dataParams) => {
    let params = '?';
    Object.keys(dataParams).forEach(key=>{
      if (key==='filter') {
        Object.keys(dataParams.filter).forEach(keyFilter=>{
          params = dataParams['filter'][keyFilter] ? `${params}${params === '?' ? '' : '&'}filter[${keyFilter}]=${dataParams['filter'][keyFilter]}` : params
        })
      }else if (key==='order') {
        Object.keys(dataParams.order).forEach(orderKey=>{
          params = dataParams['order'][orderKey] ? `${params}${params === '?' ? '' : '&'}order[${orderKey}]=${dataParams['order'][orderKey]}` : params
        })
      }else{
        params = dataParams[key] ? `${params}${params === '?' ? '' : '&'}${key}=${dataParams[key]}` : params
      }
    })
    const response = await get(`${url.hotel}${params}`);
    return response
  },

  requestPartenership: async (data) => {
    const response = await post(url.requestPartenership, data);
    return response;
  },

  acceptPartenership: async (data, id) => {
    const response = await put(`${url.hotelPartenershipAction}/${id}/accept`, data);
    return testResponse(response);
  },

  endPartenership: async (id) => {

    const response = await put(`${url.hotelPartenershipAction}/${id}/end`);
    return response;
  },

  denyPartenership: async (id) => {
    const response = await put(`${url.hotelPartenershipAction}/${id}/deny`);
    return testVoidResponse(response);
  },

  getHotelFilter: async (data) => {
    const response = await post(url.hotelFilter, data);
    return testResponse(response);
  },

  getDefaultCommission: async (codeHotel) => {
    const response = await get(`/hotel/${codeHotel}${url.hotelDefaultCommission}`);
    return testVoidResponse(response);
  },

  getPricePerCuterly: async (codeHotel) => {
    const response = await get(`${url.hotelPricePerCuterly}/${codeHotel}`);
    return testVoidResponse(response);
  },



}

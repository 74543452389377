import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';

import LoginController, { reduxConnect } from './controller'
import PopupHandler from '../../components/popup/popupHandler';
import Auth from "../../../services/authentification";

const auth = new Auth();

class login extends LoginController {

  render() {
    const { fromLandingPage, fromPassword, from } = (this.props.location && this.props.location.state) || {};
    const fromPage = this.props.location && 
        this.props.location.state && 
        this.props.location.state.from && 
        this.props.location.state.from.pathname &&
        this.props.location.state.from.pathname !== '/login' 
            ? this.props.location.state.from.pathname
            : 'dashboard';
    if (auth.isAuthenticated()) {
      return <Redirect to={fromPage} />
    }
    if (!auth.isAuthenticated() && !fromLandingPage && !fromPassword) {
      return <Redirect to={{
          pathname: '/',
          state: { fromLandingPage, fromPassword, from}
      }} />
    }
    return (
      <div ref={this.pageRef} className="page">
         <LoadingOverlay
              active={this.state.isLoading}
              spinner
              text='Chargement'
            >
        <div className="header">
          <div className="wrapper clr">
            <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
          </div>
        </div>
        <div className="bandeaux">
          <div className="wrapper clr">
            <h1>Authentification</h1>
          </div>
        </div>
        <div>
          {this.props.location.error && PopupHandler.showPopupError("Erreur", this.props.location.error)}
        </div>
        <div className="main">
          <div className="wrapper clr">
           
              <form className="block-form" onSubmit={this.handleSubmit}>
                <div className="line-input">
                  <label>Identifiant ou adresse mail</label>
                  <div className="cnt-input with-icon">
                    <input
                      type="text"
                      value={this.state.userEmail}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="userEmail"
                    />
                    <span className="edit"><i className="material-icons icon-edit">person</i></span>
                  </div>
                  {
                    this.state.errors.userEmail.length > 0 &&
                    this.state.errors.userEmail !== 'default' &&
                    <span className='error'>{this.state.errors.userEmail}</span>
                  }
                </div>
                <div className="line-input">
                  <label>Mot de passe </label>
                  <div className="cnt-input with-icon">
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.userPassword}
                      className={this.state.passwordValid === false ? "hasError" : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="userPassword"
                    />
                    <span
                      className={`edit clickable`}
                      onClick={this.togglePasswordVisibility}
                    >
                      <i className="material-icons icon-edit">{!this.state.showPassword ? 'lock' : 'lock_open'}</i>
                    </span>
                  </div>
                  {
                    this.state.errors.userPassword.length > 0 &&
                    this.state.errors.userPassword !== 'default' &&
                    <span className='error'>{this.state.errors.userPassword}</span>
                  }
                </div>
                {
                  <span className='error'>{this.state.errorsFromServer}</span>
                }
                <div className="cnt-Btn">
                  <Link to="passe-oublie" className="link-passeoublie">
                    Mot de passe oublié ?
                  </Link>
                  <input
                    className={`button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                    type="submit"
                    value="Se connecter"
                    disabled={!this.isNotVoid()}
                  />
                  <div className="clr"></div>
                  <Link to="inscription" className="link-passeoublie">
                    S'inscrire ?
                  </Link>
                </div>
              </form>
          </div>
          <div className="wrapper-modal">
            <Modal
              visible={this.state.visible}
              width="400"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="content-modal">
                <div className="head-modal">
                  <Link to="" className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></Link>
                  <h1>Erreur</h1>
                </div>
                <div className="main-modal">
                <p>{this.state.textShow}</p>
                  <div className="cnt-Btn align-right">
                    {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                    <button className="button" onClick={() => this.closeModal()}>Ok</button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(login);


import agendaBdl from '../bdl/agenda';

export default {
    getAllEvent : (sellerId) => agendaBdl.getAllEvent(sellerId),
    addEvent : (data) => agendaBdl.addEvent(data),
    searchEvent : (name, sellerId) => agendaBdl.searchEvent(name, sellerId),
    removeEvent : (idEvent) => agendaBdl.removeEvent(idEvent),
    getGoogleAccessToken : (data) => agendaBdl.getGoogleAccessToken(data),
    syncCalendar : (idCalendar) => agendaBdl.syncCalendar(idCalendar),
    unSyncCalendar : () => agendaBdl.unSyncCalendar(),
    getStateSync : () => agendaBdl.getStateSync(),
    exportEvent : (data) => agendaBdl.exportEvent(data)
}
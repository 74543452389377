import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class ResponseHotelontroller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: [
        {
          name: 'Carlton',
          address: 'Anosy',
          email: 'hotel@gmail.com',
          phone: '+325564846984',
          distance: '1.3',
          commission: '15',
          message: 'Ceci est un message'
        }
      ],
      query: {
        itemPerPage: 5,
        page: 1,
        status: 1,
      },
      data: [],
    }
  }
  componentDidMount() {
    this.getAllData();
  }
  getAllData = async () => {
    this.props.setLoadingTrue()
    await this.props.getRequetePartenariat(this.state.query, this.callBack);
    this.props.setLoadingFalse()
  }

  handlePaginationChange = (page) => {
    let query = this.state.query;
    query.page = parseInt(page)
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getRequetePartenariat(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let query = this.state.query;
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getRequetePartenariat(this.state.query, this.callBack);
  };

  callBack = (data) => {
    this.props.setLoadingFalse()
    if (data && data.status && data.status === 200 && data.data) {
      this.setState({
        data: data.data
      })
    }
  }
}

export default ResponseHotelontroller;

//
// Redux connection
//

ResponseHotelontroller.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
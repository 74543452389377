import React from 'react';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import 'react-dropdown/style.css';
import HotelPartenaire from './hotelPartenaire';
import TousLesHotels from './tousLesHotels';
import AttenteReponse from './attenteReponse';
import ReponseHotel from './responseHotel';
import LoadingOverlay from 'react-loading-overlay';
import HotelController, { reduxConnect } from './controller';
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import { getSellerType } from '../../../data/constants/seller.type';

class Hotel extends HotelController {
  render() {
    const sellerType = getSellerType(this.props.commons.infoLogin);
    const defaultTab = () => {
      if (sellerType === 3) {
        return "TousHotels";
      }
      if (this.props.location && this.props.location.pathname) {
        const res = this.props.location.pathname.split('/');
        return res[res.length - 1];
      }
      return "hotelPartenaires";
    }
    const { listAllHotel, listHotelPartner, listPartnershipRequest_1, listPartnershipRequest_5 } = this.props.hotels;
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Les hôtels</h1>
            </div>
          </div>
          <div className="main-catalogue">

            <TabProvider
              defaultTab={defaultTab()}
              //onChange={(tabId) => { this.renderDmdPartenariat(tabId) }}
            >
              <section className="my-tabs">
                <TabList className="my-tablist">
                  <div className="wrapper">
                    {
                      sellerType !== 3 && <Tab tabFor="hotelPartenaires">Hôtels partenaires <span>{listHotelPartner && "total" in listHotelPartner ? listHotelPartner.total : 0} </span></Tab>
                    }
                    <Tab tabFor="TousHotels">Tous les hôtels <span>{listAllHotel && "total" in listAllHotel ? listAllHotel.total : 0}</span></Tab>
                    {
                      sellerType !== 3 && <Tab tabFor="dmdPartenariat">Demandes de partenariat <span>{listPartnershipRequest_1 && listPartnershipRequest_5 && "total" in listPartnershipRequest_1 && "total" in listPartnershipRequest_5 ? (listPartnershipRequest_1.total + listPartnershipRequest_5.total) : 0}</span></Tab>
                    }
                    
                  </div>
                </TabList>
                <div className="wrapper cnt-tabs">
                  {
                    sellerType !== 3 &&
                    <TabPanel tabId="hotelPartenaires">
                      <HotelPartenaire
                        setLoadingTrue={this.setLoadingTrue}
                        setLoadingFalse={this.setLoadingFalse}
                        getAllData={this.getAllData}
                      />
                    </TabPanel>
                  }
                  <TabPanel tabId="TousHotels">
                    <TousLesHotels
                      setLoadingTrue={this.setLoadingTrue}
                      setLoadingFalse={this.setLoadingFalse}
                      getAllData={this.getAllData}
                    />
                  </TabPanel>
                  {
                    sellerType !== 3 &&
                    <TabPanel tabId="dmdPartenariat">
                      <div className="main-tabs content-accordeon">
                        <Accordion atomic={false}>
                          <AccordionItem title="En attente de votre réponse">
                            <AttenteReponse
                              setLoadingTrue={this.setLoadingTrue}
                              setLoadingFalse={this.setLoadingFalse}
                              getAllData={this.getAllData}
                            />
                          </AccordionItem>
                          <AccordionItem title="En attente de la réponse hôtel">
                            <ReponseHotel
                              setLoadingTrue={this.setLoadingTrue}
                              setLoadingFalse={this.setLoadingFalse}
                              getAllData={this.getAllData}
                            />
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </TabPanel>
                  }
                </div>
              </section>
            </TabProvider>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(Hotel);

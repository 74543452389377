import React from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';

import MdpOublieController, { reduxConnect } from './controller'

class MdpOublie extends MdpOublieController {

  render() {
    return (
      <div>
         <LoadingOverlay
          active={this.state.isLoading}s
          spinner
          text='Chargement'
        >
        <div className="header">
          <div className="wrapper clr">
            <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
          </div>
        </div>
        <div className="page">
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Mot de passe oublié</h1>
            </div>
          </div>
        </div>
          <div className="main">
            <div className="wrapper clr">
              <form className="block-form" onSubmit={this.handleSubmit}>
                <div className="line-input">
                  <p>Remplissez le formulaire ci-dessous et nous vous enverrons par email les informations pour vous créer un nouveau mot de passe.</p>
                  <label>Adresse mail*</label>
                  <div className="cnt-input with-icon">
                    <input
                      type="text"
                      value={this.state.userEmail}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="userEmail"
                      placeholder="adresse mail"
                    />
                    <span className="edit"><i className="material-icons icon-email">email</i></span>
                  </div>
                  {
                    this.state.errors.userEmail.length > 0 &&
                    this.state.errors.userEmail !== 'default' &&
                    <span className='error'>{this.state.errors.userEmail}</span>
                  }
                </div>
                <div className="cnt-Btn">
                  <input
                    type="submit"
                    className={`button ${this.state.errors.userEmail === "" ? '' : 'buttonIsInvalid'}`}
                    disabled={this.state.errors.userEmail === "" ? false : true}
                    value="Retrouver mon mot de passe"
                  />
                   
                  <div className="clr">
                  </div></div>
              </form>
            </div>
          </div>
          <div className="wrapper-modal">
            <Modal
              visible={this.state.visible}
              width="400"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="content-modal">
                <div className="head-modal">
                  <Link to="" className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></Link>
                  <h1>{this.state.titleShow}</h1>
                </div>
                <div className="main-modal">
                <p>{this.state.textShow}</p>
                  <div className="cnt-Btn align-right">
                    {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                    <button className="button" onClick={() => this.redirectSuccess()}>Ok</button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(MdpOublie);

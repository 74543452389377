import React from 'react';
import { Link } from 'react-router-dom';
import Currency from 'react-currency-formatter';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import ReactToPdf from "react-to-pdf";

import CommandeController, { reduxConnect } from './controller';

import Headers from "../../../components/headers/headers";
import Footer from "../../../components/footer/footer";
import PopupHandler from "../../../components/popup/popupHandler";


class DetailCommande extends CommandeController {
  render() {
    const order = this.state.order.invoice_order || {};
    const invoice = this.state.order.invoice || {};
    const customer = this.state.order.invoice_client_info || {};
    const items = this.state.order.invoice_items || [];
    const price = this.state.order.invoice_price || {};
    const currencyCode = (price && price.currency_code) || 'EUR';
    const hotel = this.state.order.invoice_hotel_info || {};
    const ref = React.createRef();
    const options = {
      
    };
    
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              {/* <h1><button onClick={this.downloadFile}><span className="back"><i className="material-icons icon-back">arrow_back</i></span></button> Votre facture</h1> */}
              <h1><Link to="/commande"><span className="back"><i className="material-icons icon-back">arrow_back</i></span></Link> Votre facture</h1>
            </div>
          </div>
          <div>
            {this.state.downloadError && PopupHandler.showError("Le téléchargement de la facture a échoué.")}
            {this.state.downloadError === false && PopupHandler.showSuccess("Succès du téléchargement de la facture.")}
          </div>
          <div className="main-commande">
            <div className="wrapper clr">
              <div className="mainBody">
                <div className="cnt-Btn align-right">

                <ReactToPdf targetRef={ref} filename="facture.pdf" options={options} x={8} y={10} scale={0.9} >
                  {({toPdf}) => (
                      <button onClick={toPdf} className="button" id="invoicePdf">{"Télécharger votre facture"}</button>
                  )}
                </ReactToPdf>
                  {/* <span onClick={this.generateInvoice} className="button">{"Télécharger votre facture"}</span> */}
                  <div className="clr"></div>
                </div>

                <div ref={ref} className="wrapper-factureToPdf">
                  <div className="infos-commande">
                  <div className="head-det-commande">
                    <p className="nameClient"><span className="ico"><i className="material-icons icon-person">description</i></span> Facture n° {invoice.invoice_number || ""}<span className="statut">Payer</span></p>
                    <p className="date-commande"><span className="ico"><i className="material-icons icon-person">date_range</i></span> {order.order_date ? moment(order.order_date).format('DD/MM/YYYY hh:mm') : ""}</p>
                    <p>{hotel.hotel_name}</p>
                    <p className="num-commande">Commande n° {order.order_number || ""}</p>
                    <p className="transaction-Commande">Transaction n° {price.transaction || ""}</p>
                  </div>
                  <div className="row">
                    <div className="col col-6 info-client-commande">
                    </div>
                    <div className="col col-6 info-client-commande">
                        <p className="nameClient">{customer.customer_name || ""}</p>
                        <p className="mailClient">{customer.customer_email || ""}</p>
                        <p className="adressClient">{`${customer.street || ""}, ${customer.postcode || ""} ${customer.region || ""} ${customer.city || ""}`}</p>
                        <p className="telClient">{customer.telephone || ""}</p>
                    </div>
                  </div>
                </div>
                  <div className="recap-commande">
                  <div className="titre-recap-commande">
                    {/* Recapitulatif de la commande / {this.state.prestationType} / {this.state.prestationPlace} */}
                    Recapitulatif de la commande
                  </div>
                  <div className="table table-commande">
                    <div className="table-responsive scrollbar">
                      <table>
                        <thead>
                          <tr>
                            <th>Nom produit</th>
                            <th className="th-date">Date de la prestation</th>
                            <th className="th-qtt">Quantité</th>
                            <th className="th-montant">Prix</th>
                            <th className="th-reponse">Montant</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items && items.map(item =>(
                            <tr>
                                <td><span className="name-prestation">{item.prestation}</span><p>{item.type_prestation}</p></td>
                                <td>{item.date_start_prestation ? moment(item.date_start_prestation).format('DD/MM/YYYY HH:mm') : ""}</td>
                                <td>{(item.qty_invoiced && parseFloat(item.qty_invoiced) > 0 && parseFloat(item.qty_invoiced).toFixed(2)) || 1}</td>
                                <td> 
                                    <Currency
                                        quantity={item.price ? parseFloat(item.price) : 0}
                                        currency={currencyCode}
                                    />
                                </td>
                                <td className="align-right"> 
                                    <Currency
                                        quantity={item.row_total ? parseFloat(item.row_total) : 0}
                                        currency={currencyCode}
                                    />
                                </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6">&nbsp;</div>
                    <div className="col col-6 table table-commande">
                      <table>
                        <tbody>
                          <tr>
                            <td className="font-bold">{"Sous-total"} </td>
                            <td className="align-right"> 
                                <Currency
                                    quantity={price.subtotal ? parseFloat(price.subtotal) : 0}
                                    currency={currencyCode}
                                />
                            </td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"Montant HT"}</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={price.total_HT ? parseFloat(price.total_HT) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"TVA"}</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={price.tax_amount ? parseFloat(price.tax_amount) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"Taux TVA"}</td>
                              <td className="align-right">{(price.taux_tva && parseFloat(price.taux_tva).toFixed(2)) || 0} %</td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"Montant TTC"}</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={price.total_TTC ? parseFloat(price.total_TTC) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"Remise"}</td>
                              <td className="align-right">{(price.discount_amount && parseFloat(price.discount_amount).toFixed(2)) || 0} %</td>
                          </tr>
                          <tr>
                              <td className="font-bold">{"Montant remboursé"}</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={price.total_refunded ? parseFloat(price.total_refunded) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr className="total-commande">
                              <td className="font-bold">{"Total"}</td>
                              <td className="align-right font-bold">
                                  <Currency
                                      quantity={price.total ? parseFloat(price.total) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(DetailCommande);

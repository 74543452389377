import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="wrapper clr">
          <div className="row">
            <div className="col col-3 logo"><a href="/" className="img-logo">&nbsp;</a></div>
            <div className="col col-2">
              <ul className="menu-footer">
                <li>
                  <a
                     href="mailto:admin@myconciergehotel.com" 
                     target="_blank" 
                     rel="noopener noreferrer"
                  >Contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.myconciergehotel.com/faq-hotels/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.myconciergehotel.com/conditions-generales-d-utilisation-mch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >CGU
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.myconciergehotel.com/conditions-generales-de-vente-mch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >CGV
                  </a>
                </li>
              </ul>
            </div>
            <div className="col col-4">
              <ul className="menu-footer">
                <li>
                  <a
                    href="https://www.myconciergehotel.com/mentions-legales-mch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Mentions légales
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.myconciergehotel.com/politique-de-confidentialite-mch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Politiques de confidentialité
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
export default Footer;
import { get, post, put} from '.';
import { url } from '../redux/constants/commons'

const testResponse = (response) => {
    if (!response || !response.data) return false;
    return response.data;
}

const testVoidResponse = (response) => {
    const data  = (response && response.status) ? response : (response && response.response) || null;
    return data;
  }

export default {
    
    getAllCategory : async () => {
        const response = await get(url.category);
        return testResponse(response);
    },

    getAllKeywords : async () => {
        const response = await get(url.keywords);
        return testResponse(response);
    },

    login : async (data) => {
        const response = await post(url.login,data);
        return response;
    },

    setUserPassword : async (data,token) => {
        const response = await post(url.setUserPassword,data,token);
        return response;
    },

    forgetPassword : async (data) => {
        const response = await post(url.forgetPassword,data);
        return response;
    },

    checkToken : async (data) => {
        const response = await post(url.checkToken, data);
        return response;
    },

    getHotelCategories : async (codeHotel) => {
        const response = await get(`${url.hotelCategories}/${codeHotel}`);
        return testVoidResponse(response);
    },

    addOption : async (idPrestation, options) => {
        const response = await put(`${url.addOption}/${idPrestation}`, options);
        return testVoidResponse(response);
    },

    getAllDeliveryPlaces : async () => {
        const response = await get(url.deliveryPlaces);
        return testVoidResponse(response);
    },

    addDeliveryPlace : async (data) => {
        const response = await put(url.addDeliveryPlace, data);
        return testVoidResponse(response);
    },
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import getColors from 'get-image-colors'

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";


class InformationBancaireController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankUserName: "",
      bankUserBirthDay: "",
      bankIban: "",
      bankBic: "",
      bankStatus: "En erreur",

      accountName: "",
      creationDate: null,
      numBic: "",
      numIban: "",
      status: "",

      errors: {
        accountName: "default",
        numBic: "default",
        numIban: "default",
        // status: "default",
        creationDate: "default",

      },
      identityFile: "",
      kbisFile: "",
      kbisFileInput: "",
      identityFileInput: "",
      identityFileStatus: "",
      kbisFileStatus: "",
      isLoading: false,
      showInput: true,
      fileData: null,
      kbisFormatError: false,
      kbisSizeError: false,
      kbisImgDimensionError: false,
      identityFormatError: false,
      identitySizeError: false,
      identityImgDimensionError: false,
      identityGreyScaleError: false,
      kbisGreyScaleError: false,
      role: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
  }
  toggleInput = () => this.setState({ showInput: !this.state.showInput })

  handleIndentyFileInput = async(e)=>{
    const file = e.target.files[0];
    if (!file) {
      return
    }
    const url = URL.createObjectURL(file);
    const identitySizeError= file.size / 1024  > 10240;
    const extension = this.getExtension(file.name);
    const identityFormatError = (extension !== "jpeg" && extension !== "jpg" && extension !== "png") || false;
    let identityImgDimensionError = false;
    this.setState({
      identityFormatError,
      identitySizeError
    });
    if (identitySizeError || identityFormatError) {
        return
    }
    const identityGreyScaleError = await this.isGreyScaleImage(url);
    this.setState({
      identityGreyScaleError
    })
    if (!identityGreyScaleError) {
      const img = new Image();
      img.addEventListener('load', () => {
          identityImgDimensionError = img.width > 8000 || img.height > 8000;
          this.setState({
            identityImgDimensionError
          });
          if (!identityImgDimensionError) {
            this.readAsDataURL(file, "identityFile")
          }
      });
      img.src = url;
    }
  }

  handlekbisFileInput = async(e) =>{
    const file = e.target.files[0];
    if (!file) {
      return
    }
    const url = URL.createObjectURL(file);
    const kbisSizeError= file.size / 1024  > 10240;
    const extension = this.getExtension(file.name);
    const kbisFormatError = (extension !== "jpeg" && extension !== "jpg" && extension !== "png" && extension !== "pdf") || false;
    let kbisImgDimensionError = false;
    this.setState({
      kbisFormatError,
      kbisSizeError,
      kbisImgDimensionError,
    });
    if (kbisSizeError || kbisFormatError) {
        return
    }
    if (extension === "jpeg" || extension === "jpg" || extension === "png") {
      const kbisGreyScaleError = await this.isGreyScaleImage(url);
      this.setState({
        kbisGreyScaleError
      })
      if (!kbisGreyScaleError) {
        const img = new Image();
        img.addEventListener('load', () => {
            kbisImgDimensionError = img.width > 8000 || img.height > 8000;
            this.setState({
              kbisImgDimensionError,
            });
            if (!kbisImgDimensionError) {
              this.readAsDataURL(file, "kbisFile")
            }
        });
        img.src = url;
      }
    }else{
      this.readAsDataURL(file, "kbisFile")
    }
  }

  isGreyScaleImage = async (url)=>{
    const options = {
      count: 10,
    }
    let isGreyScale = true;
    await getColors(url, options).then(colors => {
      for (let index = 0; index < colors.length; index++) {
        const color = colors[index].rgb();
        if (color[0] !== color[1] || color[0] !== color[2] || color[1] !== color[2]) {
          isGreyScale = false;
          break
        }
      }
    })
    return isGreyScale;
  }
  

  getExtension = (name)=>{
    const filename = name.split(".");
    return filename.length > 1 ? filename[filename.length - 1] : "";
  }

  readAsDataURL = (file, type) =>{
    const reader = new FileReader();
    reader.onloadend = () => {
      if (type === "kbisFile") {
        this.setState({ 
          kbisFileInput: reader.result,
          kbisFileName: file.name,
          kbisFile: URL.createObjectURL(file),
          kbisFileChanged: true,
          kbisFileRemoved: false,
        })
      }else{
        this.setState({ 
          identityFileInput: reader.result,
          identityFileName: file.name,
          identityFile: URL.createObjectURL(file),
          identityFileChanged: true,
          identityFileRemoved: false,
        })
      }
    }
    reader.readAsDataURL(file);
  }

  removeFile =type =>{
    if (type === "kbisFile") {
      this.setState({ 
        kbisFileInput: null,
        kbisFileName: "",
        kbisFile: null,
        kbisFileChanged: false,
        kbisFileRemoved: true,
      })
    }else{
      this.setState({ 
        identityFileInput: null,
        identityFileName: "",
        identityFile: null,
        identityFileChanged: false,
        identityFileRemoved: true,
      })
    }
  }

  callBack = async (data, type) => {
    const {steps} =this.props.dashboard;
    const fromHeadBand= (this.props.location.state && this.props.location.state.fromHeadBand) || false;
    const isRestaurant = this.state.role === 'ROLE_SELLER_RESTAURENT'
    if (type && type === "update") {
      const updateError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        updateError,
      })
      if (data && data.data && (data.data.code === 500 || data.data.code === 400) && data.data.message) {
        showPopupExtra(<PopupError title="Erreur" message={data.data.message}/>);
        this.props.setLoadingFalse();
      }else if (updateError) {
        this.props.setLoadingFalse();
      }else if (!updateError) {
        if (this.state.fileData) {
          await this.props.uploadBankFile(this.state.fileData, this.callBack);
          this.setState({
            fileData: null,
          })
        }else{
          if (!steps.includes("3b") && (isRestaurant || (this.props.bank.bankInfo && this.props.bank.bankInfo.identityFile && this.props.bank.bankInfo.kbisFile))) {
            await this.props.addSteps({steps: ["3b"]},this.callBack);
          }else if (steps.includes("3a") && steps.includes("3b") && fromHeadBand) {
            this.props.setLoadingFalse();
            this.props.history.push("/dashboard");
          }else{
            await this.props.getProgressionEtablissement(this.callBack)
          }
        }
      }
    }else if (type && type === "updateBank") {
      const uploadError = data && data.status === 200 ? false : true;
      this.setState({
        uploadError,
      })
      if (!uploadError) {
        if (!steps.includes("3b")) {
          await this.props.addSteps({steps: ["3b"]},this.callBack);
        }else if (steps.includes("3a") && fromHeadBand) {
          this.props.setLoadingFalse();
          this.props.history.push("/dashboard");
        }else{
          await this.props.verifyBankFile(this.callBack);
        }
      }
      this.setState({
        kbisFileInput: "",
        identityFileInput: "",
      });
    }else if(type==='verifyBankFile'){
      const fileError = data && data.status === 200 ? false : true;
      this.setState({
        fileError,
      })
      await this.props.getProgressionEtablissement(this.callBack)
    }else if(type==='progression'){
      this.props.getBankInfo(this.callBack);
    }else if(type==='getBankInfo'){
      this.props.setLoadingFalse();
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      const stepsResponse = (data && data.data && data.data && data.data.steps) || [];
      this.setState({
        addStepError,
      })
      if (!isRestaurant) {
        this.props.getBankInfo(this.callBack);
      }else{
        this.props.setLoadingFalse();
      }
      if(!addStepError && stepsResponse.includes("3a") && fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }
  }

  componentDidMount() {
    const { bankInfoFile, bankInfo } = this.props.bank;
    const role = this.props.commons.infoLogin.roles[0];
    if (bankInfoFile) {
      this.setState({
        ...bankInfoFile,
      });
    }
    if (role) {
      this.setState({
        role : role
      });
    }

    if (bankInfo) {
      this.setState({
        ...bankInfo,
        creationDate: bankInfo.creationDate ? this.formatDate(bankInfo.creationDate) : "",
      });
      for (let [key, value] of Object.entries(bankInfo)) {
        key && value && this.testErrors(key, value)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const prevBankInfo = prevProps.bank.bankInfo;
    const propsBankInfo = this.props.bank.bankInfo;
    const prevBankInfFile = prevProps.bank.bankInfoFile;
    const propsBankInfoFile = this.props.bank.bankInfoFile;

    if (!prevBankInfo || !prevBankInfFile) {
      return;
    }
    if (prevBankInfo !== propsBankInfo) {
      this.setState({
        ...propsBankInfo,
        creationDate: propsBankInfo.creationDate ? this.formatDate(propsBankInfo.creationDate) : "",
      });
      for (let [key, value] of Object.entries(propsBankInfo)) {
        key && value && this.testErrors(key, value)
      }
    }
    if (prevBankInfFile !== propsBankInfoFile) {
      this.setState({
        ...propsBankInfoFile,
        creationDate: propsBankInfo.creationDate ? this.formatDate(propsBankInfo.creationDate) : "",
      });
      for (let [key, value] of Object.entries(propsBankInfo)) {
        key && value && this.testErrors(key, value)
      }
    }
  }

  formatDate = (date) => {
    let d = new Date(date);
    let dt = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    let mn = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    let yy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    return yy + "-" + mn + "-" + dt
  }

  testErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case 'accountName':
        errors.accountName = this.props.errors.nameErrors(value);
        break;
      case 'creationDate':
        errors.creationDate = this.props.errors.notEmptyInput(value);
        break;
      case 'numIban':
        errors.numIban = this.props.errors.notEmptyInput(value) || this.props.errors.ibanValid(value);
        break;
      case 'numBic':
        errors.numBic = this.props.errors.notEmptyInput(value) || this.props.errors.bicValid(value);
        break;
      // case 'status':
      //   errors.status = this.props.errors.notEmptyInput(value);
      //   break;
      default:
        break;
    }
    this.setState({ errors });
  }



  isNotVoid = () => {
    const {
      accountName,
      numBic,
      numIban,
      // status,
      creationDate,
    } = this.state.errors;
    return accountName === "" && numBic === "" && numIban === "" && creationDate === ""
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    const dataIdentity = {
      accountName: this.state.accountName,
      creationDate: this.state.creationDate,
      numIban: this.state.numIban,
      numBic: this.state.numBic,
      status: this.state.status,
    }
    this.props.setLoadingTrue()
      let data = {
        identityFile: null,
        kbisFile: null,
      };
      if (!this.state.identityFileRemoved) {
        data.identityFile = {
          name: this.state.identityFileName,
          data: this.state.identityFileChanged ? this.state.identityFileInput : null
        }
      }
  
      if (!this.state.kbisFileRemoved) {
        data.kbisFile = {
          name: this.state.kbisFileName,
          data: this.state.kbisFileChanged ? this.state.kbisFileInput : null
        }
      }
      if (this.state.identityFileChanged || this.state.kbisFileChanged || this.state.identityFileRemoved || this.state.kbisFileRemoved) {
        this.setState({
          fileData: data,
        })
      }
    
    this.props.sendBankInfo(dataIdentity, this.callBack);
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event)
    this.setState({ [name]: event.target.value });
  }
  updateDayPick = (date) => {
    let errors = this.state.errors;
    const birthDay = moment().add('year', -18);
    const errorBirthdate = new Date(date) > birthDay ? "Le titulaire du compte doit avoir au moins 18ans." : ""
    errors.creationDate = this.props.errors.notEmptyInput(date) ||  errorBirthdate;
    this.setState({ errors, creationDate: date });
  }
  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'accountName':
        errors.accountName = this.props.errors.notEmptyInput(value);
        break;
      case 'creationDate':
        errors.creationDate = this.props.errors.notEmptyInput(value);
        break;
      case 'numIban':
        errors.numIban = this.props.errors.notEmptyInput(value)  || this.props.errors.ibanValid(value);;
        break;
      case 'numBic':
        errors.numBic = this.props.errors.notEmptyInput(value) || this.props.errors.bicValid(value);;
        break;
      // case 'status':
      //   errors.status = this.props.errors.notEmptyInput(value);
      //   break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  expandCollapse = (event, index, item) => {
    const url = event ? event.target.dataset.url : item;
    const element = document.getElementsByClassName('accordion-item')[index];
    const el = document.querySelector(url);
    if (element && element.lastChild.clientHeight === 0) {
      event && event.preventDefault();
      element.firstChild.click();
      setTimeout(() => {
        el.scrollIntoView()
      }, 500)
    }
  }

}

export default InformationBancaireController;

//
// Redux connection
//

InformationBancaireController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
export const GET_ALL_EVENT = 'GET_ALL_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const SEARCH_EVENT = 'SEARCH_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const EXPORT_EVENT = 'EXPORT_EVENT';
export const GET_GOOGLE_ACCESS = 'GET_GOOGLE_ACCESS';
export const SET_SYNC = 'SET_SYNC';
export const SET_UNSYNC = 'SET_UNSYNC';
export const STATE_SYNC = 'STATE_SYNC';
export const LOGOUT = 'LOGOUT';

export const url = {
    agendaEvent: '/calendar/event',
    exportEvent: '/calendar/event/export',
    searchEvent: '/calendar/event?filter[title]=vac&itemPerPage=5',
    getGoogleAccessToken: '/calendar/google/auth',
    syncToCalendar: '/calendar/google/sync',
    unsyncToCalendar: '/calendar/google/unsync',
    stateSync: '/calendar/is-sync',
  };

export default {
    GET_ALL_EVENT,
    ADD_EVENT,
    SEARCH_EVENT,
    REMOVE_EVENT,
    EXPORT_EVENT,
    GET_GOOGLE_ACCESS,
    SET_SYNC,
    SET_UNSYNC,
    STATE_SYNC,
    LOGOUT,
}
import React from 'react';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import {hidePopUp} from "../../../components/popup/popup";
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";

const isNotVoid = (text) => {
  return text && text.length > 0;
}

const isNotVoidArray = (text) => {
  return isNotVoid(text) && text[0];
}

const isNotVoidNumber = (text) => {
  const value = text ? `${text}`.replace(',','.') : ""
  return value && value > 0;
}

const toCovertH = data => {
  const num = data / 60;
  if (num >= 1) {
    const sp = Math.ceil(num);
    const isModulo = data % 60 === 0;
    return ({
      h: isModulo ? sp : sp - 1,
      m: isModulo ? 0 : data % 60,
    })
  }
  return ({
    h: 0,
    m: data,
  });
}

const NAME_LENGTH = 30;
const PRICE_LENGTH = 12;
const DESCRIPTION_LENGTH = 2000;

class FichePrestationController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      id: null,
      category: '',
      keywords: [],
      allKeywords: [],
      description: "",
      name: '',
      reduction: 0,
      price: 0,
      prestationOffert: null,
      duration: 0,
      hotels: [],
      subCategories: [],
      listHotelPartener: [],
      prestationCategory: "",
      addOption: 'No',
      addReduction: false,
      idCreate: true,
      options: [],
      prestationSubCategory: [],
      allowPrestationDuration: false,
      allowAddOption: false,
      durationHour: "0",
      durationMinute: "0",
      acceptAdvatageCustomer: "",
      reductionValue: "0",
      productToOffer: "",
      action: "",
      hasError: false,
      hasLoadingError: false,
      errorsFromServer: "",
      giveReduction: false,
      giveOffertProduct: false,
      lastIndex: 0,
      subSubCategories: [],

      errors: {
        name: "default",
        price: "default",
        category: "default",
        duration: "default",
        description: "default",
        reduction: "default",
        prestationOffert: "default",
        options: "default",
        subCategories: "default",
        //    "name": name,
        // "price": parseInt(price),
        // "specialPrice": specialPrice,
        // "minTime": "08:00",
        // "maxTime": "22:00",
        // "category": category,
        // "fromDay": 1,
        // "toDay": 5,
        // "duration": duration,
        // "description": description,
        // "reduction": reduction.toString(),
        // "prestationOffert": prestationOffert,
        // "subCategories": subCategories,
        // "options": options,
      },
      isLoading: false,
    }

    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleProviderOptionsChange = this.handleProviderOptionsChange.bind(this);
  }

  isNotVoid = () => {
    const {
      name,
      price,
      category,
      duration,
      description,
      reduction,
      prestationOffert,
      options,
      subCategories,
    } = this.state.errors;
    return category === "" && subCategories === "" && name === "" && (this.state.allowPrestationDuration ? duration === "" : true) && description === "" && (this.state.addOption ? options === "" : true) && price === "" && (this.state.giveOffertProduct ? prestationOffert === "" : true) && (this.state.giveReduction ? reduction === "" : true)
  }

  hasErrorOptions = () => {
    let hasErrorOptions = false;
    if (this.state.options && this.state.options.length > 0) {
      for (let index = 0; index < this.state.options.length; index++) {
        const option = this.state.options[index];
        const {description, price} = option.errors;
        if (description || price) {
          hasErrorOptions = true;
          break
        }
      }
    }
    return hasErrorOptions;
  }

  async componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    const { listPrestation } = this.props.prestation;
    const { pathname } = this.props.location;
    if (!pathname) return;
    const urlSplit = pathname.split('/');
    if (!urlSplit) return;
    const id = urlSplit[urlSplit.length - 1];
    if (id && listPrestation) {
      const item = listPrestation &&listPrestation.data && Array.isArray(listPrestation.data) && listPrestation.data.find(k => k.id.toString() === id.toString());
      if (item && item.id) {
        this.setState({
          ...item,
          options: this.addOptionExtraFields(item.options),
          durationHour: `${toCovertH(item.duration).h && toCovertH(item.duration).h}`,
          durationMinute: `${toCovertH(item.duration).m && toCovertH(item.duration).m}`,
          allowPrestationDuration: item.duration && item.duration > 0 ? true : false,
          giveReduction: item.reduction && parseInt(item.reduction) > 0 ? true : false,
          giveOffertProduct: item.prestationOffert ? true : false,
          addOption: item.options.length > 0 ? "Yes" : "No",
          keywords: item.keywords || [],
          idCreate: false,
        }, () =>{
          this.addOptionExtraFields();
          if (this.hasLevel3Category()) {
            this.setSubCategories();
          }
        });
      }
      for (let [key, value] of Object.entries(listPrestation)) {
        key && value && this.testErrors(key, value)
      }
    }
    this.setState({ allKeywords: this.props.commons.allKeywords || [] });
  }

  reductionChange = (event) => {
    this.setState({
      giveReduction: event.target.checked,
    });
  }

  onChangeListHotelPartener = (id, isChecked) => {
    const { hotels } = this.state;
    let data = hotels;
    if (!hotels) {
      data = []
    }
    this.setState({
      hotels: isChecked ? data.filter(i => i !== id) : [...data, id],
    });
  }

  toogleChangePrestationOffert = (event) => {
    this.setState({
      giveOffertProduct: event.target.checked,
    });
  }

  handleDropdownChangeReduction = (item) => {
    if (item && item.label) {
      const reduction = parseInt(item.label.split('%')[0]);
      this.setState({ reduction });
    }
  }

  // setAction = () => {
  //     if (this.props.location && this.props.location.pathname) {
  //         const res = this.props.location.pathname.split('/');
  //         if(res[res.length - 1]==="new")
  //         {
  //             return "new";
  //         }
  //         else{
  //             return "update";
  //         }
  //         return res[res.length - 1];
  //     }

  // }

  handleProviderOptionsChange(value) {
    let errors = this.state.errors;
    if (!(this.state.providerOptions.includes(value.value))) {
      this.setState({ errors, 'userProvider': "" });
    } else {
      this.setState({ errors, 'userProvider': "Valeur non disponible" });
    }
    this.setState({ providerOptionsValue: value.value });
  }

  isValidForm = () => {
    const {
      name, price, category, description, subCategories, selectedSubCategory, subSubCategories
    } = this.state;
    const hasLevel3 = this.hasLevel3Category();
    return isNotVoid(name) && isNotVoidNumber(price) && (hasLevel3 ? (selectedSubCategory && isNotVoidArray(subSubCategories)): isNotVoidArray(subCategories)) && isNotVoid(description) && isNotVoidNumber(category) && !this.hasErrorOptions();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { idSeller } = this.props.sellers;
    if (this.isValidForm()) {
      const {
        name, price, specialPrice, category, duration, description, reduction, prestationOffert, options, id, hotels, keywords, selectedSubCategory, subSubCategories
      } = this.state;
      let subCategories = this.state.subCategories;
      let optionsToSave = 
        [...options].map(option=>{
          option.price = parseFloat(`${option.price}`.replace(',','.'));
          return option
        })
      const hasLevel3Category = this.hasLevel3Category();

      if (hasLevel3Category) {
        subCategories = selectedSubCategory ? [parseInt(selectedSubCategory.id)] : [];
        subSubCategories && subSubCategories.length > 0 && 
          subSubCategories.forEach(subSubCategory => {
            subCategories.push(parseInt(subSubCategory.id))
          })
      }
      
      const data1 = {
        "name": name,
        "price": parseFloat(`${price}`.replace(',','.')),
        "specialPrice": specialPrice,
        "minTime": "08:00",
        "maxTime": "22:00",
        "category": category,
        "fromDay": 1,
        "toDay": 5,
        "duration": duration,
        "description": description,
        "reduction": reduction ? reduction.toString() : "0",
        "prestationOffert": prestationOffert,
        "subCategories": subCategories,
        "options": optionsToSave,
        "hotels": hotels && hotels.length > 0 ? hotels : [],
        "keywords": keywords
      }
      this.setState({ isLoading: true });
      id ? this.props.updateFullPrestation(data1, idSeller.id, id, this.callBack) : this.props.createPrestation(data1, idSeller.id, this.callBack);
    }
  }

  callBack = (data, type) => {
    this.setState({ isLoading: false });
    const response = data.response;
    if (type === "update" || type === "remove" || type === "create") {
      hidePopUp();
      let removeError = type === "remove" ? (data && (data.status === 200 || data.status === 204)? false : true) : null;
      let saveError = type === "update" || type === "create" ? (data ? false : true): null;
      this.setState({
        removeError,
        saveError
      })
      if (data && (type === "update" || type === "create")) {
        data.status = 200
      }
      if (response && response.status === 400 && response.data && response.data.num_command) {
        showPopupExtra(<PopupError title="Erreur sur la suppression" message="Cette prestation est liée à une commande en cours"/>)
      }else if(data && (data.status === 200 || data.status === 204 || data.status === 201)){
        const { history } = this.props;
        if (history) history.push({pathname: "/etablissement-institut/Prestations", state: {saveError, removeError}});
      }
    }
  }

  handleDropdownChange(value, name) {
    this.setState({ [name]: value.value });
  }

  async toogleChange(event, name) {
    await this.setState({ [name]: event.target.checked });
  }
  updateStateSimple = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name==="description" && value.length > DESCRIPTION_LENGTH) {
      return
    }
    this.handleUserInput(event);
    this.setState({ [name]: value });
  }


  async updateState(event, actor) {
    switch (actor) {
      case 'prestationSubCategory':
        await this.setState({ [actor]: event.label });
        break;
      case 'name':
        if (event.target.value.length > NAME_LENGTH) {
          return;
        }
        await this.setState({ [actor]: event.target.value });
        break;
      case 'prestationCategory':
        await this.setState({ [actor]: event.label });
        break;
      case 'allowAddOption':
        await this.setState({ [actor]: event.target.value });
        break;
      case 'description':
        if (event.target.value.length > 500) {
          return;
        }
        this.setState({ [actor]: event.target.value });
        break;
      case 'addOption':
        await this.setState({ [actor]: event.target.value });
        break;
      case 'price':
        if (event.target.value.length > PRICE_LENGTH) {
          return;
        }
        await this.setState({ [actor]: event.target.value });
        break;
      case 'productToOffer':
        await this.setState({ [actor]: event.label });
        break;
      default:
        break;
    }

  }

  setSubCategories = () => {
    const { category, subCategories } = this.state;
    const { listCategories } = this.props.commons;
    const categorie = listCategories.find(j => j.id.toString() === category.toString());
    let selectedSubCategory = null;
    let selectedSubCategoryId = null;
    let subSubCategories = [];
    if (categorie && categorie.children && categorie.children.length > 0) {
      const subCategoryList = categorie.children;
      for (let index = 0; index < subCategoryList.length; index++) {
        const currentSubCategory = subCategoryList[index];
        if (subCategories && subCategories.length > 0) {
          if(subCategories.includes(parseInt(currentSubCategory.id))){
            selectedSubCategory = currentSubCategory;
            selectedSubCategoryId = currentSubCategory.id;
          }
          if (currentSubCategory.children && currentSubCategory.children.length > 0) {
            currentSubCategory.children.forEach(element =>{
              if(subCategories.includes(parseInt(element.id))){
                subSubCategories.push(element);
              }
            });
          }
        }
      }
    }
    this.setState({
      selectedSubCategory,
      selectedSubCategoryId,
      subSubCategories
    })
  }

  hasLevel3Category = () => {
    const { category } = this.state;
    const { listCategories } = this.props.commons;
    const categorie = listCategories.find(j => j.id.toString() === category.toString());
    let hasLevel3 = false;
    if (categorie && categorie.children && categorie.children.length > 0) {
      const subCategories = categorie.children;
      for (let index = 0; index < subCategories.length; index++) {
        const subCategory = subCategories[index];
        if (subCategory.children && subCategory.children.length > 0) {
          hasLevel3 = true;
          break
        }
      }
    }
    return hasLevel3
  }

  onSelect = (value) => {
    const { category, subCategories } = this.state;
    const { listCategories } = this.props.commons;
    const categorie = listCategories.find(j => j.id.toString() === category.toString());
    const SousCategoriePrestation = categorie ? categorie.children : [];
    const set1 = new Set(subCategories);
    const subCategoriesState = SousCategoriePrestation.reduce((acc, curr) => {
      if (curr.name === value.label) {
        const id = parseInt(curr.id);
        if (set1.has(id)) {
          return subCategories.filter(i => i !== id)
        }
        return [...acc, id];
      }
      return acc;
    }, subCategories);
    this.setState({ subCategories: subCategoriesState, selectedSubCategoryId: null , selectedSubCategory: null, subSubCategories: [] });
  }

  onSelectSubCategory = (value) => {
    const { category } = this.state;
    const { listCategories } = this.props.commons;
    const categorie = listCategories.find(j => j.id.toString() === category.toString());
    if (categorie && categorie.children && categorie.children.length > 0) {
      const selectedSubCategory = categorie.children.find(element => element.id.toString() === value.value.toString());
      this.setState({
        selectedSubCategory,
        selectedSubCategoryId: value,
        subCategories: [],
        subSubCategories: [],
      })
    }
  }

  getOptionsSubSubCategory = () => {
    return this.state.selectedSubCategory.children.filter(item => [].concat(this.state.subSubCategories).map(i => i ? i.id.toString(): '').includes(item.id) === false).map(i => ({value: i.id, label: i.name}));
  }

  addSubSubCategory = (value) => {
    const subSubCategory = this.state.selectedSubCategory.children.find(subSubCategory=>(
      subSubCategory.id.toString() === value.value
    ))
    const subSubCategories = [...this.state.subSubCategories, subSubCategory];
    this.setState({ subSubCategories});
  }

  deleteSubSubCategory = (id) => {
    const subSubCategories = this.state.subSubCategories.filter(subSubCategory=>(
      subSubCategory.id.toString() !== id
    ))
    this.setState({ subSubCategories});
  }

  addKeyword = (value) => {
    const keyword = this.state.allKeywords.find(keyword=>(
      keyword.id.toString() === value.value
    ))
    const keywords = [...this.state.keywords, keyword];
    this.setState({ keywords});
  }

  deleteKeyword = (id) => {
    const keywords = this.state.keywords.filter(keyword=>(
      keyword.id.toString() !== id
    ))
    this.setState({ keywords});
  }

  getOptionsKeyword = () => {
    return this.state.allKeywords.filter(item => this.state.keywords.map(i => i.id.toString()).includes(item.id) === false).map(i => ({value: i.id, label: i.title}));
  }

  updateCategorie = (item, listCategories) => {
    const label = item.label;
    const categorie = listCategories.find(k => k.name === label);
    let errors = this.state.errors;
    errors.category = ""

    this.setState({ 
      category: categorie ? parseInt(categorie.id) : this.state.category, 
      errors,
      subSubCategories: [],
      selectedSubCategory: null,
      selectedSubCategoryId: null,
      subCategories: [],
    });
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameWithSpecialCharsErrors(value)
        break;
      case 'description':
        errors.description = this.props.errors.extraLongDescriptionErrors(value);
        break;
      case 'price':
        errors.price = this.props.errors.numberErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }
  testErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameWithSpecialCharsErrors(value)
        break;
      case 'description':
        errors.description = this.props.errors.extraLongDescriptionErrors(value);
        break;
      case 'price':
        errors.price = this.props.errors.numberErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  updateStateOption(event, index) {
    const options = this.state.options.map(option => {
      if (option.index === index) {
        let errors = {}
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'price') {
          errors.description = this.props.errors.descriptionErrors(option.description);
          errors.price = this.props.errors.numberErrors(value);
        }
        if (name === 'description') {
          errors.description = this.props.errors.descriptionErrors(value);
          errors.price = this.props.errors.numberErrors(option.price);
        }
        return ({
           ...option, 
           [name]: value ,
           errors,
          })
      }
      return option;
    });
    this.setState({ options });
  }

  addOptionExtraFields = (options)=>{
    const result = options && options.length >0 && options.map(option =>{
        option.index = parseInt(option.id);
        option.errors ={
          description: this.props.errors.descriptionErrors(option.description),
          price: this.props.errors.numberErrors(option.price)
        }
        return option;
    });
    return result || []
  }

  addOption = () => {
    const options = [
      ...this.state.options,
      {
        description: "",
        price: "",
        duration: 5,
        index: this.state.lastIndex,
        errors: {
          description: this.props.errors.descriptionErrors(""),
          price: this.props.errors.numberErrors("")
        }
      }
    ];
    this.setState(oldState =>({ 
      options,
      lastIndex: oldState.lastIndex - 1
    }));
  }

  removeOption = (index) => {
    let updateOption = [...this.state.options];
    if (updateOption.length > 0) {
      updateOption = [...updateOption.filter(option => option.index !== index)]
    }
    this.setState({ options: updateOption });
  }

  removePrestation = ()=>{
    this.setState({ isLoading: true });
    this.props.removePrestation(this.state.id, this.callBack)
  }
  

}

export default FichePrestationController;

FichePrestationController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/bank';

const initialSate = {
  bankInfoFile: {},
  bankInfo: {},
}
const bank = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_BANK_INFO_FILE:
      return {
        ...state,
        bankInfoFile: action.payload,
      }
    case constants.RECEIVE_BANK_INFO:
      return {
        ...state,
        bankInfo: action.payload,
      }
    case constants.SEND_BANK_INFO:
      return {
        ...state,
        bankInfo: action.payload,
      }
    case constants.SEND_BANK_FILE:
      return {
        ...state,
        bankInfoFile : action.payload,
      }
    case constants.LOGOUT:
      return initialSate;

    default:
      return state;
  }

}

export default bank;
import { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';


class FactureController extends Component {
    
}

export default FactureController;

//
// Redux connection
//

FactureController.propTypes = {
  
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/activityArea';
import serviceApp from '../../applicatif/activityArea';

export const getAllActivityArea = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllActivityArea();
    callBack && callBack(payload);
    if (payload) {
      return dispatch({
        type: constants.RECEIVE_ALL_ACTIVITY_AREA,
        payload,
      })
    }
  }
}

export const getSellerActivityArea = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerActivityArea();
    callBack && callBack(payload);
    if (payload) {
      return dispatch({
        type: constants.RECEIVE_ACTIVITY_AREA,
        payload,
      })
    }
  }
}

export const createActivityArea = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.createActivityArea(data);
    callBack && callBack(payload);
    if (payload) {
      return dispatch({
        type: constants.CREATE_ACTIVITY_AREA,
        payload
      })
    }
  }
}

export const addSellerActivityArea = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.addSellerActivityArea(data);
    callBack && callBack(payload);
    if ((payload && payload.status === 201) || (payload && payload.status === 200)) {
      return dispatch({
        type: constants.ADD_ACTIVITY_AREA,
        payload: data,
      })
    }
  }
}
export const deleteSellerActivityArea = (id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.deleteSellerActivityArea(id);
    callBack && callBack(payload);
    if ((payload && payload.status === 204) || (payload && payload.status === 200)) {
      return dispatch({
        type: constants.DELETE_ACTIVITY_AREA,
        payload: id,
      })
    }
  }

}
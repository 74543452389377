import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class GestionAdminController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      siret: "",
      urlKey: "",
      etablissmentContact: "",
      bankUserName: "",
      bankUserBirthDay: "",
      bankIban: "",
      bankBic: "",
      bankStatus: "",
      completion: "90",
      completionStatus: "toValidate",
      stipeError: true,
      profilCompletion: "0",
      stateOfProgress: 0,
      isLoading: false,
      infoJuridique: {
        numSiret: "",
        numTva: "",
        status: "",
        url: "",
      },

      numSiret: "",
      numTva: "",
      status: "",
      url: "",
      errors: {
        etablissementName: 'default',
        etablissementFirstName: 'default',
        etablissementEmail: 'default',
        etablissementAddress: 'default',
        etablissmentContact: 'default',
        bankUserName: 'default',
        bankUserBirthDay: 'default',
        bankIban: 'default',
        bankBic: 'default',
        bankStatus: 'default'
      },
      errorsInfoJuridique: {
        numSiret: 'default',
        url: 'default',
        status: 'default',
        numTva: 'default',
      }
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  callBack = (data) => {
  }

  setLoadingTrue = () => {
    this.setState({ isLoading: true })
  }

  setLoadingFalse = () => {
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    this.setState({ isLoading: true })
    this.getAllData()
    const { legalInfoEtablissement, infoProgressionEtablissement } = this.props.etablissement
    if (legalInfoEtablissement) {
      this.setState({
        ...legalInfoEtablissement,
      });
    }
    if (infoProgressionEtablissement) {
      this.setState({
        ...infoProgressionEtablissement,
      });
    }
    if (this.props.location.state) {
      const { defaultOpen, activeTab } = this.props.location.state
      if (defaultOpen) {
        this.expandCollapse(null, defaultOpen, activeTab || 0)
      }
    }

    //this.props.fetchApiGetAdminInfo();
  }
  getAllData = async () => {
    await this.props.getProgressionEtablissement(this.callBack);
    await this.props.getLegalInformation(this.callBack);
    await this.props.getSellerContact(this.callBack);
    await this.props.getBankInfo(this.callBack);
    await this.props.verifyBankFile(this.callBack);
    await this.props.getIdentityEtablissement(this.callBack);
    this.setState({ isLoading: false })
  }

  componentDidUpdate(prevProps) {
    const prevLegalInfo = prevProps.etablissement.legalInfoEtablissement;
    const propsLegalInfo = this.props.etablissement.legalInfoEtablissement;
    const prevProgress = prevProps.etablissement.infoProgressionEtablissement;
    const propsProgress = this.props.etablissement.infoProgressionEtablissement;

    if (!prevLegalInfo || !prevProgress) {
      return;
    }
    if (prevLegalInfo !== propsLegalInfo) {
      this.setState({
        ...propsLegalInfo,
      });
    }
    if (prevProgress !== propsProgress) {
      this.setState({
        ...propsProgress,
      });
    }
  }

  reloadData = () => {
    const { legalInfoEtablissement, infoProgressionEtablissement } = this.props.etablissement
    if (legalInfoEtablissement) {
      this.setState({
        ...legalInfoEtablissement,
      });
    }
    if (infoProgressionEtablissement) {
      this.setState({
        ...infoProgressionEtablissement,
      });
    }
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'socialSeatName':
        errors.socialSeatName = this.props.errors.notEmptyInput(value);
        break;
      case 'socialSeatAddress':
        errors.socialSeatAddress = this.props.errors.notEmptyInput(value);
        break;
      case 'socialSeatEmail':
        errors.socialSeatEmail = this.props.errors.emailErrors(value);
        break;
      case 'representativeName':
        errors.representativeName = this.props.errors.notEmptyInput(value);
        break;
      case 'representativeFirstName':
        errors.representativeFirstName = this.props.errors.notEmptyInput(value);
        break;
      case 'representativeEmail':
        errors.representativeEmail = this.props.errors.emailErrors(value);
        break;
      case 'representativeAddress':
        errors.representativeAddress = this.props.errors.emailErrors(value);
        break;
      case 'representativeDate':
        errors.representativeDate = this.props.errors.notEmptyInput(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  updateState = (event) => {
    const name = event.target.name
    this.setState({ [name]: event.target.value });
  }

  onSelect = (item) => {
    this.setState({ status: item.label });
  }

  expandCollapse = (event, indexes, item) => {
    const el = document.getElementsByClassName('accordion-item')[item];
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      const element = document.getElementsByClassName('accordion-item')[index];
      if (element && element.lastChild.clientHeight === 0) {
        event && event.preventDefault();
        element.firstChild.click();
      }
    }
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({block: 'center'})
      }, 1500)
    }
  }

  getHeadbangClosed = () => {
    const { steps } = this.props.dashboard;
    return steps.includes("closed") && !steps.includes("loading");
  }

}

export default GestionAdminController;

GestionAdminController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import fileDownload from 'js-file-download';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class FactureController extends Component {
    constructor(props) {
        super(props);
        this.pageRef = createRef();
        this.state = {
            order: {},
            autodownload: this.props.location.state && this.props.location.state.autodownload,
        }
    }

    componentDidMount() {
        if (this.pageRef && this.pageRef.current) {
            this.pageRef.current.scrollIntoView();
        }
        let agence = (this.props.match && this.props.match.params && this.props.match.params.idAgence) || null;
        const id = (this.props.match && this.props.match.params && this.props.match.params.id) || null;
        this.setState({
            id: this.props.match.params.id
        })
        if (agence === 'seller') {
            agence = null;
        }
        this.setState({ isLoading: true })
        this.props.getOrderBill(id, agence, this.callback);
    }

    callback = (data, type) => {
        this.setState({ isLoading: false })
        if (type === "getOrderBill" && data && data.data) {
            this.setState({
                order: (data.data && data.data.order_invoice) || {}
            }, ()=>{
                if (this.state.autodownload){
                    document.getElementById("invoicePdf").click();
                    this.props.history.replace({ state: null });
                }
            });
        }else if (type === "download") {
            let downloadError = data && data.data && data.status === 200 ? false : true;
            this.setState({
                downloadError,
            });
            if (data && data.data){
                this.downloadInvoice(data.data);
            }
        }
    }

    downloadInvoice = (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        fileDownload(blob, "facture.pdf")
      }

    generateInvoice = () =>{
        if (this.state.id) {
            this.setState({ isLoading: true })
            this.props.downloadOrderBill(this.state.id, null, this.callback);
        }
    }
}

export default FactureController;

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import { getSellerType } from '../../../../data/constants/seller.type';

const ORDER = {
  DISTANCE_UP: {icon: 'arrow_drop_up', field: 'distance', direction: 'asc'},
  DISTANCE_DOWN: {icon: 'arrow_drop_down', field: 'distance', direction: 'desc'}
}

class TousLesHotelsController extends Component {
  constructor(props) {
    const orderDistance = ORDER.DISTANCE_UP;
    super(props);
    this.state = {
      visible: false,
      activityZone: [],
      activitySet: [],
      isStripeOnError: true,
      search: "",
      selectedHotel: null,
      messageHotel: "",
      margeDemande: "20%",
      commission: true,
      messageRequest: "",
      isLoading: false,
      messageErrorShow: [],
      titleErrorShow: "",
      errorVisible: false,
      query: {
        itemPerPage: 5,
        page: 1,
        order: {
          distance : orderDistance.direction,
        }
      },
      data: [],
      name: "",
      orderDistance,
      commissions: {}
    }
  }

  updateChecked = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value === "true" ? true : false, });
  }

  searchIsNotVoid = () => {
    const { name } = this.state
    return name !== ""
  }

  sendSearch = () => {
    let { listSellerActivityArea } = this.props.activityArea;
    let query = this.state.query;
    const zones = this.getActivityAreaIds(listSellerActivityArea);
    query.filter = { zones }
    query.filter.search = this.state.name;
    query.page = 1;
    this.props.setLoadingTrue()
    this.props.getAllHotel(query, this.callBack);
  }

  handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.sendSearch()
    }
  }

  updateStateSimple = (event) => {
    let name = event.target.name;
    // this.handleUserInput(event);
    this.setState({ [name]: event.target.value });
  }

  selectMarge = (item) => {
    let selectedHotel = this.state.selectedHotel
    selectedHotel.commission = item.label;
    this.setState({ selectedHotel });
  }

  componentDidMount = async() =>{
    await this.props.getAllActivityArea(this.callBack);
    await this.props.getSellerActivityArea(this.callBack);
  }

  getAllData = async (keepPage) => {
    this.props.setLoadingTrue()
    let { listSellerActivityArea } = this.props.activityArea;
    let query = this.state.query;
    const zones = this.getActivityAreaIds(listSellerActivityArea);
    query.filter = { zones };
    if (!keepPage) {
      query.page = 1;
    }
    // await this.props.getRequetePartenariat(this.callBack);
    await this.props.getAllHotel(query, this.callBack);
  }
  
  getActivityAreaIds = (activityAreas)=>{
    const ids = activityAreas && activityAreas.length >0 && activityAreas ?
      activityAreas.map(activityArea=>(
        activityArea.zoneId
      )):
      []
    return ids && ids.length > 0 ? ids.join(',') : '';
  }

  isRequestValid = () => {
    const messageRequest = this.state.messageRequest;
    return messageRequest !== ""
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.activityArea.listSellerActivityArea;
    const props = this.props.activityArea.listSellerActivityArea;

    if (!prev) {
      return;
    }
    if (prev !== props) {
      this.props.setLoadingTrue()
      let query = this.state.query;
      const zones = this.getActivityAreaIds(props);
      query.filter = { zones };
      query.page = 1;
      this.props.getAllHotel(query, this.callBack);
    }
    //TO DO fetchApiSearchHotelInMyArea mapping
  }

  openModal = (value) => {
    this.props.setLoadingTrue();
    this.setState({ selectedHotel: value });
    this.props.getDefaultCommission(value.code, this.callBack)
  }
  openModalError = (titleErrorShow, messageErrorShow) => {
    this.setState({ errorVisible: true, titleErrorShow, messageErrorShow });
  }

  closeModalError() {
    this.setState({ errorVisible: false, titleErrorShow: "", messageErrorShow: "" });
  }

  closeModal() {
    this.setState({ visible: false, selectedHotel: null, messageRequest: "" });
  }

  getCommissionToSave = () =>{
    const { infoLogin } = this.props.commons;
    const type = getSellerType(infoLogin);
    const commissionToSave = {};
    switch (type) {
        case 1:
            commissionToSave.commission_indep = {...this.state.commissions.commission_indep};
            commissionToSave.commission_agence = {...this.state.commissions.commission_agence};
          break
        case 2:
            commissionToSave.commission_societe = {...this.state.commissions.commission_societe};
          break
        case 3:
            commissionToSave.commission_agence = {...this.state.commissions.commission_agence};
          break
        case 5:
            commissionToSave.commission_restaurant = {...this.state.commissions.commission_restaurant};
          break
        default:
    }
    return commissionToSave
  }

  requestPartenership = () => {
    const data = {
      hotel: this.state.selectedHotel.id,
      message: this.state.messageRequest,
      commission: 1,
      commissions: this.getCommissionToSave(),
    }
    this.props.setLoadingTrue()
    this.props.requestPartenership(data, this.callBack)
    this.closeModal()
  }

  updateState = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
    if (event.target.value === "") {
      this.props.setLoadingTrue()
      const query = this.state.query
      let { listSellerActivityArea } = this.props.activityArea;
      const zones = this.getActivityAreaIds(listSellerActivityArea);
      query.filter = { zones }
      query.page = 1;
      this.setState({ query })
      this.props.getAllHotel(query, this.callBack);
    }
  }

  selectActivityZone(value, listZone) {
    const selected = listZone.find(i => i.zoneId === value.value);
    this.props.setLoadingTrue()
    this.props.addSellerActivityArea(selected, this.callBack)
  }
  callBack = (data, type) => {
    this.props.setLoadingFalse()
    if (type === "requestPartner") {
      let requestError = data && (data.status === 200 || data.status === 201) ? false : true;
      this.setState({
        requestError
      });
      if (data && data.status && data.status === 201) {
        this.props.getAllData()
      }else if (data &&  data.error && data.error.response && data.error.response.data && data.error.response.data.message && data.error.response.data.message.includes("A Partner with the same email") && data.error.response.data.message.includes("already exists in an associated website.")) {
        this.openModalError("Erreur", "Votre email existe déjà en tant que client dans cet hôtel, merci de modifier l'email de l'établissement ou supprimer le compte client dans cet hôtel.")
        this.props.getAllData()
      }else if (data &&  data.error && data.error.response && data.error.response.data && data.error.response.data.code && data.error.response.data.code === 500) {
        this.openModalError("Erreur", data.error.response.data.message)
        this.props.getAllData()
      }else if (data &&  data.error && data.error.response && data.error.response.data && data.error.response.data.title && data.error.response.data.detail) {
        this.openModalError("Erreur de complétion du profil", data.error.response.data.violations)
        this.props.getAllData()
      }else if (data &&  data && data.error && (data.error.toString().includes('code 500') || data.error.toString().includes('code 400'))) {
        this.openModalError("Erreur", "Erreur lors de la requete dans le serveur");
        this.props.getAllData()
      }
      this.closeModal()
    }
    else if (data && type === "allHotel") {
      this.props.setLoadingFalse()
      if (data && data.data) {
        this.setState({
          data: data.data
        })
      }
      this.closeModal()
    }else if (type === "getDefaultCommission") {
      this.props.setLoadingFalse();
      let commissionError = data && (data.status === 200 || data.status === 201) ? false : true;
      this.setState({
        commissionError
      });
      if (!commissionError) {
        this.setState({ visible: true, commissions: (data && data.data && data.data.commissions) || {} });
      }
    }
    
  }

  removeActivityZone(index) {
    this.props.setLoadingTrue()
    this.props.deleteSellerActivityArea(index, this.callBack)
  }

  handleSubmit(e) {
  }

  handlePaginationChange = (page) => {
    let { listSellerActivityArea } = this.props.activityArea;
    let query = this.state.query;
    query.page = parseInt(page)
    const zones = this.getActivityAreaIds(listSellerActivityArea);
    query.filter = { zones }
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getAllHotel(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let { listSellerActivityArea } = this.props.activityArea;
    let query = this.state.query;
    const zones = this.getActivityAreaIds(listSellerActivityArea);
    query.filter = { zones }
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getAllHotel(this.state.query, this.callBack);
  };

  handleSortChange = ()=>{
    const query = this.state.query;
    const orderDistance = this.state.orderDistance === ORDER.DISTANCE_UP ? ORDER.DISTANCE_DOWN : ORDER.DISTANCE_UP;
    query.order = {
      ...query.order,
      distance: orderDistance.direction
    };
    this.setState({
      query,
      orderDistance
    })
    this.getAllData(true);

  }
}
export default TousLesHotelsController;

TousLesHotelsController.propTypes = {
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
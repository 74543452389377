import React from 'react';


const itemProduct = ({ item, onChange }) =>
  <tr>
    <td>
      <p className="titre-produit">{item.name}</p>
      <p>{item.categoryName}<br />{item.duration}</p>
    </td>
    <td>
      <p className="price">{item.price}€</p>
      <p className="pricereduce">{(item.reduction && item.reduction !== 0) ? <p className="reduction">{item.reduction}% de réduction</p> : ""}</p>
    </td>
    <td>
      {item.numberOfSales}
    </td>
    <td>
      <label className="checkbox-label">
        <input
          type="checkbox"
          // enable={false}
          checked={item.hidden}
          // onChange={(event) => this.props.updatePrestation(item, this.callBack)} 
          readOnly={true}
        />
        <span className="check"></span>Masqué
            </label>
    </td>
  </tr>

export default itemProduct;

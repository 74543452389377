import constants from '../constants/agenda';

const initialSate = {
  listAllEvent: [],
  listResultSearch: [],
  listCalendarGoogle: [],
  isSync: null,
}
const agenda = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_ALL_EVENT:
      return {
        ...state,
        listAllEvent: action.payload.data,
      }
    case constants.ADD_EVENT:
      return {
        ...state,
        listAllEvent: [...state.listAllEvent, action.payload.data],
      }
    case constants.SEARCH_EVENT:
      return {
        ...state,
        listResultSearch: action.payload.data,
      }
    case constants.REMOVE_EVENT:
      return {
        ...state,
        listAllEvent: [...state.listAllEvent.filter(i => i.id !== action.payload)]
      }
    case constants.STATE_SYNC:
      return {
        ...state,
        isSync: action.payload,
      }
    case constants.SET_SYNC:
      return {
        ...state,
        isSync: true,
      }
    case constants.SET_UNSYNC:
      return {
        ...state,
        isSync: false,
      }
    case constants.GET_GOOGLE_ACCESS:
      if (action.payload.data && !action.payload.data.auth_url) {
        return {
          ...state,
          listCalendarGoogle: action.payload.data
        }
      }
      else {
        return {
          ...state,
        }
      }
    case constants.LOGOUT:
      return initialSate;
    default:
      return state;
  }

}

export default agenda;
import React from 'react';
import Dropdown from 'react-dropdown';
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import Rating from 'react-rating';
import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import { getCommissionLabel } from "./../../../../data/constants/commission.label";

import AttenteReponseController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";

class AttenteReponse extends AttenteReponseController {

  renderPagination = () => {
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={this.state.data && this.state.data.nbPage ? this.state.data.nbPage * this.state.data.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={this.state.data && this.state.data.itemPerPage ? this.state.data.itemPerPage : 5}
            activePage={this.state.data && this.state.data.page ? this.state.data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  renderAccept(value) {
    const { listPrestation } = this.props.prestation;
    const { prestation } = this.state;
    return (
      <div>
        <label className="mb-3">Prestations que vous souhaitez proposer pour cet hôtel</label>
        <div className="row">
          {listPrestation && listPrestation.data && listPrestation.data.map((value, index) => {
            const isChecked = prestation && prestation.includes(parseInt(value.id))
            return <div className="col col-6 cnt-input cnt-partenaire idem-prestation " key={`${value.id}-listPrestation`}>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="check-part"
                  checked={isChecked}
                  onChange={(item) => this.onChangeListPrestation(item, parseInt(value.id), isChecked)}
                /><span className="prestation-name">{value.name ? value.name : "Nom de la prestation"}</span>
                <span className="price-origine">
                  {`Votre prix : ${(value && value.price) || 0}€ TTC`}
                  {/* <br />{` Après marge hôtel : non défini`}  */}
                </span>
                <span className="check"></span>
              </label>
            </div>
          }
          )
          }
        </div>
      </div>
    )
  }

  renderRefuse() {
    let percentTab = [];
    for (var i = 0; i <= 100; i++) {
      percentTab.push(i + '%');
    }
    return (
      <div className="blc-refu">
        {/* <label className="checkbox-label">
          <input 
            type="checkbox" 
            name="check-part" 
            checked 
          /> Faire une contre proposition de marge hôtel
           <span className="check"></span>
        </label>
        <br />
        <h4>Votre contre-proposition</h4> */}
        {/* <div className="line-input">
          <label>Marge Hôtel</label>
          <div className="select-down select-marge">
            <Dropdown
              className="MyclassName"
              options={percentTab}
              onChange={(value) => this.handleOptionsPercentChange(value)}
              value={this.state.percent}
              controlClassName=''
              arrowClassName='arrow-down'
              arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
              arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
            />
          </div>
        </div> */}
        <div className="line-input">
          <label>Message pour l'hôtel</label>
          <div className="cnt-input">
            <textarea
              value={this.state.messageRefused}
              onChange={(event) => this.updateStateSimple(event)}
              name="messageRefused"
              required={true}
            >
              Écrivez…
            </textarea>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { listPartnershipRequest_5 } = this.props.hotels;
    const { infoLogin } = this.props.commons;
    const reponse = [
      'Accepter', 'Refuser'
    ];
    return (
      <div className="cnt-tab cnt-tab-reps">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.requestError && PopupHandler.showError("L'envoi de la requête a échoué.")}
            {this.state.requestError === false && PopupHandler.showSuccess("Requête envoyée avec succès.")}
          </div>
          <div className="main-reps">
            <div className="main-infos-hotel">
              <div className="table-responsive scrollbar">
                <table>
                {listPartnershipRequest_5 && listPartnershipRequest_5.data && Array.isArray(listPartnershipRequest_5.data) && listPartnershipRequest_5.data.map((value, index) =>
                  <React.Fragment key={`hotel2 ${index}`}> 
                    <tr>
                      <td className="w32-percent">
                        <div className="td-content">
                          <p className="name-product">
                            {value.hotelName ? value.hotelName : "Nom non défini"}
                          </p>
                          {
                            (value.hotelStar) &&
                            <div className="star-hotel">
                              <Rating
                                readonly
                                placeholderRating={(value.hotelStar && parseInt(value.hotelStar)) || 0}
                                emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                                placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                              />
                            </div>
                          }
                          {value.hotelAddress ? `${value.hotelAddress.streetAddress1 || ""}${value.hotelAddress.zipCode ? `-${value.hotelAddress.zipCode}`: ""}${value.hotelAddress.city ? `-${value.hotelAddress.city}`: ""}`: ''}
                        </div>
                      </td>
                      <td>
                        <p>{value.hotelMail ? value.hotelMail : "email non défini"}</p>
                        <p>{value.hotelAddress && value.hotelAddress.phone ? value.hotelAddress.phone : "Telephone non défini"}</p>
                      </td>
                      <td><span className="txt-distance">Distance</span> {value.distance > 0 ? `${parseFloat(value.distance || 0).toFixed(2)} km` : "Distance non définie"}</td>
                      <td><span className="txt-distance">Commission</span> {(value && getCommissionLabel(infoLogin, value.commision, value.commissions)) || ""}</td>
                      <td>
                        <div className="cnt-btn-strock">
                          <button onClick={() => this.openModal(value, index)} className="button mch-button">Répondre</button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="wrapper-cnt-message" colSpan="5">
                        <div className="cnt-message">
                          <p className="title-msg">Message de l’hôtel</p>
                          <p>{value.message ? value.message : "Message non défini"}</p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
                </table>
              </div>              
            </div>
            <div>{this.state.data && this.state.data.total > 0 && this.renderPagination()}</div>
          </div>
          <div className="wrapper-modal">
            <Modal visible={this.state.visible} width="450" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="content-modal modal-reponse">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                  <h1>{this.state.selectedValue !== null && this.state.selectedValue.hotelName ? this.state.selectedValue.hotelName : "Nom hotel non défini"}</h1>
                  <p>
                    {this.state.selectedValue !== null ? `${this.state.selectedValue !== null && this.state.selectedValue.hotelAddress && this.state.selectedValue.hotelAddress.streetAddress1}-${this.state.selectedValue !== null && this.state.selectedValue.hotelAddress && this.state.selectedValue.hotelAddress.zipCode}-${this.selectedValue !== null && this.state.selectedValue.hotelAddress && this.state.selectedValue.hotelAddress.city}` : "Addresse non défini"}
                  </p>
                </div>
                <div className="main-modal scrollbar">
                  <div className="row">
                    <div className="col col-6">
                      <p><span className="title-infos-commande">Contact</span>
                        {this.state.selectedValue !== null && this.state.selectedValue.hotelName ? this.state.selectedValue.hotelName : "Nom hotel non défini"}
                        <br />{this.state.selectedValue !== null && `${this.state.selectedValue.hotelPrenomContact ? this.state.selectedValue.hotelPrenomContact : ""} ${this.state.selectedValue.hotelNomContact ? this.state.selectedValue.hotelNomContact : ""}`}
                      </p>
                      <p>{this.state.selectedValue !== null && this.state.selectedValue.hotelMail ? this.state.selectedValue.hotelMail : ""}
                        <br />{this.state.selectedValue && this.state.selectedValue.hotelAddress && this.state.selectedValue.hotelAddress.phone ? this.state.selectedValue.hotelAddress.phone : ""}
                      </p>
                    </div>
                    <div className="col col-6">
                      <p>
                        <span className="title-infos-commande">Distance </span>
                        {this.state.selectedValue && this.state.selectedValue.distance > 0 ? `${parseFloat(this.state.selectedValue.distance || 0).toFixed(2)} km` : "Distance non définie"}
                      </p>
                    </div>
                  </div>
                  
                  {
                    infoLogin && infoLogin.roles && (infoLogin.roles.includes('ROLE_SELLER_INSTITUT') || infoLogin.roles.includes('ROLE_SELLER_RESTAURENT')) &&
                    <div className="line-input">
                      <p><span className="title-infos-commande">Commission hôtel</span></p>
                      <p>{(this.state.selectedValue && getCommissionLabel(infoLogin, this.state.selectedValue.commision, this.state.selectedValue.commissions)) || ""}</p>
                    </div>
                  }

                  <p><span className="title-infos-commande">Message de l’hôtel</span>
                    {this.state.selectedValue !== null && this.state.selectedValue.message ? this.state.selectedValue.message : "Pas de message"}
                  </p>
                  <div className="line-input">
                    <label>Votre réponse</label>
                    <div className="select-down select-reponse">
                      <Dropdown
                        className="MyclassName"
                        options={reponse}
                        onChange={(value) => this.handleOptionsChange(value)}
                        value={this.state.accept}
                        controlClassName=''
                        arrowClassName='arrow-down'
                        arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                        arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                      />
                    </div>
                  </div>
                  <div className="line-input">
                    {this.state.accept === 'Accepter' ? this.renderAccept() : this.renderRefuse()}
                  </div>
                  <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={() => this.closeModal()}>Annuler</button>
                    <button
                      // className={this.state.accept === 'Accepter' ? `button ${this.isAcceptedsubmit() ? '' : 'buttonIsInvalid'}` : `button ${this.isRefusedSubmit() ? '' : 'buttonIsInvalid'}`}
                      disabled={this.state.accept === 'Accepter' ? false : !this.isRefusedSubmit()}
                      className='button'
                      onClick={(event) => this.sendRequest(event)}
                    >
                      Envoyer votre réponse
                  </button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(AttenteReponse);

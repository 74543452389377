import constants from '../constants/language';

const initialSate = {
  listAllLanguage: [],
  listSellerLanguage: [],
}
const language = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_ALL_LANGUAGE:
      return {
        ...state,
        listAllLanguage: action.payload
      }
    case constants.GET_SELLER_LANGUAGE:
      return {
        ...state,
        listSellerLanguage: action.payload
      }
    case constants.UPDATE_SELLER_LANGUAGE:
      return {
        ...state,
        listSellerLanguage: [...state.listSellerLanguage, ...action.payload],
      }
    case constants.REMOVE_SELLER_LANGUAGE:
      return {
        ...state,
        listSellerLanguage: [...state.listSellerLanguage.filter(i => i.id !== action.payload)],
      }
    case constants.LOGOUT :     
      return initialSate;
    default:
      return state;
  }

}

export default language;
import constants from '../constants/hotels';

const initialSate = {
  listHotelPartner: [],
  listAllHotelPartner: [],
  listPartnershipRequest_1: [],
  listPartnershipRequest_5: [],
  listAllHotel: [],
  resultSearchHotel: {},
  listHotelFilter: [],
}
const hotels = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_HOTEL_PARTENER:
      return {
        ...state,
        listHotelPartner: action.payload,
      }
    case constants.GET_HOTEL_PARTENER_ALL:
      return {
        ...state,
        listAllHotelPartner: action.payload,
      }
    case constants.GET_PARTENERSHIP_REQUEST_1:
      return {
        ...state,
        listPartnershipRequest_1: action.payload,
      }
    case constants.GET_PARTENERSHIP_REQUEST_5:
      return {
        ...state,
        listPartnershipRequest_5: action.payload,
      }
    case constants.REQUEST_PARTENERSHIP:
      return {
        ...state,
        listPartnershipRequest: state.listPartnershipRequest.map(item => {
          if (item.id === action.payload.id) {
            item.typePartenerships = 1;
          }
          return item
        }),
      }
    case constants.SEARCH_HOTEL_PARTENER:
      return {
        ...state,
        resultSearchHotel: action.payload,
      }
    case constants.GET_ALL_HOTEL:
      return {
        ...state,
        listAllHotel: action.payload,
      }
    case constants.GET_HOTEL_FILTER:
      return {
        ...state,
        listHotelFilter: action.payload.data,
      }
    case constants.ACCEPT_PARTENERSHIP:
      return {
        ...state,
        listHotelFilter: action.payload.data,
      }
    case constants.DENY_PARTENERSHIP:
      return {
        ...state,
        listHotelFilter: action.payload.data,
      }
    case constants.END_PARTENERSHIP:
      return {
        ...state,
        listHotelFilter: action.payload.data,
      }
    case constants.LOGOUT:
      return initialSate;
    default:
      return state;
  }

}

export default hotels;
import React from 'react';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input'

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import {showPopupExtra} from "../../components/popup/popup";
import PopupError from "../../components/popup/popup.error";
import Auth from "../../../services/authentification";

const auth = new Auth();

class ParametreCompteController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      isLoading: false,
      email: "",
      newEmail: "",
      firstName: "",
      lastName: "",
      password: "",
      phone: "",
      smsAuthorization: false,
      currentCountry: "FR",
      errors: {
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        currentUserNumberError: "",
      },
      defaultValue: {
        label: "",
        chipLabel: "",
        value: ""
      },
      open: false,
      title: "",
      textReturn: "successfull",
      showHidePassword: false,
      showHideMailAdress: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      errorsPass: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        newEmail: ""
      },
      visible: false,
      titleShow: "",
      messageShow: "",
    };
  }

  showHideComponent = (name)=> {
    let showEditEmailPassword = false;
    switch (name) { 
      case "showHideEditEmailPassword":
          this.setState(oldState=>({
            showEditEmailPassword: false,
            showPassword: false,
            showMailAdress: false,
            currentPassword: "", 
            newPassword: "", 
            confirmNewPassword: "", 
            emailError: '',
            newEmail: "",
            errorsPass: {
              currentPassword: "",
              newPassword: "",
              confirmNewPassword: "",
              newEmail: "",
            },
          }));
          break;
      case "showHidePassword":
          showEditEmailPassword = (this.state.showPassword && this.state.showMailAdress) || !this.state.showPassword;
          this.setState(oldState=>({
            showPassword: !oldState.showPassword, 
            showEditEmailPassword,
            currentPassword: showEditEmailPassword ? oldState.currentPassword : "", 
            newPassword: "", 
            confirmNewPassword: "", 
            changePasswordError: false,
              errorsPass: {
                ...oldState.errorsPass,
                currentPassword: showEditEmailPassword ? oldState.currentPasswordError : "",
                newPassword: "",
                confirmNewPassword: "",
              },
          }));
          break;
      case "showHideMailAdress":
          showEditEmailPassword= (this.state.showMailAdress && this.state.showPassword) || !this.state.showMailAdress;
          this.setState(oldState=>({ 
            showMailAdress: !oldState.showMailAdress,
            showEditEmailPassword,
            currentPassword: showEditEmailPassword ? oldState.currentPassword : "", 
            newEmail: "",
            errorsPass: {
              ...oldState.errorsPass,
              currentPassword: showEditEmailPassword ? oldState.currentPasswordError : "",
              newEmail: '',
            },
          }));
          break;
      default:
          break;
    }
  }

  togglePasswordVisibility = (identity) => {
    switch (identity) {
      case "currentPassword":
        this.setState(oldState => ({
          showCurrentPassword: !oldState.showCurrentPassword
        }));
        break;
      case "newPassword":
        this.setState(oldState => ({
          showNewPassword: !oldState.showNewPassword
        }));
        break;
      case "confirmNewPassword":
        this.setState(oldState => ({
          showConfirmNewPassword: !oldState.showConfirmNewPassword
        }));
        break;
      default:
        break;
    }

  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  callBack = (data, type) => {
    if (type === 'updateSellerInfoAccount') {
      let updateInfoError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        updateInfoError,
        isLoading: false
      })
      if (data) {
        if (data.status === 403) {
          showPopupExtra(<PopupError title="Erreur lors de la sauvegarde" message="Mot de passe invalide."/>)
        }else if (data.status === 409) {
          showPopupExtra(<PopupError title="Erreur lors de la sauvegarde" message="Cet email existe déjà."/>)
        }else if (data.status === 400) {
          const message = data.data && data.data.detail ? data.data.detail : "Vérifier la validité des champs.";
          showPopupExtra(<PopupError title="Erreur lors de la sauvegarde" message = {message}/>)
        }else if (data.status === 200 || data.status === 201 || data.status === 204) {
          if (this.state.showMailAdress || this.state.showPassword) {
            this.openModal("Modification paramètre du compte", "Succès de la modification du compte. Vous allez être deconnecté de votre compte.");
          }
        }
      }
    }else {
      this.setState({ isLoading: false, });
    }
  }

  redirectToLogin = ()=>{
    const { history } = this.props;
    this.props.isLogout();
    if (history) {
      history.push(
        {
          pathname: "/login",
          state: {
            from: { pathname: "/" }
          }
        });
      auth.logout();
    }
  }

  async componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    this.setState({ isLoading: true })
    this.getAllData()
    const { infoAccountSeller } = this.props.sellers
    if (infoAccountSeller) {
      this.setState({
        ...infoAccountSeller,
        currentCountry: infoAccountSeller && infoAccountSeller.phone && isValidPhoneNumber(infoAccountSeller.phone) ? parsePhoneNumber(infoAccountSeller.phone).country : "FR"
      });
    }

  }

  getAllData = async () => {
    await this.props.getSellerInfoAccount(this.callBack);
    this.setState({ isLoading: false })
  }
  componentDidUpdate(prevProps) {
    const prevInfoAccount = prevProps.sellers.infoAccountSeller;
    const propsInfoAccount = this.props.sellers.infoAccountSeller;

    if (!prevInfoAccount) {
      return;
    }
    if (prevInfoAccount !== propsInfoAccount) {
      this.setState({
        ...propsInfoAccount,
      });
    }
  }

  isPassNotVoid = () => {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
    } = this.state.errorsPass;
    return currentPassword === "" && newPassword === "" && confirmNewPassword === ""
  }

  isEmailNotVoid = () => {
    const {
      currentPassword,
      newEmail
    } = this.state.errorsPass;
    return currentPassword === "" && newEmail === ""
  }

  isNotVoid = () => {
    const {
      lastName,
      firstName,
      currentUserNumberError,
    } = this.state.errors;
    return lastName === "" && firstName === "" && currentUserNumberError === "" 
  }

  isFormValid = () => {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
      newEmail
    } = this.state.errorsPass;
    return this.isNotVoid() && !currentPassword && !newPassword && !confirmNewPassword && !newEmail
  }


  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid()) {
      this.setState({ isLoading: true });
      const data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        smsAuthorization: this.state.smsAuthorization === "true" ? true : false
      }
      if (this.state.showMailAdress) {
        data.currentPassword= this.state.currentPassword;
        data.newEmail= this.state.newEmail
      }
      if (this.state.showPassword) {
        if (!data.currentPassword) {
          data.currentPassword =  this.state.currentPassword;
        }
        data.newPassword = this.state.newPassword;
      }
      this.props.updateSellerInfoAccount(data, this.callBack);
    }
  }

  toogleChange = (event) => {
    this.setState({ isChecked: event.target.checked });
  }

  openModal = (title, message) => {
    this.setState({
      visible: true,
      titleShow: title,
      messageShow: message,
    });
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
  }

  updateState = async (event) => {
    let name = event.target.name;
    this.handleUserInput(event);
    await this.setState({ [name]: event.target.value, });
  }

  updateChecked = async (event) => {
    let name = event.target.name;
    this.handleUserInput(event);
    await this.setState({ [name]: event.target.value === "true" ? true : false, });
  }

  updateStateNumber = async (value) => {

    this.handleNumberInput(value)
    this.setState({ phone: value });
  }

  handleUserSelect = (value) => {
    if (value.value === "FR") {
      this.setState({ currentCountry: value.value, phone: "+33" });
      return
    }
    this.setState({ currentCountry: value.value, phone: "" });
  }

  updateCheck = (etat) => {
    if (etat !== this.state.allowSmsNotif) {
      this.setState({ allowSmsNotif: etat });
    }
  }

  updateDisabled = (errors) => {
    if (errors.lastName !== '' || errors.firstName !== ''
      || errors.email !== ''
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let errorsPass = this.state.errorsPass;
    switch (name) {
      case 'lastName':
        errors.lastName = this.props.errors.nameErrors(value);
        break;
      case 'firstName':
        errors.firstName = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    //this.updateDisabled(errors);
    this.setState({ errors, [name]: value, errorsPass });
  }

  handlePasswordEmailInput = (event) => {
    const { name, value } = event.target;
    this.setState({
       [name]: value
      }, ()=>{
        let errorsPass = this.state.errorsPass;
        errorsPass.currentPassword = this.props.errors.passwordErrors(this.state.currentPassword);
        if (this.state.showMailAdress) {
          errorsPass.newEmail = this.props.errors.emailErrors(this.state.newEmail);
        }
        if (this.state.showPassword) {
          errorsPass.newPassword = this.props.errors.passwordErrors(this.state.newPassword);
          errorsPass.confirmNewPassword = this.props.errors.confirmNewsPassErrors(this.state.newPassword, this.state.confirmNewPassword);
        }
        this.setState({ errorsPass });
      });
  }

  handleNumberInput = (value) => {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.currentUserNumberError = this.numberErrors(value)
      this.setState({ errors });
    }
  }

  numberErrors = (value) => {
    if (typeof value === "string") {
      if (isValidPhoneNumber(value)) {
        if (this.state.currentCountry === "FR" && (value.charAt(3) === "0")) {
          return "0 apres 33 est invalide";
        }
        else {
          return "";
        }
      }
      else {
        return "Numero invalide";
      }
    }
    else {
      return "Numero invalide";
    }
  }
}


export default ParametreCompteController;

//
// Redux connection
//

ParametreCompteController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
export const SEND_BANK_INFO = 'SEND_BANK_INFO';
export const RECEIVE_BANK_INFO = 'RECEIVE_BANK_INFO';
export const SEND_BANK_FILE = 'SEND_BANK_INFO_FILE';
export const GET_BANK_INFO_FILE = 'GET_BANK_INFO_FILE';
export const LOGOUT = 'LOGOUT';

export const url = {
    bankInfo : '/bankInformation',
    bankUpload : '/bank/upload',
    bankVerifyFile : '/bank/verify/file',
}

export default {
    RECEIVE_BANK_INFO,
    SEND_BANK_INFO,
    SEND_BANK_FILE,
    GET_BANK_INFO_FILE,
    LOGOUT,
}
import React from "react";
import { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import {showPopUp, hidePopUp, showPopupExtra} from "../../../components/popup/popup";
import PopupConfirm from "../../../components/popup/popup.confirmation";
import PopupError from "../../../components/popup/popup.error";
import Comment from "../../../components/comment/comment";


class CommentaireController extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            logo: '',
            selectedReview: null,
            statusValue: "0",
            query: {
                itemPerPage: 5,
                page: 1,
                status: "0",
                reported: null
            },
        };
    }

    getReviews = () =>{
        this.props.getReviews(null, null, this.state.query ,this.callBack);
    }

    onStatusChanged = value =>{
        const statusValue = value.value;
        const reported = statusValue === "2" ? 1 : statusValue === "3" ? 0 : null;
        const status = (statusValue === "1" && 1) || (statusValue === "2" && 2) || (statusValue === "3" && 2) || 0;
        const query = {
            ...this.state.query,
            status,
            reported
        }
        this.setState({
            statusValue,
            query
        })
        this.props.setLoadingTrue();
        this.props.getReviews(null, null, query,this.callBack);
    }

    callBack = (data, type) =>{
        if (type === "reportReview") {
            let reportError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
            this.setState({
                reportError
            })
            if (!reportError) {
                this.getReviews();
            }else{
                if(data && data.error && data.error.response && data.error.response.data && data.error.response.data.detail==="review: Already report"){
                    showPopupExtra(<PopupError title="Erreur sur le signalement" message="Cet avis est déjà signalé"/>)
                }
                this.props.setLoadingFalse();
            }
        }else if (type === "validateReview") {
            let validateError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
            this.setState({
                validateError
            })
            if (!validateError) {
                this.getReviews();
            }else{
                this.props.setLoadingFalse();
            }
        }else{
            this.props.setLoadingFalse();
        }
        
    }

    onValidateClicked = (event)=>{
        const selectedReview = parseInt(event.target.dataset.id);
        this.setState({
            selectedReview,
        })
        showPopUp(
            <PopupConfirm 
                message = "Voulez-vous vraiment valider cet avis?"
                onAccept = {this.validateReview}
            />,
           "Confirmation"
          )
    }


    onReportClicked = (event)=>{
        const selectedReview = parseInt(event.target.dataset.id);
        this.setState({
            selectedReview,
            comment: "",
        })
        showPopUp(
            <Comment onValidate = {this.reportReview}/>,
            "Signaler un avis"
        )
    }

    reportReview = comment=>{
        if (comment && this.state.selectedReview) {
            this.props.setLoadingTrue();
            const data ={
                comment,
                review: parseInt(this.state.selectedReview)
            }
            this.props.reportReview(data, this.callBack)
        }
    }

    validateReview = ()=>{
        hidePopUp();
        if (this.state.selectedReview) {
            this.props.setLoadingTrue();
            this.props.validateReview(parseInt(this.state.selectedReview), this.callBack)
        }
    }

    handlePaginationChange = (page) => {
        let query = this.state.query;
        query.page = parseInt(page)
        this.props.setLoadingTrue();
        this.setState({
            query 
        },this.getReviews)
      };
    
      handleItemsPerPageChange = (event) => {
        let query = this.state.query;
        query.itemPerPage = parseInt(event.target.value)
        query.page = 1
        this.props.setLoadingTrue();
        this.setState({ 
            query 
        },this.getReviews)
      };
    
}

export default CommentaireController;

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
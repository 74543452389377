import React from 'react';
import { Link } from 'react-router-dom';

import AvisCommandeController, { reduxConnect } from './controller';
import LoadingOverlay from 'react-loading-overlay';

import Headers from "../../../components/headers/headers";
import Footer from "../../../components/footer/footer";


class AvisCommande extends AvisCommandeController {

  render() {
    const review = this.props.notice.orderReview || {};
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1><Link to="/commande"><span className="back"><i className="material-icons icon-back">arrow_back</i></span></Link> Avis sur la commande</h1>
            </div>
          </div>
          <div className="main-commande">
            <div className="wrapper clr">
              <div className="mainBody">
                <div className="infos-commande">
                  <div className="head-det-commande">
                    <p className="nameClient"><span className="ico"><i className="material-icons icon-person">person_outline</i></span>{review.nickname}</p>
                    <p className="date-commande"><span className="ico"><i className="material-icons icon-person">date_range</i></span>{review.date}</p>
                    {/* <p className="adress-celler">{review.seller && review.seller.address && `${review.seller.address.street ? `${review.seller.address.street}, `:""}${review.seller.address.postalCode ? `${review.seller.address.postalCode} `:""}${review.seller.address.city  || ""}`}</p> */}
                  </div>
                  <div className="txt-msg">
                    <p>« {review.detail} »</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(AvisCommande);

import { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class ClientController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        itemPerPage: 5,
        page: 1,
      },
    }
  }

  handlePaginationChange = (page) => {
    let query = this.state.query;
    query.page = parseInt(page)
    this.setState({ isLoading: true });
    this.setState({ query })
    this.props.getPrestation(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let query = this.state.query;
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.setState({ isLoading: true });
    this.setState({ query })
    this.props.getPrestation(this.state.query, this.callBack);
  }

  callBack = () => {
    this.setState({
      isLoading: false
    })
  }
}

export default ClientController;

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/prestation';

const initialSate = {
  listPrestation: {},
  listPrestationIndependant: [],
  listSellerActivityArea: [],
}
const prestation = (state = initialSate, action) => {
  let response = action.payload
  switch (action.type) {
    case constants.CREATE_PRESTATION:
      let dataP = {...state.listPrestation}
      dataP.data = [...dataP.data,response]
      return {
        ...state,
        listPrestation: dataP
      }
    case constants.UPDATE_PRESTATION:
      let data = {...state.listPrestation}
      const dataPrestation = data.data.map((item) => {
        if (item.id === response.id) { item.hidden = !item.hidden; return item }
        else { return item }
      })
      data=dataPrestation;
      return {
        ...state,
        listPrestation: {data}
      }
    case constants.UPDATE_FULL_PRESTATION:
      let data1 = {...state.listPrestation}
      const dataPrestation1 = data1.data.map((item) => {
        if (item.id === response.id) { return response }
          else { return item }
      })
      data1= dataPrestation1
      return {
        ...state,
        listPrestation: {data1}
      }
    case constants.GET_PRESTATION:
      return {
        ...state,
        listPrestation: response
      }
    case constants.REMOVE_PRESTATION:
      let dataR = {...state.listPrestation}
      let newData= dataR.data.filter(i => i.id !== action.payload)
      dataR.data= newData
      return {
        ...state,
        listPrestation : dataR
        // listPrestation: [...state.listPrestation.data.filter(i => i.id !== action.payload)]
      }
    case constants.GET_PRESTATION_INDEPENDANT:
      return {
        ...state,
        listPrestationIndependant: response
      }
    case constants.ADD_INDEPENDANT_PRESTATION:
      // let dataI = {...state.listPrestation}
      // dataP.data = [...dataP.data,response]
      return {
        ...state,
        listPrestation: {...state.listPrestation}
      }
    case constants.LOGOUT:
      return initialSate;

    default:
      return state;
  }

}

export default prestation;
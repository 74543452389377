export const GET_ORDER_NOTICE = "GET_ORDER_NOTICE"
export const GET_REVIEWS = "GET_REVIEWS"
export const GET_ORDER_REVIEW = "GET_ORDER_REVIEW"
export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW"
export const LOGOUT = 'LOGOUT';

export const url = {
    orderNotice: '/order/notice',
    reviews: '/product/review',
    reportReview: '/product/review/report',
    validateReview: '/product/review/validate',
  };

export default {
    GET_ORDER_NOTICE,
    LOGOUT,
    GET_REVIEWS,
    GET_ORDER_REVIEW,
    GET_PRODUCT_REVIEW
}
import React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-light-accordion/demo/css/index.css';
import 'react-dropdown/style.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import 'moment/locale/fr'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import PopupHandler from "./../../components/popup/popupHandler";

import AgendaController, { reduxConnect } from './controller';

const localizer = momentLocalizer(moment);
const myDefaultView = 'month';

class Agenda extends AgendaController {
  render() {
    const { listAllEvent, listResultSearch, isSync, listCalendarGoogle } = this.props.agenda;
    const { nextSaleOrder } = this.props.commandes
    const { infoLogin } = this.props.commons;
    const { agenceSellers } = this.props.sellers;
    const { selectedSeller } = this.state;
    const isAgence = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_AGENCE');
    const messages = {
      allDay: 'journée',
      previous: 'préc.',
      next: 'suiv.',
      today: 'aujourd\'hui',
      month: 'mois',
      week: 'semaine',
      day: 'jour',
      agenda: 'Agenda',
      date: 'date',
      time: 'heure',
      event: 'événement', // Or anything you want
      noEventsInRange: 'Il n\'y a aucun événement dans cette plage.',
      showMore: total => `+ ${total} événement(s) supplémentaire(s)`
    }
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Mon agenda</h1>
            </div>
          </div>
          <div>
            {this.state.removeEventError && PopupHandler.showError("La suppression de l'événement a échoué.")}
            {this.state.removeEventError === false && PopupHandler.showSuccess("L'événement a bien été supprimé.")}
            {this.state.syncError && PopupHandler.showError("La synchronisation a échoué.")}
            {this.state.syncError === false && PopupHandler.showSuccess("Succès de la synchronisation.")}
            {this.state.unSyncError && PopupHandler.showError("La désynchronisation a échoué.")}
            {this.state.unSyncError === false && PopupHandler.showSuccess("Succès de la désynchronisation.")}
            {this.state.addEventError && PopupHandler.showError("La création de l'événement a échoué.")}
            {this.state.addEventError === false && PopupHandler.showSuccess("L'événement a bien été créé.")}
            {this.state.exportError && PopupHandler.showError("L'export a échoué.")}
            {this.state.exportError === false && PopupHandler.showSuccess("Succès de l'export.")}
          </div>
          <div className="main-agenda">

            <div className="wrapper clr">
              <div className="row mainBody agendabody">
                <div className="col col-5 right-agenda">
                  { isAgence &&
                    <div className="cnt-sidebar choix-seller">
                      <div className="title-sidebar">Choix du prestataire</div>
                      <div className="main-sidebar">
                        <select
                          value={this.state.selectedSeller}
                          onChange={this.handleSellerChange}
                        >
                          <option value="" key="0">Selectionner un prestataire</option>
                          { agenceSellers && agenceSellers.map(seller =>
                            <option value={seller.id} key={seller.id}>{`${seller.firstname || ''} ${seller.lastname || ''}`}</option>
                          )}
                        </select>
                      </div>
                    </div>
                  }
                  {
                    !isAgence &&
                    <div className="cnt-sidebar">
                      <div className="title-sidebar">Prochain rendez-vous</div>
                      <div className="main-sidebar">
                        {nextSaleOrder && nextSaleOrder.dateCommand &&
                          <div className="main-S">
                            <p className="title-prestation">{nextSaleOrder.productName}</p>
                            <p>{nextSaleOrder.categoryName}</p>
                          </div>
                        }
                        {nextSaleOrder && nextSaleOrder.dateCommand ?
                          <div className="main-S">
                            <p className="title-date-prestation">Date et heure prestation</p> 
                          <p>{nextSaleOrder.dateCommand ? moment(new Date(nextSaleOrder.dateCommand)).format('DD/MM/YYYY') : ""}</p>
                          </div>
                          :
                          <div className="main-12">
                            <p className="title-date-prestation">Aucune commande à venir</p>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  {
                    (!this.state.isSearch && !selectedSeller) &&
                    <div className="cnt-sidebar content-accordeon">
                      <Accordion atomic={false}>
                        <AccordionItem title="Ajouter un événement à l’agenda" className="active">
                          <div className="cnt-tab">
                            <p>L’ajout d’événement ou d’indisponibilité à votre agenda vous rendra non visible dans le catalogue durant cette période.</p>
                            <div className="line-input">
                              <label>Titre</label>
                              <input
                                type="text"
                                className="input-nom"
                                value={this.state.titleEventAdd}
                                onChange={(event) => this.updateState(event)}
                                name="titleEventAdd"
                              />
                            </div>
                            <div className="line-input slot-event">
                              <div className="slotEvent-item">
                                <label className="date-debut">Début</label>
                                <div className="select-down select-date-debut">
                                  <i className="material-icons arrow-down">keyboard_arrow_down</i>
                                  <DayPickerInput
                                    inputProps={{ readOnly: true }}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={"Choisir"}
                                    value={this.state.startEventAdd ? new Date(this.state.startEventAdd) : null}
                                    initialMonth={new Date()}
                                    onDayChange={(day) => { this.setState({ startEventAdd: day }) }}
                                    getDayPicker={(e) => {
                                      this.setState({ startEventAdd: e.target.value })
                                    }}
                                    dayPickerProps={{
                                      modifiers: {
                                        disabled: this.setDisabledDate(listAllEvent, "start")
                                      },
                                      localeUtils: MomentLocaleUtils, 
                                      locale: "fr"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="slotEvent-item">
                                <label className="date-debut">Fin</label>
                                <div className="select-down select-date-fin">
                                  <i className="material-icons arrow-down">keyboard_arrow_down</i>
                                  <DayPickerInput
                                    inputProps={{ readOnly: true }}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={this.state.endEventAdd ? new Date(this.state.endEventAdd) : null}
                                    onDayChange={(day) => { this.setState({ endEventAdd: day }) }}
                                    placeholder={"Choisir"}
                                    initialMonth={new Date()}
                                    dayPickerProps={{
                                      modifiers: {
                                        disabled: this.setDisabledDate(listAllEvent, "end")
                                      },
                                      localeUtils: MomentLocaleUtils, 
                                      locale: "fr"
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="cnt-Btn cnt-btn-agenda">
                              <button
                                className={`button ${this.isValidAdd() ? '' : 'buttonIsInvalid'}`}
                                disabled={!this.isValidAdd()}
                                onClick={(event) => this.addEvent(event)}
                                name="validateAdd"
                              >
                                Ajouter événement
    													</button>
                              <div className="clr"></div>
                            </div>
                          </div>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  }
                </div>
                <div className="col col-7">
                  <div className="filter-agenda">
                    {
                      !this.state.isSearch  &&
                      <button
                        className={"link-sinchro"}
                        disabled={isSync === null || selectedSeller}
                        onClick={() => { isSync !== null && isSync ? this.setUnSync() : this.startSync(null) }}

                      >{isSync !== null ? isSync ? "Désynchroniser avec Google calendar" : "Synchroniser avec Google Calendar" : "Synchroniser avec Google Calendar"}
                      </button>
                    }
                    {
                      this.state.isSearch && <button className="link-sinchro" onClick={() => { this.setState({ isSearch: false }) }}>Retour</button>
                    }
                    <div className="search-agenda">
                      <button
                        type="button"
                        className="btn-search"
                        disabled={this.state.searchText === ""}
                        onClick={this.searchEventSubmit}
                      >
                        <i className="material-icons icon-search">search</i>
                      </button>
                      <input type="text"
                        value={this.state.searchText}
                        onChange={(event) => { this.updateState(event) }}
                        onKeyDown={this.handleSearchKeyDown}
                        name="searchText"
                        placeholder="Recherche…" />
                    </div>
                  </div>
                  {
                    !this.state.isSearch &&

                    <div className="cnt-agenda scrollbar">
                      <Calendar
                        localizer={localizer}
                        events={listAllEvent.map(i => {
                          i.start = moment(i.start).format('YYYY-MM-DD');
                          i.end = moment(i.end).format('YYYY-MM-DD');
                          return i
                        })}
                        // startAccessor="start"
                        views={['month', 'agenda']}
                        defaultView={myDefaultView}
                        style={{ height: 400 }}
                        onSelectEvent={(event) => { this.openModal(event) }}
                        selectable={true}
                        startAccessor="start"
                        endAccessor="end"
                        onNavigate={this.onNavigate}
                        messages={messages}
                      />
                    </div>
                  }
                  {!this.state.isSearch &&
                    <div className="cnt-Btn cnt-btn-agenda">
                      <button
                        className={`button ${this.isExportValid() ? '' : 'buttonIsInvalid'}`}
                        disabled={!this.isExportValid() || selectedSeller}
                        onClick={(event) => this.exportAgenda(event)}
                        name="exportAgenda"
                      >Exporter mon agenda</button>
                      <div className="clr"></div>
                    </div>
                  }
                  {
                    this.state.isSearch &&
                    <div className="cnt-search">
                      {/* <div className="line-search-agenda date-pass">
                        <div className="date-search-agenda">
                          <span>13/05/2020</span>
                        </div>
                        <div className="heure-search-agenda">
                          <span>8:30 à 16:00</span>
                        </div>
                        <div className="title-search-agenda">
                          <span>Event search title Event search title Event search title Event search title</span>
                        </div>
                      </div>
                      <div className="line-search-agenda date-pass">
                        <div className="date-search-agenda">
                          <span>26/06/2020</span>
                        </div>
                        <div className="heure-search-agenda">
                          <span>Toute la journée</span>
                        </div>
                        <div className="title-search-agenda">
                          <span>Event independance</span>
                        </div>
                      </div>
                      <div className="line-search-agenda">
                        <div className="date-search-agenda">
                          <span>27/07/2020</span>
                        </div>
                        <div className="heure-search-agenda">
                          <span>Toute la journée</span>
                        </div>
                        <div className="title-search-agenda">
                          <span>Event 2</span>
                        </div>
                      </div> */}
                      {this.state.isSearch && listResultSearch && listResultSearch.length > 0 && listResultSearch.map((value) =>
                        <div className="line-search-agenda" key={`${value.id}-prestation`} onClick={() => this.openModal(value)} >
                          <div className="date-search-agenda">
                            <span>{(value.start && moment(value.start).format('DD/MM/YYYY')) || ""}</span>
                          </div>
                          <div className="heure-search-agenda">
                            <span>{(value.end && moment(value.end).format('DD/MM/YYYY')) || ""}</span>
                          </div>
                          <div className="title-search-agenda">
                            <span>{value.title}</span>
                          </div>
                        </div>
                      )
                      }
                      {
                        listResultSearch && listResultSearch.length === 0 && <h2>Pas de resultat</h2>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="wrapper-modal">
              <Modal visible={this.state.visible} width="400" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="content-modal modal-evenement">
                  <div className="head-modal">
                    <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                    <h1>{this.state.eventSelected && this.state.eventSelected.title}</h1>
                  </div>
                  <div className="main-modal">
                    <div className="line-input">
                      <label>Titre :</label>
                      <div className="cnt-input">
                        <p>{this.state.eventSelected && this.state.eventSelected.title}</p>
                      </div>
                    </div>
                    <div className="line-input">
                      <label>Date debut :</label>
                      <div className="cnt-input">
                        <p>{this.state.eventSelected && this.state.eventSelected.start.split(" ")[0]}</p>
                      </div>
                    </div>
                    <div className="line-input">
                      <label>Date fin :</label>
                      <div className="cnt-input">
                        <p>{this.state.eventSelected && this.state.eventSelected.end.split(" ")[0]}</p>
                      </div>
                    </div>
                    <div className="cnt-Btn align-right">
                      <button
                        className="button"
                        onClick={() => {
                          if (this.state.eventSelected !== null && !selectedSeller) {
                            this.removeEvent()
                          }
                          else {
                            this.closeModal()
                          }
                        }}>Supprimer</button>
                      <div className="clr"></div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="wrapper-modal">
              <Modal
                visible={this.state.visibleCommon}
                width="400"
                effect="fadeInUp"
                onClickAway={() => this.closeModalCommon()}
              >
                <div className="content-modal">
                  <div className="head-modal">
                    <button className="close-modal" onClick={() => this.closeModalCommon()}><i className="material-icons icons-close">close</i></button>
                    <h1>{this.state.titleCommon}</h1>
                  </div>
                  <div className="main-modal">
                    {
                      this.state.messageCommon && <p>{this.state.messageCommon}</p>
                    }
                    <div className="cnt-Btn align-right">
                      {
                        (this.state.selectAgenda || (this.state.messageCommon && this.state.titleCommon !== "Succés")) &&
                        <button className="btn-cancel" onClick={() => this.closeModalCommon()}>Annuler</button>
                      }
                      <button
                        className="button"
                        onClick={() => {
                          if (this.state.titleCommon && this.state.titleCommon === "Succés") {
                            this.setState({ isLoading: true });
                            this.closeModalCommon();
                            this.getAllData()
                          }
                          else {
                            this.submitLogin()
                          }
                        }}>{this.state.titleSubmitCommon}</button>
                      <div className="clr"></div>
                    </div>
                  </div>
                </div>

                {
                  this.state.listCalendar &&
                  <div className="content-modal">
                    <div className="head-modal">
                      <button className="close-modal" onClick={() => this.closeModalCommon()}><i className="material-icons icons-close">close</i></button>
                      <h1>Selectionner le calendrier a synchroniser</h1>
                    </div>
                    <div className="main-modal">
                      <div className="line-input">
                        <label>Agenda dans google</label>
                        <div className="select-down">
                          <Dropdown
                            className="MyclassName"
                            // options={setOption().map(i => i.language)}
                            // onChange={(value) => this.selectLang(value, )}
                            value={this.state.nameLangue ? this.state.nameLangue : "Ajouter une langue"}
                            placeholder="Selectionner une langue"
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cnt-Btn align-right">
                      <button className="button" onClick={() => this.closeModalE()}>Ok</button>
                      <div className="clr"></div>
                    </div>
                  </div>
                }
              </Modal>
            </div>
            <div className="wrapper-modal">
              <Modal
                visible={this.state.visibleSelectCalendar}
                width="400"
                effect="fadeInUp"
                onClickAway={() => this.closeModalSelectCalendar()}
              >
                <div className="content-modal">
                  <div className="head-modal">
                    <button className="close-modal" onClick={() => this.closeModalSelectCalendar()}><i className="material-icons icons-close">close</i></button>
                    <h1>Selectionner le calendrier a synchroniser</h1>
                  </div>
                  <div className="main-modal">
                    <div className="line-input">
                      <label>Agenda dans google</label>
                      <div className="select-down">
                        <Dropdown
                          className="MyclassName"
                          options={listCalendarGoogle && listCalendarGoogle.length > 0 ? listCalendarGoogle.filter(i => !i.id.includes('#')).map(i => i.summary) : []}
                          onChange={(value) => this.selectCalendar(value)}
                          value={this.state.selectedCalendar ? this.state.selectedCalendar : "Selectionner"}
                          placeholder="Selectionner une langue"
                          controlClassName='myControlClassName'
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          name="CalendarGoogle"
                        />
                      </div>
                    </div>
                    <div className="cnt-Btn align-right">
                      <button
                        className={`button ${this.state.selectedCalendar !== "" ? '' : 'buttonIsInvalid'}`}
                        disabled={this.state.selectedCalendar === ""}
                        onClick={() => this.setSync()}
                      >Ok</button>
                      <div className="clr"></div>
                    </div>
                  </div>

                </div>
              </Modal>
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}
export default reduxConnect(Agenda);
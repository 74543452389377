import React from 'react';
import Dropdown from 'react-dropdown';
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import Rating from 'react-rating';
import moment from 'moment';

import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import TousLesHotelsController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";
import { getCommissionLabel } from "./../../../../data/constants/commission.label";
import { getSellerType } from '../../../../data/constants/seller.type';

class TousLesHotels extends TousLesHotelsController {
  renderPagination = () => {
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={this.state.data && this.state.data.nbPage ? this.state.data.nbPage * this.state.data.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={this.state.data && this.state.data.itemPerPage ? this.state.data.itemPerPage : 5}
            activePage={this.state.data && this.state.data.page ? this.state.data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  renderTabHotel() {
    const { listAllHotel } = this.props.hotels;
    const { infoLogin } = this.props.commons;
    const sellerType = getSellerType(infoLogin);
    return (
      <div className="table table-prestation-hotel">
        <div className="table-responsive scrollbar">
          <table>
            <thead>
              <tr>
                <th>Hôtel</th>
                <th>Contact</th>
                <th>
                  Distance 
                  <span className="arrow clickable" onClick={this.handleSortChange}>
                    <i className="material-icons icon-arrow_drop_up">
                      {this.state.orderDistance.icon}
                    </i>
                  </span>
                </th>
                {
                  sellerType !== 3 &&
                  <th> </th>
                }
              </tr>
            </thead>
            <tbody>
              {listAllHotel && listAllHotel.data && Array.isArray(listAllHotel.data) && listAllHotel.data.length > 0 && listAllHotel.data.map((value, index) =>
                {
                  const typePartenerships = (value.partenership && value.partenership.typePartenerships) || null;
                  const dateStartPartenariat = (value.partenership && value.partenership.dateStartPartenariat) || null
                  return (
                    <tr key={`hotel1 ${index}`}>
                      <td>
                        <p className="name-product">{value.name}</p>
                        {
                          (value.star) &&
                          <div className="star-hotel">
                            <Rating
                              readonly
                              placeholderRating={(value.star && parseInt(value.star)) || 0}
                              emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                              placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                            />
                          </div>
                        }
                        {value.address ? `${value.address.streetAddress1}, ${value.address.zipCode} ${value.address.city}` : ''}
                      </td>
                      <td>
                        <p>{value.email}</p>
                        <p>{value.address && value.address.phone}</p>
                      </td>
                      <td>
                        <span className="txt-distance">{typePartenerships === 2 ? "Début partenariat" : "Distance"}
                        </span> {typePartenerships === 2 ? dateStartPartenariat ? moment(dateStartPartenariat.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') : "Non définie" : value.distance > 0 ? `${parseFloat(value.distance || 0).toFixed(2)} km` : "Non définie"}
                      </td>
                      {
                        sellerType !== 3 &&
                        <td>
                          {
                            (!typePartenerships || (typePartenerships === 4)) &&
                            <div className="cnt-btn-strock">
                              <button onClick={() => this.openModal(value)} className="button mch-button">Demande de partenariat</button>
                            </div>
                          }
                          {
                            typePartenerships && typePartenerships !== 4 &&
                            <div className="text-right">
                              <span className="txt-distance">Commission hôtel </span>{(value.partenership && getCommissionLabel(infoLogin, value.partenership.commission, value.partenership.commissions)) || ""}                            
                            </div>
                          }
                        </td>
                      }
                    </tr>
                )}
              )}
            </tbody>
          </table>
        </div>
        <div>{this.state.data && this.state.data.total > 0 && this.renderPagination()}</div>
      </div>
    )
  }

  render() {
    let { listAllActivityArea, listSellerActivityArea } = this.props.activityArea;
    const { infoLogin } = this.props.commons;
    listAllActivityArea = listAllActivityArea ? [...listAllActivityArea.filter(i => i.name !== null)] : []
    const setOption = () => {
      return listAllActivityArea && Array.isArray(listAllActivityArea) && listSellerActivityArea ?
        listAllActivityArea.filter(item => listSellerActivityArea.map(i => i.zoneId).includes(item.zoneId) === false).map(i => ({value: i.zoneId, label: i.name})):
        [{value: "", label: ""}]

    }
    const type = getSellerType(infoLogin);
    return (
      <div className="main-tabs">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.requestError && PopupHandler.showError("L'envoi de la requête a échoué.")}
            {this.state.requestError === false && PopupHandler.showSuccess("Requête envoyée avec succès.")}
            {this.state.commissionError && PopupHandler.showError("Erreur lors de la récuperation des commissions.")}
          </div>
          <p className="title-zone">Zone d’activité</p>
          <p>Veillez à bien remplir cette rubrique, elle vous permettra d’être mis en contact avec les hôtels situés dans les zones d’activité que vous aurez déclaré.</p>
          <div className="line-input">

            <div className="select-down select-prestation">
              <Dropdown
                className="MyclassName"
                //options={setOption(false).filter(i => i.name).map(i => i.name)}
                options={setOption()}
                onChange={(value) => {
                  this.selectActivityZone(value, listAllActivityArea);
                }}
                value={"Ajouter"}
                controlClassName=''
                arrowClassName='arrow-down'
                arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                required={true}
              />

            </div>
            <div className="list-sous-cat">
              {
                listSellerActivityArea && Array.isArray(listSellerActivityArea) && listSellerActivityArea.map((k, index) =>
                  <div className="item-sous-cat" key={k.zoneId}>{k.name}
                    <div onClick={() => this.removeActivityZone(k.zoneId)} className="arrow">
                      <i className="material-icons arrow-cancel">cancel</i>
                    </div>
                  </div>)
              }
            </div>
            <div className="list-sous-cat">
            </div>
          </div>
          <div className="cnt-filtre">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="block-search-filter">
                <div>
                  <h3>Rechercher hôtel</h3>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Rechercher…"
                    value={this.state.name}
                    onChange={(event) => {
                      this.updateState(event)
                    }}
                    onKeyDown={this.handleSearchKeyDown}
                    name="name"
                  />
                </div>
                <div className="cnt-btn">
                  <button
                    type="button"
                    className={`button ${this.searchIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                    onClick={this.sendSearch}
                    disabled={!this.searchIsNotVoid()}
                  >
                    Rechercher
                </button>
                </div>
              </div>
            </form>
          </div>
          {this.renderTabHotel()}
          <div className="wrapper-modal">
            <Modal visible={this.state.visible && this.state.selectedHotel} 
              width="400" 
              /* height="520px"  */
              effect="fadeInUp" 
              onClickAway={() => this.closeModal()}
            >
              <div className="content-modal modal-reponse">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                  <h1>Demande de partenariat</h1>
                  <p> {this.state.selectedHotel && this.state.selectedHotel.name ? this.state.selectedHotel.name : "Nom de l'hotel"}</p>
                </div>
                <div className="main-modal scrollbar">
                  <p><span className="title-infos-commande">Contact</span></p>
                  <p>{this.state.selectedHotel && this.state.selectedHotel.name ? this.state.selectedHotel.name : "Nom de l'hotel"}
                    <br />
                    {this.state.selectedHotel && `${this.state.selectedHotel.nomContact || ""} ${this.state.selectedHotel.prenomContact || ""}`}
                  </p>
                  <p>{this.state.selectedHotel && this.state.selectedHotel.email ? this.state.selectedHotel.email : "email de l'hotel"}
                    <br />{this.state.selectedHotel && this.state.selectedHotel.address && this.state.selectedHotel.address.phone ? this.state.selectedHotel.address.phone : "Telephone non défini"}  </p>
                  {
                    infoLogin && infoLogin.roles && (infoLogin.roles.includes('ROLE_SELLER_INSTITUT') || infoLogin.roles.includes('ROLE_SELLER_RESTAURENT')) &&
                    <div className="line-input">
                      <p><span className="title-infos-commande">Commission hôtel</span></p>
                      <div className="btn-check">
                        {
                          type !== 1 && type !== 3 && 
                          getCommissionLabel(infoLogin, "1" , this.state.commissions)
                        } 
                      </div> 
                    </div>
                  }
                  <div className="line-input">
                    <label>Message pour l’hôtel</label>
                    <div className="cnt-input">
                      <textarea
                        value={this.state.messageRequest}
                        onChange={(event) => this.updateStateSimple(event)}
                        name="messageRequest"
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="cnt-Btn align-right">
                    <button className="btn-cancel" type="button" onClick={() => this.closeModal()} >Annuler</button>
                    <button
                      className={`button ${this.isRequestValid() ? '' : 'buttonIsInvalid'}`}
                      disabled={!this.isRequestValid}
                      onClick={(event) => this.requestPartenership(event)}
                    >Envoyer demande
                  </button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="wrapper-modal">
            <Modal
              visible={this.state.errorVisible}
              width="400"
              /* height="52%" */
              effect="fadeInUp"
              onClickAway={() => this.closeModalError()}
            >
              <div className="content-modal">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModalError()}><i className="material-icons icons-close">close</i></button>
                  <h1>{this.state.titleErrorShow}</h1>
                </div>
                <div className="main-modal">
                  {this.state.messageErrorShow ?
                    Array.isArray(this.state.messageErrorShow)
                      && this.state.messageErrorShow.length > 0
                      ? this.state.messageErrorShow.map((i, index) =>
                        <p key={`${index}-error`}>
                          {i.code}
                        </p>
                      )
                      :
                      <p>{this.state.messageErrorShow}</p>
                    : ""
                  }
                  <div className="cnt-Btn align-right">
                    <button className="button" onClick={() => this.closeModalError()}>Ok</button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(TousLesHotels);

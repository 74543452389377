import React from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import Input from 'react-phone-number-input/input';
import Select, { components } from "react-select";
import LoadingOverlay from 'react-loading-overlay';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import ContactHotelController, { reduxConnect } from './controller';
import { LIST_PAYS } from "./../../../../data/constants/listePays.js";
import PopupHandler from "../../../components/popup/popupHandler";



const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

const options = getCountries().map((country) => ({
  label: en[country] + ' +' + getCountryCallingCode(country),
  chipLabel: '+' + getCountryCallingCode(country),
  value: country
}));

class ContactHotel extends ContactHotelController {

  render() {
    const { infoLogin } = this.props.commons;
    return (
      <div className="cnt-tab">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.updateError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
            {this.state.updateError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
            {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="cnt-form">
              <div className="row">
                <div className="col col-6 line-input">
                  <label>{`Nom du contact de l’${infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? "indépendant" : "établissement"}`}
                  </label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-nom-contact-et"
                      value={this.state.firstName ? this.state.firstName : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="firstName"
                    />
                    
                    {
                      this.state.errors.firstName.length > 0 &&
                      this.state.errors.firstName !== "default" &&
                      <span className='error'>{this.state.errors.firstName}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>{`Prénom du contact de l’${infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? "indépendant" : "établissement"}`}</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-prenom-contact-et"
                      value={this.state.lastName ? this.state.lastName : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="lastName"
                    />
                    
                    {
                      this.state.errors.lastName.length > 0 &&
                      this.state.errors.lastName !== "default" &&

                      <span className='error'>{this.state.errors.lastName}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>{`Adresse e-mail du contact de l’${infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? "indépendant" : "établissement"}`}</label>
                  <div className="cnt-input">
                    <input
                      type="email"
                      className="input-mail-contact-et"
                      value={this.state.email ? this.state.email : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="email"
                    />
                    
                    {
                      this.state.errors.email.length > 0 &&
                      this.state.errors.email !== "default" &&
                      <span className='error'>{this.state.errors.email}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>Rue du contact</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adress-contact-et"
                      value={this.state.address ? this.state.address.streetAddress : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="streetAddress"
                    />
                    
                    {
                      this.state.errors.streetAddress.length > 0 &&
                      this.state.errors.streetAddress !== "default" &&
                      <span className='error'>{this.state.errors.streetAddress}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Ville</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.address && this.state.address.city ? this.state.address.city : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="city"
                    />
                    
                    {
                      this.state.errors.city.length > 0 &&
                      this.state.errors.city !== 'default' &&
                      <span className='error'>{this.state.errors.city}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Pays</label>
                  <div className="cnt-input">
                    <select
                      value={(this.state.address && this.state.address.country) || ""}
                      onChange={(value) => this.updateStateAddress(value)}
                      placeholder="Select"
                      name="country"
                    >
                      <option value="">{"Select"}</option>
                      {LIST_PAYS.map(item =>
                        <option key={`item-${item.value}`} value={item.value}>{item.label}</option>
                      )}
                    </select>
                    {/* <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.address && this.state.address.country ? this.state.address.country : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="country"
                    /> */}
                    {
                      this.state.errors.country.length > 0 &&
                      this.state.errors.country !== 'default' &&
                      <span className='error'>{this.state.errors.country}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Code postal</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.address && this.state.address.zipCode ? this.state.address.zipCode : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="zipCode"
                    />
                    
                    {
                      this.state.errors.zipCode.length > 0 &&
                      this.state.errors.zipCode !== 'default' &&
                      <span className='error'>{this.state.errors.zipCode}</span>
                    }

                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>{`Numéro de téléphone du contact de l’${infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? "indépendant" : "établissement"}`}</label>
                  <div className="PhoneInput">
                    <Select
                      onChange={(value) => this.handleUserSelect(value)}
                      options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                      components={{ SingleValue }}
                      value={options.find(op => { return op.value === (this.state.currentCountry) })}
                    />
                    <Input
                      country={this.state.currentCountry}
                      international
                      value={this.state.phone ? this.state.phone : ""}
                      onChange={((value) => this.updateStateNumber(value))}
                      onBlur={(event) => this.handleNumberInput(event)}
                      name='phone'
                    />
                    {
                      this.state.errors.currentUserNumberError.length > 0 &&
                      this.state.errors.currentUserNumberError !== "default" &&
                      <span className='error'>{this.state.errors.currentUserNumberError}</span>
                    }
                  </div>
                </div>
                {
                  infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') &&
                  <div className="col col-6 line-input">
                    <label>Date de naissance de l'indépendant</label>
                    <div className="">
                      <DatePickerInput
                        disabled={this.state.disabled}
                        displayFormat='DD/MM/YYYY'
                        returnFormat='YYYY-MM-DD'
                        value={this.state.birthdayContact ? new Date(this.state.birthdayContact) : null}
                        className='my-react-component'
                        onChange={(jsDate, dateString) => this.updateDayPick(dateString)}
                        showOnInputClick
                        placeholder='Selectionner'
                        locale='fr'
                        maxDate={new Date()}
                        onClear={() => this.setState({ birthdayContact: null })}
                        readOnly={true}
                      />
                    </div>
                    <div className='ui input'>

                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="cnt-Btn">
              <input
                className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                type="submit"
                value="Enregistrer"
                disabled={!this.isNotVoid()}
              />
              <div className="clr"></div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(ContactHotel);
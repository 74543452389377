export const CREATE_PRESTATION = "CREATE_PRESTATION"
export const UPDATE_PRESTATION = "UPDATE_PRESTATION"
export const UPDATE_FULL_PRESTATION = "UPDATE_FULL_PRESTATION"
export const GET_PRESTATION = "GET_PRESTATION"
export const REMOVE_PRESTATION = "REMOVE_PRESTATION"
export const ADD_INDEPENDANT_PRESTATION = "ADD_INDEPENDANT_PRESTATION"
export const GET_PRESTATION_INDEPENDANT = "GET_PRESTATION_INDEPENDANT"
export const LOGOUT = 'LOGOUT';



export const url = {
    prestation: '/seller/prestation',
    update: '/seller/prestation/toggle-hide/',
    addIndependantPrestation : '/seller/prestation/add',
    prestationIndependant : '/seller/prestation-interne',
  };

export default {
    CREATE_PRESTATION,
    UPDATE_PRESTATION,
    UPDATE_FULL_PRESTATION,
    GET_PRESTATION,
    REMOVE_PRESTATION,
    ADD_INDEPENDANT_PRESTATION,
    GET_PRESTATION_INDEPENDANT,
    LOGOUT,
}
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {hidePopUp, hidePopupExtra} from "../../components/popup/popup";
import Auth from '../../../services/authentification';
import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import Headers from "../../components/headers/headers";
import { EXPIRES_DATE_ERROR } from "../../../services/redux/constants/commons";

const auth = new Auth();

class MainContainer extends React.Component {

  componentDidUpdate(prevProps){
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
    const {commons} = this.props;
    if (commons && commons.expiresDate) {
      hidePopUp();
      hidePopupExtra();
      this.deconnexion();
    }
  }

  deconnexion = () => {
      const { history } = this.props;
      this.props.isLogout();
      if (history) {
        history.push(
          {
            pathname: "/login",
            state: {
              from: this.props.location, authenticationError: EXPIRES_DATE_ERROR
            }
          });
        auth.logout();
      }
    }

  render() {
      const {Component,componentProps, withHeader, errors} = this.props;
      const props = {
          ...componentProps,
          errors
      }
      return (
          <React.Fragment>
              { withHeader && <Headers/> }
              <Component {...props} />
          </React.Fragment>
          
      );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));

export const GET_ALL_LANGUAGE = 'GET_LANGUAGE';
export const GET_SELLER_LANGUAGE = 'GET_SELLER_LANGUAGE';
export const UPDATE_SELLER_LANGUAGE = 'UPDATE_SELLER_LANGUAGE';
export const REMOVE_SELLER_LANGUAGE = 'REMOVE_SELLER_LANGUAGE';
export const LOGOUT = 'LOGOUT';

export const url = {
    language : '/languages',
    sellerLanguage : '/seller/languages',
  };

export default {
    GET_ALL_LANGUAGE,
    GET_SELLER_LANGUAGE,
    UPDATE_SELLER_LANGUAGE,
    REMOVE_SELLER_LANGUAGE,
    LOGOUT,
}
import React, {Component} from 'react';
import  {hidePopupExtra} from './popup';

class PopupError extends Component {

    render = ()  => {
        return (
            <React.Fragment>
                <div className="popup-header">
                    <span>
                        <span className="ico dashicons dashicons-star-filled">
                            <i className="material-icons arrow-down">error</i>
                        </span>
                        <span className="title">{this.props.title}</span>
                        <span className="close-modal clickable" onClick={hidePopupExtra}>
                                <i className="material-icons icons-close">close</i>
                            </span>
                    </span>
                </div>
                <div className="popup-body">
                    {this.props.message}
                </div>
                <div className="popup-error-btn">
                    <button className="button" onClick={hidePopupExtra}>OK</button>
                </div>
            </React.Fragment>
        )
    }
    
}

export default PopupError
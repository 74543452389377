import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';


class EtablissementController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      asCustomer: false,
      buttonText: "Voir en tant que client",
      openHour: [],
      newStartDay: "Lundi",
      newEndDay: "Vendredi",
      newStartHour: "09:00",
      newEndHour: "18:00",
      visible: false,
      indexToUpdate: "",
      currentCountry: "US",
      currentUserNumber: "",
      currentUserNumberError: "default",
      primaryPic: null,
      primaryFile: null,
      subPic1: null,
      subFile1: null,
      subPic2: null,
      subFile2: null,
      subPic3: null,
      subFile3: null,
      endDayTab: [
        'Lundi', 'Mardi', 'Mercredi', 'jeudi', 'Vendredi', 'Samedi', 'Dimanche'
      ],
      endHourTab: [
        '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'
      ],
      etablissementName: "",
      etablissementAddress: "",
      etablissementDescri: "",
      etablissementAccess: "",
      errors: {
        etablissementName: 'default',
        etablissementAddress: 'default',
        etablissementAccess: 'default',
        etablissementDescri: 'default'
      },
      lang: [
        {
          text: 'test'
        }
      ],
      isLoading: false,
      preExpanded: [1],
      queryPrestation: {
        itemPerPage : 5,
        page : 1,
      },
      nbWSGet: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.updateStateNumber = this.updateStateNumber.bind(this);
    this.handleNumberInput = this.handleNumberInput.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    // this.renderHour = this.renderHour.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addNewOpenHour = this.addNewOpenHour.bind(this);
    this.updateNewHour = this.updateNewHour.bind(this);
    this.selectLang = this.selectLang.bind(this);
    this.removeLang = this.removeLang.bind(this);

  }
  selectLang(value) {
    const tabLang = [...this.state.lang];
    tabLang.push({
      text: value.value
    });
    this.setState({ lang: tabLang });
  }

  removeLang(index) {
    const tabLang = [...this.state.lang];
    tabLang.splice(index, 1);
    this.setState({ lang: tabLang });
  }
  componentWillMount = () => {
    if (this.props.location.state) {
      const defaultOpen = this.props.location.state && this.props.location.state.defaultOpen;
      if (defaultOpen) {
        let preExpanded = this.state.preExpanded;
        preExpanded = [...defaultOpen];
        this.setState({ preExpanded });
      }
    }
  }
  componentDidMount() {
    this.getAllData();
    const propsIdentiteEtablissement = this.props.etablissement.infoEtablissement;
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    if (propsIdentiteEtablissement) {
      this.setState({
        name: propsIdentiteEtablissement.name,
        logo: propsIdentiteEtablissement.logo
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevIdentiteEtablissement = prevProps.etablissement.infoEtablissement;
    const propsIdentiteEtablissement = this.props.etablissement.infoEtablissement;

    if (!prevIdentiteEtablissement) {
      return;
    }
    if (prevIdentiteEtablissement !== propsIdentiteEtablissement) {
      this.setState({
        name: propsIdentiteEtablissement.name,
        logo: propsIdentiteEtablissement.logo
      });
    }
}

  getAllData = async () => {
    this.setState({ isLoading: true, nbWSGet: 0 })
    const { idSeller } = this.props.sellers;
    await this.props.getAllLanguage(this.callBack);
    await this.props.getSellerLanguage(this.callBack);
    await this.props.getAllDeliveryPlaces(this.callBack);
    await this.props.getReviews(null, null,null,this.callBack);
    if (localStorage.getItem('type_compte') && localStorage.getItem('type_compte').includes('ROLE_SELLER_INDEPENDANT')) {
      await this.props.getSellerInfoIndependant(this.callBack);
      let query = this.state.queryPrestation;
      query.itemPerPage = 200
      await this.props.getPrestation(query,this.callBack);
    }
    else {
      await this.props.getOpeningTime(idSeller.id, this.callBack);
      if (localStorage.getItem('type_compte') && !localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE')) {
        await this.props.getInfoEtablissement(this.callBack);
      }
      await this.props.getPrestation(this.state.queryPrestation,this.callBack);
    }
    const queryHP = {
      itemPerPage:"ALL"
    }
    if (localStorage.getItem('type_compte') && !localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE')) {
      await this.props.getAllCategory(this.callBack);
      await this.props.getHotelPartner(queryHP,this.callBack);
    }
    // this.props.getAllKeywords(this.callBack);
  }
  callBack = (data, type) => {
    const { infoLogin } = this.props.commons;
    const typeSeller = (infoLogin && infoLogin.roles && ((infoLogin.roles.includes('ROLE_SELLER_AGENCE') && 3) || (infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') && 1))) || 1;
    if (type === 'updateIdentite') {
      let saveError = data && (data.status === 200 || data.status === 201) ? false : true;
      this.setState({
        saveError,
      })
    }else{
      const nbWSGet = this.state.nbWSGet + 1;
      this.setState({
        nbWSGet
      })
      if((typeSeller === 1 && nbWSGet === 8) || (typeSeller === 3 && nbWSGet === 6) || nbWSGet === 9){
        this.setState({ isLoading: false })
      }
    }
  }
  setLoadingTrue = () => {
    this.setState({ isLoading: true })
  }

  setLoadingFalse = () => {
    this.setState({ isLoading: false })
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.errors.etablissementName === "" && this.state.errors.etablissementAddress === ""
      && this.state.errors.etablissementDescri === ""
      && this.state.errors.etablissementAccess === ""
      && this.state.currentUserNumberError === ""
    ) {
      this.props.fetchApiUpdateIdentite(
        this.state.etablissementName,
        this.state.etablissementAddress,
        this.state.etablissementDescri,
        this.state.etablissementAccess,
        this.state.currentUserNumber,
        this.state.primaryPic,
        this.state.subPic1,
        this.state.subPic2,
        this.state.subPic3,
        this.callBack
      );
    }
    //fetchApiUpdateLang
  }

  handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ primaryPic: reader.result });
    }
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ primaryFile: URL.createObjectURL(e.target.files[0]) });
  };

  handleSubImageChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (this.state.subPic1 == null) {
        alert(reader.result);
        this.setState({ subPic1: reader.result });
      } else if (this.state.subPic2 == null) {
        this.setState({ subPic2: reader.result });
      } else if (this.state.subPic3 == null) {
        this.setState({ subPic3: reader.result });
      }
    }
    reader.readAsDataURL(e.target.files[0]);
    if (this.state.subFile1 == null) {
      this.setState({ subFile1: URL.createObjectURL(e.target.files[0]) });
    } else if (this.state.subFile2 == null) {
      this.setState({ subFile2: URL.createObjectURL(e.target.files[0]) });
    } else if (this.state.subFile3 == null) {
      this.setState({ subFile3: URL.createObjectURL(e.target.files[0]) });
    }
  }

  numberErrors() {
    if (isValidPhoneNumber(this.state.currentUserNumber)) {
      return "";
    } else {
      return "Numero invalide";
    }
  }

  updateStateNumber(value) {
    this.setState({ currentUserNumber: value });
  }

  handleNumberInput(value) {
    this.setState({ currentUserNumberError: this.numberErrors(value) });
  }

  handleUserSelect(value) {
    this.setState({ currentCountry: value.value });
  }

  updateState(event) {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'etablissementName':
        errors.etablissementName = this.props.errors.nameErrors(value);
        break;
      case 'etablissementAddress':
        errors.etablissementAddress = this.props.errors.nameErrors(value);
        break;
      case 'etablissementAccess':
        errors.etablissementAccess = this.props.errors.notEmptyInput(value);
        break;
      case 'etablissementDescri':
        errors.etablissementDescri = this.props.errors.notEmptyInput(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  openModal() {
    this.setState({ visible: true });
  }
  closeModal() {
    this.setState({ newStartDay: "Lundi" });
    this.setState({ newEndDay: "Vendredi" });
    this.setState({ newStartHour: "09:00" });
    this.setState({ newEndHour: "18:00" });
    this.setState({ indexToUpdate: "" });
    this.setState({
      endDayTab: [
        'Lundi', 'Mardi', 'Mercredi', 'jeudi', 'Vendredi', 'Samedi', 'Dimanche'
      ]
    });
    this.setState({
      endHourTab: [
        '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'
      ]
    });
    this.setState({
      visible: false
    });
  }

  addNewOpenHour = (startDay, endDay, startHour, endHour) => {
    const openHour = [...this.state.openHour];
    if (this.state.indexToUpdate !== "") {
      openHour[this.state.indexToUpdate]['startDay'] = startDay;
      openHour[this.state.indexToUpdate]['endDay'] = endDay;
      openHour[this.state.indexToUpdate]['startHour'] = startHour;
      openHour[this.state.indexToUpdate]['endHour'] = endHour;
    } else {
      openHour.push(
        {
          startDay: startDay,
          endDay: endDay,
          startHour: startHour,
          endHour: endHour
        }
      );
    }
    this.setState({ openHour: openHour });
    this.closeModal();
  }

  removeOpenHour(index) {
    const openHour = [...this.state.openHour];
    openHour.splice(index, 1);
    this.setState({ openHour: openHour });
  }

  clickButton(value) {
    this.setState({ asCustomer: !value });
    let text = value ? "Voir en tant que client" : "Voir en tant qu'administrateur";
    this.setState({ buttonText: text });
  }

  updateHour(index) {
    const updateHour = [...this.state.openHour];
    this.setState({ newStartDay: updateHour[index]['startDay'] });
    this.setState({ newEndDay: updateHour[index]['endDay'] });
    this.setState({ newStartHour: updateHour[index]['startHour'] });
    this.setState({ newEndHour: updateHour[index]['endHour'] });
    this.setState({ indexToUpdate: index });
    this.openModal();
  }

  updateNewHour(value, key) {
    const dayTab = ['Lundi', 'Mardi', 'Mercredi', 'jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const hourTab = [
      '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'
    ];
    if (key === 'newStartDay') {
      let index = dayTab.indexOf(value.value);
      let newTab = dayTab.splice(index);
      this.setState({ newEndDay: value.value });
      this.setState({ endDayTab: newTab });
    }
    if (key === 'newStartHour') {
      let index = hourTab.indexOf(value.value);
      let newTab = hourTab.splice(index);
      this.setState({ newEndHour: value.value });
      this.setState({ endHourTab: newTab });
    }
    this.setState({ [key]: value.value });
  }

  uploadLogo() {
    document.getElementById("selectImage").click();
  }

  uploadSubLogo() {
    document.getElementById("selectSubImage").click();
  }

  onImageChanged = (logo)=>{
    this.setState({
      logo
    })
  }

  onNameChanged = (name)=>{
    this.setState({
      name
    })
  }
}

export default EtablissementController;

//
// Redux connection
//

EtablissementController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
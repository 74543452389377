import sellerBdl from '../bdl/sellers';

export default {
  getSellerContact: () => sellerBdl.getSellerContact(),
  updateSellerContact: (data) => sellerBdl.updateSellerContact(data),
  getSellerInfoAccount: () => sellerBdl.getSellerInfoAccount(),
  updateSellerInfoAccount: (data) => sellerBdl.updateSellerInfoAccount(data),
  inscription: (data) => sellerBdl.inscription(data),
  getSellerId: () => sellerBdl.getSellerId(),
  getDataGraphe: () => sellerBdl.getDataGraphe(),
  getSellerInfoIndependant: () => sellerBdl.getSellerInfoIndependant(),
  updateSellerInfoIndependant: (data) => sellerBdl.updateSellerInfoIndependant(data),
  getSellerAgence: () => sellerBdl.getSellerAgence(),
  getRestaurantPriceRange : () => sellerBdl.getRestaurantPriceRange(),
  getRestaurantRestrictionTable : () => sellerBdl.getRestaurantRestrictionTable(),
  getAgenceSellers : () => sellerBdl.getAgenceSellers(),
}
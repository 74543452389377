import React from 'react';
import {Link} from 'react-router-dom'

import NewMdpController, {reduxConnect} from './controller'

class NewMdp extends NewMdpController {
    
    render() {
        return (
            <div>
                <div className="header">
                    <div className="wrapper clr">
                        <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
                    </div>
                </div>
                <div className="page">
                    <div className="bandeaux">
                        <div className="wrapper clr">
                            <h1>Changer mot de passe</h1>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="wrapper clr">
                        <form className="block-form" action="">
                            <div className="line-input">
                                <label>Nouveau mot de passe* </label>
                                <div className="cnt-input with-icon">
                                    <input 
                                        type="password" 
                                        value={this.state.userPassword} 
                                        onChange={(event) => this.updateState(event)}
                                        onBlur={(event) => this.handleUserInput(event)}
                                        name = "userPassword"            
                                    />
                                    <span className="edit"><i className="material-icons icon-lock">lock</i></span>
                                </div>
                                {
                                    this.state.errors.userPassword.length > 0 && 
                                    this.state.errors.userPassword !== 'default' && 
                                    <span className='error'>{this.state.errors.userPassword}</span>
                                }
                            </div>
                            <div className="line-input">
                                <label>Confirmation nouveau mot de passe*</label>
                                <div className="cnt-input with-icon">
                                    <input
                                    type="password"
                                    value={this.state.confirmNewsPass}
                                    onChange={(event) => this.updateState(event)}
                                    onBlur={(event) => this.handleUserInput(event)}
                                    name = "confirmNewsPass"
                                    />
                                    <span className="edit"><i className="material-icons icon-lock">lock</i></span>
                                    </div>
                                    {
                                        this.state.errors.confirmNewsPass.length > 0 &&
                                        this.state.errors.confirmNewsPass !== 'default' &&
                                        <span className='error'>{this.state.errors.confirmNewsPass}</span>
                                    }
                                </div>
                            <div className="cnt-Btn"><button className={`button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}>Enregistrer</button><div className="clr"></div></div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default reduxConnect(NewMdp);
import constants from '../constants/agenda';
import serviceApp from '../../applicatif/agenda';


export const getAllEvent = (sellerId, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllEvent(sellerId)
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_ALL_EVENT,
        payload,
      })
    }
  }
}

export const addEvent = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.addEvent(data)
    callBack && callBack(payload, "addEvent")
    if (payload && !payload.error) {
      return dispatch({
        type: constants.ADD_EVENT,
        payload
      })
    }
  }
}

export const removeEvent = (idEvent,callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.removeEvent(idEvent)
    if (payload) {
      callBack && callBack(payload, "removeEvent")
      if (payload.status && payload.status === 200) {
        return dispatch({
          type: constants.REMOVE_EVENT,
          payload: idEvent,
        })
      }
    }
  }
}

export const searchEvent = (data, callBack) => {
  return async (dispatch) => {
    const {name, sellerId} = data;
    const payload = await serviceApp.searchEvent(name, sellerId)
    if (payload.Error) {
      callBack && callBack(payload, "Error")
    }
    else {
      callBack && callBack(payload, "search")
      return dispatch({
        type: constants.SEARCH_EVENT,
        payload
      })
    }
  }
}

export const getGoogleAccessToken = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getGoogleAccessToken(data)
    callBack && callBack(payload, "getAccess")
    if (payload) {
      return dispatch({
        type: constants.GET_GOOGLE_ACCESS,
        payload,
      })
    }
  }
}

export const syncCalendar = (idCalendar, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.syncCalendar(idCalendar)
    if (payload) {
      callBack && callBack(payload, "syncCalendar")
      if (payload.status && payload.status === 200) {
        return dispatch({
          type: constants.SET_SYNC,
          payload,
        })
      }
    }
  }
}

export const unSyncCalendar = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.unSyncCalendar()
    if (payload) {
      callBack && callBack(payload, "unSyncCalendar")
      if (payload.status && payload.status === 200) {
        return dispatch({
          type: constants.SET_UNSYNC,
          payload,
        })
      }
    }
  }
}

export const getStateSync = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getStateSync()
    if (payload) {
      callBack && callBack(payload)
      if (payload.statusText && payload.statusText === "OK") {
        return dispatch({
          type: constants.STATE_SYNC,
          payload: payload.data.sync,
        })
      }

    }
  }
}

export const exportEvent = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.exportEvent(data)
    callBack && callBack(payload, "exportAgenda")
    // if (payload) {
    //   return dispatch({
    //     type: constants.EXPORT_EVENT,
    //     payload,
    //   })
    // }
  }
}
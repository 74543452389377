import React from 'react';
import { ReactSortable } from "react-sortablejs";
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';
import Collapse from './Collapse';

import PrestationIndependantController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";
import Options from "../options";

class PrestationIndependant extends PrestationIndependantController {
  renderCategorie = (category) => {
    const header = (
      <div
        className={this.state.panel.category && this.state.panel.category.id === category.id ? "wrapper-menuParent active" : "wrapper-menuParent"}>
        <span className="ico"><i className="material-icons icon-menu clickable handle">menu</i></span>
        <div className="menu-parent not-draggable">
          {(!category.children || !Array.isArray(category.children) || category.children.length < 1) &&
            <label className="checkbox-label">
              <input type="checkbox"
                checked={category.selected}
                onChange={(event) => { (listAllHotelPartner && listAllHotelPartner.length > 0) || !event.target.checked ? this.openModal(event) : this.handleCheckedChange(event) }}
                value={category.id}
              />
              <span className="check"></span>
            </label>
          }
          <span onClick={this.handleCategoryClick}
            data-id={category.id}
            className="titre-menu clickable">
            {category.name} {category.has_children ? `(${category.children.length})` : ""}
          </span>
        </div>
      </div>
    );
    const { listAllHotelPartner } = this.props.hotels;
    return (
      <div className="content-menu" data-id={category.id} key={category.id}>
        <Collapse
          title={header}
          noChildren={!category.has_children || category.children.length < 1}
        >
          {category.has_children && category.children.map(sousCategorie => sousCategorie.has_children ? this.renderSousCategorie(sousCategorie, category) : this.renderProduct(sousCategorie, category))}
        </Collapse>
      </div>
    );
  };

  renderSousCategorie = (sousCategorie, category) => {
    if (!this.state.stripe) {
      sousCategorie.enabled = 0;
    }
    sousCategorie.enabled = parseInt(sousCategorie.enabled);
    const header = (
      <div className="cnt-menu-sub">
        <span className="titre-menu">{sousCategorie.name} {sousCategorie.has_children ? `(${sousCategorie.children.length})` : ""}</span>
      </div>
    );
    return (
      <div className="menu-sub"
        key={sousCategorie.id}
      >
        <Collapse title={header}
        >
          {sousCategorie.children.map(product => product.has_children ? this.renderSousCategorie(product, category) : this.renderProduct(product, category))}
        </Collapse>
      </div>
    );
  };

  renderProduct = (product, category) => {
    const { listAllHotelPartner } = this.props.hotels;
    return (
      <div key={product.id}
        className={this.state.panel.product === product.id ? "sub-category active" : "sub-category"}
      >
        <label className="checkbox-label">
          <input type="checkbox"
            checked={product.selected}
            onChange={(event) => { (listAllHotelPartner && listAllHotelPartner.length > 0) || !event.target.checked ? this.openModal(event) : this.handleCheckedChange(event) }}
            value={product.id}
          />
          <span className="check"></span>
        </label>
        <span
          className="titre-menu-sub clickable"
          data-id={product.id}
          onClick={() => this.handleProductClick(product, category)}
        >
          {product.name}
          <span className="cnt-price">
            {
              !(category && category.flag && category.flag=== 'vtc') &&
              <span className="price-b">{product.price_cession ? parseFloat(product.price_cession).toFixed(2) : 0} €</span>
            }
          </span>
        </span>
      </div >
    );
  };

  render() {
    const { listCategories } = this.props.commons;
    let { listPrestation } = this.props.prestation;
    const { listAllHotelPartner } = this.props.hotels;
    this.renderListShow()
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div className="page">
            <div className="bandeaux position-static">
              <div className="wrapper clr">
                <h1>Prestation indépendant</h1>
              </div>
            </div>
            <div>
              {this.state.removeError && PopupHandler.showError("La suppression de la prestatation a échoué.")}
              {this.state.removeError === false && PopupHandler.showSuccess("La prestatation a bien été supprimée.")}
              {this.state.saveError && PopupHandler.showError("La sauvegarde de la prestation a échoué.")}
              {this.state.saveError === false && PopupHandler.showSuccess("Succès de la sauvegarde de la prestation.")}
              {this.state.addOptionError && PopupHandler.showError("L'ajout de l'option a échoué.")}
              {this.state.addOptionError === false && PopupHandler.showSuccess("Succès de l'ajout de l'option.")}
              {this.state.addDeliveryPlaceError && PopupHandler.showError("L'ajout du lieu a échoué.")}
              {this.state.addDeliveryPlaceError === false && PopupHandler.showSuccess("Succès de l'ajout du lieu.")}
              {this.state.removeDeliveryPlaceError && PopupHandler.showError("La supression du lieu a échoué.")}
              {this.state.removeDeliveryPlaceError === false && PopupHandler.showSuccess("Succès de la supression du lieu.")}
              
            </div>
            <div className="main main-prest-independant">
              <div className="wrapper clr">
                <div className="row main-tabs">
                  <div className="col col-5 menu-catalogue">
                    <ReactSortable
                      handle=".handle"
                      list={this.renderListShow()}
                      setList={newState => this.setState({ categories: newState })}
                      onEnd={this.handleSort}
                      disabled
                    >
                      {listCategories && listCategories.length > 0 && listPrestation && this.renderListShow().map(category =>
                        this.renderCategorie(category)
                      )}
                    </ReactSortable>
                  </div>
                  {
                    this.state.selectedProduct &&
                    <Options 
                      product= {this.state.selectedProduct} 
                      key={this.state.selectedProduct.id} 
                      setAddOptionSuccess={this.setAddOptionSuccess}
                      setAddDeliveryPlaceSuccess={this.setAddDeliveryPlaceSuccess}
                      setRemoveDeliveryPlaceSuccess={this.setRemoveDeliveryPlaceSuccess}
                      setLoadingFalse={this.props.setLoadingFalse}
                      setLoadingTrue={this.props.setLoadingTrue}
                      category={this.state.selectedProductCategory}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper-modal">
            <Modal
              visible={this.state.visible}
              width="450"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="content-modal">
                <div className="head-modal">
                  <button className="close-modal" type="button" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                  <h1>Selection hotel partenaire</h1>
                </div>
                <div className="main-modal">
                  <div className="col col-12 line-input">
                    <label>Afficher cette prestation dans les catalogues des hôtels partenaires suivants :</label>
                    {
                      listAllHotelPartner && listAllHotelPartner.map(item => {
                        const isChecked = this.state.hotels && this.state.hotels.includes(parseInt(item.id))
                        return <div className="cnt-input cnt-partenaire" key={`${item.id}-listHotelPartener`}>
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              checked={this.state.hotels && this.state.hotels.includes(parseInt(item.id))}
                              name="check-part"
                              onChange={() => this.onChangeListHotelPartener(parseInt(item.id), isChecked)}
                            />{item.name}
                            <span className="check"></span>
                          </label>
                        </div>
                      })
                    }
                  </div>
                  <div className="cnt-Btn align-right">
                    {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                    {
                      this.state.dataChecked && !this.state.dataChecked.checked &&
                      <button className="btn-cancel" onClick={() => this.handleCheckedChange()} >Supprimer</button>
                    }
                    <button
                      className={`button ${this.hotelIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                      type="button"
                      onClick={() => this.handleCheckedChange()}
                      disabled={!this.hotelIsNotVoid()}
                    >{this.state.dataChecked.checked ? "Ok" : "Modifier"}
                    </button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>

              {/* <div className="content-modal modal-reponse">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                </div>
                <div className="main-modal scrollbar">
                  <div className="col col-12 line-input">
                    <label>Afficher cette prestation dans les catalogues des hôtels partenaires suivants :</label>
                    {
                      listAllHotelPartner && listAllHotelPartner.map(item => {
                        const isChecked = this.state.hotels && this.state.hotels.includes(parseInt(item.id))
                        return <div className="cnt-input cnt-partenaire" key={`${item.id}-listHotelPartener`}>
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              checked={this.state.hotels && this.state.hotels.includes(parseInt(item.id))}
                              name="check-part"
                              onChange={() => this.onChangeListHotelPartener(parseInt(item.id), isChecked)}
                            />{item.name}
                            <span className="price-origine">Prix après marge hôtel : {item.price}€</span>
                            <span className="check"></span>
                          </label>
                        </div>
                      })
                    }
                  </div>

                  <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={() => this.closeModal()}>Annuler</button>
                    <button
                      onClick={(event) => this.sendRequest(event)}
                    >
                      Envoyer votre réponse
                  </button> */}
              {/* <div className="clr"></div>
                  </div>
                </div> */}
              {/* </div> */}

            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(PrestationIndependant);

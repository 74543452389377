import constants from '../constants/hotels';
import serviceApp from '../../applicatif/hotels';


export const getHotelPartner = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getHotelPartner(data)
    if (payload) {
      callBack && callBack(payload,"pagination")
      if (payload && payload.status && payload.status === 200) {
        return dispatch({
          type: data.itemPerPage==="ALL"? constants.GET_HOTEL_PARTENER_ALL : constants.GET_HOTEL_PARTENER,
          payload: payload.data,
        })
      }
    }
  }
}

export const getAllHotel = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllHotel(data)
    if (payload) {
      callBack && callBack(payload, "allHotel")
      if (payload && payload.status && payload.status === 200) {
        return dispatch({
          type: constants.GET_ALL_HOTEL,
          payload: payload.data,
        })
      }
    }
  }
}

export const getRequetePartenariat = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getRequetePartenariat(data)
    callBack && callBack(payload)
    if (payload && payload.status && payload.status === 200) {
      return dispatch({
        type: data && data.status === 1 ? constants.GET_PARTENERSHIP_REQUEST_1 : constants.GET_PARTENERSHIP_REQUEST_5,
        payload: payload.data,
      })
    }
  }
}

export const requestPartenership = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.requestPartenership(data);
    callBack && callBack(payload, "requestPartner");
    if(payload){
      // if (payload && payload.status && payload.status === 201) {
      //   return dispatch({
      //     type: constants.GET_PARTENERSHIP_REQUEST,
      //     payload: data,
      //   })
      // }
    }
    
  }
}

export const acceptPartenership = (data, id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.acceptPartenership(data, id)
    callBack && callBack(payload,"acceptPartenership")
    if (payload) {
      // return dispatch({
      //   type: constants.GET_PARTENERSHIP_REQUEST,
      //   payload,
      // })
    }
  }
}

export const endPartenership = (id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.endPartenership(id);
    callBack && callBack(payload,"endPartenership")
    if (payload) {
      // return dispatch({
      //   type: constants.END_PARTENERSHIP,
      //   payload,
      // })
    }
  }
}

export const denyPartenership = (id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.denyPartenership(id)
    callBack && callBack(payload, "denyPartenership")
    if (payload) {
      return dispatch({
        type: constants.DENY_PARTENERSHIP,
        payload,
      })
    }
  }
}

export const getHotelFilter = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getHotelFilter()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_HOTEL_FILTER,
        payload: payload,
      })
    }
  }
}

export const getDefaultCommission = (codeHotel, callBack) => {
  return async () => {
    const payload = await serviceApp.getDefaultCommission(codeHotel)
    callBack && callBack(payload,"getDefaultCommission");
  }
}

export const getPricePerCuterly = (codeHotel, callBack) => {
  return async () => {
    const payload = await serviceApp.getPricePerCuterly(codeHotel)
    callBack && callBack(payload,"getPricePerCuterly");
  }
}

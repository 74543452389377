
import commandesBdl from '../bdl/commandes';

export default {
    getOrderSale : (data, id) => commandesBdl.getOrderSale(data,id),
    getNextOrderSale : () => commandesBdl.getNextOrderSale(),
    acceptSaleOrder:(id) => commandesBdl.acceptSaleOrder(id),
    denySaleOrder:(data) => commandesBdl.denySaleOrder(data),
    cancelSaleOrder:(data) => commandesBdl.cancelSaleOrder(data),
    exportOrder:(ids, sellerId) => commandesBdl.exportOrder(ids, sellerId),
    getOrderDetails:(id) => commandesBdl.getOrderDetails(id),
    getOrderBill:(id, idSeller) => commandesBdl.getOrderBill(id, idSeller),
    downloadOrderBill:(id, idSeller) => commandesBdl.downloadOrderBill(id, idSeller),
    refundOrder:(data) => commandesBdl.refundOrder(data),

}
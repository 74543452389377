import constants from '../constants/bank';
import serviceApp from '../../applicatif/bank';


export const getBankInfo = (callBack) => {
	return async (dispatch) => {
		const payload = await serviceApp.getBankInfo();
		callBack && callBack(payload, "getBankInfo");
		if (payload) {
			return dispatch({
				type: constants.RECEIVE_BANK_INFO,
				payload,
			})
		}
	}
}

export const verifyBankFile = (callBack) => {
	return async (dispatch) => {
		const payload = await serviceApp.verifyBankFile();
		callBack && callBack(payload, "verifyBankFile");
		if (payload  && payload.status && payload.status === 200) {
			return dispatch({
				type: constants.GET_BANK_INFO_FILE,
				payload,
			})
		}
	}
}

export const sendBankInfo = (data, callBack) => {
	return async (dispatch) => {
		const payload = await serviceApp.sendBankInfo(data);
		callBack && callBack(payload, "update");
		if (payload && payload.status && payload.status === 200) {
			return dispatch({
				type: constants.SEND_BANK_INFO,
				payload: data,
			})
		}
	}
}

export const uploadBankFile = (data, callBack) => {
	return async (dispatch) => {
		const payload = await serviceApp.uploadBankFile(data);
		callBack && callBack(payload, "updateBank")
		if (payload && payload.status && payload.status === 200) {
			return dispatch({
				type: constants.SEND_BANK_FILE,
				payload: data,
			})
		}
	}
}


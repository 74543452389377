import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment'

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";


class InformationSiegeController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        streetAddress: "",
        city: "",
        country: "",
        zipCode: ""
      },
      addressContact: {
        streetAddress: "",
        city: "",
        country: "",
        zipCode: ""
      },
      birthdayContact:null,
      email: "",
      emailContact: "",
      firstNameContact: "",
      lastNameContact: "",
      name: "",
      errors: {
        address: 'default',
        addressContact: '',
        birthdayContact: 'default',
        email: 'default',
        emailContact: 'default',
        firstNameContact: 'default',
        lastNameContact: 'default',
        streetAddress: "default",
        city: "default",
        country: "default",
        zipCode: "default",
        streetAddressContact: "default",
        cityContact: "default",
        countryContact: "default",
        zipCodeContact: "default",
        name: '',
      },
      isLoading : false,
      showInput: true,
      disabled: false

    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { infoIdentiteEtablissement } = this.props.etablissement;
    if (infoIdentiteEtablissement) {
      this.setState({
        ...infoIdentiteEtablissement,
        address: infoIdentiteEtablissement && infoIdentiteEtablissement.address !== null ? infoIdentiteEtablissement.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
        addressContact: infoIdentiteEtablissement && infoIdentiteEtablissement.addressContact !== null ? infoIdentiteEtablissement.addressContact : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
        birthdayContact: infoIdentiteEtablissement.birthdayContact ? this.formatDate(infoIdentiteEtablissement.birthdayContact) :"",
      });
      for (let [key, value] of Object.entries(infoIdentiteEtablissement)) {
        if (key === "address" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            key1 && value1 && this.testErrors(key1, value1)
          }
        }
        else if (key === "addressContact" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            let keySend = ""
            switch (key1) {
              case 'city':
                keySend = "cityContact"
                break;
              case 'country':
                keySend = "countryContact"
                break;
              case 'streetAddress':
                keySend = "streetAddressContact"
                break;
              case 'zipCode':
                keySend = "zipCodeContact"
                break;
              default:
                break;
            }
            key && value && this.testErrors(keySend, value1)
          }
        }
        else {
          key && value && this.testErrors(key, value)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const prevInfoSiege = prevProps.etablissement.infoIdentiteEtablissement;
    const propsInfoSiege = this.props.etablissement.infoIdentiteEtablissement;

    if (!prevInfoSiege) {
      if (propsInfoSiege) {
        this.setState({
          ...propsInfoSiege,
          address: propsInfoSiege && propsInfoSiege.address !== null ? propsInfoSiege.address : {
            streetAddress: "",
            city: "",
            country: "",
            zipCode: ""
          },
          addressContact: propsInfoSiege && propsInfoSiege.addressContact !== null ? propsInfoSiege.addressContact : {
            streetAddress: "",
            city: "",
            country: "",
            zipCode: ""
          },
          birthdayContact: propsInfoSiege.birthdayContact ? this.formatDate(propsInfoSiege.birthdayContact) :""
        });
        for (let [key, value] of Object.entries(propsInfoSiege)) {
          if (key === "address" && value !== null) {
            for (let [key1, value1] of Object.entries(value)) {
              key1 && value1 && this.testErrors(key1, value1)
            }
          }
          else if (key === "addressContact" && value !== null) {
            for (let [key1, value1] of Object.entries(value)) {
              let keySend = ""
              switch (key1) {
                case 'city':
                  keySend = "cityContact"
                  break;
                case 'country':
                  keySend = "countryContact"
                  break;
                case 'streetAddress':
                  keySend = "streetAddressContact"
                  break;
                case 'zipCode':
                  keySend = "zipCodeContact"
                  break;
                default:
                  break;
              }
              keySend && value1 && this.testErrors(keySend, value1)
            }
          }
          else {
            key && value && this.testErrors(key, value)
          }
        }

      }
      return;
    }
    if (prevInfoSiege !== propsInfoSiege) {
      this.setState({
        ...propsInfoSiege,
        address: propsInfoSiege && propsInfoSiege.address !== null ? propsInfoSiege.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
        addressContact: propsInfoSiege && propsInfoSiege.addressContact !== null ? propsInfoSiege.addressContact : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
        birthdayContact: propsInfoSiege.birthdayContact? this.formatDate(propsInfoSiege.birthdayContact): ""
      });
      for (let [key, value] of Object.entries(propsInfoSiege)) {
        if (key === "address" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            key1 && value1 && this.testErrors(key1, value1)
          }
        }
        else if (key === "addressContact" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            let keySend = ""
            switch (key1) {
              case 'city':
                keySend = "cityContact"
                break;
              case 'country':
                keySend = "countryContact"
                break;
              case 'streetAddress':
                keySend = "streetAddressContact"
                break;
              case 'zipCode':
                keySend = "zipCodeContact"
                break;
              default:
                break;
            }
            keySend && value1 && this.testErrors(keySend, value1)
          }
        }
        else {
          key && value && this.testErrors(key, value)
        }
      }
    }
  }

  callBack = async (data,type) => {
    const {steps} =this.props.dashboard;
    const fromHeadBand= (this.props.location.state && this.props.location.state.fromHeadBand) || false;
    if(type && type==="update"){
      const updateError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        updateError,
      })
      if (data && data.data && (data.data.code === 500 || data.data.code === 400) && data.data.message) {
        showPopupExtra(<PopupError title="Erreur" message={data.data.message}/>)
      }
      if (!updateError) {
        if (!steps.includes("1a")) {
          await this.props.addSteps({steps: ["1a"]},this.callBack);
        }else if (steps.includes("1b") && fromHeadBand) {
          this.setState({isLoading:false});
          this.props.history.push("/dashboard");
        }else{
          await this.props.getProgressionEtablissement(this.callBack)
        }
      }else{
        this.setState({isLoading:false});
      }
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      const stepsResponse = (data && data.data && data.data && data.data.steps) || [];
      this.setState({
        addStepError,
        isLoading:false
      })
      if(!addStepError && stepsResponse.includes("1b") && fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }else{
      this.setState({isLoading:false});
    }
    
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      name: this.state.name,
      address: this.state.address,
      email: this.state.email,
      firstNameContact: this.state.firstNameContact,
      lastNameContact: this.state.lastNameContact,
      addressContact: this.state.addressContact,
      emailContact: this.state.emailContact,
      birthdayContact: this.state.birthdayContact,
    };
    this.setState({isLoading:true});
    this.props.updateIdentityEtablissement(data, this.callBack);
    //setTimeout(() => this.setState({errorsFromServer: localStorage.getItem('API_ERROR')}),  1500);
  }
  testErrors(name, value) {
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameErrors(value);
        break;
      case 'email':
        errors.email = this.props.errors.emailErrors(value);
        break;
      case 'firstNameContact':
        errors.firstNameContact = this.props.errors.nameErrors(value);
        break;
      case 'lastNameContact':
        errors.lastNameContact = this.props.errors.nameErrors(value);
        break;
      case 'emailContact':
        errors.emailContact = this.props.errors.emailErrors(value);
        break;
      case 'birthdayContact':
        errors.birthdayContact = this.props.errors.notEmptyInput(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      case 'streetAddressContact':
        errors.streetAddressContact = this.props.errors.nameErrors(value);
        break;
      case 'cityContact':
        errors.cityContact = this.props.errors.nameErrors(value);
        break;
      case 'countryContact':
        errors.countryContact = this.props.errors.nameErrors(value);
        break;
      case 'zipCodeContact':
        errors.zipCodeContact = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  isNotVoid = () => {
    const {
      streetAddress,
      city,
      country,
      zipCode,
      streetAddressContact,
      cityContact,
      countryContact,
      zipCodeContact,
      email,
      emailContact,
      firstNameContact,
      lastNameContact,
      birthdayContact,
      name,
    } = this.state.errors;
    return streetAddress === "" && city === "" && country === "" && zipCode === "" && streetAddressContact === "" && cityContact === "" && countryContact === "" && zipCodeContact === "" && name === "" && email === "" && emailContact === "" && firstNameContact === "" && lastNameContact === "" && birthdayContact === ""
  }
  updateDayPick = (date) => {
    const errors = this.state.errors;
    const birthDay = moment().add('year', -18);
    const errorBirthdate = new Date(date) > birthDay ? "Le gérant doit avoir au moins 18ans." : ""
    errors.birthdayContact = this.props.errors.notEmptyInput(date) || errorBirthdate;
    this.setState({ errors, birthdayContact:date });

  }

  onChange = (value) => {
    this.setState({ birthdayContact: value });
    let errors = this.state.errors;
    errors.birthdayContact = this.props.errors.notEmptyInput(value);
    this.setState({ errors });
  };

  formatDate = (date) => {
    let d = new Date(date);
    let dt = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    let mn = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    let yy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    return yy + "-" + mn + "-" + dt
  }
  updateStateAddress = (value) => {
    let name = value.target.name;
    let address = this.state.address
    let addressContact = this.state.addressContact;
    switch (name) {
      case 'streetAddress':
        address.streetAddress = value.target.value
        break;
      case 'city':
        address.city = value.target.value
        break;
      case 'country':
        address.country = value.target.value
        break;
      case 'zipCode':
        address.zipCode = value.target.value
        break;
      case 'streetAddressContact':
        addressContact.streetAddress = value.target.value
        break;
      case 'cityContact':
        addressContact.city = value.target.value
        break;
      case 'countryContact':
        addressContact.country = value.target.value
        break;
      case 'zipCodeContact':
        addressContact.zipCode = value.target.value
        break;
      default:
        break;
    }
    this.handleUserInput(value);

    this.setState({ address, addressContact })
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event);
    this.setState({ [name]: event.target.value });
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameErrors(value);
        break;
      case 'address':
        errors.address = this.props.errors.notEmptyInput(value);
        break;
      case 'email':
        errors.email = this.props.errors.emailErrors(value);
        break;
      case 'firstNameContact':
        errors.firstNameContact = this.props.errors.nameErrors(value);
        break;
      case 'lastNameContact':
        errors.lastNameContact = this.props.errors.nameErrors(value);
        break;
      case 'emailContact':
        errors.emailContact = this.props.errors.emailErrors(value);
        break;
      case 'addressContact':
        errors.addressContact = this.props.errors.notEmptyInput(value);
        break;
      case 'birthdayContact':
        errors.birthdayContact = this.props.errors.notEmptyInput(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      case 'streetAddressContact':
        errors.streetAddressContact = this.props.errors.nameErrors(value);
        break;
      case 'cityContact':
        errors.cityContact = this.props.errors.nameErrors(value);
        break;
      case 'countryContact':
        errors.countryContact = this.props.errors.nameErrors(value);
        break;
      case 'zipCodeContact':
        errors.zipCodeContact = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }



  toggleInput = () => this.setState({ showInput: !this.state.showInput })



}

export default InformationSiegeController;

//
// Redux connection
//

InformationSiegeController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
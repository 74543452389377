import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";

// import history from '../../history';
import Inscription from "./inscription";
import Login from "./login";
import Dashboard from "./dashboard";
import ParametreCompte from './parametreCompte';
import GestionAdministration from './parametreCompte/gestionAdmin';
import MonAgenda from './agenda';
import ResultatRecherche from './agenda/resultatRecherche';
import EtablissementInstitut from './etablissement';
import EtablissementInstitutClient from './etablissement/client';
import EtablissementIndependant from './etablissement/independant';
import FichePrestation from './etablissement/fichePrestation';
import Prestation from './etablissement/prestation';
import PrestationIndependant from './etablissement/prestationIndependant';
import Password from './mdp';
import PasseOublie from './mdp/mdpOublie';
import NouveauPasse from './mdp/newMdp';
import Email from "./email/index";
import Auth from "../../services/authentification";
import Commande from "./commande/index";
import DetailCommande from "./commande/detailCommande";
import AvisCommande from "./commande/avisCommande";
import Facture from "./commande/facture";
import Hotel from "./hotel/index";
import Errors from "../../services/erreurs/formErrors";
import Remboursement from "./commande/remboursement";
import LandingPage from "./landing-page";
import Maincontainer from "./main/Maincontainer";



const auth = new Auth();
const errors = new Errors();

const ProtectedRoute = ({ component: Component, errors, withHeader, ...rest }) => (
  <Route {...rest} render={(props) => {
      if (auth.isAuthenticated()) {
        return <Maincontainer Component={Component} componentProps={props} errors={errors} withHeader={withHeader}/>
      }
      else {
        return <Redirect to={{
          pathname: '/login',
          state: {from: props.location}
      }} />
      }
  }} />
)

export default function ParamsExample() {
  return (
    <React.Fragment>
      <div id='popUp-container' />
      <div id='popUpExtra-container' />
      <BrowserRouter>
        <Router>
          <Switch>
            <Route exact path="/" >
              <LandingPage />
            </Route>
            <Route exact path="/inscription" >
              <Inscription
                errors={errors}
              />
            </Route>
            <Route exact path="/login" >
              <Login
                auth={auth}
                errors={errors}
              />
            </Route>
            <Route exact path="/mot-de-passe" >
              <Password
                errors={errors}
              />
            </Route>
            <Route exact path="/passe-oublie" >
              <PasseOublie
                errors={errors}
              />
            </Route>
            <Route exact path="/nouveau-mot-de-passe" >
              <NouveauPasse />
            </Route>
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/parametrecompte" errors={errors} component={ParametreCompte}/>
            <ProtectedRoute exact path="/gestionadministration" errors={errors} component={GestionAdministration}/>
            <ProtectedRoute exact path="/mon-agenda" component={MonAgenda}/>
            <ProtectedRoute exact path="/resultat-recherche" component={ResultatRecherche}/>
            <ProtectedRoute exact path="/etablissement-institut/:page" errors={errors} aaaa={"aaaaa"} component={EtablissementInstitut}/>
            <ProtectedRoute exact path="/etablissement-institut-client" component={EtablissementInstitutClient}/>
            <ProtectedRoute exact path="/etablissement-independant" component={EtablissementIndependant}/>
            <ProtectedRoute exact path="/fichePrestation/:page" errors={errors} component={FichePrestation}/>
            <ProtectedRoute exact path="/prestation" errors={errors} component={Prestation}/>
            <ProtectedRoute exact path="/commande" component={Commande}/>
            <ProtectedRoute exact path="/commande/detail/:id" component={DetailCommande}/>
            <ProtectedRoute exact path="/commande/avis-commande/:id" component={AvisCommande}/>
            <ProtectedRoute exact path="/commande/facture/:id/:idAgence" component={Facture}/>
            {/* <ProtectedRoute exact path="/commande/remboursement" withHeader errors={errors} component={Remboursement}/> */}
            <ProtectedRoute exact path="/hotel/:page" component={Hotel}/>
            <ProtectedRoute exact path="/prestationIndependant" errors={errors} component={PrestationIndependant}/>
            <Route exact path="/email" >
              <Email
                auth={auth}
              />
            </Route>
          </Switch>
        </Router>
      </BrowserRouter>
    </React.Fragment>
  );
}
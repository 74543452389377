import constants from '../constants/commons';
import serviceApp from '../../applicatif/commons';
import prestationApp from '../../applicatif/prestation';


export const getAllCategory = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllCategory()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_CATEGORIES,
        payload,
      })
    }
  }
}

export const getAllKeywords = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllKeywords()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_KEYWORDS,
        payload,
      })
    }
  }
}

export const login = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.login(data);
    if (payload && payload.error){
      callBack && callBack(payload,'error')
    }
    else if (payload && payload.data){
      callBack && callBack(payload.data);
      return dispatch({
        type: constants.GET_TOKEN,
        payload: payload.data
      })
    }
    else{
      callBack && callBack(payload)
    }
  }
}
export const setUserPassword = (data,token,callBack) => {
  return async (dispatch) => {
    const response = await serviceApp.setUserPassword(data, token);
    callBack && callBack(response,"setPassword");
    if (response && (response.status === 200 || response.status === 201 ||  response.status === 204)) {
      return dispatch({
        type: constants.GET_TOKEN,
        payload: response.data
      })
    }
  }
}

export const forgetPassword = (data,callBack) => {
  return async (dispatch) => {
    const response = await serviceApp.forgetPassword(data);
    if ((response && response.status === 200) || (response && response.status === 201) || (response && response.status === 204)) {
      callBack && callBack(response,"succes")
    }
    else {
      callBack && callBack(response,"echec")
    }
  }
}

export const checkToken = (data,callBack) => {
  return async () => {
    const payload = await serviceApp.checkToken(data);
    callBack && callBack(payload,"checkToken")
  }
}

export const getHotelCategories = (codeHotel, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getHotelCategories(codeHotel)
    callBack && callBack(payload, "getHotelCategories");
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.GET_HOTEL_CATEGORIES,
        payload: payload,
      })
    }
  }
}

export const addOption = (idPrestation, options,callBack) => {
  return async () => {
    const payload = await serviceApp.addOption(idPrestation, options);
    callBack && callBack(payload,"addOption");
  }
}

export const getAllDeliveryPlaces = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllDeliveryPlaces()
    callBack && callBack(payload, "getAllDeliveryPlaces");
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.GET_ALL_DELIVERY_PLACES,
        payload: payload.data,
      })
    }
  }
}

export const addDeliveryPlace = (data,callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.addDeliveryPlace(data);
    callBack && callBack(payload,"addDeliveryPlace");
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.ADD_DELIVERY_PLACE,
        payload: payload.data,
      })
    }
  }
}

export const removeDeliveryPlace = (id, serviceId, callBack) => {
  return async (dispatch) => {
    const payload = await prestationApp.removePrestation(serviceId);
    if (payload) {
      callBack && callBack(payload, "removeDeliveryPlace")
      if ((payload && payload.status === 201) || (payload && payload.status === 200)) {
        return dispatch({
          type: constants.REMOVE_DELIVERY_PLACE,
          payload: id,
        })
      }
    }
  }
}


export const isLogout = () => ({ type: 'LOGOUT' });

export const setExpireDate = (value) => {
  return async (dispatch) => {
    return dispatch({
      type: constants.EXPIRES_AT,
      payload: value,
    })
  }
}
import React from 'react';
import { Link } from 'react-router-dom';
import 'react-web-tabs/dist/react-web-tabs.css';
import Dropdown from 'react-dropdown';
import LoadingOverlay from 'react-loading-overlay';

import FichePrestationController, { reduxConnect } from './controller';
import Headers from "./../../../components/headers/headers";
import Footer from "./../../../components/footer/footer";
import 'react-dropdown/style.css';

import PopupConfirm from "../../../components/popup/popup.confirmation";
import {showPopUp} from "../../../components/popup/popup";
import PopupHandler from "../../../components/popup/popupHandler";

const HeurePrestation=()=>{
  let list = []
  for (let index = 0; index < 25; index ++) {
    list.push({
      value: `${index}`,
      label: index < 10 ? `0${index}`: `${index}`
    })
  }
  return list;
}

const MinutePrestation=()=>{
  let list = []
  for (let index = 0; index < 60; index += 5) {
    list.push({
      value: `${index}`,
      label: index < 10 ? `0${index}`: `${index}`
    })
  }
  return list;
}


class FichePrestation extends FichePrestationController {
  onDeletePrestation = () => {
    showPopUp(
      <PopupConfirm 
          message = "Voulez-vous vraiment supprimer cette prestation ?"
          onAccept = {this.removePrestation}
      />,
      "Confirmation"
    )
  };
  renderOption() {
    return (
      this.state.options.map(value =>
        <div className="row det-option" key={`${value.index}-option`}>
          <div className="col col-9 line-input">
            <label>Description de l’option</label>
            <div className="cnt-input">
              <input
                type="text"
                className="input-nom"
                value={value.description}
                onChange={(event) => this.updateStateOption(event, value.index)}
                name="description"
              />
              {
                value.errors && value.errors.description &&
                <span className='error'>{value.errors.description}</span>
              }
            </div>
          </div>
          <div className="col col-2 line-input">
            <label>Prix de l'option</label>
            <div className="cnt-input">
              <input
                type="text"
                className="input-nom"
                value={value.price}
                onChange={(event) => this.updateStateOption(event, value.index)}
                name="price"
                pattern="[0-9,.]*"
              //onBlur={(event) => this.state.handleUserInput(event)}
              />
              {
                value.errors && value.errors.price &&
                <span className='error'>{value.errors.price}</span>
              }
            </div>
          </div>
          <div className="col col-1 line-input align-right remove-option" onClick={() => this.removeOption(value.index)}>
            <Link to="#"><i className="material-icons icons-cancel">cancel</i></Link>
          </div>
        </div>
      )
    );
  }

  render() {
    const { listAllHotelPartner} = this.props.hotels;
    const { category, subCategories, subSubCategories } = this.state;
    const { listCategories } = this.props.commons;
    const CategoriePrestation = listCategories ? listCategories.map(i => i.name) : [];
    const defaultOption = "Selectionner";
    const reduction = [
      '0%', '5%', '10%', '15%', '20%', '25%', '30%', '35%', '40%', '45%', '50%', '55%', '60%'
    ];
    const categorie = listCategories && category && Array.isArray(listCategories) && listCategories.length > 0 ? listCategories.find(j => j.id.toString() === category.toString()) : [];
    const hasLevel3Category = this.hasLevel3Category();
    const SousCategoriePrestation = categorie && categorie.children ? categorie.children : [];
    const discountedPrice = parseFloat(`${this.state.price || '0'}`.replace(',','.')) * ((100 - (this.state.reduction || 0)) / 100);

    const setOption = (flag) => {
      return SousCategoriePrestation.filter(item => subCategories.map(i => i.toString()).includes(item.id) === flag);
    }
    
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>{!this.state.id ? "Créer une prestation" : "Modifier une prestation"}</h1>
              <Link to={{ pathname: "/etablissement-institut/Prestations" }} className="close"><span className="arrow"><i className="material-icons icon-close">close</i></span></Link>
            </div>
          </div>
          <div>
              {this.state.removeError && PopupHandler.showError("La suppression de la prestatation a échoué.")}
              {this.state.removeError === false && PopupHandler.showSuccess("La prestatation a bien été supprimée.")}
              {this.state.saveError && PopupHandler.showError("La sauvegarde de la prestation a échoué.")}
              {this.state.saveError === false && PopupHandler.showSuccess("Succès de la sauvegarde de la prestation.")}
            </div>
          <div className="wrapper main-independant">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="row mainBody">
                <div className="col col-12 line-input">
                  <label>Catégorie de la prestation</label>
                  <div className="select-down select-prestation">
                    <Dropdown
                      className="MyclassName"
                      defaultOption={defaultOption}
                      options={CategoriePrestation}
                      onChange={(event) => this.updateCategorie(event, listCategories)}
                      value={categorie ? categorie.name : 'Ajouter'}
                      controlClassName=''
                      label={"categorie"}
                      arrowClassName='arrow-down'
                      arrowClosed={<i className="material-icons arrow-down">{this.state.id ? "" : "keyboard_arrow_down"}</i>}
                      arrowOpen={<i className="material-icons arrow-down">{this.state.id ? "" : "keyboard_arrow_down"}</i>}
                      required={true}
                      disabled={this.state.id}
                    />
                    {
                      this.state.errors.category.length > 0 &&
                      this.state.errors.category !== 'default' &&
                      <span className='error'>{this.state.errors.category}</span>
                    }
                  </div>
                </div>
                { hasLevel3Category &&
                  <div className="col col-12 line-input">
                    <label>Sous-catégorie de la prestation</label>
                    <div className="select-down select-prestation">
                      <Dropdown
                        className="MyclassName"
                        defaultOption={defaultOption}
                        options={categorie && categorie.children && categorie.children.map(subCategory => ({value: subCategory.id, label: subCategory.name}))}
                        onChange={this.onSelectSubCategory}
                        value={this.state.selectedSubCategoryId}
                        controlClassName=''
                        label={"Sous-categorie"}
                        arrowClassName='arrow-down'
                        arrowClosed={<i className="material-icons arrow-down">{this.state.id ? "" : "keyboard_arrow_down"}</i>}
                        arrowOpen={<i className="material-icons arrow-down">{this.state.id ? "" : "keyboard_arrow_down"}</i>}
                        required={true}
                        disabled={this.state.id}
                      />
                      {
                        this.state.errors.subCategories.length > 0 &&
                        this.state.errors.subCategories !== 'default' &&
                        <span className='error'>{this.state.errors.subCategories}</span>
                      }
                    </div>
                  </div>
                }
                {
                  !hasLevel3Category && categorie && categorie.children && categorie.children.length > 0 &&
                  <div className="col col-12 line-input">
                    <label>Sous-catégorie de la prestation</label>
                    {!this.state.id &&
                      <div className="select-down select-prestation">
                        <Dropdown
                          className="MyclassName"
                          options={setOption(false).map(i => i.name)}
                          onChange={(value) => {
                            this.onSelect(value);
                          }}
                          value={"Ajouter"}
                          controlClassName=''
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          required={true}
                          disabled={this.state.id}
                        />
                      </div>
                    }
                    <div className="list-sous-cat">
                      {
                        setOption(true).map((k, index) =>
                          <div className="item-sous-cat" key={`${index}-sousC`}>{k.name}
                            {!this.state.id &&

                              <button type='button' onClick={() => this.onSelect({ value: k.name, label: k.name })} className="arrow">
                                <i className="material-icons arrow-cancel">cancel</i>
                              </button>
                            }
                          </div>
                        )
                      }
                      {
                        this.state.errors.subCategories.length > 0 &&
                        this.state.errors.subCategories !== 'default' &&
                        <span className='error'>{this.state.errors.subCategories}</span>
                      }
                      {/* <div className="item-sous-cat">Soin <span className="arrow"><i className="material-icons arrow-cancel" bt>cancel</i></span></div>
                                  <div className="item-sous-cat">Visage <span className="arrow"><i className="material-icons arrow-cancel">cancel</i></span></div> */}
                    </div>
                  </div>
                }
                
                {
                  hasLevel3Category && this.state.selectedSubCategory && this.state.selectedSubCategory.children && this.state.selectedSubCategory.children.length > 0 &&
                  <div className="col col-12 line-input">
                    <label>Sous-catégorie de la sous-catégorie</label>
                      {!this.state.id &&
                        <div className="select-down select-prestation">
                          <Dropdown
                            className="MyclassName"
                            options={this.getOptionsSubSubCategory()}
                            onChange={this.addSubSubCategory}
                            value={"Ajouter"}
                            controlClassName=''
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                            disabled={this.state.id}
                          />

                        </div>
                      }
                    <div className="list-sous-cat">
                      {
                        subSubCategories && subSubCategories.map(subSubCategory =>
                          <div className="item-sous-cat" key={subSubCategory.id}>{subSubCategory.name}
                            {!this.state.id &&
                              <button type='button' onClick={() => this.deleteSubSubCategory(subSubCategory.id)} className="arrow">
                                <i className="material-icons arrow-cancel">cancel</i>
                              </button>
                            }
                          </div>
                        )
                      }
                    </div>
                  </div> 
                }
                <div className="col col-7 line-input">
                  <label>Nom de la prestation</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-nom"
                      value={this.state.name}
                      onChange={(event) => this.updateStateSimple(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="name"
                      // onChange={(event) => this.ChangeName(event)}
                      required={true}
                    />
                    {
                      this.state.errors.name.length > 0 &&
                      this.state.errors.name !== 'default' &&
                      <span className='error'>{this.state.errors.name}</span>
                    }
                  </div>
                </div>
                <div className="col col-12 line-input">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="check-all"
                      checked={this.state.allowPrestationDuration}
                      onChange={(event) => this.toogleChange(event, "allowPrestationDuration")}
                      name="allowPrestationDuration"
                    />
                      Définir une durée pour la prestation
                  <span className="check"></span>
                  </label>
                </div>
                <div className="col col-12 line-input">
                  {this.state.allowPrestationDuration && <div><label>Durée de la prestation</label>
                    <div className="line-dure">
                      <div className="select-down heure-prestation">
                        <Dropdown
                          className="MyclassName"
                          options={HeurePrestation()}
                          defaultOption={0}
                          name="heurePrestation"
                          onChange={(value) => {
                            const v = parseInt(value.value);
                            this.setState({ duration: (v * 1 * 60) + (this.state.durationMinute * 1) });
                            this.handleDropdownChange(value, "durationHour");
                          }}
                          value={this.state.durationHour}
                          placeholder={0}
                          controlClassName=''
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                      </div>
                      <div className="txt">heure(s)</div>
                      <div className="select-down minute-prestation">
                        <Dropdown
                          className="MyclassName"
                          options={MinutePrestation()}
                          onChange={(value) => {
                            const v = parseInt(value.value);
                            this.setState({ duration: (this.state.durationHour * 1 * 60) + (v * 1) });
                            this.handleDropdownChange(value, "durationMinute")
                          }}
                          value={this.state.durationMinute}
                          controlClassName=''
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                      </div>
                      <div className="txt">minute(s)</div>
                    </div>
                  </div>}
                </div>
                <div className="col col-12 line-input">
                  <label>Description du produit</label>
                  <div className="cnt-input">
                    <textarea
                      value={this.state.description}
                      onChange={(event) => this.updateStateSimple(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="description"
                      required={true}
                    >
                    </textarea>
                    {
                      this.state.errors.description.length > 0 &&
                      this.state.errors.description !== 'default' &&
                      <span className='error'>{this.state.errors.description}</span>
                    }
                  </div>
                </div>
                <div className="col col-12 line-input">
                  <label>Ajouter une option</label>
                  <div className="btn-check">
                    <label className="check">
                      <input
                        type="radio"
                        checked={this.state.addOption === 'Yes'}
                        name="addOption"
                        onChange={(event) => this.updateState(event, "addOption")}
                        value="Yes"
                      />
                      <span className="radiobtn"></span> Oui
                                </label>
                    <label className="check">
                      <input
                        type="radio"
                        checked={this.state.addOption === 'No'}
                        name="addOption"
                        onChange={(event) => this.updateState(event, "addOption")}
                        value="No"
                      />
                      <span className="radiobtn"></span> Non
                                </label>
                  </div>
                </div>
                {this.state.addOption === "Yes" && <div className="col col-12 line-input">
                  {this.renderOption()}
                  <div className="cnt-Btn align-right cnt-btn-strock">
                    <button onClick={this.addOption} type="button" className="button">+ Ajouter une option</button>
                    <div className="clr"></div>
                  </div>
                </div>}
                <div className="col col-12 line-input">
                  <label>Prix de la prestation</label>
                  <div className="cnt-input price-prestation">
                    <input
                      type="text"
                      className="input-nom"
                      value={this.state.price}
                      name="price"
                      pattern="[0-9,.]*"
                      onChange={(event) => this.updateStateSimple(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                    />
                    {
                      this.state.errors.price.length > 0 &&
                      this.state.errors.price !== 'default' &&
                      <span className='error'>{this.state.errors.price}</span>
                    }
                  </div>
                </div>
                <div className="col col-12 line-input">
                  <label>Type d’avantage client</label>
                  <div className="cnt-input cnt-avantage">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={this.state.giveReduction}
                        onChange={(event) => this.reductionChange(event)}
                        name="addReduction"
                      />
                        Réduction %
                        <span className="check"></span>
                    </label>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={this.state.giveOffertProduct}
                        onChange={(event) => this.toogleChangePrestationOffert(event)}
                        name='addOffert'
                      />
                      Produit ou prestation offert
                      <span className="check"></span>
                    </label>
                  </div>
                </div>
                <div className="col col-12 line-input">
                  {this.state.giveReduction &&
                    <div>
                      <label>Réduction</label>
                      <div className="select-down select-reduction">
                        <Dropdown
                          className="MyclassName"
                          options={reduction}
                          onChange={(value) => this.handleDropdownChangeReduction(value)}
                          value={`${this.state.reduction}%`}
                          controlClassName=''
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                      </div>
                      <div className="defaultPrice">
                        Prix de votre prestation après réduction : {discountedPrice.toFixed(2)} €
                  </div>
                    </div>}
                </div>
                <div className="col col-12 line-input">
                  {this.state.giveOffertProduct &&
                    <div>
                      <label>Produit ou prestation offert(e)</label>
                      <div className="cnt-input price-prestation">
                        <input
                          type="text"
                          className="input-nom"
                          value={this.state.prestationOffert}
                          name="productToOffer"
                          onChange={(event) => this.setState({ prestationOffert: event.target.value })}
                        // onBlur={(event) => this.handleUserInput(event)}
                        />
                        {
                          this.state.productToOffer && this.state.errors.productToOffer.length > 0 &&
                          this.state.errors.productToOffer !== 'default' &&
                          <span className='error'>{this.state.errors.productToOffer}</span>
                        }
                      </div>
                    </div>}
                </div>
                <div className="col col-12 line-input">
                  <label>Afficher cette prestation dans les catalogues des hôtels partenaires suivants :</label>
                  {
                    listAllHotelPartner && listAllHotelPartner.map(item => {
                      const isChecked = this.state.hotels && this.state.hotels.includes(parseInt(item.id))
                      return <div className="cnt-input cnt-partenaire" key={`${item.id}-listHotelPartener`}>
                        <label className="checkbox-label">
                          <input 
                          type="checkbox" 
                          checked={isChecked} 
                          name="check-part"
                          onChange={() => this.onChangeListHotelPartener(parseInt(item.id), isChecked)}
                        />{item.name}
                          {/* <span className="price-origine">Prix après marge hôtel : {item.price}€</span> */}
                          <span className="check"></span>
                        </label>
                      </div>
                    })
                  }
                </div>
                {this.state.id && <div className="col col-12 delete-compte">
                  <p><span className={`link-delete clickable`} onClick={this.onDeletePrestation} >Supprimer cette prestation</span></p>
                  <p className="info-delete">Attention ! Cette action est irréversible, vous ne pourrez plus accéder à vos informations ou à vos prestations.</p>
                </div>
                }
                <div className="col col-12  cnt-Btn align-right">
                  <input
                    className={`button mch-button ${this.isValidForm() ? '' : 'buttonIsInvalid'}`}
                    type="submit"
                    value={!this.state.id ? "Enregistrer" : "Modifier"}
                  />
                  <div className="clr"></div>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}
export default reduxConnect(FichePrestation);

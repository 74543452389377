import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Link, Redirect } from 'react-router-dom';

import LandingPageController, { reduxConnect } from './controller';
import Auth from "../../../services/authentification";
import { EXPIRES_DATE_ERROR } from "../../../services/redux/constants/commons";
import PopupHandler from '../../components/popup/popupHandler';

const auth = new Auth();


class LandingPage extends LandingPageController {

  render() {
    const authenticationError = this.props.location.state && this.props.location.state.authenticationError;
    const from = this.props.location && 
        this.props.location.state && 
        this.props.location.state.from && 
        this.props.location.state.from.pathname &&
        this.props.location.state.from.pathname !== '/login' 
            ? this.props.location.state.from.pathname
            : '/';
    if (auth.isAuthenticated()) {
      return <Redirect to="/dashboard" />
    }
    return (
      <div ref={this.pageRef} className="page landing-page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div className="header">
            <div className="wrapper clr">
              <div className="logo"><Link to="#" className="img-logo">&nbsp;</Link></div>
              <div className="mainMenu"><Link to={{pathname: "login", state: { fromLandingPage: true, from: {pathname: from} }}} className="button">Se connecter</Link></div>
            </div>
          </div>
          <div className="banner banner-lp">
            <div className="wrapper clr">
              <div className="banner-text">
                MyConciergeHotel permet aux acteurs locaux de proposer leurs services auprès d’une clientèle hôtelière 3, 4, 5* via le site web de l’hôtel et les emails de pré-séjour.
              </div>
            </div>
          </div>
          {(from !== '/' || authenticationError === EXPIRES_DATE_ERROR) && PopupHandler.showPopupError("Erreur", "Votre session a expiré, veuillez vous reconnecter.")}
          <div className="main">
            <div className="wrapper clr">
              <div className="row">
                <div className="col-6 column-list-lp">
                  <h4 className="title-lp">Vos avantages</h4>
                  <ul className="list-lp">
                    <li className="list-item-lp">
                      <span className="icon-lp icon-7"></span>
                      Bénéficiez de la mise  en avant de vos services auprès des hôtels partenaires
                    </li>
                    <li className="list-item-lp">
                      <span className="icon-lp icon-6"></span>
                      Générez des revenus additionnels en gardant la main sur vos disponibilités 
                    </li>
                    <li className="list-item-lp">
                      <span className="icon-lp icon-5"></span>
                      Rencontrez une clientèle  qualitative grâce à un processus automatisé et simplifié 
                    </li>
                  </ul>
                </div>
                <div className="col-6 column-list-lp">
                  <h4 className="title-lp">Votre programme</h4>
                  <ul className="list-lp">
                    <li className="list-item-lp">
                      <span className="icon-lp icon-2"></span>
                      Enregistrez votre boutique en quelques clics
                    </li>
                    <li className="list-item-lp">
                      <span className="icon-lp icon-4"></span>
                      Créez les offres que vous souhaitez référencer
                    </li>
                    <li className="list-item-lp">
                      <span className="icon-lp icon-3"></span>
                      Confirmez vos demandes de réservation client
                    </li>
                    <li className="list-item-lp">
                      <span className="icon-lp icon-1"></span>
                      Recevez instantanément le paiement de votre prestation
                    </li>
                  </ul>
                </div>
              </div>
              <div className="align-center cnt-btn-strock">
                <Link to="inscription" className="button button-lp">
                  Commencer mon inscription
                </Link>
              </div>
              <div className="wrapper-video-lp">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/w_qOAIJ0VUg" frameBorder="0" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(LandingPage);

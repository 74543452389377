export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_HOTEL_CATEGORIES = 'GET_HOTEL_CATEGORIES';
export const GET_KEYWORDS = 'GET_KEYWORDS';
export const GET_TOKEN = 'GET_TOKEN';
export const REDIRECT_LOGIN = '/login';
export const REDIRECT_SUCCESS_LOGIN = '/accueil';
export const TOKEN = 'auth_token';
export const REFRESH_TOKEN = 'refresh_token';
export const TYPE_COMPTE = 'type_compte'
export const EXPIRES_AT = 'auth_expires_at';
export const SEND_FORGET_PASSWORD = 'SEND_FORGET_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const EXPIRE_SUBSCRIBE_TOKEN_ERROR = "Ce lien n'est plus actif.";
export const URL_ALREADY_DEFINED_ERROR = "Mot de passé déjà défini, le lien n'est plus actif.";
export const GET_ALL_DELIVERY_PLACES = 'GET_ALL_DELIVERY_PLACES';
export const ADD_DELIVERY_PLACE = 'ADD_DELIVERY_PLACE';
export const REMOVE_DELIVERY_PLACE = 'REMOVE_DELIVERY_PLACE';
export const WS_CALL_TIME = 'ws_call_time';
export const EXPIRES_DATE_ERROR = 'EXPIRES_DATE_ERROR';


export const url = {
    category : '/categories',
    hotelCategories: '/category/hotel',
    login: '/user/login',
    refreshToken: '/user/refresh',
    setUserPassword: '/user/update',
    checkToken : '/user/token/check',
    forgetPassword : '/user/password/forget',
    legalInformation : '/legalInformation',
    keywords : '/prestation/keywords',
    addOption: '/seller/prestation/option/add',
    deliveryPlaces: '/prestation/vtc',
    addDeliveryPlace: '/seller/prestation-vtc/add'
}

export default {
    GET_CATEGORIES,
    GET_HOTEL_CATEGORIES,
    GET_KEYWORDS,
    GET_TOKEN,
    REFRESH_TOKEN,
    REDIRECT_LOGIN,
    REDIRECT_SUCCESS_LOGIN,
    TOKEN,
    EXPIRES_AT,
    LOGOUT,
    TYPE_COMPTE,
    GET_ALL_DELIVERY_PLACES,
    ADD_DELIVERY_PLACE,
    REMOVE_DELIVERY_PLACE,
    WS_CALL_TIME,
    EXPIRES_DATE_ERROR
}
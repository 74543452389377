export const GET_IDENTITÉ_ETABLISSEMENT = 'GET_IDENTITÉ_ETABLISSEMENT';
export const UPDATE_IDENTITÉ_ETABLISSEMENT = 'UPDATE_IDENTITÉ_ETABLISSEMENT';
export const GET_INFO_ETABLISSEMENT = 'GET_INFO_ETABLISSEMENT';
export const CREATE_ETABLISSEMENT = 'CREATE_ETABLISSEMENT';
export const UPDATE_INFO_ETABLISSEMENT = 'UPDATE_INFO_ETABLISSEMENT';
export const GET_PROGRESSION_ETABLISSEMENT = 'GET_PROGRESSION_ETABLISSEMENT';
export const GET_LEGAL_INFO = 'GET_LEGAL_INFO';
export const SEND_LEGAL_INFO = 'SEND_LEGAL_INFO';
export const GET_OPENING_TIME = 'GET_OPENING_TIME';
export const CREATE_OPENING_TIME = 'CREATE_OPENING_TIME';
export const UPDATE_OPENING_TIME = 'UPDATE_OPENING_TIME';
export const REMOVE_OPENING_TIME = 'REMOVE_OPENING_TIME';
export const LOGOUT = 'LOGOUT';

export const url = {
    etablissementIdentité : '/establishmentIdentity',
    etablissement : '/establishment',
    progressionEtablissement :'/establishment/stateOfProgress',
    legalInformation : '/legalInformation',
    openingTime: '/etablishment/opening-time',
    contactEtablissement: '/establishment/contact',
  };

export default {
    GET_IDENTITÉ_ETABLISSEMENT,
    UPDATE_IDENTITÉ_ETABLISSEMENT,
    GET_INFO_ETABLISSEMENT,
    CREATE_ETABLISSEMENT,
    UPDATE_INFO_ETABLISSEMENT,
    GET_PROGRESSION_ETABLISSEMENT,
    GET_LEGAL_INFO,
    SEND_LEGAL_INFO,
    GET_OPENING_TIME,
    CREATE_OPENING_TIME,
    UPDATE_OPENING_TIME,
    REMOVE_OPENING_TIME,
    LOGOUT,   
}
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import { REDIRECT_LOGIN } from '../../../../services/redux/constants/commons';


class MdpOublieController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      errors: {
        userEmail: 'default',
      },
      isLoading: false,
      visible: false,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal(title,text) {
    this.setState({
      visible: true,
      titleShow: title,
      textShow: text,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
      userEmail: "",
    });
  }
  redirectSuccess = () => {
    const { history } = this.props;
    if (history) history.push(REDIRECT_LOGIN);
    window.location.href = REDIRECT_LOGIN;
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.errors.userEmail === "") {
      const data = { email: this.state.userEmail }
      this.setState({ isLoading: true })
      this.props.forgetPassword(data, this.callBack);
      //TODO affichage message d'erreur si mail n'existe pas dans  la  base
    }
  }
  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'userEmail':
        errors.userEmail = this.props.errors.emailErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event)
    this.setState({ [name]: event.target.value });
  }

  callBack = (data,type) => {
    this.setState({ isLoading: false, userEmail: "" })
    if (type==="succes"){
      this.openModal("Succès","Votre demande a été traiter, verifier votre boite mail")
    }
    else{
      this.openModal("Echec","Echec de la demande, verifier l'adresse email ou la connexion")
    }
  }

}

export default MdpOublieController;

//
// Redux connection
//

MdpOublieController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/dashboard';
import serviceApp from '../../applicatif/dashboard';

export const getInfoDashboard = (query, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getInfoDashboard(query)
    callBack && callBack(payload, "getInfoDashboard")
    if (payload) {
      return dispatch({
        type: constants.INFO_DASHBOARD,
        payload,
      })
    }
  };

};

export const getAllInfoDashboard = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAllInfoDashboard()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.INFO_DASHBOARD_ALL,
        payload,
      })
    }
  };
};

export const getSteps = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSteps()
    callBack && callBack(payload,"getSteps")
    if (payload) {
      return dispatch({
        type: constants.STEPS_FINISHED,
        payload: payload.steps || [],
      })
    }
  };
};

export const addSteps = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.addSteps(data)
    callBack && callBack(payload,"addSteps")
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.STEPS_FINISHED,
        payload: (payload.data && payload.data.steps) || [],
      })
    }
  };
};
import constants from '../constants/sellers';

const initialSate = {
  infoContactSeller: {},
  infoAccountSeller: {},
  infoSellerIndependant: {},
  listSellerAgence: [],
  listRestaurantPriceRange: [],
  listRestaurantRestrictionTable: [],
  idSeller: '',
  c: [],
  agenceSellers: []
}
const sellers = (state = initialSate, action) => {
  let response = action.payload
  switch (action.type) {
    case constants.GET_SELLER_CONTACT:
      return {
        ...state,
        infoContactSeller: response,
      }
    case constants.UPDATE_SELLER_CONTACT:
      return {
        ...state,
        infoContactSeller: response,
      }
    case constants.GET_SELLER_INFO_ACCOUNT:
      return {
        ...state,
        infoAccountSeller: response
      }
    case constants.UPDATE_SELLER_INFO_ACCOUNT:
      return {
        ...state,
        infoAccountSeller: response
      }
    case constants.GET_ID_SELLER:
      return {
        ...state,
        idSeller: response
      }
    case constants.GET_DATA_GRAPHE:
      return {
        ...state,
        dataGraphe: response
      }
    case constants.GET_SELLER_INFO_INDEPENDANT:
      return {
        ...state,
        infoSellerIndependant: response
      }
    case constants.UPDATE_SELLER_INFO_INDEPENDANT:
      return {
        ...state,
        infoSellerIndependant: response
      }
    case constants.GET_SELLER_AGENCE:
      return {
        ...state,
        listSellerAgence: response
      }
    case constants.GET_RESTAURANT_PRICE_RANGE:
      return {
        ...state,
        listRestaurantPriceRange: response
      }
    case constants.GET_RESTAURANT_RESTRICTION_TABLE:
      return {
        ...state,
        listRestaurantRestrictionTable: response
      }
    case constants.GET_AGENCE_SELLERS:
      return {
        ...state,
        agenceSellers: response
      }
    case constants.LOGOUT:
      return initialSate;
    default:
      return state;
  }

}

export default sellers;
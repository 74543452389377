import { get, post, remove } from '.';
import { url } from '../redux/constants/activityArea'

const testResponse = (response) => {
    if (!response || !response.data) return false;
    return response.data;
}

// const testResponseData = (response) => {
//     if (!response || !response.data || !response.data.data) return false;
//     return response.data.data;
// }

export default {
    getAllActivityArea: async () => {
        const response = await get(url.allActivityArea);
        return testResponse(response)
    },

    createActivityArea : async (data) => {
        const response = await post(url.allActivityArea, data)
        return testResponse(response)
    },

    getSellerActivityArea: async () => {
        const response = await get(url.sellerActivityArea);
        return testResponse(response)
    },

    addSellerActivityArea: async (data) => {
        const response = await post(`${url.sellerActivityArea}/${data.zoneId}`,data);
        return response
    },

    deleteSellerActivityArea : async (id) => {
        const response= await remove(`${url.sellerActivityArea}/${id}`)
        return response
    }
}
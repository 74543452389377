import constants from '../constants/language';
import serviceApp from '../../applicatif/language';

const getLanguage = (code, languages) => {
    if (!languages || languages.length <1 ) {
        return null
    }
    const lang = languages.find(language => language.code === code);
    if (lang) {
        return lang.language
    }
    return null
}

export const getAllLanguage = (callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getAllLanguage();
        callBack && callBack(payload);
        if (payload) {
            return dispatch({
                type: constants.GET_ALL_LANGUAGE,
                payload,
            })
        }
    }
}

export const getSellerLanguage = (callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getSellerLanguage();
        callBack && callBack(payload, "getSellerLanguage");
        if (payload) {
            return dispatch({
                type: constants.GET_SELLER_LANGUAGE,
                payload,
            })
        }
    }
}

export const UpdateSellerLanguage = (data, callBack) => {
    return async (dispatch) => {
        const languages =  data && data.data && data.data.length > 0 ? data.data : [];
        let dataSend = languages.map(language =>({
            code: language.code,
            skills: language.skills,
            language: language.language,
        }));
        dataSend = {
            data: dataSend
        }
        const payload = await serviceApp.UpdateSellerLanguage(dataSend);
        callBack && callBack(payload, 'UpdateSellerLanguage')
        if ((payload && payload.status === 201) || (payload && payload.status === 200) || (payload && payload.status === 204)) {
            const dataReturn = payload.data ? payload.data.map(language =>({
                id: language.id,
                code: language.code,
                language: getLanguage(language.code, languages),
                skills: language.skills
            }))
            : []
            return dispatch({
                type: constants.UPDATE_SELLER_LANGUAGE,
                payload: dataReturn,
            })
        }
    }
}

export const removeSellerLanguage = (id, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.removeSellerLanguage(id);
        callBack && callBack(payload, 'removeSellerLanguage')
        if ((payload && payload.status === 204) || (payload && payload.status === 200)) {
            return dispatch({
                type: constants.REMOVE_SELLER_LANGUAGE,
                payload: id,
            })
        }
    }
}

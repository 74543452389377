import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import { isValidPhoneNumber } from 'react-phone-number-input'

import { parsePhoneNumber } from 'react-phone-number-input/input';
import { showPopupExtra } from '../../../components/popup/popup';
import ImageSelect from '../../../components/image-select/image-select';

const day = [
  '',
  'Lundi',
  'Mardi',
  'Mercredi',
  'jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];

class IdentitéController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpeningTime: [],
      listSellerLanguage: [],
      address: {
        streetAddress: "",
        city: "",
        country: "",
        zipCode: ""
      },
      currentCountry: "",
      description: "",
      logofile: null,
      logo: {},
      name: "",
      phone: "",
      photos: [],
      transportAccess: "",
      errors: {
        name: '',
        streetAddress: "",
        city: "",
        country: "",
        zipCode: "",
        transportAccess: '',
        description: '',
        photo: '',
        currentUserNumberError: '',
        logo: '',
      },
      errorsLanguage: {
        id: "default",
        language: "default",
        skills: "default",
      },
      listNewAddLanguage: [],
      language: {
        id: null,
        language: "string",
        skills: null,
        code: null,
      },
      newListLanguage: [],
      visible: false,
      visibleL: false,
      nameLangue: null,
      niveauLangue: null,
      indexToUpdate: null,
      lastIndex: 0,
      updateInfoEtablissement: true,
      isCreateOpeningTime: false,
      createOpeningTime: 0,
      isRemoveOpeningTime: false,
      removeOpeningTime: 0,
      isUpdateOpeningTime: false,
      updateOpeningTime: 0,
      isRemoveSellerLanguage: false,
      removeSellerLanguage: 0,
      isUpdateSellerLanguage: false,
      lastPhotoId: 0,
      fromHeadBand: this.props.location.state && this.props.location.state.fromHeadBand,
    }
  }
  componentDidMount() {
    const { listOpeningTime } = this.props.etablissement;
    const infoEtablissement = {...this.props.etablissement.infoEtablissement}
    const { listSellerLanguage } = this.props.language
    if (infoEtablissement) {
      this.setState({
        ...infoEtablissement,
        currentCountry: isValidPhoneNumber(infoEtablissement.phone) ? parsePhoneNumber(infoEtablissement.phone).country : "",
        address: infoEtablissement && infoEtablissement.address && infoEtablissement.address.zipCode > 0 ? infoEtablissement.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        }
      });
      if (infoEtablissement) {
        if (!infoEtablissement.address) {
          infoEtablissement.address= {
            streetAddress: "",
            city: "",
            country: "",
            zipCode: ""
          }
        }
        for (let [key, value] of Object.entries(infoEtablissement)) {
          if (key === "address") {
            for (let [key1, value1] of Object.entries(value)) {
              this.testErrors(key1, value1)
            }
          }else if(key === "logo"){
            this.testPhotoInput(value);
          }else {
            this.testErrors(key, value)
          }
        }
      }
    }
    if (listOpeningTime && listOpeningTime.length > 0) {
      this.setState({
        listOpeningTime: this.addOpenTimeErrors(listOpeningTime)
      });
    }else{
      this.addNewOpenTime();
    }
    if (listSellerLanguage) {
      this.setState({ listSellerLanguage });
    }
    // this.props.getInfoEtablissement(this.callBack);
  }

  componentDidUpdate(prevProps) {
    const prevIdentiteEtablissement = prevProps.etablissement.infoEtablissement;
    const propsIdentiteEtablissement = this.props.etablissement.infoEtablissement;
    const prevOpeningTime = prevProps.etablissement.listOpeningTime;
    const propsOpeningTime = this.props.etablissement.listOpeningTime;
    const prevSellerLanguage = prevProps.language.listSellerLanguage;
    const propsSellerLanguage = this.props.language.listSellerLanguage;


    if (!prevIdentiteEtablissement || !prevOpeningTime || !prevSellerLanguage) {
      return;
    }
    if (prevIdentiteEtablissement !== propsIdentiteEtablissement) {
      this.setState({
        ...propsIdentiteEtablissement,
        address: propsIdentiteEtablissement && propsIdentiteEtablissement.address !== null && propsIdentiteEtablissement.address.zipCode ? propsIdentiteEtablissement.address : {
          streetAddress: "",
          city: "",
          country: "",
          zipCode: ""
        },
      });
      for (let [key, value] of Object.entries(propsIdentiteEtablissement)) {
        if (key === "address" && value !== null) {
          for (let [key1, value1] of Object.entries(value)) {
            key1 && value1 && this.testErrors(key1, value1)
          }
        }
        else {
          key && value && this.testErrors(key, value)
        }

      }
    }

    if (prevOpeningTime !== propsOpeningTime) {
      if (propsOpeningTime && propsOpeningTime.length > 0) {
        this.setState({
          listOpeningTime: this.addOpenTimeErrors(propsOpeningTime)
        });
      }else if(!this.state.listOpeningTime || this.state.listOpeningTime < 1) {
        this.addNewOpenTime();
      }
    }

    if (prevSellerLanguage.toString() !== propsSellerLanguage.toString()) {
      this.setState({
        listSellerLanguage: propsSellerLanguage
      });
    }
  }

  languageIsNotVoid = () => {
    const {
      nameLangue,
      niveauLangue,
    } = this.state;
    return nameLangue !== null && niveauLangue !== null
  }

  hasErrorOpeningTimeError = () => {
    let hasErrorOpeningTimeError = false;
    if (this.state.listOpeningTime && this.state.listOpeningTime.length > 0) {
      for (let index = 0; index < this.state.listOpeningTime.length; index++) {
        const openingTime = this.state.listOpeningTime[index];
        const {
          dayStart,
          dayEnd,
          timeStart,
          timeEnd,
        } = openingTime.errors;
        if (dayStart || dayEnd || timeStart || timeEnd) {
          hasErrorOpeningTimeError = true;
          break
        }
      }
    }
    return hasErrorOpeningTimeError;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { listSellerLanguage } = this.props.language
    const { listOpeningTime } = this.props.etablissement;
    let data ={};
    const photosSend= this.state.photos && this.state.photos.map(photo=>{
      if (photo.id < 0) {
        photo.id = null
      }
      if (!photo.data) {
        photo.data = null
      }
      return photo
    });
    const logoSend = {...this.state.logo};

    data = {
      name: this.state.name,
      address: this.state.address,
      phone: this.state.phone,
      transportAccess: this.state.transportAccess,
      description: this.state.description,
      logo: logoSend,
      photos: photosSend,
    }
    let listOpeningTimeToSave = JSON.parse(JSON.stringify(this.state.listOpeningTime)); 
    listOpeningTimeToSave = listOpeningTimeToSave && listOpeningTimeToSave.map(openingTime=>{
      if (openingTime.id < 1) {
        openingTime.id = null
      }
      openingTime.dayStart = parseInt(openingTime.dayStart);
      openingTime.dayEnd = parseInt(openingTime.dayEnd);
      delete openingTime.errors
      return openingTime;
    })
    this.setState({
      updateInfoEtablissement: true,
    })
    this.props.setLoadingTrue();
    await this.props.updateInfoEtablissement(data, this.callBack);
    if (JSON.stringify(listOpeningTime) !== JSON.stringify(listOpeningTimeToSave)) {
      let listAdd = listOpeningTimeToSave.filter(i => !i.id);
      let listDelete = listOpeningTime.filter(this.comparerOpeningT(listOpeningTimeToSave))
      let listUpdate = listOpeningTimeToSave.filter(this.comparerUpdateOpeningT(listOpeningTime))
      if (listAdd.length > 0) {
        this.setState({
          isCreateOpeningTime: true,
          createOpeningTime: listAdd.length
        })
        listAdd.forEach(item => {
          const data = {
            day_start: item.dayStart,
            day_end: item.dayEnd,
            timeStart: item.timeStart,
            timeEnd: item.timeEnd
          }
          this.props.createOpeningTime(data, this.props.sellers.idSeller.id, this.callBack);
        })
      }
      if (listDelete.length > 0) {
        this.setState({
          isRemoveOpeningTime: true,
          removeOpeningTime: listDelete.length
        })
        listDelete.forEach(item => {
          this.props.removeOpeningTime(item.id, this.props.sellers.idSeller.id, this.callBack);
        })
      }
      if (listUpdate.length > 0) {
        this.setState({
          isUpdateOpeningTime: true,
          updateOpeningTime: listUpdate.length
        })
        listUpdate.forEach(item => {
          const data = {
            id: item.id,
            day_start: item.dayStart,
            day_end: item.dayEnd,
            timeStart: item.timeStart,
            timeEnd: item.timeEnd,
          }
          this.props.updateOpeningTime(data, this.props.sellers.idSeller.id, this.callBack);
        })
      }
    }
    if (JSON.stringify(listSellerLanguage) !== JSON.stringify(this.state.listSellerLanguage)) {
      let differenceAdd = this.state.listSellerLanguage.filter(this.comparerLanguageUpdate(listSellerLanguage));
      let differenceDelete = listSellerLanguage.filter(this.comparerLanguage(this.state.listSellerLanguage));
      if (differenceDelete.length > 0) {
        this.setState({
          isRemoveSellerLanguage: true,
          removeSellerLanguage: differenceDelete.length
        })
        differenceDelete.forEach(item => {
          this.props.removeSellerLanguage(item.id, this.callBack)
        })
      }
      if (differenceAdd.length > 0) {
        let data = []
        differenceAdd.forEach(item => {
          const dataSend = {
            language: item.language,
            code: item.code,
            skills: parseInt(item.skills)
          }
          data = [...data, dataSend]
        })
        const dataSendF = {
          data: data
        }
        this.setState({
          isUpdateSellerLanguage: true
        })
        this.props.UpdateSellerLanguage(dataSendF, this.callBack)
      }
    }
  }

  comparerLanguage = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.code === current.code
      }).length === 0;
    }
  }

  comparerLanguageUpdate = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.code === current.code && other.skills === current.skills
      }).length === 0;
    }
  }

  comparerOpeningT = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.id && other.id === current.id
      }).length === 0;
    }
  }
  

  comparerUpdateOpeningT = (otherArray) => {
    return (current) => {
      if (current.id) {
        return otherArray.filter((other) => {
          if (current.id && other.id) {
            return current.id && other.id && other.id === current.id && JSON.stringify(other) === JSON.stringify(current)
          }
          else return {}
        }).length === 0;
      }

    }
  }
  updateStateNumber(value) {
    this.handleNumberInput(value)
    this.setState({ phone: value });
    // this.handleNumberInput(value);
  }
  addLanguage = () => {
    const { code, id } = this.state.language
    const data =
    {
      id,
      code,
      skills: this.state.niveauLangue,
      language: this.state.nameLangue,
    };

    const listSellerLanguage = [...this.state.listSellerLanguage, data];
    this.setState({
      listSellerLanguage,
      visibleL: false,
      nameLangue: null,
      niveauLangue: null
    });
    // this.props.UpdateSellerLanguage(data, this.callBack)
  }

  addOption = () => {
    const options = [
      ...this.state.options,
      {
        description: "",
        price: "",
        duration: 5,
      }
    ];
    this.setState({ options });
  }

  handleNumberInput(value) {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.photo = this.numberErrors(value)
      this.setState({ errors });
    }

    //this.setState({currentUserNumberError : this.numberErrors(value)});
  }
  handleUserSelect(value) {
    if (value.value === "FR") {
      this.setState({ currentCountry: value.value, currentUserNumber: "+336" });
      return
    }
    this.setState({ currentCountry: value.value, currentUserNumber: "" });
  }
  updateStateAddress = (value) => {
    let name = value.target.name;
    let address = this.state.address;
    switch (name) {
      case 'streetAddress':
        address.streetAddress = value.target.value
        break;
      case 'city':
        address.city = value.target.value
        break;
      case 'country':
        address.country = value.target.value
        break;
      case 'zipCode':
        address.zipCode = value.target.value
        break;
      default:
        break;
    }
    this.setState({ address })
  }

  testPhotoInput(value) {
    let errors = this.state.errors;
    errors.logo = value ? "" : "Photo obligatoire";
    this.setState({ errors });
  }

  updateState = (event) => {
    const name = event.target.name;
    const value =event.target.value;
    this.handleUserInput(event);
    if (name === "name") {
      this.props.onNameChanged(value);
    }if (name === "description" && value && value.length > 2000) {
      return
    }
    this.setState({ [name]: value });
  }

  isNotVoid = () => {

    const {
      name,
      streetAddress,
      city,
      zipCode,
      transportAccess,
      description,
      currentUserNumberError,
      logo
    } = this.state.errors;
    const country = this.state.address.country;
    return name === "" && streetAddress === "" && city === "" && country !== "" && zipCode === "" && transportAccess === "" && description === "" && currentUserNumberError === "" &&  logo === "" && !this.hasErrorOpeningTimeError()
  }

  testErrors(name, value) {
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameErrors(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      case 'description':
        errors.description = this.props.errors.extraLongDescriptionErrors(value);
        break;
      case 'transportAccess':
        errors.transportAccess = this.props.errors.nameErrors(value);
        break;
      case 'phone':
        errors.currentUserNumberError = this.numberErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    if (name === "description" && value && value.length > 2000) {
      return
    }
    switch (name) {
      case 'name':
        errors.name = this.props.errors.nameErrors(value);
        break;
      case 'streetAddress':
        errors.streetAddress = this.props.errors.nameErrors(value);
        break;
      case 'city':
        errors.city = this.props.errors.nameErrors(value);
        break;
      case 'country':
        errors.country = this.props.errors.nameErrors(value);
        break;
      case 'zipCode':
        errors.zipCode = this.props.errors.nameErrors(value);
        break;
      case 'description':
        errors.description = this.props.errors.extraLongDescriptionErrors(value);
        break;
      case 'transportAccess':
        errors.transportAccess = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    //this.updateDisabled(errors);
    this.setState({ errors, [name]: value });
  }
  handleNumberInput = (value) => {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.currentUserNumberError = this.numberErrors(value)
      this.setState({ errors });
    }
  }

  updateStateNumber = (value) => {
    this.handleNumberInput(value);
    this.setState({ phone: value });
  }

  getInfoOrAddStep = ()=>{
    const { steps } = this.props.dashboard;
    if (!steps.includes("2") && !this.state.saveInfoError) {
      this.props.addSteps({steps: ["2"]},this.callBack);
    }else if (steps.includes("2") && this.state.fromHeadBand){
      this.props.setLoadingFalse();
      this.props.history.push("/dashboard");
    }else{
      this.props.getSellerLanguage(this.callBack);
      this.props.getInfoEtablissement(this.callBack);
    }
  }

  callBack = (data, type) => {
    this.setState({
      visible: false,
      visibleL: false,
      nameLangue: null,
      niveauLangue: null,
    });
    const isError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
    if(type==='updateInfoEtablissement'){
      const saveInfoError = isError;
      this.setState({
        saveInfoError,
        saveLanguageError: null,
        removeLanguageError: null,
        createOpeningTimeError: null,
        updateOpeningTimeError: null,
        removeOpeningTimeError: null,
        updateInfoEtablissement: false,
      })
      if (!saveInfoError) {
        this.setState({
          photos: [],
          logo: null
        })
      }
      if (!this.state.isCreateOpeningTime && !this.state.isRemoveOpeningTime && !this.state.isUpdateOpeningTime && !this.state.isRemoveSellerLanguage && !this.state.isUpdateSellerLanguage) {
        this.getInfoOrAddStep();
      }
    }else if(type==='UpdateSellerLanguage'){
      const saveLanguageError = isError;
      this.setState({
        saveLanguageError,
        isUpdateSellerLanguage: false,
      });
      if (!this.state.updateInfoEtablissement && !this.state.isCreateOpeningTime && !this.state.isRemoveOpeningTime && !this.state.isUpdateOpeningTime && !this.state.isRemoveSellerLanguage) {
        this.setState({
          isUpdateSellerLanguage: false,
        });
        this.getInfoOrAddStep();
      }
    }else if(type==='removeSellerLanguage'){
      const removeLanguageError = isError;
      const removeSellerLanguage = this.state.removeSellerLanguage - 1
      this.setState({
        removeLanguageError,
        removeSellerLanguage,
        isRemoveSellerLanguage: removeSellerLanguage ? true : false,
      })
      if (!this.state.updateInfoEtablissement && !this.state.isCreateOpeningTime && !this.state.isRemoveOpeningTime && !this.state.isUpdateOpeningTime  && !removeSellerLanguage && !this.state.isUpdateSellerLanguage) {
        this.getInfoOrAddStep();
      }
    }else if(type==='createOpeningTime'){
      const createOpeningTimeError = isError;
      const createOpeningTime = this.state.createOpeningTime - 1
      this.setState({
        createOpeningTimeError,
        createOpeningTime,
        isCreateOpeningTime: createOpeningTime ? true : false,
      })
      if (!this.state.updateInfoEtablissement && !createOpeningTime && !this.state.isRemoveOpeningTime && !this.state.isUpdateOpeningTime && !this.state.isRemoveSellerLanguage && !this.state.isUpdateSellerLanguage) {
        this.getInfoOrAddStep();
      }
    }else if(type==='updateOpeningTime'){
      const updateOpeningTimeError = isError;
      const updateOpeningTime = this.state.updateOpeningTime - 1
      this.setState({
        updateOpeningTimeError,
        updateOpeningTime,
        isUpdateOpeningTime: updateOpeningTime ? true : false,
      })
      if (!this.state.updateInfoEtablissement && !this.state.isCreateOpeningTime && !this.state.isRemoveOpeningTime && !updateOpeningTime && !this.state.isRemoveSellerLanguage && !this.state.isUpdateSellerLanguage) {
        this.getInfoOrAddStep();
      }
    }else if(type==='removeOpeningTime'){
      let removeOpeningTimeError = isError;
      const removeOpeningTime = this.state.removeOpeningTime - 1
      this.setState({
        removeOpeningTimeError,
        removeOpeningTime,
        isRemoveOpeningTime: removeOpeningTime ? true : false,
      });
      if (!this.state.updateInfoEtablissement && !this.state.isCreateOpeningTime && !removeOpeningTime && !this.state.isUpdateOpeningTime && !this.state.isRemoveSellerLanguage && !this.state.isUpdateSellerLanguage) {
        this.getInfoOrAddStep();
      }
    }else if(type==="addSteps"){
      this.props.setLoadingFalse()
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        addStepError,
      })
      if(!addStepError && this.state.fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }else{
      this.props.setLoadingFalse()
    }
  }

  openModalLanguage = () => {
    this.setState({ visibleL: true });
  }

  closeModalLanguage = () => {
    this.setState({
      visibleL: false,
      nameLangue: null,
      niveauLangue: null,

    });

  }
  selectLang = (value, listLang) => {
    const selected = listLang.filter(i => i.language === value.label)
    const language1 = this.state.language
    language1.id = selected[0].id;
    language1.language = selected[0].language;
    language1.code = selected[0].code;
    this.setState({ language: language1, nameLangue: selected[0].language });
  }
  selectSkills = (value) => {
    const language = this.state.language
    language.skills = parseInt(value)
    this.setState({ language, niveauLangue: value });
  }
  removeLang = (id) => {
    let listLanguage = [this.state.listSellerLanguage];
    if (listLanguage.length > 0) {
      listLanguage = [...listLanguage[0].filter(i => i.id !== id)]
    }
    this.setState({ listSellerLanguage: listLanguage });
  }

  numberErrors = (value) => {
    if (typeof value === "string") {
      if (isValidPhoneNumber(value)) {
        if (this.state.currentCountry === "FR" && (value.charAt(3) === "0")) {
          return "0 apres 33 est invalide";
        }
        else {
          return "";
        }
      }
      else {
        return "Numero invalide";
      }
    }
    else {
      return "Numero invalide";
    }
  }

  addNewOpenTime = () => {
    const newOpenTime = {
      dayStart: "",
      dayEnd: "",
      timeStart: "",
      timeEnd: "",
      id: this.state.lastIndex,
      errors: {
        dayStart: this.props.errors.notEmptyInput(""),
        dayEnd: this.props.errors.notEmptyInput(""),
        timeStart: this.props.errors.notEmptyInput(""),
        timeEnd: this.props.errors.notEmptyInput(""), 
      }
    }
    this.setState(oldState =>({ 
      listOpeningTime:[
        ...oldState.listOpeningTime,
        newOpenTime,
      ],
      lastIndex: oldState.lastIndex - 1,
    }));
  }

  removeOpenTime = (id) => {
    let listOpeningTime = [...this.state.listOpeningTime];
    if (listOpeningTime.length > 0) {
      listOpeningTime = [...listOpeningTime.filter(i => i.id !== id)]
      this.setState({ listOpeningTime });
    }
  }

  updateStateOpenTime=(value, name, id)=> {
    let listOpeningTime = this.state.listOpeningTime.map(openingTime => {
      if (openingTime.id === id) {
        return ({
           ...openingTime, 
           [name]: value.value ,
          })
      }
      return openingTime;
    });
    listOpeningTime = this.addOpenTimeErrors(listOpeningTime);
    this.setState({ listOpeningTime });
  }

  addOpenTimeErrors = (listOpeningTime) => {
    return listOpeningTime && listOpeningTime.length > 0 ?
    listOpeningTime.map(openingTime=>{
      return ({
        ...openingTime, 
        errors: {
          dayStart: this.props.errors.notEmptyInput(openingTime.dayStart) || this.getDayRangeError(openingTime.dayStart, openingTime.dayEnd),
          dayEnd: this.props.errors.notEmptyInput(openingTime.dayEnd),
          timeStart: this.props.errors.notEmptyInput(openingTime.timeStart)  || this.getHourRangeError(openingTime.timeStart, openingTime.timeEnd),
          timeEnd: this.props.errors.notEmptyInput(openingTime.timeEnd) || this.getHourDifferenceError(openingTime.timeStart, openingTime.timeEnd), 
        }
      })
    }):
    []
  }

  hourToMinutes = hour =>{
    if (!hour) {
      return 0
    }
    const fieldArray = hour.split(":");
    return parseInt(fieldArray[0])*60 + parseInt(fieldArray[1]);
  }

  getHourRangeError = (start, end) =>{
    const startMinutes = this.hourToMinutes(start);
    const endMinutes = this.hourToMinutes(end);
    if (!startMinutes || !endMinutes) {
      return ""
    }
    if (startMinutes >= endMinutes) {
      return `Cette valeur doit être avant ${end}`
    }
    return ""
  }

  getHourDifferenceError = (start, end) =>{
    const startMinutes = this.hourToMinutes(start);
    const endMinutes = this.hourToMinutes(end);
    if (!startMinutes || !endMinutes) {
      return ""
    }
    if (endMinutes-startMinutes < 120) {
      return `La différence d'heure minimum est 2h`
    }
    return ""
  }

  getDayRangeError = (start, end) =>{
    const startDay = parseInt(start);
    const endDay = parseInt(end);
    if (!startDay || !endDay) {
      return ""
    }
    if (startDay > endDay) {
      return `Cette valeur doit être avant ${day[endDay]}`
    }
    return ""
  }

  setValue(value, key) {
    this.setState({ [key]: value.label });
  }

  uploadLogo =()=> {
    showPopupExtra(
    <ImageSelect 
      onPictureValidate={this.handleImageChange} 
      currentPictureUrl={(this.state.logo && this.state.logo.url)|| ''}
      minWidth = {196}
      minHeight= {148}
      maxWidth= {450}
      maxHeight= {350}
      maxSize={5120}
    />, "landing-page");
  }

  uploadSubLogo =()=> {
    showPopupExtra(
      <ImageSelect 
        onPictureValidate={this.handleSubImageChange} 
        currentPictureUrl=""
        minWidth = {930}
        minHeight= {200}
        maxWidth= {1920}
        maxHeight= {413}
        maxSize={8192}
      />, "landing-page");
  }

  handleChange1 = (event) => {
    this.setState({
      logo: URL.createObjectURL(event.target.files[0])
    })
  }

  getExtension = (name)=>{
    const filename = name.split(".");
    return filename.length > 1 ? filename[filename.length - 1].toLowerCase() : "";
  }

  handleImageChange = async ({file}) => {
    if (!file) {
      return
    }
    const url = URL.createObjectURL(file);
    const imageSizeError= file.size / 1024  > 5120;
    const extension = this.getExtension(file.name);
    const imageFormatError = (extension !== "jpeg" && extension !== "jpg" && extension !== "png") || false;
    let logoDimensionError = false;
    this.setState({
      imageSizeError,
      imageFormatError,
      logoDimensionError,
    });
    if (imageSizeError || imageFormatError) {
      return
    }
    const img = new Image();
    img.addEventListener('load', () => {
      logoDimensionError = img.width < 196 || img.height < 148 || img.width > 450 || img.height > 350;
      this.setState({
        logoDimensionError,
      });
      if (!logoDimensionError) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = {
            name: file.name,
            data: reader.result,
            url
          }
          this.setState({ logo: data });
          this.testPhotoInput(data);
          this.props.onImageChanged(data);
        }
        reader.readAsDataURL(file);
      }
    });
    img.src = url;
  };

  handleSubImageChange = ({file}) => {
    if (!file) {
      return
    }
    const url = URL.createObjectURL(file);
    const imageSizeError= file.size / 1024  > 8192;
    const extension = this.getExtension(file.name);
    const imageFormatError = (extension !== "jpeg" && extension !== "jpg" && extension !== "png") || false;
    const imageNbError= this.state.photos && this.state.photos.length > 4;
    let imageDimensionError = false;
    this.setState({
      imageSizeError,
      imageFormatError,
      imageDimensionError,
      imageNbError
    });
    if (imageSizeError || imageFormatError || imageNbError) {
      return
    }
    const img = new Image();
    img.addEventListener('load', () => {
      imageDimensionError = img.width < 930 || img.height < 200 || img.width > 1920 || img.height > 413;
      this.setState({
        imageDimensionError,
      });
      if (!imageDimensionError) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = {
            id: this.state.lastPhotoId - 1,
            name: file.name,
            data: reader.result,
            url
          }
          let photos = this.state.photos;
          photos.push(data);
          this.setState(oldState=>({ 
            photos,
            lastPhotoId: oldState.lastPhotoId - 1
          }))
        }
        reader.readAsDataURL(file);
      }
    });
    img.src = url;
  }

  deleteSubImage = id =>{
    const photos = this.state.photos.filter(photo=>photo.id !== id);
    this.setState({
      photos
    })
  }

}

export default IdentitéController;

IdentitéController.propTypes = {
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import fileDownload from 'js-file-download';
import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import PopupError from "../../components/popup/popup.error";
import {showPopUp, hidePopUp, showPopupExtra} from "../../components/popup/popup";
import { ORDER_STATUS_LABEL } from "../../../data/constants/order.status";
import Remboursement from "./remboursement/index";


const ORDER = {
  PRESTATION_DATE_UP: {icon: 'arrow_drop_up', field: 'datePrestation', direction: 'asc'},
  PRESTATION_DATE_DOWN: {icon: 'arrow_drop_down', field: 'datePrestation', direction: 'desc'}
}

class CommandeController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.csvLink = React.createRef();
    let prestationDateOrder = ORDER.PRESTATION_DATE_DOWN;
    const locationState = (props.location && props.location.state) || null
    const status = (locationState && locationState.status) || '';
    const direction = (locationState && locationState.direction) || 'desc';
    const hotel = (locationState && locationState.hotel) || '';

    if (direction !== 'desc') {
      Object.keys(ORDER).forEach(key=>{
          if (ORDER[key].direction === direction) {
              prestationDateOrder = ORDER[key]
          }
      });
    }
    this.state = {
      responseModal: false,
      cancelModal: false,
      order: [],
      defaultFilter: "",
      orderToexport: "",
      orderIdToCancel: 0,
      orderIdToAnswer: 0,
      newDate: new Date(),
      todayDate: new Date(),
      listOrderSale: [],
      listHotelPartner: [],
      allChecked: false,
      isLoading: false,

      currentHour: "",
      heure: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
      hourTab: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
      query: {
        itemPerPage: 5,
        page: 1,
        start: '',
        end: '',
        filter: {
          search: '',
          hotel,
          status,
        },
        order: {
          start : prestationDateOrder.direction,
        },
      },
      prestationDateOrder,
      data: [],
      statusResponse: "",
      contreProposition: false,
      heureSend: "",
      minuteSend: "",
      orderChecked: [],
      selectedSeller: null,
      statusOptions: [
        {value: '', label: 'Tous'},
        ...Object.keys(ORDER_STATUS_LABEL).map(key =>({value: ORDER_STATUS_LABEL[key].value, label: ORDER_STATUS_LABEL[key].label})),
      ],
    }
  }

  componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }

    this.setState({ isLoading: true });
    if (localStorage.getItem('type_compte') && localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE')) {
      this.getAgenceSellers();
    }
    this.setState({ isLoading: true })
    this.getAllData()

    const { listOrderSale } = this.props.commandes
    if (listOrderSale) {
      this.setState({
        listOrderSale: listOrderSale.map(i => { i.checked = false; return i }),
      });

    }
    const { listHotelPartner } = this.props.hotels;
    if (listHotelPartner) {
      this.setState({
        listHotelPartner: listHotelPartner,
      });

    }
  }

  componentDidUpdate(prevProps) {
    const prevListOrderSale = prevProps.commandes.listOrderSale;
    const propsListOrderSale = this.props.commandes.listOrderSale;
    const prevListHotelPartner = prevProps.hotels.listHotelPartner;
    const propsListHotelPartner = this.props.hotels.listHotelPartner;
    let resizeEvent = window.document.createEvent('UIEvents'); 

    if (!prevListOrderSale || !prevListHotelPartner) {
      return;
    }
    if (prevListOrderSale !== propsListOrderSale) {
      this.setState({
        listOrderSale: propsListOrderSale.map(i => { i.checked = false; return i }),
      });
    }
    if (prevListHotelPartner !== propsListHotelPartner) {
      this.setState({
        listHotelPartner: propsListHotelPartner,
      });
    }
    resizeEvent.initUIEvent('resize', true, false, window, 0); 
    window.dispatchEvent(resizeEvent);
  }

  getAllData = async () => {
    await this.props.getOrderSale(this.state.query, this.state.selectedSeller, this.callback);
    await this.props.getHotelPartner(this.callback);
    await this.props.getAllInfoDashboard(this.callback);
  }

  getAgenceSellers = async () => {
    await this.props.getAgenceSellers(this.callback);
  }

  getOrderSale = ()=>{
    this.setState({ isLoading: true })
    this.props.getOrderSale(this.state.query, this.state.selectedSeller, this.callback);
  }

  search = ()=>{
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        page: 1
      }
    }), this.getOrderSale)
  }

  handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.search()
    }
  }

  callback = (data, type) => {
    this.setState({ isLoading: false })
    if (type && type === "getOrderSale") {
      if (data && data.data) {
        this.setState({
          data: data.data
        })
      }
    }
    else if (type && (type === "accept" || type === "deny" || type === "cancel")) {
      let responseError = data && data.status === 200 ? false : true;
      this.setState({ isLoading: true, responseError });
      if (data && data.error && data.error.response && data.error.response.data) {
        data.error.response.data.message === "Date is in the past" && showPopupExtra(<PopupError title="Erreur" message="Date déjà depassée"/>);
        (data.error.response.data.message === "This order is no longer available." || data.error.response.data.message === "This order no longer exists.") && 
          showPopupExtra(<PopupError title="Erreur" message="La commande n'est plus disponible."/>)
      }
      this.getAllData(this.props.query, this.callback);
    }
    else if (type === "export") {
      let exportError = data && data.status === 200 ? false : true;
      this.setState({
        exportError,
      })
      if (data && data.data) {
        this.downloadCsv(data.data);
      }
    }else if (type === "download") {
      let downloadError = data && data.data && data.status === 200 ? false : true;
      this.setState({
          downloadError,
      });
      if (data && data.data){
          this.downloadInvoice(data.data);
      }
    }else if (type === "refundOrder") {
      let refundError = data && data.data && (data.status === 200 || data.status === 201 || data.status === 204)? false : true;
      if (data && data.error && data.error.response && data.error.response.data && data.error.response.data[0] && data.error.response.data[0].message) {
        const error = data.error.response.data[0].message;
        let message = "error"
        if (error === "AMOUNT_TOO_HIGH") {
          message = "Le montant du remboursement ne doit pas dépasser la commission du seller."
        }else if (error === "SALE_ORDER_NOT_PROCESSING") {
          message = "La commande n'est pas en statut confirmé."
        }else{
          message=error;
        }
        showPopupExtra(<PopupError title="Erreur remboursement" message={message}/>);
      }else if(data && data.error && data.error.response && data.error.response.data &&  data.error.response.data.message){
        showPopupExtra(<PopupError title="Erreur remboursement" message={data.error.response.data.message}/>);
      }
      this.setState({
        refundError,
      });
      if (!refundError){
        this.getAllData(this.props.query, this.callback);
      }
    }

  }

  downloadCsv = (text)=> {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', `export-saleorder_${moment(new Date()).format('YYYY_MM_DD_HH_MM_SS')}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getBlockedHour(value) {
    let index = this.state.hourTab.indexOf(value);
    var newTab = [...this.state.hourTab];
    newTab = newTab.splice(index);
    return newTab;
  }

  onChange = date => {
    this.setState({ newDate: date });
    if (this.state.todayDate.getTime() === date.getTime()) {
      this.setState({ heure: this.getBlockedHour(this.state.currentHour.toString()) });
    } else {
      this.setState({ heure: this.state.hourTab });
    }
  };

  openModal(name, id) {
    let date = new Date();
    this.setState({ [name]: true });
    if (name === 'responseModal') {
      this.setState({ orderIdToAnswer: id });
      this.setState({ currentHour: date.getHours().toString() });
      this.setState({ heure: this.getBlockedHour(date.getHours().toString()) });
    } else if (name === 'cancelModal') {
      this.setState({ orderIdToCancel: id });
    }
  }

  closeModal = (name, id) => {
    this.setState({ [name]: false, statusResponse: "", contreProposition: false, heureSend: "", minuteSend: "", });
    if (name === 'responseModal') {
      this.setState({ orderIdToAnswer: "0" });
    } else if (name === 'cancelModal') {
      this.setState({ orderIdToCancel: "0" });
    }
  }

  toogleChange(event, index) {
    let tabOrder = [...this.state.listOrderSale];
    tabOrder[index].checked = event.target.checked;
    this.setState({ listOrderSale: tabOrder });
    let countChecked = 0;
    let orderChecked = [];
    tabOrder.map((value) => {
      if (value.checked === true) {
        countChecked++;
        orderChecked.push(value)
      }
      return value
    })
    const canExport = countChecked > 0 ? true : false;
    const canDownloadInvoice =  countChecked === 1 && orderChecked && orderChecked[0] && orderChecked[0].status === "complete" ? true : false;
    this.setState({
      canExport,
      orderChecked,
      canDownloadInvoice
    })
  }
  toogleChangeAll = (event) => {
    let tabOrder = [...this.state.listOrderSale];
    let countChecked = 0;
    tabOrder.map((value) => {
      if (value.status !== "canceled") {
        value.checked = event.target.checked
        if (value.checked === true) {
          countChecked++;
        }
        return value
      }
      else {
        return value
      }
    });
    const canExport = countChecked > 0 ? true : false;
    const canDownloadInvoice =  countChecked === 1 && tabOrder && tabOrder.length ===1 && tabOrder[0].status === "complete" ? true : false;
    this.setState({ 
      allChecked: event.target.checked, 
      listOrderSale: tabOrder,
      canExport,
      canDownloadInvoice
    });
  }

  renderSwitch(value, index) {
    if (!this.state.selectedSeller) {
      switch (value.status) {
        case 'processing':
          const datePrestation = new Date(moment(value.datePrestation, "DD/MM/YYYY HH:mm"));
          return (
            <td className="cnt-link no-border-bottom">
              {
                datePrestation <= new Date() && 
                  <span className="link-command clickable" onClick={() => this.showRefundOrder(value)}>Rembourser</span>
              }
              <span className="link-command clickable" onClick={() => this.openModal('cancelModal', index)}>Annuler la<br/> commande</span>
            </td>
          )
        case 'pending':
          return(
            <td className="cnt-link no-border-bottom">
              {
                !value.hasCouterOffer &&
                <button className="btn-repondre" onClick={() => this.openModal('responseModal', index)}>Répondre</button>
              }
            </td>
          ) 
        case 'complete':
          return <td className="cnt-link no-border-bottom">
            <Link to={`/commande/facture/${value.id}/seller`}>
              Facture
            </Link>
          </td>;
        default:
          return <td className="no-border-bottom"/>;
      }
    }else if (value.status === 'complete'){
      return( 
        <td className="cnt-link no-border-bottom">
          <Link to={`/commande/facture/${value.id}/${this.state.selectedSeller}`}>
            Facture
          </Link>
        </td>
      )
    }else{
      return <td className="no-border-bottom"/>;
    }
  }

  showRefundOrder = (order)=>{
    if (order) {
      showPopUp(
        <Remboursement 
            order = {order}
            onValidate = {this.refundOrder }
        />,
        order.product,
        order.categoryProduct
      )
    }
  }

  refundOrder = (data)=>{
    hidePopUp();
    this.setState({
      isLoading: true
    })
    this.props.refundOrder(data, this.callback);
  }

  exportToCsv =() =>{
    if (this.state.canExport) {
      let ids = '';
      this.state.orderChecked.forEach((order) => {
        ids = `${ids}${ids ? ',' :'' }${order.id}`
      });
      this.setState({ isLoading: true })
      this.props.exportOrder(ids,this.state.selectedSeller, this.callback);
    }
  }

  generateInvoice = () =>{
    if (this.state.canDownloadInvoice && this.state.orderChecked && this.state.orderChecked[0] && this.state.orderChecked[0].id && this.state.orderChecked[0].status === "complete") {
      // this.setState({ isLoading: true })
      this.props.history.push({pathname: `/commande/facture/${this.state.orderChecked[0].id}/${this.state.selectedSeller ? this.state.selectedSeller : 'seller'},`, state:{ autodownload: true}});
    }
  }

  handlePaginationChange = (page) => {
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        page: parseInt(page)
      }
    }), this.getOrderSale)
  };

  handleItemsPerPageChange = (event) => {
    const value = event.target.value;
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        itemPerPage: parseInt(value)
      }
    }), this.getOrderSale)
  };

  selectResponse = async (event) => {
    await this.setState({ statusResponse: event.label, contreProposition: false })
  };

  toogleProposeChange = async (event, name) => {
    await this.setState({ [name]: event.target.checked });
  };

  sethour = (event, name) => {
    this.setState({ [name]: event.label })
  }

  isReponseValid = () => {
    const status = this.state.statusResponse;
    if (status === "") {
      return status !== ""
    }
    else if (status === "Refuser") {
      const contreProps = this.state.contreProposition
      if (contreProps === false) {
        return status !== ""
      }
      else {
        const { newDate, heureSend, minuteSend } = this.state

        return status !== "" && newDate !== null && heureSend !== "" && minuteSend !== ""
      }

    }
    else {
      return status !== ""
    }
  }

  responseSubmit = (event) => {
    const status = this.state.statusResponse;
    if (status === "Accepter") {
      this.setState({ isLoading: true, responseModal: false });
      const data = {
        date: new Date(),
        start: "13:00",
        end: "15:00",
        counterOffer: false,
        id: event,
      }
      this.props.acceptSaleOrder(data, this.callback)
    }
    else {
      const contreProps = this.state.contreProposition;
      const data = {
        date: new Date(this.state.newDate),
        start: `${this.state.heureSend}:${this.state.minuteSend}`,
        end: `${this.state.heureSend}:${this.state.minuteSend}`,
        counterOffer: contreProps,
        id: event,
      }
      this.setState({ isLoading: true, responseModal: false });
      if (contreProps) {
        this.props.acceptSaleOrder(data, this.callback)
      }else{
        this.props.denySaleOrder(data, this.callback)
      }
    }
  }

  cancelSubmit = (id) => {
    const data = {
      id
    }
    this.setState({ isLoading: true, cancelModal: false });
    this.props.cancelSaleOrder(data, this.callback)
  }

  handleQueryChange = (event)=>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        [name]: value
      }
    }))
  }

  handleFilterChange = (event)=>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        filter: {
          ...oldState.query.filter,
          [name]: value
        }
      }
    }))
  }

  handleSortChange = ()=>{
    const query = this.state.query;
    const prestationDateOrder = this.state.prestationDateOrder === ORDER.PRESTATION_DATE_UP ? ORDER.PRESTATION_DATE_DOWN : ORDER.PRESTATION_DATE_UP;
    query.order = {
      ...query.order,
      start: prestationDateOrder.direction
    };
    this.setState({
      query,
      prestationDateOrder
    }, this.getOrderSale)
  }

  handleDateChange = (value, name)=>{
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        [name]: value
      }
    }))
  }

  handleDropdownChange = (value, name)=>{
    this.setState(oldState=>({ 
      query: {
        ...oldState.query,
        filter: {
          ...oldState.query.filter,
          [name]: value.value
        }
      }
    }))
  }

  downloadInvoice = (data) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    fileDownload(blob, "facture.pdf")
  }

  handleSellerChange = (event) => {
    const selectedSeller = event.target.value;
    this.setState({
      selectedSeller
    },this.getOrderSale);
  };


}
export default CommandeController;

CommandeController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import moment from 'moment';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getSellerType } from '../../../data/constants/seller.type';
//
// Module DashboardController
//
am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class DashboardController extends Component {
  chart;
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      income: "0",
      prestation: "0",
      hotel: "0",
      partenershipRequest: "0",
      rating: "5",
      review: {
        nombre: "0",
        value: "0"
      },
      isStripeOK: false,
      identityFile: "",
      kbisFile: "",
      isLoading: false,
      completionProfileValue: '90',

      identityFileStatus: "ko",
      kbisFileStatus: "ko",
      stateOfProgress: 0,
      listPrestation: [],
      nbWSGet: 0,
      dataGraphe: [
        { category: "Janvier", value: 0 },
        { category: "Février", value: 0 },
        { category: "Mars", value: 0 },
        { category: "Avril", value: 0 },
        { category: "Mai", value: 0 },
        { category: "Juin", value: 0 },
        { category: "Juillet", value: 0 },
        { category: "Aout", value: 0 },
        { category: "Septembre", value: 0 },
        { category: "Octobre", value: 0 },
        { category: "Novembre", value: 0 },
        { category: "Décembre", value: 0 },],
    };

  }
  callBack = (data, type) => {
    const isAgence = localStorage.getItem('type_compte') && localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE');
    if (this.state.getAllData) {
      const nbWSGet = this.state.nbWSGet + 1;
      this.setState({
        nbWSGet,
      })
      if((isAgence && nbWSGet === 9) ||  nbWSGet === 11){
        this.setState({ 
          isLoading: false,
          getAllData: false
        })
      }
    }
    if (type === 'GID') {
      this.setState({
        revenus: data.revenus ? data.revenus : 0,
        nbPrestations: data.nbPrestations ? data.nbPrestations : 0,
        nbPartenaires: data.nbPartenaires ? data.nbPartenaires : 0,
        nbPartenairesStand: data.nbPartenairesStand ? data.nbPartenairesStand : 0,
        rating: data.rating ? data.rating : "0",
        appreciation: data.appreciation ? data.appreciation : { nbAvis: 0, moyennes: 0 },
      });
    }
    else if (type === 'updateBank') {
      let uploadError = data && data.status === 200 ? false : true;
      this.setState({
        uploadError,
      });
      if (data && data.status === 200) {
        this.props.verifyBankFile(this.callBack);
      }else{
        this.setState({
          isLoading: false,
        });
      }
    }
    else if(type === 'verifyBankFile' && !this.state.getAllData){
      this.setState({
        isLoading: false,
      });
    }
    else if (type === 'GIP') {
      this.setState({ isStripeOK: data.etat });
    }
    else if (type === 'BVF') {
      // this.setState({identityFile: data.identityFile, kbisFile: data.kbisFile})
    }
    else if (type === 'PCV') {
      // this.setState({completionProfileValue: data.data.stateOfProgress})
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        addStepError,
        isLoading:false
      })
    }else if (type==="getInfoDashboard" && this.state.getInfo){
      this.setState({
        getInfo: false,
        isLoading:false
      })
    }
  }

  onChange = () => {
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({ isLoading: true });
    this.getAllData()

    const { bankInfoFile } = this.props.bank
    if (bankInfoFile && bankInfoFile) {
      this.setState({
        ...bankInfoFile,
      });
    }
    const { infoProgressionEtablissement } = this.props.etablissement
    if (infoProgressionEtablissement) {
      this.setState({
        ...infoProgressionEtablissement,
      });
    }
    const { listPrestation } = this.props.prestation;
    if (listPrestation) {
      this.setState({
        listPrestation,
      });
    }
    const { dataGraphe } = this.props.sellers;
    if (dataGraphe) {
      this.setState({
        dataGraphe,
      });
    }

    const { infoDashboard } = this.props.dashboard;
    if (infoDashboard) {
      this.setState({
        ...infoDashboard,
      })
    }
    this.chart = am4core.create("chartdiv", am4charts.XYChart);
    this.chart.data = this.state.dataGraphe;

    const title = this.chart.titles.create();
    title.text = "MES REVENUS (€)";
    title.fontSize = 12;
    title.marginBottom = 20;

    this.chart.legend = new am4charts.Legend();
    this.chart.legend.useDefaultMarker = true;
    this.chart.legend.contentAlign = "left";

    var marker = this.chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.location = 0;

    categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
      return -target.maxRight / 2;
    })

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.min = 0;

    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = "value";
    series.tooltipText = "{valueY.value}";
    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.strokeDashArray = "1,3";
    series.columns.template.width = 0.01;
    series.tooltip.pointerOrientation = "horizontal";
    series.name = "Revenu par mois"
    series.bullets.create(am4charts.CircleBullet);
    this.chart.cursor = new am4charts.XYCursor();
  }
  getAllData = async () => {
    const { idSeller } = this.props.sellers;
    const date = new Date();
    const queryIncome = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`
    const queryHotel = {
      itemPerPage: 1,
      page: 1,
      status: 1,
    }
    this.setState({
      nbWSGet: 0,
      getAllData: true,
    })
    await this.props.getInfoDashboard(queryIncome,this.callBack);
    await this.props.getAllInfoDashboard(this.callBack);
    await this.props.verifyBankFile(this.callBack);
    await this.props.getProgressionEtablissement(this.callBack);
    await this.props.getSteps(this.callBack);
    if (localStorage.getItem('type_compte') && !localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE')) {
      await this.props.getPrestation(null,this.callBack);
      await this.props.getRequetePartenariat(queryHotel, this.callBack);
    }
    await this.props.getSellerId(this.callBack);
    await this.props.getDataGraphe(this.callBack);
    await this.props.getOpeningTime(idSeller.id, this.callBack);
    if (localStorage.getItem('type_compte') && localStorage.getItem('type_compte').includes('ROLE_SELLER_INDEPENDANT')) {
      await this.props.getSellerInfoIndependant(this.callBack);
    }
    else {
      await this.props.getInfoEtablissement(this.callBack);
    }

  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  componentDidUpdate(prevProps) {
    const prevBankInfFile = prevProps.bank.bankInfoFile;
    const propsBankInfoFile = this.props.bank.bankInfoFile;
    const prevProgress = prevProps.etablissement.infoProgressionEtablissement;
    const propsProgress = this.props.etablissement.infoProgressionEtablissement;
    const prevListPrestation = prevProps.prestation.listPrestation;
    const propsListPrestation = this.props.prestation.listPrestation;
    const prevDataGraphe = prevProps.sellers.dataGraphe;
    const propsDataGraphe = this.props.sellers.dataGraphe;
    const prevInfoDashboard = prevProps.dashboard.infoDashboard;
    const propsInfoDashboard = this.props.dashboard.infoDashboard;

    if (!prevBankInfFile || !prevProgress || !prevListPrestation || !prevDataGraphe || !prevInfoDashboard) {
      return;
    }
    if (prevBankInfFile.toString() !== propsBankInfoFile.toString()) {
      this.props.verifyBankFile(this.callBack);
      this.props.getProgressionEtablissement(this.callBack)
      // this.setState({
      //   ...propsBankInfoFile,
      // });
    }
    if (prevProgress !== propsProgress) {
      this.setState({
        ...propsProgress,
      });
    }
    if (prevListPrestation !== propsListPrestation) {
      this.setState({
        listPrestation: propsListPrestation,
      });
    }
    if (prevDataGraphe !== propsDataGraphe) {
      this.setState({
        dataGraphe: propsDataGraphe,
      });
      if (this.chart) {
        this.chart.data = propsDataGraphe;
      }
    }
    if (prevInfoDashboard !== propsInfoDashboard) {
      this.setState({
        ...propsInfoDashboard,
      });
    }
  }

  handleClick(e) {
    // this.refs.fileUploader.click();
    // th

  }
  uploadFileIdentity = () => {
    document.getElementById("fileIdentity").click();
  }

  uploadFileKbis = () => {
    document.getElementById("fileKbis").click();
  }

  sendIdentityFile = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const file1 = reader.result;
      const data2 = {
        name: "namePic",
        data: file1
      }
      this.setState({ isLoading: true })
      const data = {
        identityFile: data2
      }

      this.props.uploadBankFile(data, this.callBack)


      //this.setState({ logo: data2 });
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  sendKbisFile = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const file1 = reader.result;
      const data2 = {
        name: "namePic",
        data: file1
      }
      this.setState({ isLoading: true })
      const data = {
        kbisFile: data2
      }
      this.props.uploadBankFile(data, this.callBack)
      //this.setState({ logo: data2 });
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  containsAll = (arr, target) => target.every(v => arr.includes(v));

  goToStep1 = () => {
    const { infoLogin } = this.props.commons;
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    const defaultOpen =  isIndependant ? [0] : [0,1];
    this.props.history.push({ pathname: '/gestionadministration', state: { defaultOpen, activeTab: 0, fromHeadBand: true } })
  }

  goToStep2 = () => {
    this.props.history.push({ pathname: "/etablissement-institut/Etablissement", state: { fromHeadBand: true } })
  }
  goToStep3 = () => {
    const { infoLogin } = this.props.commons;
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    const defaultOpen =  isIndependant ? [1,2] : [2,3];
    const activeTab =  isIndependant ? 1 : 2;
    this.props.history.push({ pathname: '/gestionadministration', state: { defaultOpen, activeTab, fromHeadBand: true } })
  }
  goToStep4 = () => {
    const { infoLogin } = this.props.commons;
    const isAgence = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_AGENCE');
    if (!isAgence) {
      this.props.history.push({ pathname: "/etablissement-institut/Prestations", state: { fromHeadBand: true } })
    }
  }
  goToStep5 = () => {
    this.props.history.push({ pathname: "/hotel/TousHotels", state: { fromHeadBand: true } })
  }

  getStep1Finished = () => {
    const { steps } = this.props.dashboard;
    const { infoLogin } = this.props.commons;
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    const finishedSteps = isIndependant ?  ["1b"] : ["1a", "1b"];
    return this.containsAll(steps,finishedSteps);
  }

  getStep2Finished = () => {
    const { steps } = this.props.dashboard;
    return this.getStep1Finished() && this.containsAll(steps,["2"]);
  }

  getStep3Finished = () => {
    const { steps } = this.props.dashboard;
    return this.getStep2Finished() && this.containsAll(steps, ["3a", "3b"]);
  }

  getStep4Finished = () => {
    const hasProduct = this.state.listPrestation && this.state.listPrestation.data && Array.isArray(this.state.listPrestation.data) ? this.state.listPrestation.data.length > 0 : false;
    return this.getStep3Finished() && hasProduct;
  }

  getStep5Finished = () => {
    const sellerType = getSellerType(this.props.commons.infoLogin);
    if (sellerType === 3) {
      return this.getStep3Finished();
    }
    const partnershipRequests = (this.props.hotels.listPartnershipRequest_1 && this.props.hotels.listPartnershipRequest_1.data) || null;
    const {listHotelPartner} = this.props.hotels;
    const hasPartnershipRequest = (partnershipRequests && Array.isArray(partnershipRequests) && partnershipRequests.length > 0) || false;
    const hasPartner = (listHotelPartner && listHotelPartner.data && Array.isArray(listHotelPartner.data) && listHotelPartner.data.length > 0) || false;    
    return this.getStep4Finished() && (hasPartnershipRequest || hasPartner);
  }

  getStepsLoading = () => {
    const { steps } = this.props.dashboard;
    return this.containsAll(steps, ["loading"]);
  }

  getHeadbangClosed = () => {
    const { steps } = this.props.dashboard;
    return this.containsAll(steps, ["closed"]);
  }

  onDateSelected = (query) =>{
    this.setState({ 
      isLoading: true,
      getInfo: true
    }, ()=>{
      this.props.getInfoDashboard(query, this.callBack);
    })
  }

  hideCongratulation = () => {
    this.setState({ 
      isLoading: true,
    })
    this.props.addSteps({steps: ["closed"]}, this.callBack);
  }
}

export default DashboardController;

DashboardController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
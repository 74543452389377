import { combineReducers } from 'redux';
import activityArea from './activityArea';
import bank from './bank';
import commandes from './commandes';
import commons from './commons';
import dashboard from './dashboard';
import etablissement from './etablissement';
import hotels from './hotels';
import language from './language';
import notice from './notice';
import prestation from './prestation';
import sellers from './sellers';
import agenda from './agenda';


const reducers = combineReducers({
    activityArea,
    bank,
    commandes,
    commons,
    dashboard,
    etablissement,
    hotels,
    language,
    notice,
    prestation,
    sellers,
    agenda,
});


export default reducers;

import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class HotelPartenaireController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hotel: [{
        name: "test",
        address: "test",
        email: "test",
        phone: "test",
        dateBegin: "test",
        isPartenaire: true,
        marge: "test",
        dateEnd: "test",
        id: "1"
      }],
      finPartenariatId: "",
      search: "",
      query: {
        itemPerPage: 5,
        page: 1,
      },
      data: [],
      txtSearch: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  openModal(id) {
    this.setState({
      visible: true
    });
    this.setState({ finPartenariatId: id });
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
    this.setState({ finPartenariatId: "" });
  }

  searchIsNotVoid = () => {
    const { txtSearch } = this.state
    return txtSearch !== ""
  }

  handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.sendSearch()
    }
  }

  sendSearch = (event) => {
    let query = this.state.query;
    query.filter = this.state.txtSearch;
    this.props.setLoadingTrue()
    this.props.getHotelPartner(query, this.callBack);
  }

  endPartenership = (id) => {
    this.props.setLoadingTrue()
    this.props.endPartenership(id, this.callBack);
    this.closeModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.search !== "") {
      this.props.initLoading();
      this.props.fetchApiSearchHotelPartener(this.state.search);
    }
  }

  updateState = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
    if (event.target.value === "") {
      this.props.setLoadingTrue()
      const query = {
        itemPerPage: 5,
        page: 1,
      }
      this.setState({ query })
      this.props.getHotelPartner(query, this.callBack);
    }
  }

  componentDidMount() {
    this.getAllData();
  }
  getAllData = async () => {
    this.props.setLoadingTrue()
    await this.props.getHotelPartner(this.state.query, this.callBack);
    this.props.setLoadingFalse()
  }

  handlePaginationChange = (page) => {
    let query = this.state.query;
    query.page = parseInt(page)
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getHotelPartner(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let query = this.state.query;
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.props.setLoadingTrue();
    this.setState({ query })
    this.props.getHotelPartner(this.state.query, this.callBack);
  };

  callBack = (data, type) => {
    if (data && data.status && data.status === 200 && type && type === "pagination" && data.data) {
      this.props.setLoadingFalse()
      this.setState({
        data: data.data
      })
    }
    else if (type === "endPartenership") {
      let requestError = data && data.status && (data.status === 200 || data.status === 201) ? false : true;
      this.setState({
        requestError
      })
      if (!requestError) {
        const date = new Date();
        const queryIncome = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`;
        this.props.getHotelPartner(this.state.query, this.callBack);
        this.props.getInfoDashboard(queryIncome, this.callBack);
      }else{
        this.props.setLoadingFalse()
      }
    }
    else {
      this.props.setLoadingFalse()
    }
  }

}

export default HotelPartenaireController;

//
// Redux connection
//

HotelPartenaireController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
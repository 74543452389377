import axios from 'axios';
import config from '../../config';
import { TOKEN, EXPIRES_AT, REFRESH_TOKEN, WS_CALL_TIME, url } from '../redux/constants/commons';
import store from '../redux/store';

const LOGIN_URL= `${config.baseURL}${url.login}`;
const REFRESH_TOKEN_URL= `${config.baseURL}${url.refreshToken}`;

export const headers = (token,id) => ({
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "Authorization":'Bearer ' + (token ? token : localStorage.getItem('auth_token')),
  "SELLER":id? id : localStorage.getItem('idSeller'),
});

const setCallTime = () => {
  const wsCallTime = new Date().getTime();
  localStorage.setItem(WS_CALL_TIME, wsCallTime);
}

const refreshTokenAndReload = async(exception) => {
  const originalRequest = exception.config;
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const accessTOken = localStorage.getItem(TOKEN);
  const header= {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
  }
  if (!refreshToken || !accessTOken) {
    dispatchTokenExpired();
    return exception;
  }
  if (originalRequest && !originalRequest._retry && originalRequest.url !== LOGIN_URL && originalRequest.url !== REFRESH_TOKEN_URL) {
    try {
      originalRequest._retry = true;
      const tokenRefreshResponse = await axios.post(REFRESH_TOKEN_URL, {refresh_token: refreshToken}, { headers: header});
      if(tokenRefreshResponse && tokenRefreshResponse.data){
          const ttl = 3600;
          const expiresAt = JSON.stringify(new Date().getTime() + ttl * 1000);
          const newAccessToken = tokenRefreshResponse.data.token;
          const newRefreshToken = tokenRefreshResponse.data.refreshToken;
          localStorage.setItem(REFRESH_TOKEN, newRefreshToken);
          localStorage.setItem(TOKEN, newAccessToken);
          localStorage.setItem(EXPIRES_AT, expiresAt);
          originalRequest.headers = headers(newAccessToken);
          const res = await axios(originalRequest)
          return res;
      }
      return exception
    }catch (e) {
      return e;
    }
  }else {
    return exception;
  }
}

const dispatchTokenExpired = ()=>{
  store.dispatch({
      type: EXPIRES_AT,
      payload: true,
  });
}

const handleException = async(exception) => {
  const isTokenExpiredException = exception.response && exception.response.data && exception.response.data.message && exception.response.data.message.includes("This token is expired") &&  exception.response.data.code === 403
  const ttl = 3600;
  const currentTime = new Date().getTime();
  const lastWsCallTime = localStorage.getItem(WS_CALL_TIME);
  // const expiresDate = localStorage.getItem(EXPIRES_AT);
  // const lastConnection = (expiresDate && parseInt(expiresDate) && parseInt(expiresDate) - ttl * 1000) || currentTime;
  const expires = isTokenExpiredException && currentTime - parseInt(lastWsCallTime) > ttl * 1000;
  if (expires) {
    dispatchTokenExpired(exception);
    return exception
  } else if (isTokenExpiredException) {
    const res = await refreshTokenAndReload(exception);
    return res
  }
  setCallTime();
  return exception
}


export const get = async (url,token, extraHeader, isDownload) => {
  try {
    let allHeaders = token ? headers(token): headers();
    if (extraHeader) {
      allHeaders = {
        ...allHeaders,
        ...extraHeader
      }
    };
    const conf = {
      headers: {
          ...allHeaders
      }
    };
    if (isDownload) {
      conf.responseType = 'blob'
    }
    const res = await axios.get(`${config.baseURL}${url}`, conf);
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
    const res = await handleException(e)
    return res;
  }
};

export const getWithExtraURL = async (url,token, extraHeader, isDownload) => {
  try {
    let allHeaders = token ? headers(token): headers();
    if (extraHeader) {
      allHeaders = {
        ...allHeaders,
        ...extraHeader
      }
    };
    const conf = {
      headers: {
          ...allHeaders
      }
    };
    if (isDownload) {
      conf.responseType = 'blob'
    }
    const res = await axios.get(`${url}`, conf);
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
    const res = await handleException(e)
    return res;
  }
};

export const postWithID = async (url, data, id) => {
  try {
    const header= {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Authorization":'Bearer ' + localStorage.getItem('auth_token'),
      "SELLER":id
    }
    const res = await axios.post(`${config.baseURL}${url}`, data?data:[], { headers: header});
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
    const res = await handleException(e)
    return res;
  }
};
export const post = async (url, data, token, idSeller) => {
  try {
    const res = await axios.post(`${config.baseURL}${url}`, data?data:[], { headers: headers(token,idSeller) });
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
     const error = {
       error : e
     }
    const res = await handleException(error)
    return res;
  }
};

export const put = async (url, data = {}, token,id) => {
  try {
    const res = await axios.put(`${config.baseURL}${url}`, data, { headers: token? headers(token,id): headers() });
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
    const res = await handleException(e)
    return res;
  }
}

export const putWithId = async (url, data = {}, id) => {
  try {
    const header= {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Authorization":'Bearer ' + localStorage.getItem('auth_token'),
      // "SELLER":id
    }
    const res = await axios.put(`${config.baseURL}${url}`, data, { headers: header});
    if (res) {
      setCallTime();
    }
    return res;
  } catch (e) {
    const res = await handleException(e)
    return res;
  }
}

export const remove = async(url,token,id) => {
  try{
    const res = await axios.delete(`${config.baseURL}${url}`,{ headers: headers(token,id) });
    if (res) {
      setCallTime();
    }
    return res;
  } catch(e) {
    const res = await handleException(e)
    return res;
  }
   
}

export const patch = async() => {

}
  export default {
    get,
    patch,
    post,
    put,
    remove,
  };
  
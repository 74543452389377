import React from 'react';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
// import { Accordion, AccordionItem } from 'react-light-accordion';
import EtablissementController, { reduxConnect } from './controller'

import 'react-web-tabs/dist/react-web-tabs.css';
import 'react-light-accordion/demo/css/index.css';
import 'react-dropdown/style.css';
import LoadingOverlay from 'react-loading-overlay';

import Commentaires from './commentaire';
import Client from './client';
import Identite from './identite';
import Independant from './independant';
import Prestation from './prestation';
import PrestationIndependant from './prestationIndependant';
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import PopupHandler from "./../../components/popup/popupHandler";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';



class Etablissement extends EtablissementController {

  render() {
    const { infoLogin } = this.props.commons;
    const defaultTab = () => {
      if (this.props.location && this.props.location.pathname) {
        const res = this.props.location.pathname.split('/');
        return res[res.length - 1];
      }
      return "Etablissement";
    }
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Mon établissement</h1>
            </div>
          </div>
          {this.state.saveError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
          {this.state.saveError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
          <div className="main-catalogue">
            <TabProvider defaultTab={defaultTab()}>
              <section className="my-tabs">
                <TabList className="my-tablist">
                  <div className="wrapper">
                    <Tab tabFor="Etablissement">Mon établissement</Tab>
                    {
                      infoLogin && infoLogin.roles && !infoLogin.roles.includes('ROLE_SELLER_AGENCE') &&
                      <Tab tabFor="Prestations">Mes prestations</Tab>
                    }
                  </div>
                </TabList>
                <div className="wrapper cnt-tabs">
                  <TabPanel tabId="Etablissement">
                    <div className="btn-vue">
                      <button className="button" onClick={() => this.clickButton(this.state.asCustomer)}>
                        <span className="vue">
                          <i className="material-icons arrow-eye">remove_red_eye</i>
                        </span> {this.state.buttonText}
                      </button>
                    </div>
                    {!this.state.asCustomer ? <div className="content-accordeon">
                      <Accordion
                        allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={this.state.preExpanded}
                      >
                        <AccordionItem uuid={1} >
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Identité
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            {
                              infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ?
                                <Independant
                                  errors={this.props.errors}
                                  setLoadingTrue={this.setLoadingTrue}
                                  setLoadingFalse={this.setLoadingFalse}
                                />
                                :
                                <Identite
                                  errors={this.props.errors}
                                  setLoadingTrue={this.setLoadingTrue}
                                  setLoadingFalse={this.setLoadingFalse}
                                  onImageChanged={this.onImageChanged}
                                  onNameChanged={this.onNameChanged}
                                />
                            }
                          </AccordionItemPanel>
                        </AccordionItem>
                          <AccordionItem uuid={2}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Commentaires de l’établissement
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <Commentaires 
                                logo={this.state.logo}
                                name={this.state.name}
                                setLoadingTrue={this.setLoadingTrue}
                                setLoadingFalse={this.setLoadingFalse}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                    </div> : <Client
                        etablissementName={this.state.etablissementName}
                        etablissementAddress={this.state.etablissementAddress}
                        openHour={this.state.openHour}
                        etablissementDescri={this.state.etablissementDescri}
                        picture={this.state.primaryFile}
                      />}
                  </TabPanel>
                  {
                    infoLogin && infoLogin.roles && !infoLogin.roles.includes('ROLE_SELLER_AGENCE') &&
                    <TabPanel tabId="Prestations">
                      <div className="btn-vue">
                        <button className="button" onClick={() => this.clickButton(this.state.asCustomer)}>
                          <span className="vue">
                            <i className="material-icons arrow-eye">remove_red_eye</i>
                          </span> {this.state.buttonText}
                        </button>
                      </div>
                      {
                        this.state.asCustomer ?
                          <Client />
                          :
                          infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ?
                            <PrestationIndependant
                              setLoadingTrue={this.setLoadingTrue}
                              setLoadingFalse={this.setLoadingFalse} />
                            :
                            <Prestation />
                      }
                    </TabPanel>
                  }
                </div>
              </section>
            </TabProvider>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(Etablissement);

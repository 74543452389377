import React from 'react';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import Input from 'react-phone-number-input/input';
import Rating from 'react-rating';
import Dropdown from 'react-dropdown';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';
import IdentiteController, { reduxConnect } from './controller';
import RenderHour from './../../../components/etablissement/renderHour';
import InstitutImg from './../img/avatar.png';
import { LIST_PAYS } from "./../../../../data/constants/listePays.js";
import PopupHandler from "../../../components/popup/popupHandler";

const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

const options = getCountries().map((country) => ({
  label: en[country] + ' +' + getCountryCallingCode(country),
  chipLabel: '+' + getCountryCallingCode(country),
  value: country
}));

class Identite extends IdentiteController {
  renderHour() {
    return (
      <RenderHour
        updateStateOpenTime={this.updateStateOpenTime}
        removeOpenTime={this.removeOpenTime}
        listOpeningTime={this.state.listOpeningTime}
      />
    )
  }

  render() {
    const { listAllLanguage } = this.props.language;
    const setOption = () => {
      return listAllLanguage && this.state.listSellerLanguage ? listAllLanguage.filter(item => this.state.listSellerLanguage.map(i => i.language).includes(item.language) === false) : [];
    }
    
    return (
      <div className="cnt-tab">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
        <div>
          {this.state.removeLanguageError && PopupHandler.showError("La suppression de la langue a échoué.")}
          {this.state.saveLanguageError && PopupHandler.showError("L'enregistrement de la langue a échoué.")}
          {this.state.createOpeningTimeError && PopupHandler.showError("La création de l'horaire a échoué.")}
          {this.state.updateOpeningTimeError && PopupHandler.showError("La modification de l'horaire a échoué.")}
          {this.state.removeOpeningTimeError && PopupHandler.showError("La suppression de l'horaire a échoué.")}
          {this.state.saveInfoError && PopupHandler.showError("L'enregistrement de l'établissement a échoué.")}
          {this.state.saveInfoError === false && PopupHandler.showSuccess("Les informations de l'établissement ont bien été enregistrées.")}
          {this.state.imageFormatError && PopupHandler.showError("L'image doit être au format .jpeg, .jpg ou .png.")}
          {this.state.logoDimensionError && PopupHandler.showError("La Photo principale doit avoir des dimensions entre 196 x 148px et 450 x 350px.")}
          {this.state.imageDimensionError && PopupHandler.showError("La Photo complémantaire doit avoir des dimensions entre 930 x 200px et 1920 x 413px.")}
          {this.state.imageSizeError && PopupHandler.showError("L'image doit avoir une taille maximum de 5Mo.")}
          {this.state.imageNbError && PopupHandler.showError("Le nombre de Photos complémantaires ne doit pas dépasser 5.")}
          {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
        </div>
          <form className="" onSubmit={this.handleSubmit}>
            <div className="main-indentite">
              <div className="row">
                <div className="col col-5">
                  <p className="titlephoto">Photo principale ou logo</p>
                  <div className="cnt-photo">
                    <div className="blc-photo">
                      {/* <img src={this.state.logo && this.state.logo.data && !this.state.logo.url ? (this.state.logo.data.includes("data:image/")) ? this.state.logo.data : `data:image/jpeg;base64,${this.state && this.state.logo && this.state.logo.data}` : this.state.logo && this.state.logo.url ? this.state.logo.url : InstitutImg} alt="Institut" /> */}
                      <img src={(this.state.logo && this.state.logo.url) || InstitutImg} alt="Institut" />
                    </div>
                    <div className="blc-edit">
                      <Link to="#"><span className="icon-edit">
                        <i className="material-icons icon-edit" onClick={this.uploadLogo}>edit</i>
                      </span></Link>
                    </div>
                    {
                      this.state.errors.logo &&
                      <span className='error'>{this.state.errors.logo}</span>
                    }
                  </div>
                  <div className="photo-compl">
                    <p className="titlephoto"><span className="add-subimage clickable" onClick={this.uploadSubLogo}><span className="icon-add"><i className="material-icons icon-edit">library_add</i></span>Ajouter des photos complémentaires</span></p>
                    <div className="liste-Photo">
                      {
                        this.state.photos && this.state.photos.length > 0 && this.state.photos.map((item) =>
                          <div
                            className="item-Photo"
                            key={`${item.id}`}
                          >
                            <img src={(item && item.url) || InstitutImg} alt="" className="complementary-photo"/>
                            <div className="remove-complementaryPhoto clickable">
                              <i 
                                onClick={()=>this.deleteSubImage(item.id)}
                                class="material-icons icons-cancel"
                              >
                                cancel
                              </i>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="col col-7">
                  <div className="line-input">
                    <label>Nom de l’établissement</label>
                    <div className="cnt-input">
                      <input
                        type="text"
                        className="input-nom-etablissement"
                        value={this.state.name ? this.state.name : ""}
                        onChange={(event) => this.updateState(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="name"
                      />
                      {
                        this.state.errors.name.length > 0 &&
                        this.state.errors.name !== 'default' &&
                        <span className='error'>{this.state.errors.name}</span>
                      }
                    </div>
                  </div>
                  <div className="line-input">
                    <label>Rue de l'établissement</label>
                    <div className="cnt-input">
                      <input
                        type="text"
                        className="input-adresse-etablissement"
                        value={this.state.address ? this.state.address.streetAddress : ""}
                        onChange={(event) => this.updateStateAddress(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="streetAddress"
                      />
                      {
                        this.state.errors.streetAddress.length > 0 &&
                        this.state.errors.streetAddress !== 'default' &&
                        <span className='error'>{this.state.errors.streetAddress}</span>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-4 line-input">
                      <label>Ville</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-adresse-etablissement"
                          value={this.state.address.city ? this.state.address.city : ""}
                          onChange={(event) => this.updateStateAddress(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="city"
                        />
                        {
                          this.state.errors.city.length > 0 &&
                          this.state.errors.city !== 'default' &&
                          <span className='error'>{this.state.errors.city}</span>
                        }
                      </div>
                    </div>
                    <div className="col col-4 line-input">
                      <label>Pays</label>
                      <div className="cnt-input">
                        <select
                          value={(this.state.address && this.state.address.country) || ""}
                          onChange={(value) => this.updateStateAddress(value)}
                          onBlur={(event) => this.handleUserInput(event)}
                          placeholder="Select"
                          name="country"
                        >
                          <option value="">{"Select"}</option>
                          {LIST_PAYS.map(item =>
                            <option key={`item-${item.value}`} value={item.value}>{item.label}</option>
                          )}
                        </select>
                        {
                          this.state.errors.country.length > 0 &&
                          this.state.errors.country !== 'default' &&
                          <span className='error'>{this.state.errors.country}</span>
                        }
                      </div>
                    </div>
                    <div className="col col-4 line-input">
                      <label>Code postal</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-adresse-etablissement"
                          value={this.state.address.zipCode ? this.state.address.zipCode : ""}
                          onChange={(event) => this.updateStateAddress(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="zipCode"
                        />
                        {
                          this.state.errors.zipCode.length > 0 &&
                          this.state.errors.zipCode !== 'default' &&
                          <span className='error'>{this.state.errors.zipCode}</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6 line-input">
                      <label>Numéro de téléphone</label>
                      <div className="PhoneInput">
                        <Select
                          onChange={(value) => this.handleUserSelect(value)}
                          options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                          components={{ SingleValue }}
                          value={options.find(op => { return op.value === (this.state.currentCountry ? this.state.currentCountry : "") })}
                        />
                        <Input
                          country={this.state.currentCountry}
                          international
                          value={this.state.phone ? this.state.phone : ""}
                          onChange={((value) => this.updateStateNumber(value))}
                          onBlur={(event) => this.handleNumberInput(event)}
                          name='phone'
                        />
                        {
                          this.state.errors.currentUserNumberError.length > 0 &&
                          this.state.errors.currentUserNumberError !== 'default' &&
                          <span className='error'>{this.state.errors.currentUserNumberError}</span>
                        }
                      </div>
                    </div>
                    <div className="col col-6 line-input">
                      <label>Accès (métro, RER, bus)</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-acces"
                          value={this.state.transportAccess ? this.state.transportAccess : ""}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="transportAccess"
                        />
                        {
                          this.state.errors.transportAccess.length > 0 &&
                          this.state.errors.transportAccess !== 'default' &&
                          <span className='error'>{this.state.errors.transportAccess}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-input">
                <label>Description de l’établissement</label>
                <div className="cnt-input">
                  <textarea
                    value={this.state.description ? this.state.description : ""}
                    onChange={(event) => this.updateState(event)}
                    onBlur={(event) => this.handleUserInput(event)}
                    name="description"
                  >
                  </textarea>
                  {
                    this.state.errors.description.length > 0 &&
                    this.state.errors.description !== 'default' &&
                    <span className='error'>{this.state.errors.description}</span>
                  }
                </div>
              </div>
              <div className="line-input">
                <label>Horaires d’ouverture</label>
                <div className="cnt-input">
                  {
                    this.renderHour()
                  }
                </div>
                <div className="cnt-Btn align-right cnt-btn-strock">
                  <button className="button" type="button" onClick={this.addNewOpenTime}>+ Ajouter un horaire</button>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="line-input">
                <label>Langues pratiquées*</label>
                {
                  this.state.listSellerLanguage && [].concat(this.state.listSellerLanguage).map((value, index) =>
                    <div className="line-langue" key={`${index}-langue`}>
                      <div className="blc-langue">
                        <span className="arrow"><i className="material-icons icon-language">language</i></span> {value.language}
                      </div>
                      <div className="txt-niveau-langue">
                        Votre niveau dans cette langue
                      </div>
                      <div className="blc-niveau-langue">
                        <Rating
                          initialRating={value.skills}
                          readonly
                        />
                      </div>
                      <div className="remove-langue">
                        <button
                          onClick={() => this.removeLang(value.id)}
                          className="arrow"
                          type="button">
                          <i className="material-icons icons-cancel">cancel</i>
                        </button>
                        {/* <Link to="#">
                          <i className="material-icons icons-cancel">cancel
                          </i>
                        </Link> */}
                      </div>
                    </div>
                  )
                }
                <div className="cnt-Btn align-right cnt-btn-strock">
                  <button
                    type="button"
                    className="button"
                    onClick={this.openModalLanguage}
                  >+ Ajouter une langue</button>
                  <div className="clr"></div>
                </div>
              </div>
              {/* <div className="col col-12 line-input">
                <label>Langues pratiquées</label>
                <div className="select-down select-langue">
                  <Dropdown
                    className="MyclassName"
                    options={setOption().map(i => i.language)}
                    onChange={(value) => this.selectLang(value, listAllLanguage)}
                    value='Ajouter une langue'
                    controlClassName=''
                    arrowClassName='arrow-down'
                    arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                    arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                  />
                </div>
                <div className="list-sous-cat">
                  {
                    listSellerLanguage && listSellerLanguage.map((value, index) =>
                      <div className="item-sous-cat" key={`${index}-langue`}>{value.language}
                        <button to="" onClick={() => this.removeLang(value.id)} className="arrow">
                          <i className="material-icons arrow-cancel">cancel</i>
                        </button>
                      </div>
                    )
                  }

                </div>
              </div> */}
              <div className="cnt-Btn align-right">
                <input
                  className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                  type="submit"
                  value="Enregistrer les modifications"
                  disabled={!this.isNotVoid()}
                />
                <div className="clr"></div>
              </div>
              <div className="wrapper-modal">
                <Modal visible={this.state.visibleL} width="350" effect="fadeInUp" onClickAway={this.closeModalLanguage}>
                  <div className="content-modal">
                    <div className="head-modal">
                      <button className="close-modal" type="button" onClick={this.closeModalLanguage}><i className="material-icons icons-close">close</i></button>
                      <h1>Ajouter une langue</h1>
                    </div>
                    <div className="main-modal">
                      <div className="line-input">
                        <label>Langue</label>
                        <div className="select-down">
                          <Dropdown
                            className="MyclassName"
                            options={setOption().map(i => i.language)}
                            onChange={(value) => this.selectLang(value, listAllLanguage)}
                            value={this.state.nameLangue ? this.state.nameLangue : "Ajouter une langue"}
                            placeholder="Selectionner une langue"
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                      <div className="line-input">
                        <label>Niveau</label>
                        <div className="blc-niveau-langue">
                          <Rating
                            initialRating={this.state.niveauLangue ? this.state.niveauLangue : 0}
                            onChange= {(event)=>{this.selectSkills(event)}}
                          />
                        </div>
                      </div>
                      <div className="cnt-Btn align-right">
                        <button
                          type="button"
                          className={`button ${this.languageIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                          onClick={() => this.addLanguage()}
                          disabled={!this.languageIsNotVoid()}
                        >
                          Ajouter une langue
                    </button>
                        <div className="clr"></div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}

export default reduxConnect(Identite);
import constants from '../constants/dashboard';

const initialSate = {
  infoDashboard: [],
  steps: ["loading"],
}
const dashboard = (state = initialSate, action) => {
  switch (action.type) {
    case constants.INFO_DASHBOARD:
      return {
        ...state,
        infoDashboard: action.payload,
      }
    case constants.INFO_DASHBOARD_ALL:
      return {
        ...state,
        infoDashboardAll: action.payload,
      }
    case constants.STEPS_FINISHED:
      return {
        ...state,
        steps: action.payload || [],
      }
    case constants.LOGOUT :     
      return initialSate;
    default:
      return state;
  }

}

export default dashboard;
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import 'react-day-picker/lib/style.css';
import 'moment/locale/fr';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

import InformationBancaireController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";

class InformationBancaire extends InformationBancaireController {


  render() {
    return (
      <div className="cnt-tab">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.updateError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
            {this.state.updateError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
            {this.state.uploadError && PopupHandler.showError("L'upload a échoué.")}
            {(this.state.kbisImgDimensionError || this.state.identityImgDimensionError) && PopupHandler.showError("L'image doit avoir des dimensions maximum 8000 x 8000px.")}
            {(this.state.kbisSizeError || this.state.identitySizeError) && PopupHandler.showError("L'image doit avoir une taille maximum de 10Mo.")}
            {this.state.kbisFormatError && PopupHandler.showError("La photocopie Kbis doit être au format .jpeg ou .png ou .pdf.")}
            {this.state.identityFormatError && PopupHandler.showError("Le papier d'identité doit être au format .jpeg ou .png.")}
            {this.state.identityGreyScaleError && PopupHandler.showError("L'image d'identité doit être en couleur.")}
            {this.state.kbisGreyScaleError && PopupHandler.showError("L'image de Kbis doit être en couleur.")}
            {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
          </div>
          <form className="" onSubmit={this.handleSubmit}>
            <div className="cnt-form">
              <div className="row">
                <div className="col col-5 line-input">
                  <label>Nom du compte titulaire</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-nom-titulaire"
                      value={this.state.accountName ? this.state.accountName : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="accountName"
                    />
                    
                    {
                      this.state.errors.accountName.length > 0 &&
                      this.state.errors.accountName !== "default" &&
                      <span className='error'>{this.state.errors.accountName}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Date de naissance</label>
                  <div className="cnt-input">
                  <DatePickerInput
                      disabled={this.state.disabled}
                      displayFormat='DD/MM/YYYY'
                      returnFormat='YYYY-MM-DD'
                      value={this.state.creationDate ? new Date(this.state.creationDate) : null}
                      className={`my-react-component${this.state.errors.creationDate ? ' hasError' : ''}`}
                      onChange={(jsDate, dateString) => this.updateDayPick(dateString)}
                      showOnInputClick
                      placeholder='Selectionner'
                      locale='fr'
                      maxDate = {new Date()}
                      onClear={() => this.setState({ creationDate: null })}
                      readOnly={true}
                    />
                    {
                      this.state.errors.creationDate.length > 0 &&
                      this.state.errors.creationDate !== "default" &&
                      <span className='error'>{this.state.errors.creationDate}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>Numéro de l’IBAN</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className={`input-num-iban ${this.state.errors.numIban && this.state.errors.numIban !== "default" ? "hasError" : ""}`}
                      value={this.state.numIban ? this.state.numIban : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="numIban"
                    />
                    
                    {
                      this.state.errors.numIban.length > 0 &&
                      this.state.errors.numIban !== "default" &&
                      <span className='error'>{this.state.errors.numIban}</span>
                    }
                  </div>
                </div>
                <div className="col col-3 line-input">
                  <label>Numéro du BIC</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className={`input-num-bic ${this.state.errors.numBic && this.state.errors.numBic !== "default" ? "hasError" : ""}`}
                      value={this.state.numBic ? this.state.numBic : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="numBic"
                    />
                    
                    {
                      this.state.errors.numBic.length > 0 &&
                      this.state.errors.numBic !== "default" &&
                      <span className='error'>{this.state.errors.numBic}</span>
                    }
                  </div>
                </div>
                {this.state.role !== 'ROLE_SELLER_RESTAURENT' &&
                  <div className="col col-3 line-input">
                    <label>Statut</label>
                    <div className={`cnt-input with-icon${!this.state.status || this.state.status === "unverified" ? "erreur" : ""}`} id="bankStatus">
                      <input
                        type="text"
                        disabled
                        className="input-statut"
                        onChange={(event) => this.updateState(event)}
                        value={(this.state.status === 'verified' && 'Valide') || "Invalide"}
                        name="status"
                      />
                      {
                        (!this.state.status || this.state.status === "unverified") &&
                        <span className="edit"><i className="material-icons">error</i></span>
                      }
                    </div>
                  </div>
                }
                {this.state.role !== 'ROLE_SELLER_RESTAURENT' &&
                <div className="col col-5 line-input">
                  <label>Importer un papier d’identité</label>
                  <div className="cnt-input cnt-btn-import">
                    <div className="cnt-Btn btn-file">
                      <input
                        type="file"
                        // disabled={this.state.identityFile === 'ok' ? 'disabled' : ''}
                        name="identityFile" id="identite"
                        className="inputfile"
                        onChange={(event) => this.handleIndentyFileInput(event)}
                        onClick={e => (e.target.value = null)}
                      />
                      <label htmlFor="identite">Importer</label>
                    </div>
                    {
                      (this.state.status === "verified" && <span className="icon valid"><i className="material-icons">check_circle</i></span>) || 
                      ((!this.state.status || this.state.status === "unverified") && <span className="icon error"><i className="material-icons">error</i></span>) ||
                      (this.state.status === "processing" && <span className="icon in-progress"><i className="material-icons">cached</i></span>) ||
                      (this.state.status === "pending" && <span className="icon pending"><i className="material-icons">hourglass_empty</i></span>)
                    }
                  </div>
                  {
                    this.state.identityFileName &&
                    <span className="arrow file-delete">
                      <i className="material-icons arrow-cancel clickable" onClick={()=>this.removeFile("identityFile")}>cancel</i>
                    </span>
                  }
                  {
                    this.state.identityFile && 
                      <a href={this.state.identityFile} target="_blank" rel="noopener noreferrer">{this.state.identityFileName || ""}</a>
                  }
                  {
                    !this.state.identityFile && 
                      <span>{this.state.identityFileName || ""}</span>
                  }
                </div>}
                {this.state.role !== 'ROLE_SELLER_RESTAURENT' &&
                <div className="col col-4 line-input">
                  <label>Importer la photocopie du Kbis</label>
                  <div className="cnt-input cnt-btn-import">
                    <div className="cnt-Btn btn-file">
                      <input
                        type="file"
                        //disabled={this.state.kbisFile === 'ok' ? 'disabled' : ''}
                        name="file" id="fleKbis"
                        className="inputfile"
                        onChange={(event) => this.handlekbisFileInput(event)}
                        onClick={e => (e.target.value = null)}
                      />
                      <label htmlFor="fleKbis">Importer</label>
                    </div>
                    {
                      (this.state.status === "verified" && <span className="icon valid"><i className="material-icons">check_circle</i></span>) || 
                      ((!this.state.status || this.state.status === "unverified") && <span className="icon error"><i className="material-icons">error</i></span>) ||
                      (this.state.status === "processing" && <span className="icon in-progress"><i className="material-icons">cached</i></span>) ||
                      (this.state.status === "pending" && <span className="icon pending"><i className="material-icons">hourglass_empty</i></span>)
                    }
                  </div>
                  {
                    this.state.kbisFileName &&
                    <span className="arrow file-delete">
                      <i className="material-icons arrow-cancel clickable" onClick={()=>this.removeFile("kbisFile")}>cancel</i>
                    </span>
                  }
                  {
                    this.state.kbisFile && 
                      <a href={this.state.kbisFile} target="_blank" rel="noopener noreferrer">{this.state.kbisFileName || ""}</a>
                  }
                  {
                    !this.state.kbisFile && 
                      <span>{this.state.kbisFileName || ""}</span>
                  }
                </div>}
              </div>
          </div>
            <div className="cnt-Btn">
              <input
                className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                type="submit"
                value="Enregistrer"
                disabled={!this.isNotVoid()}
              />
              <div className="clr"></div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(InformationBancaire);

import React from 'react';
import Dropdown from 'react-dropdown';
import LoadingOverlay from 'react-loading-overlay';

import InfoJuridiquelController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";

class ContactHotel extends InfoJuridiquelController {

  render() {
    
    this.state.infoJuridique = this.props.etablissement.legalInfoEtablissement;
    const {defaultOption} = this.state;

    return (
      <div className="cnt-tab">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.updateError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
            {this.state.updateError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
            {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="cnt-form">
              <div className="row">
                <div className="col col-6 line-input">
                  <label>Statut juridique</label>
                  <div className="select-down">
                    <Dropdown
                      className="MyclassName"
                      defaultOption={defaultOption}
                      options={this.getOptionsStatus()}
                      onChange={(value) => this.handleChange(value)}
                      value={this.state.status ? this.state.status : ""}
                      placeholder="Select"
                      controlClassName='myControlClassName'
                      arrowClassName='arrow-down'
                      arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                      arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                    />
                  </div>
                </div>
                <div className="col col-6 line-input"/>
                <div className="col col-6 line-input">
                  <label>SIRET</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className={`input-siret ${this.state.errors.numSiret && this.state.errors.numSiret !== "default" ? "hasError" : ""}`}
                      value={this.state.numSiret ? this.state.numSiret : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="numSiret"
                    />
                    
                  </div>
                  {
                    this.state.errors.numSiret.length > 0 &&
                    this.state.errors.numSiret !== "default" &&
                    <span className='error'>{this.state.errors.numSiret}</span>
                  }
                </div>
                {/* <div className="col col-4 line-input">
                  <label>URL Key</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-url-key"
                      value={this.state.url ? this.state.url : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="url"
                    />
                    
                  </div>
                  {
                    this.state.errors.url.length > 0 &&
                    this.state.errors.url !== "default" &&
                    <span className='error'>{this.state.errors.url}</span>
                  }
                </div> */}
                <div className="col col-6 line-input">
                  <label>Numéro de TVA</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className={`input-nom-contact-et ${this.state.errors.numTva && this.state.errors.numTva !== "default" ? "hasError" : ""}`}
                      value={this.state.numTva ? this.state.numTva : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="numTva"
                    />
                    
                  </div>
                  {
                    this.state.errors.numTva.length > 0 &&
                    this.state.errors.numTva !== "default" &&
                    <span className='error'>{this.state.errors.numTva}</span>
                  }
                </div>
              </div>
            </div>
            <div className="cnt-Btn">
              <input
                className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                type="submit"
                value="Enregistrer"
                disabled={!this.isNotVoid()}
              />
              <div className="clr"></div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(ContactHotel);
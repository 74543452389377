import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import PopupConfirm from "../../../components/popup/popup.confirmation";
import {showPopUp} from "../../../components/popup/popup";
import PopupHandler from "../../../components/popup/popupHandler";


import PrestationController, { reduxConnect } from './controller'

class Prestation extends PrestationController {

  onDeletePrestation = (value) => {
    showPopUp(
      <PopupConfirm 
          message = "Voulez-vous vraiment supprimer cette prestation ?"
          onAccept = {()=>this.deletePrestation(value.id)}
      />,
     "Confirmation"
    )
  };

  renderPagination = () => {
    const { listPrestation } = this.props.prestation;
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={listPrestation && listPrestation.nbPage ? listPrestation.nbPage * listPrestation.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={listPrestation && listPrestation.itemPerPage ? listPrestation.itemPerPage : 5}
            activePage={listPrestation && listPrestation.page ? listPrestation.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  renderRow() {
    const { listPrestation } = this.props.prestation;
    return (
      listPrestation && listPrestation.data && Array.isArray(listPrestation.data) && listPrestation.data.map((value) =>
        <tr key={`${value.id}-prestation`} >
          <td><p className="name-product">{value.name}</p>{value.categoryName !== null ? value.categoryName : ""}<br />{this.getDuration(value.duration)}</td>
          <td>
            <p className="price">{`${value.price}€`}</p>
            {(value.reduction && value.reduction !== 0) ? <p className="reduction">{value.reduction}% de réduction</p> : ""}
          </td>
          <td>{value.numberOfSales}</td>
          <td> <label className="checkbox-label">
            <input type="checkbox"
              checked={value.hidden}
              name="itemPrestation"
              onChange={(event) => { this.setState({ isLoading: true }); this.props.updatePrestation(value, this.callBack) }} />
            <span className="check"></span>{(value && value.hidden) ? "Masqué" : "Non masqué"}
          </label>
          </td>
          <td className="cnt-link">
            <Link to={{ pathname: "/fichePrestation/" + value.id, item: value }}><span className="arrow"><i className="material-icons icon-edit">edit</i></span></Link>
            <button onClick={() => this.onDeletePrestation(value)}><span className="arrow"><i className="material-icons icon-delete">delete</i></span></button>
          </td>
        </tr>
      )
    );
  }

  render() {
    const { listPrestation } = this.props.prestation;
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.removeError && PopupHandler.showError("La suppression de la prestatation a échoué.")}
            {this.state.removeError === false && PopupHandler.showSuccess("La prestatation a bien été supprimée.")}
            {this.state.saveError && PopupHandler.showError("La sauvegarde de la prestation a échoué.")}
            {this.state.saveError === false && PopupHandler.showSuccess("Succès de la sauvegarde de la prestation.")}
          </div>
          <div className="cnt-filtre">
            <div className="block-search-filter">
              <div>
                <h3>Nom du produit</h3>
                <input
                  type="text"
                  className="input-search"
                  placeholder="Rechercher…"
                  value={this.state.txtSearch}
                  onChange={(event) => {
                    this.updateState(event)
                  }}
                  name="txtSearch"
                />
              </div>
              <div className="cnt-mask">
                <h3>Produit masqué</h3>
                <div className="cnt-check">
                  <label className="checkbox-label"><input type="checkbox" /><span className="check"></span></label>
                </div>
              </div>
              <div className="cnt-btn">
                <button
                  type="button"
                  className={`button ${this.searchIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                  onClick={(event) => { this.sendSearch() }}
                  disabled={!this.searchIsNotVoid()}
                >
                  Rechercher
                </button>
              </div>
            </div>
          </div>
          <div className="table table-prestation">
            <div className="table-responsive scrollbar">
              <table>
                <thead>
                  <tr>
                    <th>Prestation</th>
                    <th>Prix prestation</th>
                    <th>Nombre de ventes <span className="arrow"><i className="material-icons icon-arrow_drop_up">arrow_drop_up</i></span></th>
                    <th>Masquer du catalogue</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderRow()}
                </tbody>
              </table>
            </div>
            <div>{listPrestation && listPrestation.total > 0 && this.renderPagination()}</div>
            <div className="cnt-Btn align-right">
              <input
                className="button mch-button"
                value="+ Créer une prestation"
                onClick={this.createPrestation}
              />
              <div className="clr"></div>
            </div>
          </div>
          <div className="wrapper-modal">
            <Modal visible={this.state.visible} width="370" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="">
                <h4>Il y a encore une commande en cours</h4>
              </div>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(Prestation);

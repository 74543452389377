import React from 'react';
import { Component } from 'react';
import Auth from "../../../services/authentification";

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {isMobile} from 'react-device-detect';
import MenuMobile from "../../components/menu-mobile/menu-mobile";
import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import { withRouter } from 'react-router-dom';
import { getSellerType } from '../../../data/constants/seller.type';

const auth = new Auth();

class Headers extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isShown: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  deconnexion = () => {
    const { history } = this.props;
    this.props.isLogout();
    if (history) {
      history.push(
        {
          pathname: "/login",
          state: {
            from: { pathname: "/" }
          }
        });
      auth.logout();
    }
  }

  showMenuProfil = ()=>{
    this.setState({ isShown: true });
  }

  hideMenuProfil = (event)=>{
    event.stopPropagation();
    this.setState({ isShown: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isShown: false });
    }
  }

  toggleMenuProfil = (e)=> {
    e.stopPropagation();
    this.setState({
        isShown: !this.state.isShown
    });
  }

  render() {
    const { infoDashboardAll, infoDashboard } = this.props.dashboard;
    const nbOrderPending = (infoDashboardAll && infoDashboardAll.data && parseInt(infoDashboardAll.data.nbOrderPending)) || 0;
    const sellerType = getSellerType(this.props.commons.infoLogin);

    return (
      <div className="header">
        <div>
          {/* <button onClick={() => i18n.changeLanguage('de')}>fr</button>
                <button onClick={() => i18n.changeLanguage('en')}>en</button> */}
        </div>
        <div className="wrapper clr">
          <MenuMobile/>
          <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
          <div className="mainMenu">
            <div className={`profil ${this.state.isShown? 'show': ''}`}
              onMouseEnter={isMobile ? () => { return false; } : this.showMenuProfil}
              onMouseLeave={isMobile ? () => { return false; } : this.hideMenuProfil}
              onClick={!isMobile ? () => { return false; } : this.toggleMenuProfil}
              ref={this.setWrapperRef}
            >
              <span className="imgProfil"></span>
              {this.state.isShown && (
                <ul className="submenu">
                  <li onClick={(event) => this.hideMenuProfil(event)}><Link to="/parametrecompte">Paramètres du compte</Link></li>
                  <li onClick={(event) => this.hideMenuProfil(event)}><Link to="/gestionadministration">Gestion administration</Link></li>
                  <li onClick={(event) => this.hideMenuProfil(event)}><a href="mailto:admin@myconciergehotel.com" target="_blank" rel="noopener noreferrer">{("Contacter le support")}</a></li>
                  <li onClick={(event) => this.hideMenuProfil(event)}><a href="https://www.myconciergehotel.com/faq-hotels" target="_blank" rel="noopener noreferrer">FAQ</a></li>
                  <li><Link to="#" onClick={this.deconnexion}>Déconnexion</Link></li>
                </ul>
              )}
            </div>
            <ul className="menu">
              <li><Link to="/">Tableau de bord</Link></li>
              <li><Link to="/mon-agenda">Mon agenda</Link></li>
              <li><Link to="/etablissement-institut/Etablissement">Mon établissement</Link></li>
              <li>
                <Link to="/hotel/hotelPartenaires">
                  Les hôtels
                  {
                    sellerType!== 3 &&
                    <span className="nbr">
                      {(infoDashboard && infoDashboard.hotel && parseInt(infoDashboard.hotel)) || 0}
                    </span>
                  }
                </Link>
              </li>
              <li>
                <Link to="/commande">
                  Gestion des commandes 
                  <span className="nbr">
                    {nbOrderPending}
                  </span>
                </Link>
              </li>
            </ul>
            <div className="clr"></div>
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Headers));

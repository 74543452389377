import React,{ Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input/input';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import { showPopupExtra } from '../../../components/popup/popup';
import ImageSelect from '../../../components/image-select/image-select';


class IndependantController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      language: {
        id: null,
        language: "string",
        skills: null,
        code: null,
      },
      listSellerLanguage: [],
      newListLanguage: [],
      nameLangue: null,
      niveauLangue: null,
      rating: 4,
      currentCountry: "FR",
      phone: "",
      lastname: '',
      firstname: '',
      logo: null,
      visible: false,
      errors: {
        lastname: 'default',
        firstname: 'default',
        currentUserNumberError: "default",
        logo: 'default',
      },
      errorsLanguage: {
        id: "default",
        language: "default",
        skills: "default",
      },
      fromHeadBand: this.props.location.state && this.props.location.state.fromHeadBand

    }
  }
  componentDidMount() {
    const { infoSellerIndependant } = this.props.sellers;
    const listSellerLanguage = this.copyArray(this.props.language.listSellerLanguage);
    const initialSellerLanguage = this.copyArray(this.props.language.listSellerLanguage);

    if (infoSellerIndependant && infoSellerIndependant.length > 0) {
      this.setState({
        ...infoSellerIndependant,
        currentCountry: isValidPhoneNumber(infoSellerIndependant.phone) ? parsePhoneNumber(infoSellerIndependant.phone).country : "",
      });

      for (let [key, value] of Object.entries(infoSellerIndependant)) {
        if(key !== "logo"){
          this.testErrors(key, value)
        }else{
          this.testPhotoInput(value);
        }
      }
    }
    if (listSellerLanguage) {
      this.setState({ listSellerLanguage, initialSellerLanguage});
    }
    // this.props.getSellerInfoIndependant(this.callBack);
    // this.props.getSellerLanguage(this.callBack);

  }

  componentDidUpdate(prevProps) {
    const prevIdentiteIndependant = prevProps.sellers.infoSellerIndependant;
    const propsIdentiteIndependant = this.props.sellers.infoSellerIndependant;
    const prevSellerLanguage = prevProps.language.listSellerLanguage;
    const propsSellerLanguage = this.props.language.listSellerLanguage;

    if (!prevIdentiteIndependant || !prevSellerLanguage) {
      return;
    }
    if (prevIdentiteIndependant !== propsIdentiteIndependant) {
      const identite = {...propsIdentiteIndependant};
      if (identite.logo === "null") {
        identite.logo = null
      }
      this.setState({
        ...identite,
      });
      for (let [key, value] of Object.entries(identite)) {
        if(key !== "logo"){
          this.testErrors(key, value)
        }else{
          this.testPhotoInput(value);
        }
      }
    }
    if (prevSellerLanguage.toString() !== propsSellerLanguage.toString()) {
      this.setState({
        listSellerLanguage: this.copyArray(propsSellerLanguage),
        initialSellerLanguage: this.copyArray(propsSellerLanguage)
      });
    }
  }

  copyArray= (array)=>{
    if (!array || array.length === 0) {
      return []
    }
    return JSON.parse(JSON.stringify(array)); 
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const listSellerLanguage = this.state.initialSellerLanguage;
    let data ={};
    let logoSend={};
    if(this.state.logo && !this.state.logo.name && !this.state.logo.data){
      let nameS = this.state.logo.url.split('/')
      logoSend={
        name: nameS[nameS.length-1],
        url: this.state.logo.url
      }
    }
    else{
      logoSend = this.state.logo
    }
    data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone,
    }
    if (logoSend) {
      data.logo= logoSend
    }
    this.setState({ isLoading: true });
    this.props.updateSellerInfoIndependant(data, this.callBack);
    if (JSON.stringify(listSellerLanguage) !== JSON.stringify(this.state.listSellerLanguage)) {
      let differenceUpdate = this.state.listSellerLanguage.filter(this.comparerLanguageUpdate(listSellerLanguage));
      let differenceDelete = listSellerLanguage.filter(this.comparerLanguage(this.state.listSellerLanguage));
      if (differenceDelete.length > 0) {
        differenceDelete.forEach(item => {
          this.setState({ isLoading: true, isRemoveSellerLanguage: true });
          this.props.removeSellerLanguage(item.id, this.callBack)
        })
      }
      if (differenceUpdate.length > 0) {
        let data = []
        differenceUpdate.forEach(item => {
          const dataSend = {
            language: item.language,
            code: item.code,
            skills: parseInt(item.skills),
            id: item.id
          }
          data = [...data, dataSend]
        })
        this.setState({ isLoading: true, isUpdateSellerLanguage: true });
        const dataSendF = {
          data: data
        }
        this.props.UpdateSellerLanguage(dataSendF, this.callBack)
      }
    }
  }
  comparerLanguage = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.code === current.code
      }).length === 0;
    }
  }

  comparerLanguageUpdate = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.code === current.code && other.skills === current.skills
      }).length === 0;
    }
  }

  testErrors(name, value) {
    let errors = this.state.errors;
    switch (name) {
      case 'firstname':
        errors.firstname = this.props.errors.nameErrors(value);
        break;
      case 'lastname':
        errors.lastname = this.props.errors.nameErrors(value);
        break;
      case 'phone':
        errors.currentUserNumberError = this.numberErrors(value);
        break;
      case 'logo':
        errors.logo = value && value !== {} ? "" : "non definie";
        break;
      default:
        break;
    }
    this.setState({ errors });
  }

  isNotVoid = () => {
    const {
      firstname,
      lastname,
      currentUserNumberError,
      logo,
    } = this.state.errors;
    return firstname === "" && lastname === "" && currentUserNumberError === "" && logo === ""
  }

  languageIsNotVoid = () => {
    const {
      nameLangue,
      niveauLangue,
    } = this.state;
    return nameLangue !== null && niveauLangue !== null
  }
  resetSelect = () => {
    this.setState({ niveauLangue: null, nameLangue: null })
  }

  openModal = () => {
    this.setState({ visible: true });
    this.resetSelect()
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
    this.resetSelect()
  }

  selectLang = (value, listLang) => {
    let deleted = this.state.initialSellerLanguage.find(i => i.language === value.label)
    const selected = listLang.find(i => i.language === value.label)
    const id = deleted ? deleted.id : selected.id;
    const language1 = this.state.language
    language1.id = id;
    language1.language = selected.language;
    language1.code = selected.code;
    this.setState({ language: language1, nameLangue: selected.language });
  }

  selectSkills = (value) => {
    const language = this.state.language
    language.skills = parseInt(value)
    this.setState({ language, niveauLangue: value });
  }
  addLanguage = () => {
    const { code, id } = this.state.language
    const data =
    {
      id,
      code,
      skills: this.state.niveauLangue,
      language: this.state.nameLangue,
    };
    const listSellerLanguage = [...this.state.listSellerLanguage, data];
    this.setState({
      listSellerLanguage,
      visible: false,
      nameLangue: null,
      niveauLangue: null
    });

    // const { id, language, skills } = this.state.language
    // const data = {
    //   "data": [
    //     {
    //     id,
    //     language,
    //     skills
    //     }
    //   ]
    // }
    // this.props.UpdateSellerLanguage(data, this.callBack)
  }

  callBack = (data, type) => {
    const { steps } = this.props.dashboard;
    this.setState({
      visible: false,
    });
    if(type==='updateSellerInfoIndependant'){
      let saveInfoError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        saveInfoError,
        saveLanguageError: null,
        removeLanguageError: null,
      })
      if (!steps.includes("2") && !saveInfoError) {
        this.props.addSteps({steps: ["2"]},this.callBack);
      }else if (steps.includes("2") && this.state.fromHeadBand){
        this.setState({
          isLoading: false,
        });
        this.props.history.push("/dashboard");
      }else if (!this.state.isUpdateSellerLanguage && !this.state.isRemoveSellerLanguage) {
        this.setState({
          isLoading: false,
        });
      }
    }else if(type==='UpdateSellerLanguage'){
      let saveLanguageError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        saveLanguageError,
        isUpdateSellerLanguage: false
      });
      if (!this.state.isRemoveSellerLanguage) {
        this.props.getSellerLanguage(this.callBack);
      }else{
        this.setState({
          isLoading: false,
        })
      }
    }else if(type==='removeSellerLanguage'){
      let removeLanguageError = data && (data.status === 200 || data.status === 204) ? false : true;
      this.setState({
        removeLanguageError,
        isLoading: false,
        isRemoveSellerLanguage: false
      })
      if (!this.state.isUpdateSellerLanguage) {
        this.props.getSellerLanguage(this.callBack);
      }else{
        this.setState({
          isLoading: false,
        })
      }
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        addStepError,
        isLoading: false,
      })
      if(!addStepError && this.state.fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }else if (type === "getSellerLanguage") {
      this.setState({
        isLoading: false,
      })
    }
  }

  removeLang = (id) => {
    let listLanguage = [this.state.listSellerLanguage];
    if (listLanguage.length > 0) {
      listLanguage = [...listLanguage[0].filter(i => i.id !== id)]
    }
    this.setState({ listSellerLanguage: listLanguage });
  }

  handleUserSelect(value) {
    if (value.value === "FR") {
      this.setState({ currentCountry: value.value, currentUserNumber: "+33" });
      return
    }
    this.setState({ currentCountry: value.value, currentUserNumber: "" });
  }

  updateStateNumber(value) {
    this.handleNumberInput(value)
    this.setState({ phone: value });
  }
  uploadLogo =()=> {
    showPopupExtra(
    <ImageSelect 
      onPictureValidate={this.handleImageChange} 
      currentPictureUrl={(this.state.logo && this.state.logo.url)|| ''}
      minWidth = {196}
      minHeight= {148}
      maxWidth= {450}
      maxHeight= {350}
      maxSize={5120}
    />, "landing-page");
  }

  handleNumberInput(value) {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.currentUserNumberError = this.numberErrors(value)
      this.setState({ errors });
    }
  }
  testPhotoInput(value) {
    let errors = this.state.errors;
    errors.logo = value ? "" : "Photo obligatoire";
    this.setState({ errors });
  }

  updateState = (event) => {
    let name = event.target.name;
    this.handleUserInput(event);
    this.setState({ [name]: event.target.value });
  }
  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'firstname':
        errors.firstname = this.props.errors.nameErrors(value);
        break;
      case 'lastname':
        errors.lastname = this.props.errors.nameErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  numberErrors = (value) => {
    if (typeof value === "string") {
      if (isValidPhoneNumber(value)) {
        if (this.state.currentCountry === "FR" && (value.charAt(3) === "0")) {
          return "0 après +33 n'est pas valide";
        }
        else {
          return "";
        }
      }
      else {
        return "Numero invalide";
      }
    }
    else {
      return "Numero invalide";
    }
  }

  handleImageChange = async ({file}) => {
    if (!file) {
      return
    }
    const url = URL.createObjectURL(file);
    const imageSizeError= file.size / 1024  > 5120;
    const extension = this.getExtension(file.name);
    const imageFormatError = (extension !== "jpeg" && extension !== "jpg" && extension !== "png") || false;
    let logoDimensionError = false;
    this.setState({
      imageSizeError,
      imageFormatError,
      logoDimensionError,
    });
    if (imageSizeError || imageFormatError) {
      return
    }
    const img = new Image();
    img.addEventListener('load', () => {
      logoDimensionError = img.width < 196 || img.height < 148 || img.width > 450 || img.height > 350;
      this.setState({
        logoDimensionError,
      });
      if (!logoDimensionError) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = {
            name: file.name,
            data: reader.result,
            url
          }
          this.setState({ logo: data });
          this.testPhotoInput(data)
        }
        reader.readAsDataURL(file);
      }
    });
    img.src = url;
  };
  
  getExtension = (name)=>{
    const filename = name.split(".");
    return filename.length > 1 ? filename[filename.length - 1].toLowerCase() : "";
  }
}

export default IndependantController;

IndependantController.propTypes = {
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import React from 'react';
import Rating from 'react-rating';
import ReponseHotelController, { reduxConnect } from './controller';
import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import { getCommissionLabel } from "./../../../../data/constants/commission.label";

class ReponseHotel extends ReponseHotelController {
  renderPagination = () => {
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={this.state.data && this.state.data.nbPage ? this.state.data.nbPage * this.state.data.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={this.state.data && this.state.data.itemPerPage ? this.state.data.itemPerPage : 5}
            activePage={this.state.data && this.state.data.page ? this.state.data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };
  render() {
    const { listPartnershipRequest_1 } = this.props.hotels;
    const { infoLogin } = this.props.commons;
    return (
      <div className="cnt-tab cnt-tab-reps">
        <div className="main-reps">
          <div className="main-infos-hotel">
              <div className="table-responsive scrollbar">
                <table>
                  <tbody>
                  {listPartnershipRequest_1 && listPartnershipRequest_1.data && Array.isArray(listPartnershipRequest_1.data) && listPartnershipRequest_1.data.map((value, index) =>
                    <React.Fragment key={`hotel ${index}`}>
                      <tr>
                        <td className="w32-percent">
                          <div className="td-content">
                            <p className="name-product">
                              {value.hotelName ? value.hotelName : "Nom non definit"}
                            </p>
                            {
                              (value.hotelStar) &&
                              <div className="star-hotel">
                                <Rating
                                  readonly
                                  placeholderRating={(value.hotelStar && parseInt(value.hotelStar)) || 0}
                                  emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                                  placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                                />
                              </div>
                            }
                            {value.hotelAddress ? `${value.hotelAddress.streetAddress1 || ""}${value.hotelAddress.zipCode ? `-${value.hotelAddress.zipCode}`: ""}${value.hotelAddress.city ? `-${value.hotelAddress.city}`: ""}`: ''}
                          </div>
                        </td>
                        <td>
                          <p>{value.hotelMail ? value.hotelMail : "email non definit"}</p>
                          <p>{value.hotelAddress && value.hotelAddress.phone ? value.hotelAddress.phone : "Telephone non défini"}</p>
                        </td>
                        <td><span className="txt-distance">Distance</span> {value.distance > 0 ? `${parseFloat(value.distance || 0).toFixed(2)} km` : "Distance non définie"}</td>
                        <td><span className="txt-distance">Commission</span>{(value && getCommissionLabel(infoLogin, value.commision, value.commissions)) || ""}</td>
                        {/* <td>
                        <div className="cnt-btn-strock">
                          <button onClick={() => this.openModal(value, index)} className="button mch-button">Répondre</button>
                        </div>
                      </td> */}
                      </tr>
                      <tr>
                        <td className="wrapper-cnt-message" colSpan="4">
                          <div className="cnt-message">
                            <p className="title-msg">Votre message</p>
                            <p>{value.message ? value.message : "Message non definit"}</p>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                  </tbody>
                </table>
              </div>  
          </div>
          <div>{this.state.data && this.state.data.total > 0 && this.renderPagination()}</div>
        </div>
      </div>
    )
  }
}

export default reduxConnect(ReponseHotel);
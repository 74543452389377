import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import { TOKEN, REFRESH_TOKEN, EXPIRES_AT, TYPE_COMPTE } from '../../../services/redux/constants/commons';


// import history from '../../../history';

class LoginController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      userEmail: "",
      userPassword: "",
      showPassword: false,
      errors: {
        userPassword: 'default',
        userEmail: 'default',
      },
      isLoading: false,
      errorsFromServer: "",
      visible: false,
      textShow : "",
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount (){
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
  }

  isNotVoid = () => {
    const {
      userEmail, userPassword
    } = this.state.errors;
    return userPassword === "" && userEmail === ""
  }

  openModal(text) {
    this.setState({
      visible: true,
      textShow: text,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
      userEmail: "",
      userPassword: "",
    });
  }

  callback = (data,type) => {
    this.setState({ isLoading: false});
    if(type && type==='error'){
      if(data.error.toString().includes('401') || data.error.toString().includes('403')){
        this.openModal("Email ou mot de passe incorrect")
      }
      else if(data.error.toString().includes('Network Error')) {
        this.openModal("Problème de connexion internet")
      } 
    }
    else if( data && data.token) {
      const token = data.token;
      const ttl = 3600;
      const expiresAt = JSON.stringify(new Date().getTime() + ttl * 1000);
      const date = new Date();
      const queryIncome = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      localStorage.setItem(TYPE_COMPTE, data.roles);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
      this.props.setExpireDate(false)
      this.props.getInfoDashboard(queryIncome, this.callBackOK);
      this.props.verifyBankFile(this.callBackOK);
      this.props.getProgressionEtablissement(this.callBackOK);
      if (!data.roles.includes('ROLE_SELLER_AGENCE')) {
        this.props.getPrestation(this.callBackOK);
        this.props.getAllCategory(this.callBackOK);
        this.props.getHotelPartner(this.callBackOK);
      }
      this.props.getSellerId(this.callBackOK);
      this.props.getDataGraphe(this.callBackOK);
      this.props.getLegalInformation(this.callBackOK)
      this.props.getSellerInfoAccount(this.callBackOK);
      this.props.getBankInfo(this.callBackOK);
      this.props.getInfoEtablissement(this.callBackOK);
      this.props.getAllLanguage(this.callBackOK);
      this.props.getSellerLanguage(this.callBackOK);
      this.props.getAllEvent(null,this.callBackOK);
      this.props.getSteps(this.callBackOK);
      const { history, location } = this.props;
      let { from } = location.state || { from: { pathname: "/dashboard" } };
      if (history) history.replace(from);

    } else {
      this.setState({ errorsFromServer: "Email ou mot de passe incorrect" });
    }
  }
  callBackOK = (data) => {

  }

  togglePasswordVisibility = e => {
    e.preventDefault();
    this.setState(oldState => ({
      showPassword: !oldState.showPassword
    }));
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      email: this.state.userEmail,
      password: this.state.userPassword,
    }
    this.setState({ isLoading: true });
    this.props.login(data, this.callback);
  }


  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'userEmail':
        errors.userEmail = this.props.errors.emailErrors(value);
        break;
      case 'userPassword':
        errors.userPassword = this.props.errors.passwordErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event)
    this.setState({ [name]: event.target.value });
  }
}

export default LoginController;

LoginController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
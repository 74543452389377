import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';

import MdpController, { reduxConnect } from './controller';

const grecaptchaObject = window.grecaptcha;

class Mdp extends MdpController {
  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading} s
          spinner
          text='Chargement'
        >
          <div className="header">
            <div className="wrapper clr">
              <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
            </div>
          </div>
          <div className="page">
            <div className="bandeaux">
              <div className="wrapper clr">
                <h1>Mot de passe</h1>
              </div>
            </div>
          </div>
          <div className="main">
            <div className="wrapper clr">
            {!this.state.tokenChecked && <div/>}
            {this.state.tokenChecked && 
                <form className="block-form" onSubmit={this.handleSubmit}>
                  <div className="line-input">
                    <label>Mot de passe* </label>
                    <div className="cnt-input">
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.userPassword}
                        onChange={(event) => this.updateState(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="userPassword"
                      />
                      <span
                        className={`edit clickable`}
                        onClick={this.togglePasswordVisibility}
                        name='password'
                      >
                        <i className="material-icons icon-edit">{!this.state.showPassword ? 'lock' : 'lock_open'}</i>
                      </span>
                    </div>
                    {
                      this.state.errors.userPassword.length > 0 &&
                      this.state.errors.userPassword !== 'default' &&
                      <span className='error'>{this.state.errors.userPassword}</span>
                    }
                  </div>
                  <div className="line-input">
                    <label>Confirmation mot de passe*</label>
                    <div className="cnt-input">
                      <input
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        value={this.state.confirmNewsPass}
                        onChange={(event) => this.updateState(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="confirmNewsPass"
                      />
                      <span
                        className={`edit clickable`}
                        onClick={this.toggleConfirmPasswordVisibility}
                        name="passwordConfirm"
                      >
                        <i className="material-icons icon-edit">{!this.state.showConfirmPassword ? 'lock' : 'lock_open'}</i>
                      </span>
                    </div>
                    {
                      this.state.errors.confirmNewsPass.length > 0 &&
                      this.state.errors.confirmNewsPass !== 'default' &&
                      <span className='error'>{this.state.errors.confirmNewsPass}</span>
                    }
                  </div>
                  <div className="line-input">
                    <ReCAPTCHA
                      ref={(r) => this.recaptcha = r}
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      grecaptcha={grecaptchaObject}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="cnt-Btn"><input className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`} type="submit" value="Enregistrer" /><div className="clr"></div></div>
                </form>
              }
            </div>
          </div>
        </LoadingOverlay>
        <div className="wrapper-modal">
          <Modal
            visible={this.state.visible}
            width="400"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="content-modal">
              <div className="head-modal">
                <Link to="" className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></Link>
                <h1>{this.state.titleShow}</h1>
              </div>
              <div className="main-modal">
                <p>{this.state.messageShow}</p>
                <div className="cnt-Btn align-right">
                  {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                  <button className="button" onClick={() => this.state.messageShow.includes("enregistrée") ? this.redirectToLogin() : this.closeModal()}>Ok</button>
                  <div className="clr"></div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default reduxConnect(Mdp);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import PopupConfirm from "../../../components/popup/popup.confirmation";
import {showPopUp, hidePopUp} from "../../../components/popup/popup";

const CATEGORY = 'CATEGORY';
const PRODUCT = 'PRODUCT';
class PrestationIndependantController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      prestation: [],
      isLoading: false,
      panel: {
        active: '',
        category: null,
        product: null,
      },
      categories: [],
      listPrestationSelected: [],
      txtRecherche: "",
      dataChecked: {
        id: "",
        checked: false,
      },
      data: {
        id: "",
        hotels: [],
      },
      hotels: [],
    }
  }

  renderListShow = () => {
    const { listCategories } = this.props.commons;
    let { listPrestation } = this.props.prestation;
    const testSelected = (id) => {
      let selected
      const prestation = listPrestation && listPrestation.data && listPrestation.data.find(k => id == k.categorieInterne);
      selected = typeof prestation === 'undefined' ? false : true;
      return selected
    }
    const result = listCategories.map(item => {
      if(!item.children || !Array.isArray(item.children) || item.children.length < 1){
        item.selected= testSelected(item.id)
      } 
      const subcategory = item.children;
      const children2 = subcategory && subcategory.length > 0
        ? subcategory.map(i => {
          if (i.has_children) {
            const children = i.children.map(l => {
              return {
                ...l,
                selected: testSelected(l.id)
              }
            })
            return {
              ...i,
              children,
            }
          }
          else {
            return {
              ...i,
              selected: testSelected(i.id)
            }
          }
        })
        : subcategory;
      return {
        ...item,
        children: children2,
      };
    });
    return result
  }

  callBack = (data, type) => {
    this.props.setLoadingFalse()
    if (type === "update" || type === "remove" || type === "add") {
      this.setState({
        removeError: null,
        saveError: null,
        addOptionError: null,
        addDeliveryPlaceError: null,
        removeDeliveryPlaceError: null,
      })
      let removeError = type === "remove" ? (data && data.status === 200 ? false : true) : null;
      let saveError = type === "update" || type === "add" ? (data && (data.status === 200 || data.status === 201) ? false : true): null;
      this.setState({
        removeError,
        saveError
      })
      if (!removeError && !saveError) {
        this.props.setLoadingTrue()
        const queryPrestation = {
          itemPerPage : 200,
          page : 1,
        }
        this.props.getPrestation(queryPrestation  ,this.callBack);
      }
    }
  }
  sortCategories = (categories) => {
    return [].concat(categories).sort((a, b) => {
      return (parseInt(a.position) > parseInt(b.position)) ? 1 : -1;
    });
  };

  handleQueryChange = (event) => {
    this.setState({ query: event.target.value });
  };

  handleSearch = () => {
    this.props.fetchCategories(this.state.query);
  };

  handleSort = (event) => {
  };

  handleCategoryClick = (event) => {
    const id = event.target.dataset.id;
    const category = this.state.categories.filter((e) => parseInt(e.id) === parseInt(id), id);
    if (category.length > 0) {
      const panel = {
        ...this.state.panel,
        active: CATEGORY,
        category: category[0],
        product: null,
      };
      this.setState({
        panel,
        selectedCategory: id,
        selectedProduct: null
      });
      //this.scrollTo();
    }
  };

  scrollTo() {
    if (window.matchMedia("(max-width: 767px)").matches) {
        scroller.scrollTo('scroll-to-anchor', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -120
        })
    }
  }

  showViewCategory = (event) => {
    const id = event.target.dataset.id;
    const category = this.state.categories.filter((e) => parseInt(e.id) === parseInt(id), id);
    if (category.length > 0) {
      const preview = {
        visible: true,
        category: category[0],
      };
      this.setState({ preview });
    }
  };

  closeViewCategory = () => {
    const preview = {
      visible: false,
      category: null,
    };
    this.setState({ preview });
  };

  handleProductClick = (product, category) => {
    const panel = {
      active: PRODUCT,
      category: null,
      product: parseInt(product.id),
    };
    this.setState({
      panel,
      selectedProduct: product,
      selectedProductCategory: category
    });
    this.scrollTo();
  };

  hotelIsNotVoid = () => {
    const {
      hotels
    } = this.state;
    return hotels.length !== 0
  }

  handleCheckedChange = (event) => {
    let id = 0;
    let data = {}
    let listPrestationSelected = this.state.listPrestationSelected;
    if (event) {
      id = parseInt(event.target.value)
      data = {
        ids: [id],
        hotels: [],
      }
      if (event.target.checked) {
        this.props.setLoadingTrue();
        this.props.addPrestationIndependant(data, this.callBack)
      }
      else {
        let { listPrestation } = this.props.prestation;
        this.props.setLoadingTrue();
        const prestation = listPrestation && listPrestation.data && listPrestation.data.find(k => id == k.categorieInterne);
        this.props.removePrestation(prestation.id, this.callBack)
      }
    }
    else {
      id = parseInt(this.state.dataChecked.id);
      data = {
        ids: [id],
        hotels: this.state.hotels,
      }
      if (this.state.dataChecked.checked) {
        this.props.setLoadingTrue();
        this.props.addPrestationIndependant(data, this.callBack)
      }
      else {
        let { listPrestation } = this.props.prestation;
        this.props.setLoadingTrue();
        const prestation = listPrestation && listPrestation.data && listPrestation.data.find(k => id == k.categorieInterne);
        this.props.removePrestation(prestation.id, this.callBack)
      }
      hidePopUp();
    }

    this.setState({ 
      listPrestationSelected, 
      visible: false, 
      hotels: [] ,
      selectedProduct: null,
      selectedProductCategory: null
    })
  };
  openModal = (event) => {
    const checked = event.target.checked;
    const id = parseInt(event.target.value);
    let dataChecked = this.state.dataChecked;
    dataChecked.id = id;
    dataChecked.checked = checked;
    this.setState({
      dataChecked
    })
    if (!checked) {
      this.openDeletePrestationConfirm();
    }else{
      this.setState({
        visible: true,
      })
    }

  }

  openDeletePrestationConfirm = () => {
    showPopUp(
      <PopupConfirm 
          message = "Voulez-vous vraiment supprimer cette prestation ?"
          onAccept = {this.handleCheckedChange}
      />,
     "Confirmation"
    )
  };
  closeModal() {
    this.setState({
      visible: false,
    });
  }

  onChangeListHotelPartener = (id, isChecked) => {
    const { hotels } = this.state;
    let data = hotels;
    if (!hotels) {
      data = []
    }
    this.setState({
      hotels: isChecked ? data.filter(i => i !== id) : [...data, id],
    });
  }
  showInfo = () => {
  }

  setAddOptionSuccess =(addOptionError)=>{
    this.setState({
      addOptionError,
      removeError: null,
      saveError: null,
      addDeliveryPlaceError: null,
      removeDeliveryPlaceError: null,
    })
  }

  setAddDeliveryPlaceSuccess =(addDeliveryPlaceError)=>{
    this.setState({
      addDeliveryPlaceError,
      addOptionError: null,
      removeError: null,
      saveError: null,
      removeDeliveryPlaceError: null,
    })
  }

  setRemoveDeliveryPlaceSuccess =(removeDeliveryPlaceError)=>{
    this.setState({
      removeDeliveryPlaceError,
      addDeliveryPlaceError: null,
      addOptionError: null,
      removeError: null,
      saveError: null,
    })
  }

}
export default PrestationIndependantController;
PrestationIndependantController.propTypes = {
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
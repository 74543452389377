import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";


class OptionsController extends Component {
    constructor(props) {
        super(props);
        let options =  (props.product && props.product.options) || {};
        options = this.objectToList(options);
        this.state = {
            product: props.product,
            options,
            addedOptions: [],
        }; 
    }

    componentDidMount(){
        const allDeliveryPlaces = this.copyArray(this.props.commons.allDeliveryPlaces);
        this.filterAlllDeliveryPlaces(allDeliveryPlaces);
        this.setAddedOptions();
    }

    componentDidUpdate(prev){
        const prevDeliveryPlaces = prev.commons.allDeliveryPlaces ;
        const propsDeliveryPlaces = this.props.commons.allDeliveryPlaces;
        const prevListPrestation = prev.prestation.listPrestation ;
        const propsListPrestation = this.props.prestation.listPrestation;
        if (JSON.stringify(prevDeliveryPlaces) !== JSON.stringify(propsDeliveryPlaces)){
            this.filterAlllDeliveryPlaces(this.copyArray(propsDeliveryPlaces));
        }
        if (JSON.stringify(prevListPrestation) !== JSON.stringify(propsListPrestation)){
            this.setAddedOptions();
        }
    }

    filterAlllDeliveryPlaces = (allDeliveryPlaces)=>{
        let deliveryPlaces = []
        if (allDeliveryPlaces.length >0) {
            deliveryPlaces = allDeliveryPlaces.map(deliveryPlace =>{
                deliveryPlace.type_traject = deliveryPlace.type_traject.filter(typeTraject => parseInt(typeTraject.id) === parseInt(this.state.product.id));
                return deliveryPlace;
            })
            deliveryPlaces = deliveryPlaces.filter(deliveryPlace =>{
                return deliveryPlace.type_traject && deliveryPlace.type_traject.length > 0
            })
        }
        this.setState({
            deliveryPlaces
        })
    }

    copyArray = (array)=>{
        if (!array || array.length === 0) {
            return []
        }
        return JSON.parse(JSON.stringify(array)); 
    }

    objectToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {...object[key]}
        })
    }

    setAddedOptions = () => {
        let listPrestation = (this.props.prestation && this.props.prestation.listPrestation && this.props.prestation.listPrestation.data) || [];
        let addedOptions = []
        const prestation = listPrestation.find(prestation => parseInt(this.state.product.id) === parseInt(prestation.categorieInterne));
        if (prestation && prestation.categoryOptions && prestation.categoryOptions) {
            const categoryOptions = this.objectToList(prestation.categoryOptions);
            if (categoryOptions&& categoryOptions.length > 0) {
                [].concat(categoryOptions).forEach(categoryOption =>{
                    const optionValues = this.objectToList(categoryOption.options_values);
                    const ids = [].concat(optionValues).map(optionValue=>parseInt(optionValue.value_id));
                    addedOptions = [...addedOptions, ...ids]
                })
            }
        }
        this.setState({
            addedOptions,
            prestation
        })
    }

    handleAddOption = (event) => {
        if (!this.state.product || !this.state.product.selected) {
            showPopupExtra(<PopupError title="Erreur" message="Il faut d'abord ajouter la prestation"/>);
            return
        }
        const id = parseInt(event.target.dataset.id);

        let options = this.state.addedOptions;
        if (event.target.checked && !options.includes(id)) {
            options = [...options, id]
        }else{
            options = options.filter(option => option !== id)
        }
        this.props.setLoadingTrue();
        this.props.addOption(this.state.prestation.id, { options: options }, this.callBack);
    };

    handleAddDeliveryPlace = (event) => {
        if (!this.state.product || !this.state.product.selected) {
            showPopupExtra(<PopupError title="Erreur" message="Il faut d'abord ajouter la prestation"/>);
            return
        }
        const id = event.target.dataset.id;
        const hotelId = event.target.dataset.hotel;
        const checked = event.target.checked;
        const serviceId = event.target.dataset.service
        const data ={};
        this.state.deliveryPlaces.forEach(deliveryPlace =>{
            if (parseInt(deliveryPlace.hotel_id) === parseInt(hotelId)) {
                data.hotel_id = deliveryPlace.hotel_id;
                data.hotel_name = deliveryPlace.hotel_name;
                deliveryPlace.type_traject && deliveryPlace.type_traject.length > 0 && deliveryPlace.type_traject.forEach(typeTraject=>{
                    data.type_traject = {...typeTraject};
                    delete data.type_traject.traject
                    typeTraject && typeTraject.traject && typeTraject.traject.length > 0 &&  typeTraject.traject.forEach(traject=>{
                        if (parseInt(traject.place_id) === parseInt(id)) {
                            data.type_traject.traject = {...traject, id: traject.place_id };
                            data.type_traject.traject.added = traject.added && parseInt(traject.added) ? 0 : 1;
                            delete data.type_traject.traject.place_id
                        }
                    })
                })
            }
        })
        this.props.setLoadingTrue();
        if (checked) {
            this.props.addDeliveryPlace(data, this.callBack);
        }else{
            this.props.removeDeliveryPlace(id, serviceId, this.callBack)
        }
        
    };


    callBack = (data, type) =>{
        if (type === "addOption") {
            const addOptionError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
            this.props.setAddOptionSuccess(addOptionError);
            const queryPrestation = {
                itemPerPage : 200,
                page : 1,
              }
            if (!addOptionError) {
                this.props.getPrestation(queryPrestation, this.callBack);
            }else{
                this.props.setLoadingFalse();
            }
        }else if (type === "addDeliveryPlace") {
            const addDeliveryPlaceError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
            this.props.setAddDeliveryPlaceSuccess(addDeliveryPlaceError);
            this.props.setLoadingFalse();
        }else if (type === "removeDeliveryPlace") {
            const removeDeliveryPlaceError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
            this.props.setRemoveDeliveryPlaceSuccess(removeDeliveryPlaceError);
            this.props.setLoadingFalse();
        }else{
            this.props.setLoadingFalse();
        }
    }
}



export default OptionsController;

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
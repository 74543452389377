import React from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import Rating from 'react-rating';
import moment from 'moment';

import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import PopupHandler from "../../../components/popup/popupHandler";
import { getCommissionLabel } from "./../../../../data/constants/commission.label";

// import {fetchApiEndPartenership, fetchApiSearchHotelPartener, fetchApiGetHotelPatener, initLoading} from '../../../services/redux/actions';
import HotelPartenaireController, { reduxConnect } from './controller';


class HotelPartenaire extends HotelPartenaireController {
  renderPagination = () => {
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={this.state.data && this.state.data.nbPage ? this.state.data.nbPage * this.state.data.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={this.state.data && this.state.data.itemPerPage ? this.state.data.itemPerPage : 5}
            activePage={this.state.data && this.state.data.page ? this.state.data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };
  render() {
    const { listHotelPartner } = this.props.hotels;
    const { infoLogin } = this.props.commons;
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text='Chargement'
      >
        <div>
          {this.state.requestError && PopupHandler.showError("L'envoi de la requête a échoué.")}
          {this.state.requestError === false && PopupHandler.showSuccess("Requête envoyée avec succès.")}
        </div>
        <div className="main-tabs">
          <div className="cnt-filtre">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="block-search-filter">
                <div>
                  <h3>Rechercher hôtel</h3>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Rechercher…"
                    value={this.state.txtSearch}
                    onChange={(event) => {
                      this.updateState(event)
                    }}
                    onKeyDown={this.handleSearchKeyDown}
                    name="txtSearch"
                  />
                </div>
                <div className="cnt-btn">
                <button
                  type="button"
                  className={`button ${this.searchIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                  onClick={(event) => { this.sendSearch() }}
                  disabled={!this.searchIsNotVoid()}
                >
                  Rechercher
                </button>
                </div>
              </div>
            </form>
          </div>
          <div className="table table-prestation-hotel">
            <div className="table-responsive scrollbar">
              <table>
                <thead>
                  <tr>
                    <th>Hôtel</th>
                    <th>Contact</th>
                    <th>Début partenariat <span className="arrow"><i className="material-icons icon-arrow_drop_up">arrow_drop_up</i></span></th>
                    <th>Commission hôtel</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listHotelPartner && listHotelPartner.data && Array.isArray(listHotelPartner.data) && listHotelPartner.data.map((value, index) =>
                      <tr key={`hotel ${index}`}>
                        <td className="cnt-nameceller">
                          <p className="name-product">{value.name}</p>
                          {
                            (value.star) &&
                            <div className="star-hotel">
                              <Rating
                                readonly
                                placeholderRating={(value.star && parseInt(value.star)) || 0}
                                emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                                placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                              />
                            </div>
                          }
                          {/* <p>{value.address && value.address.stringAddress ? value.address.stringAddress : "Addresse non definit" }</p> */}
                          <p>{`${value.address.streetAddress1}, ${value.address.zipCode} ${value.address.city}`}</p>
                        </td>
                        <td className="cnt-contact">
                          <p>{value.email}</p>
                          <p>{value.phone}</p>
                        </td>
                        <td>{value.dateStartPartenariat ? moment(value.dateStartPartenariat.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') : "Non definit"}</td>
                        <td>
                          {(value.typePartenerships && value.typePartenerships === 1) || (value.dateEndPartenariat && value.dateEndPartenariat !== null) ?
                            <div>
                              <p className="titre-fin">Fin de partenariat
                              </p>{value.dateEndPartenariat && moment(value.dateEndPartenariat.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </div>
                            :
                            <div>{(value && getCommissionLabel(infoLogin, value.commission, value.commissions)) || ""}</div>
                          }
                        </td>
                        <td className="cnt-link">
                          { value.dateEndPartenariat && value.dateEndPartenariat !== null ?
                            <div>
                            </div>
                            :
                            <div>
                              <Link to={{pathname: '/commande', state: {hotel: value.code}}} className="link-hotel-associe">Voir les commandes associées à cet hôtel</Link>
                              <button className="link-hotel-associe" onClick={() => this.openModal(value.partenershipId)}>Mettre fin au partenariat</button>
                            </div>
                          }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <div>{this.state.data && this.state.data.total > 0 && this.renderPagination()}</div>
          </div>
          <div className="wrapper-modal">
            <Modal visible={this.state.visible} width="370" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="content-modal">
                <div className="head-modal">
                  <button className="close-modal" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                  <h1>Confirmation</h1>
                </div>
                <div className="main-modal">
                  <p>Voulez-vous vraiment mettre fin au partenariat ?</p>
                  <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={() => this.closeModal()}>Annuler</button>
                    <button className="button" onClick={() => this.endPartenership(this.state.finPartenariatId)}>Valider</button>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </LoadingOverlay>
    )
  }
}
export default reduxConnect(HotelPartenaire);

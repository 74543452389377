import { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class NewMdpController extends Component {
  constructor(props) {
    super(props);
    this.state = {
        confirmNewsPass : "",
        userPassword : "",
        errors: {
                 userPassword : 'default',
                 confirmNewsPass : 'default',
                }
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
}  
isNotVoid = () => this.state.errors.userPassword === "" && this.state.errors.confirmNewsPass === ""

handleSubmit (event) {
    event.preventDefault();
    if (this.isNotVoid()) {
       
    }
}

handleUserInput (event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
        case 'confirmNewsPass':
            errors.confirmNewsPass = this.confirmNewsPassErrors(this.state.userPassword, value);
            break;
        case 'userPassword':
            errors.userPassword = this.passwordErrors(value);    
            break;
        default:
        break;    
    }
    this.setState({errors, [name]: value});  
}

updateState(event) {
    let name= event.target.name;
    this.handleUserInput(event);
    this.setState({[name] : event.target.value});
}

confirmNewsPassErrors (value) {
    if (value.length === 0) {
        return "Ce champ est obligatoire";
    } else {
        return "";
    }
}

passwordErrors (value) {
    if (value.length === 0) {
        return "Ce champ est obligatoire";
    } else {
        return "";
    }
}
    
}

export default NewMdpController;

//
// Redux connection
//

NewMdpController.propTypes = {
  
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
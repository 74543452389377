import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import Rating from 'react-rating';
import LoadingOverlay from 'react-loading-overlay';

import IndependantController, { reduxConnect } from './controller';

import Select, { components } from "react-select";
import Input from 'react-phone-number-input/input';
import Dropdown from 'react-dropdown';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';

import 'react-web-tabs/dist/react-web-tabs.css';
import 'react-dropdown/style.css';
import InstitutImg from './../img/avatar.png';
import PopupHandler from "../../../components/popup/popupHandler";

const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

const options = getCountries().map((country) => ({
  label: en[country] + ' +' + getCountryCallingCode(country),
  chipLabel: '+' + getCountryCallingCode(country),
  value: country
}));

class Independant extends IndependantController {
  render() {
    const { listAllLanguage } = this.props.language;
      const setOption = () => {
      return listAllLanguage && this.state.listSellerLanguage ? listAllLanguage.filter(item => this.state.listSellerLanguage.map(i => i.language).includes(item.language) === false) : [];
    }
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text='Chargement'
      >
        <div>
          {this.state.removeLanguageError && PopupHandler.showError("La suppression de la langue a échoué.")}
          {this.state.removeLanguageError === false && PopupHandler.showSuccess("La langue a bien été supprimé.")}
          {this.state.saveLanguageError && PopupHandler.showError("L'enregistrement de la langue a échoué.")}
          {this.state.saveLanguageError === false && PopupHandler.showSuccess("La langue a bien été enregistrée.")}
          {this.state.saveInfoError && PopupHandler.showError("L'enregistrement de l'établissement a échoué.")}
          {this.state.saveInfoError === false && PopupHandler.showSuccess("Les informations de l'établissement a bien été enregistré.")}
          {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
          {this.state.logoDimensionError && PopupHandler.showError("La Photo principale doit avoir des dimensions entre 196 x 148px et 450 x 350px.")}
          {this.state.imageSizeError && PopupHandler.showError("L'image doit avoir une taille maximum de 5Mo.")}
          {this.state.imageFormatError && PopupHandler.showError("L'image doit être au format .jpeg, .jpg ou .png.")}
        </div>
        <form className="" onSubmit={this.handleSubmit} >
          <div className="wrapper main-independant">
            <div className="row">
              <div className="col col-5">
                <p className="titlephoto">Photo du profil</p>
                <div className="cnt-photo">
                  <div className="blc-photo">
                    <img src={(this.state.logo && this.state.logo.url) || InstitutImg} alt="Institut" />
                  </div>
                  <div className="blc-edit">
                    <Link to="#">
                      <span className="icon-edit">
                        <i className="material-icons icon-edit" onClick={this.uploadLogo}>edit</i>
                      </span>
                    </Link>
                  </div>
                  {
                    this.state.errors.logo.length > 0 &&
                    this.state.errors.logo !== 'default' &&
                    <span className='error'>{this.state.errors.logo}</span>
                  }
                </div>
              </div>
              <div className="col col-7">
                <div className="row">
                  <div className="col col-6 line-input">
                    <label>Nom de l’indépendant</label>
                    <div className="cnt-input">
                      <input
                        type="text"
                        className="input-nom-etablissement"
                        value={this.state.firstname}
                        onChange={(event) => this.updateState(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="firstname"
                      />
                      {
                        this.state.errors.firstname.length > 0 &&
                        this.state.errors.firstname !== 'default' &&
                        <span className='error'>{this.state.errors.firstname}</span>
                      }
                    </div>
                  </div>
                  <div className="col col-6 line-input">
                    <label>Prénom de l’indépendant</label>
                    <div className="cnt-input">
                      <input
                        type="text"
                        className="input-prenom-etablissement"
                        value={this.state.lastname}
                        onChange={(event) => this.updateState(event)}
                        onBlur={(event) => this.handleUserInput(event)}
                        name="lastname"
                      />
                      {
                        this.state.errors.lastname.length > 0 &&
                        this.state.errors.lastname !== 'default' &&
                        <span className='error'>{this.state.errors.lastname}</span>
                      }
                    </div>
                  </div>
                  <div className="col col-6 line-input">
                    <label>Numéro de téléphone</label>
                    <div className="PhoneInput">
                      <Select
                        onChange={(value) => this.handleUserSelect(value)}
                        options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                        components={{ SingleValue }}
                        value={options.find(op => { return op.value === (this.state.currentCountry ? this.state.currentCountry : "") })}
                      />
                      <Input
                        country={this.state.currentCountry}
                        international
                        value={this.state.phone ? this.state.phone : ""}
                        onChange={((value) => this.updateStateNumber(value))}
                        name='phone'
                      />
                      {
                        this.state.errors.currentUserNumberError.length > 0 &&
                        this.state.errors.currentUserNumberError !== 'default' &&
                        <span className='error'>{this.state.errors.currentUserNumberError}</span>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12">
                <label>Langues pratiquées*</label>
                {
                  this.state.listSellerLanguage && this.state.listSellerLanguage.map((value, index) =>
                    <div className="line-langue" key={`${index}-langue`}>
                      <div className="blc-langue">
                        <span className="arrow"><i className="material-icons icon-language">language</i></span> {value.language}
                      </div>
                      <div className="txt-niveau-langue">
                        Votre niveau dans cette langue
                      </div>
                      <div className="blc-niveau-langue">
                        <Rating
                          initialRating={value.skills}
                          readonly
                        />
                      </div>
                      <div className="remove-langue">
                        <button type="button" onClick={() => this.removeLang(value.id)} className="arrow">
                          <i className="material-icons icons-cancel">cancel</i>
                        </button>
                      </div>
                    </div>
                  )
                }
                <div className="cnt-Btn align-right cnt-btn-strock">
                  <button
                    type="button"
                    className="button"
                    onClick={this.openModal}
                  >+ Ajouter une langue</button>
                  <div className="clr"></div>
                </div>
              </div>
            </div>
            <div className="cnt-Btn align-right">
              <input
                className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                type="submit"
                value="Enregistrer les modifications"
                disabled={!this.isNotVoid()}
              />
              <div className="clr"></div>
            </div>
            <div className="wrapper-modal">
              <Modal visible={this.state.visible} width="350" effect="fadeInUp" onClickAway={this.closeModal}>
                  <div className="content-modal">
                    <div className="head-modal">
                      <button className="close-modal" type="button" onClick={this.closeModal}><i className="material-icons icons-close">close</i></button>
                      <h1>Ajouter une langue</h1>
                    </div>
                    <div className="main-modal">
                      <div className="line-input">
                        <label>Langue</label>
                        <div className="select-down">
                          <Dropdown
                            className="MyclassName"
                            options={setOption().map(i => i.language)}
                            onChange={(value) => this.selectLang(value, listAllLanguage)}
                            value={this.state.nameLangue ? this.state.nameLangue : "Ajouter une langue"}
                            placeholder="Selectionner une langue"
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                      <div className="line-input">
                        <label>Niveau</label>
                        <div className="blc-niveau-langue">
                          <Rating
                            initialRating={this.state.niveauLangue ? this.state.niveauLangue : 0}
                            onChange= {(event)=>{this.selectSkills(event)}}
                          />
                        </div>
                      </div>
                      <div className="cnt-Btn align-right">
                        <button
                          type="button"
                          className={`button ${this.languageIsNotVoid() ? '' : 'buttonIsInvalid'}`}
                          onClick={() => this.addLanguage()}
                          disabled={!this.languageIsNotVoid()}
                        >
                          Ajouter une langue
                    </button>
                        <div className="clr"></div>
                      </div>
                    </div>
                  </div>
                </Modal>
            </div>
          </div>
        </form>
      </LoadingOverlay>
    )
  }
}
export default reduxConnect(Independant);
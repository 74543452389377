export const RECEIVE_ALL_ACTIVITY_AREA = 'RECEIVE_ALL_ACTIVITY_AREA';
export const RECEIVE_ACTIVITY_AREA = 'RECEIVE_ACTIVITY_AREA';
export const CREATE_ACTIVITY_AREA = 'CREATE_ACTIVITY_AREA';
export const ADD_ACTIVITY_AREA = 'ADD_ACTIVITY_AREA';
export const DELETE_ACTIVITY_AREA = 'DELETE_ACTIVITY_AREA';
export const LOGOUT = 'LOGOUT';

export const url = {
    allActivityArea: '/activity-zone',
    sellerActivityArea: '/activity-zone/seller'
  };

export default {
    RECEIVE_ALL_ACTIVITY_AREA,
    RECEIVE_ACTIVITY_AREA,
    CREATE_ACTIVITY_AREA,
    ADD_ACTIVITY_AREA,
    DELETE_ACTIVITY_AREA,
    LOGOUT,
}
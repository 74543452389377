import React from 'react';
import Dropdown from 'react-dropdown';
import { Helmet } from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
// import '../../../index.css';

class selectionDate extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.state = {
      isVisible: false,
      value: 'Aujourd’hui',
      from: '',
      to: '',
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
}

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day) {
    const date = new Date(moment(day).format('YYYY-MM-DD'));
    const infoDashboard = this.props.infoDashboard;
    if (date > new Date(moment().format('YYYY-MM-DD')) || (infoDashboard && infoDashboard.seller_creation && date < new Date(moment(infoDashboard.seller_creation).format('YYYY-MM-DD')))) {
        return
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    if (range.from && range.to) {
      this.setState({ isVisible: false });
    }
    
    if(range.from && range.to && range.from.toDateString() && range.to.toDateString()) {
      const rangeFrom = range.from.toLocaleString().split(" ")[0];
      const rangeTo = range.to.toLocaleString().split(" ")[0]
      this.setState({ value: `${rangeFrom} à ${rangeTo}`});
      const query = `?start=${moment(range.from).format('YYYY-MM-DD')}&end=${moment(range.to).format('YYYY-MM-DD')}`
      this.props.onDateSelected(query)
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }


  selectDate = (value) => {
    const oldValue = this.state.value;
    const date = new Date();
    this.setState({ value: value.label, oldValue });
    let query = ''
    switch (value.value) {
      case 'one':
        this.setState({ isVisible: false });
        query = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`
        break
      case 'two':
        this.setState({ isVisible: false });
        query = this.getQueryCurrentWeek();
        break
      case 'three':
        this.setState({ isVisible: false });
        query = this.getQueryCurrentMonth();
        break
      case 'four':
        this.setState({ isVisible: false });
        query = this.getQueryCurrentYear();
        break
      case 'five':
        this.setState({ isVisible: true });
        break
      default:
    }
    if (value.value !== 'five') {
      this.props.onDateSelected(query);
    }
  }

  handleClickOutside = event => {
    if (event.target.offsetParent&& !event.target.offsetParent.matches(".DayPicker-wrapper")) {
      let value = this.state.value;
      if (value === 'Choisir date') {
        value = this.state.oldValue || 'Aujourd’hui';
      }
      this.setState({
          isVisible: false,
          value,
      });
    }
  };

  setDisabledDate = () => {
    const infoDashboard = this.props.infoDashboard;
    let list = infoDashboard && infoDashboard.seller_creation ? [{ "before": new Date(moment(infoDashboard.seller_creation).format('YYYY-MM-DD'))}] : [];
    list = [...list, { "after": new Date() }];
    return list
  }

  getQueryCurrentWeek =() =>{
    let startDate = new Date();
    startDate.setHours(0,0,0,0);    
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    startDate = moment(startDate).format('YYYY-MM-DD');
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate = moment(endDate).format('YYYY-MM-DD');
    return `?start=${startDate}&end=${endDate}`;
    
  }

  getQueryCurrentMonth =() =>{
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let startDate = new Date(y, m, 1);
    startDate = moment(startDate).format('YYYY-MM-DD');
    let endDate = new Date(y, m + 1, 0);
    endDate = moment(endDate).format('YYYY-MM-DD');
    return `?start=${startDate}&end=${endDate}`;
    
  }

  getQueryCurrentYear =() =>{
    const date = new Date();
    const startDate = `${date.getFullYear()}-01-01`
    const endDate = `${date.getFullYear()}-12-31`
    return `?start=${startDate}&end=${endDate}` 
  }


  render() {
    const { from, to, isVisible } = this.state;
    const modifiers = { start: from, end: to };
    const options = [
      { value: 'one', label: 'Aujourd’hui' },
      { value: 'two', label: 'Semaine en cours' },
      { value: 'three', label: 'Mois en cours' },
      { value: 'four', label: 'Année en cours' },
      { value: 'five', label: 'Choisir date' },
    ];

    const MONTHS = [
      'Janvier',
      'Fevrier',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre',
    ];
    const WEEKDAYS_LONG = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ];
    const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Jeu', 'Ve', 'Sa'];
    return (
      <div className="select-down" >
        <Dropdown
          className="MyclassName"
          options={options}
          onChange={this.selectDate}
          value={this.state.value}
          placeholder="Select an option"
          controlClassName='myControlClassName'
          arrowClassName='arrow-down'
          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
        />
        {
          isVisible && 
          <div>
            <DayPicker
            className="Selectable"
            numberOfMonths={this.props.numberOfMonths}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            style={{ position: "relative", background: "#3e82cf", }}
            onDayClick={this.handleDayClick}
            locale="fr"
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            firstDayOfWeek={1}
            disabledDays={this.setDisabledDate()}
          />
          </div>
        }
        <Helmet><style>{`
          .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #4a90e2;
          }
          .Selectable .DayPicker-Day {
              border-radius: 0 !important;
          }
          .Selectable .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
          }
          .Selectable .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
          }
          .Selectable .DayPicker-wrapper { background:#ffffff}

          Selectable .DayPicker-wrapper { background:#ffffff; box-shadow:0 0 5px #000;}                          
          `}</style>
        </Helmet>
      </div>
    );
  }
}
export default selectionDate;

import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';
import { hidePopUp } from '../../../components/popup/popup';
import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";


class PrestationController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      prestation: [],
      isLoading: false,
      txtSearch: "",
      query: {
        itemPerPage: 5,
        page: 1,
      },
      data: [],
      hasPendingCommand: false,
    }
  }

  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  deletePrestation(index) {
    this.setState({ isLoading: true });
    this.props.removePrestation(index, this.callBack);
  }
  searchIsNotVoid = () => {
    const { txtSearch } = this.state
    return txtSearch !== ""

  }
  sendSearch = (event) => {
    let query = this.state.query;
    query.filter = this.state.txtSearch;
    this.setState({ isLoading: true })
    this.props.getPrestation(query, this.callBack);
  }
  componentDidMount() {
    // this.props.getPrestation(this.callBack);
    // this.props.getAllCategory(this.callBack);
    const stateLocation = (this.props.location && this.props.location.state) || null;
    if (stateLocation) {
      this.setState({
        ...stateLocation
      })
    }
  }

  callBack = (data, type) => {
    const response = data.response;
    if (type === "remove") {
      hidePopUp();
      let removeError = data && data.status === 200 ? false : true;
      this.setState({
        removeError,
      })
      if (response && response.status === 400 && response.data && response.data.num_command) {
        this.setState({
          isLoading: false
        })
        showPopupExtra(<PopupError title="Erreur sur la suppression" message="Cette prestation est liée à une commande en cours"/>)
      }else {
        const query = this.state.query;
        query.page= 1;
        this.setState({
          query
        })
        this.props.getPrestation(query, this.callBack);
      }
    }else if (type === "update" || type === "create") {
      let saveError = data && (data.status === 201 || data.status === 200) ? false : true;
      this.setState({
        saveError,
      })
      if (!saveError) {
        const query = this.state.query;
        query.page= 1;
        this.setState({
          query
        })
        this.props.getPrestation(query, this.callBack);
      }else{
        this.setState({ isLoading: false });
      }
    }else{
      this.setState({ isLoading: false });
    }
  }

  updateState = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
    if (event.target.value === "") {
      this.setState({ isLoading: true });
      const query = {
        itemPerPage: 5,
        page: 1,
      }
      this.setState({ query })
      this.props.getPrestation(query, this.callBack);
    }
  }

  handlePaginationChange = (page) => {
    let query = this.state.query;
    query.page = parseInt(page)
    this.setState({ isLoading: true });
    this.setState({ query })
    this.props.getPrestation(this.state.query, this.callBack);
  };

  handleItemsPerPageChange = (event) => {
    let query = this.state.query;
    query.itemPerPage = parseInt(event.target.value)
    query.page = 1
    this.setState({ isLoading: true });
    this.setState({ query })
    this.props.getPrestation(this.state.query, this.callBack);
  };

  getDuration = data => {
    var hours = Math.floor(data / 60);  
    var minutes = data % 60;
    return `${hours ? `${hours}h ` : ""}${hours && minutes && minutes <10 ? '0' : ''}${minutes}min`;         
  }

  createPrestation =()=>{
    const { bankInfo } = this.props.bank;
    const { infoProgressionEtablissement } = this.props.etablissement
    const { infoLogin } = this.props.commons;
    const isRestaurant = infoLogin && infoLogin.roles.includes('ROLE_SELLER_RESTAURENT')
    if (infoProgressionEtablissement.stateOfProgress !== 100 || (!isRestaurant && (!bankInfo || !bankInfo.identityFile || !bankInfo.kbisFile || bankInfo.status !== "verified"))) {
      let message = "Vous ne pouvez pas créer une prestation: "
      message = message + (infoProgressionEtablissement.stateOfProgress !== 100 ? "Votre profil n'est pas complet" : "Des informations sont manquantes/incorrectes pour la vérification de votre profil");
      showPopupExtra(<PopupError title="Erreur" message={message}/>)
      return
    } 
    this.props.history.push("/fichePrestation/new");
  }

}

export default PrestationController;

PrestationController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
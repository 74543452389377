import { Component } from 'react';

class RemboursementController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptCard: false,
      amount: "",
      comment: "",
      errors: {
        amount: "default",
        comment: ""
      },
    }
  }
  
  updateRadioState() {
    this.setState({ acceptCard: !this.state.acceptCard });
  }

  updateState(event) {
    let name = event.target.name;
    this.setState({ 
      [name]: event.target.value 
    },this.testErrors);
  }

  isButtonValid = () => {
    const {amount,comment} = this.state.errors
    return !amount && amount !== "default" && !comment  && comment !== "default"
  }

  testErrors = () => {
    let errors = this.state.errors;
    errors.amount = this.amountErrors();
    //errors.comment = (!this.state.comment && "Ce champ est obligatoire") || "";
    this.setState({ errors });
  }

  amountErrors() {
    const amount = this.state.amount;
    const amountFloat = amount ? parseFloat(`${amount}`.replace(',','.')) : 0;
    const commissionFloat = this.props.order.partSeller ? parseFloat(this.props.order.partSeller) : 0;
    if (!amount) {
      return "Ce champ est obligatoire";
    }
    if (isNaN(`${amount}`.replace(',','.'))) {
      return 'Montant invalide';
    }
    if(amountFloat > commissionFloat){
      return 'La somme des remboursements ne doivent pas dépasser la commission du seller sur la commande.'
    }
    return "";
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.isButtonValid()) {
      const data = {
        amount: this.state.amount ? parseFloat(`${this.state.amount}`.replace(',','.')) : 0,
        num_command: this.props.order.id
      }
      this.props.onValidate(data)
    } 
  }
}

export default RemboursementController;
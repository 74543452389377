export const GET_SELLER_CONTACT = 'GET_SELLER_CONTACT';
export const UPDATE_SELLER_CONTACT = 'UPDATE_SELLER_CONTACT';
export const GET_SELLER_INFO_ACCOUNT = 'GET_SELLER_INFO_ACCOUNT';
export const UPDATE_SELLER_INFO_ACCOUNT = 'UPDATE_SELLER_INFO_ACCOUNT';
export const REDIRECT_LOGIN = '/login';
export const GET_ID_SELLER = 'GET_ID_SELLER';
export const LOGOUT = 'LOGOUT';
export const GET_DATA_GRAPHE = 'GET_DATA_GRAPHE';
export const GET_SELLER_INFO_INDEPENDANT = 'GET_SELLER_INFO_INDEPENDANT';
export const UPDATE_SELLER_INFO_INDEPENDANT = 'UPDATE_SELLER_INFO_INDEPENDANT';
export const GET_SELLER_AGENCE = 'GET_SELLER_AGENCE';
export const GET_RESTAURANT_PRICE_RANGE = 'GET_RESTAURANT_PRICE_RANGE';
export const GET_RESTAURANT_RESTRICTION_TABLE = 'GET_RESTAURANT_RESTRICTION_TABLE';
export const GET_AGENCE_SELLERS = 'GET_AGENCE_SELLERS'

export const url = {
    sellerContact : '/establishment/contact',
    sellerAccount : '/seller/account',
    sellerRegister :  '/seller/register',
    sellerId : '/seller/get/id', 
    graphe: '/seller/income',
    sellerIndependant : '/seller-independent/infos',
    // sellerAgence : '/seller?itemPerPage=100&filter[type]={type_agence}',
    sellerAgence : '/seller-agence',
    restaurantPriceRange : '/restaurant/price-range',
    restaurantRestrictionTable : '/restaurant/restriction-table',
    agenceSellers: '/agence/seller'
  };

export default {
    GET_SELLER_CONTACT,
    UPDATE_SELLER_CONTACT,
    GET_SELLER_INFO_ACCOUNT,
    UPDATE_SELLER_INFO_ACCOUNT,
    REDIRECT_LOGIN,
    GET_ID_SELLER,
    LOGOUT,
    GET_DATA_GRAPHE,
    GET_SELLER_INFO_INDEPENDANT,
    UPDATE_SELLER_INFO_INDEPENDANT,
    GET_SELLER_AGENCE,
    GET_RESTAURANT_PRICE_RANGE,
    GET_RESTAURANT_RESTRICTION_TABLE,
    GET_AGENCE_SELLERS,
}

import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import 'react-day-picker/lib/style.css';
import 'moment/locale/fr';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

import { LIST_PAYS } from "./../../../../data/constants/listePays.js";
import InformationSiegeController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";

class InformationSiege extends InformationSiegeController {

  render() {
    return (
      <div className="cnt-tab">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div>
            {this.state.updateError && PopupHandler.showError("L'enregistrement des modifications a échoué.")}
            {this.state.updateError === false && PopupHandler.showSuccess("Les modifications ont été enregistrées.")}
            {this.state.addStepError && PopupHandler.showError("Erreur lors de l'enregistrement de l'étape actuelle.")}
          </div>
          <form className="" onSubmit={this.handleSubmit}>
            <div className="cnt-form">
              <div className="row">
                <div className="col col-6 line-input">
                  <label>Nom du siège social</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-nom-siege"
                      value={this.state.name ? this.state.name : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="name"
                    />
                    {
                      this.state.errors.name.length > 0 &&
                      <span className='error'>{this.state.errors.name}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>Rue du siège social</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adress-siege"
                      value={this.state.address ? this.state.address.streetAddress : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="streetAddress"
                    />
                    {
                      this.state.errors.streetAddress.length > 0 &&
                      this.state.errors.streetAddress !== 'default' &&
                      <span className='error'>{this.state.errors.streetAddress}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Ville du siège social</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.address && this.state.address.city ? this.state.address.city : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="city"
                    />
                    
                    {
                      this.state.errors.city.length > 0 &&
                      this.state.errors.city !== 'default' &&
                      <span className='error'>{this.state.errors.city}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Pays du siège social</label>
                  <div className="cnt-input">
                    <select
                      value={(this.state.address && this.state.address.country) || ""}
                      onChange={(value) => this.updateStateAddress(value)}
                      placeholder="Select"
                      name="country"
                    >
                      <option value="">{"Select"}</option>
                      {LIST_PAYS.map(item =>
                        <option key={`item-${item.value}`} value={item.value}>{item.label}</option>
                      )}
                    </select>
                    {
                      this.state.errors.country.length > 0 &&
                      this.state.errors.country !== 'default' &&
                      <span className='error'>{this.state.errors.country}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Code postal du siège social</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.address && this.state.address.zipCode ? this.state.address.zipCode : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="zipCode"
                    />
                    
                    {
                      this.state.errors.zipCode.length > 0 &&
                      this.state.errors.zipCode !== 'default' &&
                      <span className='error'>{this.state.errors.zipCode}</span>
                    }

                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>Adresse e-mail du siège social</label>
                  <div className="cnt-input">
                    <input
                      type="email"
                      className="input-mail-siege"
                      value={this.state.email ? this.state.email : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="email"
                    />
                    
                    {
                      this.state.errors.email.length > 0 &&
                      this.state.errors.email !== 'default' &&
                      <span className='error'>{this.state.errors.email}</span>
                    }
                  </div>
                </div>
                <div className="col col-12"></div>
                <div className="col col-4 line-input">
                  <label>Nom du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-nom-presta"
                      value={this.state.firstNameContact ? this.state.firstNameContact : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="firstNameContact"
                    />
                    
                    {
                      this.state.errors.firstNameContact.length > 0 &&
                      this.state.errors.firstNameContact !== 'default' &&
                      <span className='error'>{this.state.errors.firstNameContact}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Prénom du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-prenom-presta"
                      value={this.state.lastNameContact ? this.state.lastNameContact : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="lastNameContact"
                    />
                    
                    {
                      this.state.errors.lastNameContact.length > 0 &&
                      this.state.errors.lastNameContact !== 'default' &&
                      <span className='error'>{this.state.errors.lastNameContact}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>N° et rue du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adress-presta"
                      value={this.state.addressContact ? this.state.addressContact.streetAddress : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="streetAddressContact"
                    />
                    
                    {
                      this.state.errors.streetAddressContact.length > 0 &&
                      this.state.errors.streetAddressContact !== 'default' &&
                      <span className='error'>{this.state.errors.streetAddressContact}</span>
                    }
                  </div>
                </div>

                <div className="col col-4 line-input">
                  <label>Ville du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.addressContact && this.state.addressContact.city ? this.state.addressContact.city : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="cityContact"
                    />
                    
                    {
                      this.state.errors.cityContact.length > 0 &&
                      this.state.errors.cityContact !== 'default' &&
                      <span className='error'>{this.state.errors.cityContact}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Pays du gérant</label>
                  <div className="cnt-input">
                    <select
                      value={this.state.addressContact && this.state.addressContact.country ? this.state.addressContact.country : ""}
                      onChange={(value) => this.updateStateAddress(value)}
                      placeholder="Select"
                      name="countryContact"
                    >
                      <option value="">{"Select"}</option>
                      {LIST_PAYS.map(item =>
                        <option key={`item-${item.value}`} value={item.value}>{item.label}</option>
                      )}
                    </select>
                    {
                      this.state.errors.countryContact.length > 0 &&
                      this.state.errors.countryContact !== 'default' &&
                      <span className='error'>{this.state.errors.countryContact}</span>
                    }
                  </div>
                </div>
                <div className="col col-4 line-input">
                  <label>Code postal du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="text"
                      className="input-adresse-etablissement"
                      value={this.state.addressContact && this.state.addressContact.zipCode ? this.state.addressContact.zipCode : ""}
                      onChange={(event) => this.updateStateAddress(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="zipCodeContact"
                    />
                    
                    {
                      this.state.errors.zipCodeContact.length > 0 &&
                      this.state.errors.zipCodeContact !== 'default' &&
                      <span className='error'>{this.state.errors.zipCodeContact}</span>
                    }

                  </div>
                </div>

                <div className="col col-6 line-input">
                  <label>E-mail du gérant</label>
                  <div className="cnt-input">
                    <input
                      type="email"
                      className="input-mail-representant"
                      value={this.state.emailContact ? this.state.emailContact : ""}
                      onChange={(event) => this.updateState(event)}
                      onBlur={(event) => this.handleUserInput(event)}
                      name="emailContact"
                    />
                    
                    {
                      this.state.errors.emailContact.length > 0 &&
                      this.state.errors.emailContact !== 'default' &&
                      <span className='error'>{this.state.errors.emailContact}</span>
                    }
                  </div>
                </div>
                <div className="col col-6 line-input">
                  <label>Date de naissance du gérant</label>
                  <div className="">
                    <DatePickerInput
                      disabled={this.state.disabled}
                      displayFormat='DD/MM/YYYY'
                      returnFormat='YYYY-MM-DD'
                      value={this.state.birthdayContact ? new Date(this.state.birthdayContact) : null}
                      className={`my-react-component${this.state.errors.birthdayContact ? ' hasError' : ''}`}
                      onChange={(jsDate, dateString) => this.updateDayPick(dateString)}
                      showOnInputClick
                      placeholder='Selectionner'
                      locale='fr'
                      maxDate={new Date()}
                      onClear={() => this.setState({ birthdayContact: null })}
                      readOnly={true}
                    />
                  </div>
                  {
                    this.state.errors &&  this.state.errors.birthdayContact && this.state.errors.birthdayContact !== 'default' && 
                    <span className='error'>{this.state.errors.birthdayContact}</span>
                  }
                  <div className='ui input'>

                  </div>
                </div>
              </div>
            </div>
            <div className="cnt-Btn">
              <input
                className={`button mch-button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                type="submit"
                value="Enregistrer"
                disabled={!this.isNotVoid()}
              />
              <div className="clr"></div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    )
  }

}

export default reduxConnect(InformationSiege);

import React from 'react';
import Dropdown from 'react-dropdown';
// import '../../../value.id.css'; 


const RenderHour= ({ updateStateOpenTime, removeOpenTime, listOpeningTime, disabled}) => {
    const jour = [
        { value: "1", label: 'Lundi' },
        { value: "2", label: 'Mardi' },
        { value: "3", label: 'Mercredi' },
        { value: "4", label: 'jeudi' },
        { value: "5", label: 'Vendredi' },
        { value: "6", label: 'Samedi' },
        { value: "7", label: 'Dimanche' },
    ];
    const Myheurs = [
        '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'
    ];

    return (
        listOpeningTime.map((openingTime, index) => 
        <div  className="line-horaire" key={`${openingTime.id}-hour`}>
            <div className="line-horaire-item">
                <div className="horaire-item">
                    <div className="txt">Du</div>
                    <div className="select-down">
                        <Dropdown
                            disabled={disabled}
                            className="MyclassName"
                            options={jour}
                            onChange={(value) => updateStateOpenTime(value,'dayStart',openingTime.id)}
                            value={`${openingTime.dayStart}`}
                            placeholder="" 
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                    </div>
                </div>
                {
                    openingTime.errors && openingTime.errors.dayStart && 
                    <span className='error'>{openingTime.errors.dayStart}</span>
                }
            </div>
            <div className="line-horaire-item">
                <div className="horaire-item">
                    <div className="txt">au</div>
                    <div className="select-down">
                        <Dropdown
                            disabled={disabled}
                            className="MyclassName"
                            options={jour}
                            onChange={(value) => updateStateOpenTime(value,'dayEnd',openingTime.id)}
                            value={`${openingTime.dayEnd}`}
                            placeholder=""
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                    </div>
                </div>
                {
                    openingTime.errors && openingTime.errors.dayEnd && 
                    <span className='error'>{openingTime.errors.dayEnd}</span>
                }
            </div>
            <div className="line-horaire-item">
                <div className="horaire-item">
                    <div className="txt">De</div>
                    <div className="select-down">
                        <Dropdown
                            disabled={disabled}
                            className="MyclassName"
                            options={Myheurs}
                            onChange={(value) => updateStateOpenTime(value,'timeStart',openingTime.id)}
                            value={openingTime.timeStart}
                            placeholder=""
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                    </div>
                </div>
                {
                    openingTime.errors && openingTime.errors.timeStart && 
                    <span className='error'>{openingTime.errors.timeStart}</span>
                }
            </div>
            <div className="line-horaire-item">
                <div className="horaire-item">
                    <div className="txt">à</div>
                    <div className="select-down">
                        <Dropdown
                            disabled={disabled}
                            className="MyclassName"
                            options={Myheurs}
                            onChange={(value) => updateStateOpenTime(value,'timeEnd',openingTime.id)}
                            value={openingTime.timeEnd}
                            placeholder=""
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                    </div>
                </div>
                {
                    openingTime.errors && openingTime.errors.timeEnd && 
                    <span className='error'>{openingTime.errors.timeEnd}</span>
                }
            </div>
            <div className="removeLine">
                <i className="material-icons icons-cancel" onClick = {() => removeOpenTime(openingTime.id)} style={index === 0 ? {visibility: "hidden"}: {}}>cancel</i>
            </div>
            
        </div>    
    ));
}
export default RenderHour;

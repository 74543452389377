import React, {Component} from 'react';
import  {hidePopUp} from './popup';

class PopupConfirm extends Component {

    validPopup = () => {
        this.props.onAccept()
    }

    render = ()  => {
        return (
            <div  className="main-modal">
                <p>{this.props.message}</p>
                <div className="cnt-Btn align-right">
                    <span className="btn-cancel" onClick={hidePopUp}>Annuler</span>
                    <button className="button" onClick={this.validPopup}>Valider</button>
                    <div className="clr"></div>
                </div>
            </div>
        )
    }
}

export default PopupConfirm
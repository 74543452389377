import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { REDIRECT_LOGIN, EXPIRE_SUBSCRIBE_TOKEN_ERROR, URL_ALREADY_DEFINED_ERROR, TOKEN, EXPIRES_AT, TYPE_COMPTE, REFRESH_TOKEN } from '../../../services/redux/constants/commons';
import queryString from 'query-string';
import moment from 'moment';


import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';


class MdpController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      confirmNewsPass: "",
      userPassword: "",
      errors: {
        userPassword: 'default',
        confirmNewsPass: 'default',
      },
      isLoading : false,
      titleShow: "",
      messageShow: "",
      visible : false,
      parametersValues: queryString.parse(this.props.location.search),
      tokenChecked: false
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount (){
    const { parametersValues } = this.state;
    if (parametersValues) {
      const data={
        token: parametersValues.token,
        code: parametersValues.code
      }
      this.setState({ isLoading : true });
      this.props.checkToken(data, this.callBack)
    }else{
      this.redirectToLogin(URL_ALREADY_DEFINED_ERROR)
    }
  }

  onChange = (value) => {
  }

  onSubmit = () => {
    // const recaptchaValue = recaptchaRef.current.getValue();
    // this.props.onSubmit(recaptchaValue);
  }
 
  togglePasswordVisibility = e => {
    e.preventDefault();
    this.setState(oldState => ({
      showPassword: !oldState.showPassword
    }));
  };

  toggleConfirmPasswordVisibility = e => {
    e.preventDefault();
    this.setState(oldState => ({
      showConfirmPassword: !oldState.showConfirmPassword
    }));
  };

  isNotVoid = () => this.state.errors.userPassword === "" && this.state.errors.confirmNewsPass === ""

  handleSubmit(event) {
    event.preventDefault();
    if (this.isNotVoid()) {
      const { parametersValues } = this.state;
      const data = {
        password: this.state.userPassword,
        passwordConfirm: this.state.confirmNewsPass,
        code: parametersValues.code
      }
      this.setState({ isLoading : true });
      this.props.setUserPassword(data, this.state.token,this.callBack);
    }
  }
  callBack = (data,type) => {
    this.setState({ isLoading : false });
    if (type ==="setPassword" && data && (data.status === 200 || data.status === 204) && data.data) {
      this.login(data.data)
    }else if (type === "checkToken") {
      if (data && (data.status === 200 || data.status === 204)) {
        this.setState({ 
          tokenChecked : true,
          token: (data && data.data && data.data.token) || "",
        });
      }else{
        const error = data && data.error && data.error.response && data.error.response.data && data.error.response.data.message && data.error.response.data.message.includes("This token is expired") ? EXPIRE_SUBSCRIBE_TOKEN_ERROR : URL_ALREADY_DEFINED_ERROR;
        this.redirectToLogin(error)
      }
    }else if (data && data.error && data.error.response && data.error.response.status ===403) {
      this.openModal("Erreur", "Mot de passé déjà défini, le lien n'est plus actif.")
    }else if (data && data.error && data.error.toString().includes('code 500')) {
      this.openModal("Erreur serveur", "Une erreur est survenu lors de l'insertion, veuiller réessayer")
    }else if (data && data.error && data.error.toString().includes('code 401')) {
      this.openModal("Erreur", "Delai expiré")
    }
    else if (data && data.error && data.error.toString().includes('Network Error'))
      this.openModal("Problème de connexion", "Vérifier la connectivité internet")
    else if ((data && data.status && data.status === 201) || (data && data.status && data.status === 200) || (data && data.status && data.status === 204)) {
      this.openModal("Succés", "Mot de passe enregistrée")
    }
  }

  login = (data)=>{
    if( data && data.token) {
      const token = data.token;
      const ttl = 3600;
      const expiresAt = JSON.stringify(new Date().getTime() + ttl * 1000);
      const { history } = this.props;
      const date = new Date();
      const queryIncome = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      localStorage.setItem(TYPE_COMPTE, data.roles);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
      this.props.setExpireDate(false);
      this.props.getInfoDashboard(queryIncome,this.callBackOK);
      this.props.verifyBankFile(this.callBackOK);
      this.props.getProgressionEtablissement(this.callBackOK);
      if (!data.roles.includes('ROLE_SELLER_AGENCE')) {
        this.props.getPrestation(this.callBackOK);
        this.props.getAllCategory(this.callBackOK);
        this.props.getHotelPartner(this.callBackOK);
      }
      this.props.getSellerId(this.callBackOK);
      this.props.getDataGraphe(this.callBackOK);
      this.props.getLegalInformation(this.callBackOK)
      this.props.getSellerInfoAccount(this.callBackOK);
      this.props.getBankInfo(this.callBackOK);
      this.props.getInfoEtablissement(this.callBackOK);
      this.props.getAllLanguage(this.callBackOK);
      this.props.getSellerLanguage(this.callBackOK);
      this.props.getAllEvent(null,this.callBackOK);
      if (history) history.replace({ pathname: "/dashboard" });
    }
  }

  callBackOK = (data) => {

  }

  redirectToLogin = (tokenError) => {
    const { history } = this.props;
    if (history) history.push({pathname: REDIRECT_LOGIN, error: tokenError, state: { fromPassword: true }});
    if(!tokenError){
      window.location.href = REDIRECT_LOGIN;
    }
  }
  
  openModal(title, message) {
    this.setState({
      visible: true,
      titleShow: title,
      messageShow: message,
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'confirmNewsPass':
        errors.confirmNewsPass = this.props.errors.confirmNewsPassErrors(this.state.userPassword, value);
        break;
      case 'userPassword':
        errors.userPassword = this.props.errors.passwordErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event);
    this.setState({ [name]: event.target.value });
  }
}

export default MdpController;

//
// Redux connection
//

MdpController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
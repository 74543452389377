import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';

import PopupError from "../../../components/popup/popup.error";
import {showPopupExtra} from "../../../components/popup/popup";

const options =[
  {
      value: "EI",
      abbreviation: "EI",
      label: "Entreprise individuelle"
  },
  {
      value: "EIRL",
      abbreviation: "EIRL",
      label: "Entreprise individuelle à responsabilité limitée"
  },
  {
      value: "EURL",
      abbreviation: "EURL",
      label: "Entreprise unipersonnelle à responsabilité limitée"
  },
  {
      value: "SA",
      abbreviation: "SA",
      label: "Société anonyme"
  },
  {
      value: "SARL",
      abbreviation: "SARL",
      label: "Société à responsabilité limitée"
  },
  {
      value: "SARLU",
      abbreviation: "SARLU",
      label: "Société anonyme à responsabilité limité uni-personnelle"
  },
  {
      value: "SAS",
      abbreviation: "SAS",
      label: "Société par actions simplifiée"
  },
  {
      value: "SASU",
      abbreviation: "SASU",
      label: "Société par actions simplifiée unipersonnelle"
  },
  {
      value: "SC",
      abbreviation: "SC",
      label: "Société civile"
  },
  {
      value: "SCA",
      abbreviation: "SCA",
      label: "Société en commandite par actions"
  },
  {
      value: "SCS",
      abbreviation: "SCS",
      label: "Société en commandite simple"
  },
  {
      value: "SNC",
      abbreviation: "SNC",
      label: "Société en nom collectif"
  }
]

class InfoJuridiqueController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoJuridique: {
        numSiret: "",
        url: "",
        numTva: "",
        status: "SAS",
      },
      numSiret: "",
      url: "",
      numTva: "",
      status: "SAS",
      errors : {
        numSiret : 'default',
        url : 'default',
        numTva: 'default',
      },
      isLoading: false,
      defaultOption: options[0].value
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { legalInfoEtablissement } = this.props.etablissement;
    if (legalInfoEtablissement) {
      this.setState({
        ...legalInfoEtablissement,
      });
      for (let [key, value] of Object.entries(legalInfoEtablissement)) {
       key && value && this.testErrors(key,value)
      }
    }
    else{
      this.resetValue()
    }
  }
  componentDidUpdate (prevProps) {
    const prevLegalInfo = prevProps.etablissement.legalInfoEtablissement;
    const propsLegalInfo = this.props.etablissement.legalInfoEtablissement;
    
    if (!prevLegalInfo) {
        return;
    }
    if(prevLegalInfo!==propsLegalInfo){
        this.setState({
            ...propsLegalInfo,
        });
        for (let [key, value] of Object.entries(propsLegalInfo)) {
          key && value && this.testErrors(key,value)
         }
    }
   }

  testErrors(name,value) {
    let errors = this.state.errors;
    switch (name) {
      case 'numSiret':
        errors.numSiret = this.props.errors.notEmptyInput(value) || this.props.errors.siretValid(value);;
        break;
      // case 'url':
      //   errors.url = this.props.errors.notEmptyInput(value);
      //   break;
      case 'numTva':
        errors.numTva = this.props.errors.notEmptyInput(value) || this.props.errors.tvaValid(value);;
        break;
      default:
        break;
    }
    this.setState({ errors });
  }
  resetValue = () => {
    this.setState({ 
      numSiret: "",
      // url: "",
      numTva: "",
      status: "SAS", });
  }

  isNotVoid = () => {
    const {
      numSiret,
      // url,
      numTva,
    } = this.state.errors;
    return numSiret === "" && numTva === ""
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.isNotVoid() && this.state.status !== "") {
      const data = {
        numSiret : this.state.numSiret,
        status: this.state.status,
        numTva: this.state.numTva,
      }
      this.props.setLoadingTrue()
      this.props.sendLegalInformation(data, this.callBack);
    }
  }

  callBack = async (data,type) => {
    const {steps} =this.props.dashboard;
    const fromHeadBand= (this.props.location.state && this.props.location.state.fromHeadBand) || false;
    if(type && type==="update"){
      const updateError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      this.setState({
        updateError,
      })
      if (data && data.data && (data.data.code === 500 || data.data.code === 400) && data.data.message) {
        showPopupExtra(<PopupError title="Erreur" message={data.data.message}/>)
      }
      if (!updateError) {
        if (!steps.includes("3a")) {
          await this.props.addSteps({steps: ["3a"]},this.callBack);
        }else if (steps.includes("3b") && fromHeadBand) {
          this.props.setLoadingFalse()
          this.props.history.push("/dashboard");
        }else{
          await this.props.getProgressionEtablissement(this.callBack)
        }
      }else{
        this.props.setLoadingFalse()
      }
    }else if(type==="addSteps"){
      const addStepError = data && (data.status === 200 || data.status === 201 || data.status === 204) ? false : true;
      const stepsResponse = (data && data.data && data.data && data.data.steps) || [];
      this.props.setLoadingFalse()
      this.setState({
        addStepError,
      })
      if(!addStepError && stepsResponse.includes("3b") && fromHeadBand){
        this.props.history.push("/dashboard");
      }
    }else{
      this.props.setLoadingFalse()
    }
  }

  updateState(event) {
    let name = event.target.name;
    this.handleUserInput(event);
    this.setState({ [name]: event.target.value });
  }

  handleUserInput(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'numSiret':
        errors.numSiret = this.props.errors.notEmptyInput(value) || this.props.errors.siretValid(value);
        break;
      case 'url':
        errors.url = this.props.errors.notEmptyInput(value);
        break;
      case 'numTva':
        errors.numTva = this.props.errors.notEmptyInput(value) || this.props.errors.tvaValid(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  handleChange(value) {
    this.setState({ status: value.value });
  }

  getOptionsStatus =()=>{
    const { infoLogin } = this.props.commons;
    const optionAuto = {
      value: "Auto-entrepreneur",
      abbreviation: "Auto-entrepreneur",
      label: "Auto-entrepreneur"
    }
    const isSocietyOrResto = infoLogin && infoLogin.roles && (infoLogin.roles.includes('ROLE_SELLER_RESTAURENT') || infoLogin.roles.includes('ROLE_SELLER_INSTITUT'));
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    const optionsStatus = (isSocietyOrResto && options) || (isIndependant && [optionAuto]) || [optionAuto, ...options];
    return optionsStatus.map(status=>(
        status.abbreviation === 'Auto-entrepreneur' ? 
            {
                value: status.value,
                label: status.abbreviation,
            }
        : 
            {
                value: status.value,
                label: `${status.abbreviation} : ${status.label}`
            }
    ))
  }

}

export default InfoJuridiqueController;

InfoJuridiqueController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import { get, post } from '.';
import { url } from '../redux/constants/commandes'

export default {

  getOrderSale: async (dataParams, id) => {
    let params = '?';
    Object.keys(dataParams).forEach(key=>{
      if (key==='filter') {
        Object.keys(dataParams.filter).forEach(keyFilter=>{
          params = dataParams['filter'][keyFilter] ? `${params}${params === '?' ? '' : '&'}filter[${keyFilter}]=${dataParams['filter'][keyFilter]}` : params
        })
      }else if (key==='order') {
        Object.keys(dataParams.order).forEach(orderKey=>{
          params = dataParams['order'][orderKey] ? `${params}${params === '?' ? '' : '&'}order[${orderKey}]=${dataParams['order'][orderKey]}` : params
        })
      }else{
        params = dataParams[key] ? `${params}${params === '?' ? '' : '&'}${key}=${dataParams[key]}` : params
      }
    })
    const header = id ? {SELLER: id} : null;
    const response = await get(`${url.saleOrder}${params}`, null, header);
    return response
  },
  getNextOrderSale: async () => {
    const response = await get(url.nextSaleOrder);
    return response;
  },
  denySaleOrder: async (data) => {
    let response=""
    const id = data.id;
    if(data && data.counterOffer){
      delete data.id;
      response = await post(`${url.denySaleOrder}/${id}`,data);
    }
    else{
      response = await post(`${url.denySaleOrder}/${data.id}`,);
    } 
    return response;
  },
  acceptSaleOrder: async (data) => {
    const id = data.id;
    delete data.id;
    const response = await post(`${url.acceptSaleOrder}/${id}`,data);
    return response;
  },
  cancelSaleOrder: async (data) => {
    const id = data.id;
    delete data.id;
    const response = await post(`${url.cancelSaleOrder}/${id}`);
    return response;
  },
  exportOrder: async (ids, sellerId) => {
    const header = {'Content-Type': 'text/csv;charset=utf-8'};
    if (sellerId) {
      header.SELLER = sellerId
    }
    const response = await get(`${url.saleOrderExport}?ids=${ids}`, null, header);
    return response;
  },
  getOrderDetails: async (id) => {
    const response = await get(`${url.saleOrderDetails}/${id}`);
    return response;
  },
  getOrderBill: async (id, idSeller) => {
    const header = idSeller ? {SELLER: idSeller} : null;
    const response = await get(`${url.orderBill}?idOrder=${id}`, null, header);
    return response;
  },
  downloadOrderBill: async (id, idSeller) => {
    const header = {'Content-Type': 'application/pdf'};
    if (idSeller) {
      header.SELLER = idSeller
    }
    const response = await get(`${url.downloadOrderBill}/${id}`, null, header, true);
    return response;
  },
  refundOrder: async (data) => {
    const response = await post(`${url.refundOrder}`, data);
    return response;
  },

  
} 
import React, { Component } from "react";
import Pagination from "react-js-pagination";

import PropTypes from 'prop-types';
 
class PaginationComponent extends Component {
   
    handlePageChange = (pageNumber) => {
        if (this.props.activePage !== pageNumber) {
            this.setState({activePage: pageNumber});
            this.props.changePageCallback(pageNumber);
        }
    }
  
    render() {
        const { totalElements, itemsPerPage, activePage } = this.props;
        return (
            <div className={totalElements <= 1 ? 'hidden' : ''}>
                <Pagination
                    activePage = {activePage}
                    itemsCountPerPage = {itemsPerPage}
                    totalItemsCount = {totalElements}
                    pageRangeDisplayed = {5}
                    onChange = {this.handlePageChange}
                    hideFirstLastPages = {true}
                    prevPageText = ''
                    itemClassPrev = 'chevron-left'
                    nextPageText = ''
                    itemClassNext = 'chevron-right'
                    itemClass = 'button'
                    hideDisabled
                />
            </div>
        );
    }
}
PaginationComponent.defaultProps = {
    totalPages: 0,
    activePage: 1,
    itemsPerPage: 10
};

PaginationComponent.propTypes = {
    activePage: PropTypes.number,
    itemsPerPage:  PropTypes.number,
    totalPages: PropTypes.number,
    changePageCallback: PropTypes.func.isRequired,

};

export default PaginationComponent;

import constants from '../constants/notice';

const initialSate = {
  reviews: [],
  orderReview: null,
  productReview: null
}
const notice = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_ORDER_NOTICE:
      return {
        ...state,
      }
    case constants.GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      }
    case constants.GET_ORDER_REVIEW:
      return {
        ...state,
        orderReview: (action.payload && action.payload.data && action.payload.data.length > 0  && action.payload.data[0]) || null,
      }
    case constants.GET_PRODUCT_REVIEW:
    return {
      ...state,
      productReview: (action.payload && action.payload.data && action.payload.data.length > 0  && action.payload.data[0]) || null,
    }
    case constants.LOGOUT :     
      return initialSate;
    default:
      return state;
  }
}

export default notice;
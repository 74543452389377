import constants from '../constants/commandes';
import serviceApp from '../../applicatif/commandes';


export const getOrderSale = (data,id, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getOrderSale(data, id)
        if(payload){
            callBack && callBack(payload,"getOrderSale")
            if(payload && payload.status && payload.status===200)
            return dispatch({ 
                type : constants.GET_SALE_ORDER,
                payload : payload.data.data,
            })
       }
    }
}

export const getNextOrderSale = (callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getNextOrderSale()
        if(payload){
            callBack && callBack(payload,"nextOrderSale")
            if(payload && payload.status && payload.status===200)
            return dispatch({ 
                type : constants.GET_NEXT_SALE_ORDER,
                payload : payload.data,
            })
       }
    }
}

export const acceptSaleOrder = (data,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.acceptSaleOrder(data)
        if(payload){
            callBack && callBack(payload,"accept")
       }
    }
}
export const denySaleOrder = (data,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.denySaleOrder(data)
        if(payload){
            callBack && callBack(payload,"deny")
       }
    }
}

export const cancelSaleOrder = (data,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.cancelSaleOrder(data)
        if(payload){
            callBack && callBack(payload,"cancel")
       }
    }
}

export const exportOrder = (data, sellerId,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.exportOrder(data, sellerId)
        if(payload){
            callBack && callBack(payload,"export")
       }
    }
}

export const getOrderDetails = (id,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getOrderDetails(id)
        if(payload){
            callBack && callBack(payload,"orderDetails")
       }
    }
}

export const getOrderBill = (id, idSeller,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.getOrderBill(id, idSeller)
        if(payload){
            callBack && callBack(payload,"getOrderBill")
       }
    }
}

export const downloadOrderBill = (id, idSeller, callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.downloadOrderBill(id, idSeller)
        if(payload){
            callBack && callBack(payload,"download")
       }
    }
}

export const refundOrder = (data,callBack) => {
    return async (dispatch) => {
        const payload = await serviceApp.refundOrder(data)
        if(payload){
            callBack && callBack(payload,"refundOrder")
       }
    }
}




import React from 'react';
import Dropdown from 'react-dropdown';
import { Link } from 'react-router-dom'
import 'react-dropdown/style.css';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import Input from 'react-phone-number-input/input';
import Select, { components } from "react-select";
import 'react-phone-number-input/style.css';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-awesome-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import InscriptionController, { reduxConnect } from './controller'


const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

const options = getCountries().map((country) => ({
  label: en[country] + ' +' + getCountryCallingCode(country),
  chipLabel: '+' + getCountryCallingCode(country),
  value: country
}));

class Inscription extends InscriptionController {

  render() {
    const categories = (this.props.commons && this.props.commons.hotelCategories) || []
    const { listSellerAgence, listRestaurantPriceRange, listRestaurantRestrictionTable } = this.props.sellers;
    const listRestrictionTable = listRestaurantRestrictionTable && listRestaurantRestrictionTable.length > 0 ?
      listRestaurantRestrictionTable.map(item => {
        switch (item.label) {
          case "WHOLE":
            item.translate = "Aucun";
            break;
          case "ODD":
            item.translate = "Impair";
            break;
          case "EVEN":
            item.translate = "Pair";
            break;
          default:
            break;
        }
        return item;
      }) : []

    //slick slider
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
    };

    return (
      <div ref={this.pageRef}>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <div className="header">
            <div className="wrapper clr">
              <div className="logo"><Link to="/" className="img-logo">&nbsp;</Link></div>
            </div>
          </div>
          <div className="page">
            <div className="bandeaux">
              <div className="wrapper clr">
                <h1>Inscription</h1>
              </div>
            </div>
            <div className="banner"></div>
            <div className="main monHotel">
              <div className="wrapper clr">

                <form className="" onSubmit={this.handleSubmit}>
                  <div className="row mainBody">
                    <div className="col col-12 line-input">
                      <h2>Type de prestataire</h2>
                      <div className="select-down select-institut">
                        <Dropdown
                          className="MyclassName"
                          options={this.state.providerOptions}
                          onChange={(value) => this.handleProviderOptionsChange(value)}
                          value={this.state.providerOptionsValue}
                          controlClassName=''
                          arrowClassName='arrow-down'
                          arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                          arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                        />
                      </div>
                    </div>
                    {this.state.textProvider && this.state.textProvider !== "" &&
                      <div className="col col-12 line-input txt-type">
                        <div>{this.state.textProvider}</div>
                      </div>
                    }
                    {this.state.providerOptionsValue && this.state.providerOptionsValue === "Indépendant" &&
                      <div className="col col-12 line-input">
                        <h2>Agence de l'indépendant</h2>
                        <div className="select-down select-institut">
                          <Dropdown
                            className="MyclassName"
                            options={(listSellerAgence && Array.isArray(listSellerAgence) && listSellerAgence.length > 0) ? [{value: "", label: " "}].concat(listSellerAgence.map(i => ({value: `${i.id}`, label: i.name}))) : []}
                            onChange={(value) => { this.setState({ agence: value.value })}}
                            value={this.state.agence}
                            controlClassName=''
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                            placeholder ="Séléctionner une agence"
                          />
                        </div>
                      </div>
                    }
                    {this.state.providerOptionsValue && this.state.providerOptionsValue !== "Indépendant" &&
                      <React.Fragment>
                        <div className="col col-6 line-input">
                          <label>Nom de l'établissement*</label>
                          <div className="cnt-input">
                            <input
                              type="text"
                              className="input-nom"
                              value={this.state.establishmentName}
                              onChange={(event) => this.updateState(event)}
                              onBlur={(event) => this.handleUserInput(event)}
                              name="establishmentName"
                            />
                            
                          </div>
                          {
                            this.state.errors.establishmentName &&
                            this.state.errors.establishmentName !== 'default' &&
                            <span className='error'>{this.state.errors.establishmentName}</span>
                          }
                        </div>
                        <div className="col col-6 line-input"/>
                      </React.Fragment>
                    }

                    <div className="col col-6 line-input">
                      <label>Nom du contact principal*</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-nom"
                          onInput={this.maxLengthCheck}
                          maxLength={31}
                          value={this.state.userFirstName}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="userFirstName"
                        />
                        
                      </div>
                      {
                        this.state.errors.userFirstName.length > 0 &&
                        this.state.errors.userFirstName !== 'default' &&
                        <span className='error'>{this.state.errors.userFirstName}</span>
                      }
                    </div>
                    <div className="col col-6 line-input">
                      <label>Prénom du contact principal*</label>
                      <div className="cnt-input">
                        <input
                          type="text"
                          className="input-prenom"
                          onInput={this.maxLengthCheck}
                          maxLength={31}
                          value={this.state.userName}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="userName"
                        />
                        
                      </div>
                      {
                        this.state.errors.userName.length > 0 &&
                        this.state.errors.userName !== 'default' &&
                        <span className='error'>{this.state.errors.userName}</span>
                      }
                    </div>
                    <div className="col col-6 line-input">
                      <label>Adresse e-mail*</label>
                      <div className="cnt-input">
                        <input
                          type="email"
                          className="input-nom"
                          value={this.state.userEmail}
                          onChange={(event) => this.updateState(event)}
                          onBlur={(event) => this.handleUserInput(event)}
                          name="userEmail"
                        />
                        
                      </div>
                      {
                        this.state.errors.userEmail.length > 0 &&
                        this.state.errors.userEmail !== 'default' &&
                        <span className='error'>{this.state.errors.userEmail}</span>
                      }
                    </div>
                    <div className="col col-6 line-input">
                      <label>Numéro de téléphone du contact</label>
                      <div className="PhoneInput">
                        <Select
                          onChange={(value) => this.handleUserSelect(value)}
                          options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                          components={{ SingleValue }}
                          value={options.find(op => { return op.value === (this.state.currentCountry) })}
                        />
                        <Input
                          country={this.state.currentCountry}
                          international
                          value={this.state.currentUserNumber}
                          onChange={((value) => {
                            this.updateStateNumber(value);
                          })}
                          onBlur={(event) => this.handleNumberInput(event)}
                          name='currentUserNumber'
                        />
                        {
                          this.state.errors.currentUserNumberError.length > 0 &&
                          this.state.errors.currentUserNumberError !== 'default' &&
                          <span className='error'>{this.state.errors.currentUserNumberError}</span>
                        }
                      </div>
                    </div>
                    {
                      this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-12 line-input">
                        <label>Description de l'établissement*</label>
                        <div className="cnt-input">
                          <div className="cnt-input">
                            <input
                              type="text"
                              className="input-prenom"
                              onInput={this.maxLengthCheck}
                              maxLength={2000}
                              value={this.state.descriptionOnResult}
                              onChange={(event) => this.updateState(event)}
                              onBlur={(event) => this.handleUserInput(event)}
                              name="descriptionOnResult"
                            />
                            
                          </div>
                          {
                            this.state.errors.descriptionOnResult.length > 0 &&
                            this.state.errors.descriptionOnResult !== 'default' &&
                            <span className='error'>{this.state.errors.descriptionOnResult}</span>
                          }
                        </div>
                      </div>
                    }
                    {this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-6 line-input">
                        <label>Nombre de convives*</label>
                        <div className="cnt-input">
                          <input
                            type="text"
                            className="input-nom"
                            onInput={this.maxLengthCheck}
                            maxLength={5}
                            value={this.state.nombreConvives}
                            name="nombreConvives"
                            pattern="[0-9]*"
                            onChange={(event) => this.updateState(event)}
                            onBlur={(event) => this.handleUserInput(event)}
                          />
                          
                          {
                            this.state.errors.nombreConvives.length > 0 &&
                            this.state.errors.nombreConvives !== 'default' &&
                            <span className='error'>{this.state.errors.nombreConvives}</span>
                          }
                        </div>

                      </div>
                    }
                    {this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-6 line-input">
                        <label>Prix ​​moyen*</label>
                        <div className="cnt-input">
                          <input
                            type="text"
                            className="input-nom"
                            onInput={this.maxLengthCheck}
                            maxLength={6}
                            value={this.state.averagePrice}
                            name="averagePrice"

                            onChange={(event) => this.updateState(event)}
                            onBlur={(event) => this.handleUserInput(event)}
                          />
                          
                          {
                            this.state.errors.averagePrice.length > 0 &&
                            this.state.errors.averagePrice !== 'default' &&
                            <span className='error'>{this.state.errors.averagePrice}</span>
                          }
                        </div>
                      </div>
                    }

                    {this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-6 line-input">
                        <h2>Fourchette de prix</h2>
                        <div className="select-down select-institut">
                          <Dropdown
                            className="MyclassName"
                            options={(listRestaurantPriceRange && Array.isArray(listRestaurantPriceRange) && listRestaurantPriceRange.length > 0) ? listRestaurantPriceRange.map(i => i.label) : []}
                            onChange={(listRestaurantPriceRange && Array.isArray(listRestaurantPriceRange) && listRestaurantPriceRange.length > 0) ? (value) => { this.setState({ priceRange: listRestaurantPriceRange ? listRestaurantPriceRange.find(i => i.label === value.value).value : null }) } : console.log("")}
                            value={(listRestaurantPriceRange && Array.isArray(listRestaurantPriceRange) && listRestaurantPriceRange.length > 0 && this.state.priceRange) ? listRestaurantPriceRange.find(i => i.value === this.state.priceRange).label : ""}
                            controlClassName=''
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                    }
                    {this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-6 line-input">
                        <h2>Restriction de table</h2>
                        <div className="select-down select-institut">
                          <Dropdown
                            className="MyclassName"
                            options={(listRestrictionTable && Array.isArray(listRestrictionTable) && listRestrictionTable.length > 0) ? listRestrictionTable.map(i => i.translate) : []}
                            onChange={(value) => { this.setState({ restrictionTable: listRestrictionTable.find(i => i.translate === value.value).value }) }}
                            value={listRestrictionTable && this.state.restrictionTable !== null && listRestrictionTable.find(i => i.value === parseInt(this.state.restrictionTable)).translate}
                            controlClassName=''
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                    }
                    {/* <div className="col col-12 line-input">
                    <label>Autoriser les notifications par SMS ? </label>
                    <div className="btn-check">
                      <label className="check">
                        <input
                          type="radio"
                          checked={this.state.allowSmsNotif === 'true'}
                          name="allowSmsNotif"
                          onChange={(event) => this.updateState(event)}
                          value={true}
                        />
                        <span className="radiobtn"></span> Oui
                                    </label>
                      <label className="check">
                        <input
                          type="radio"
                          checked={this.state.allowSmsNotif === 'false'}
                          name="allowSmsNotif"
                          onChange={(event) => this.updateState(event)}
                          value={false}
                        />
                        <span className="radiobtn"></span> Non
                      </label>
                    </div>
                  </div> */}
                    <div className="col col-12 cnt-condition">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="check-all"
                          checked={this.state.isChecked}
                          onChange={(event) => this.toogleChange(event)}
                        />
                        <span className="check"></span>
                        <a
                          href="https://www.myconciergehotel.com/conditions-generales-de-service-mch/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >J'accepte les conditions de service
                        </a>
                      </label>
                      {
                        this.state.errorCheckRequired !== '' &&
                        <span className='error pt-1'>{this.state.errorCheckRequired}</span>
                      }
                    </div>
                    <div className="clr"></div>
                    {
                      this.state.providerOptionsValue && this.state.providerOptionsValue === "Restaurant" &&
                      <div className="col col-12 cnt-condition">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="check-all"
                            checked={this.state.isStripeConditionChecked}
                            onChange={(event) => this.toogleStripeChange(event)}
                          />
                          <span className="check"></span>
                          <button type="button" className="link-hotel-associe" onClick={() => this.showCondition()}>J'accepte les conditions de payement de stripe</button>

                          {/* <a href 
                          onClick={() => this.openModal("test","test")}
                        >J'accepte les conditions de payement de stripe
                        </a> */}
                        </label>
                        {
                          this.state.errorCheckStripeRequired !== '' &&
                          <span className='error pt-1'>{this.state.errorCheckStripeRequired}</span>
                        }
                      </div>
                    }
                    <div className="cnt-Btn cnt-btn-inscription">
                      <input
                        className={`button ${this.isNotVoid() ? '' : 'buttonIsInvalid'}`}
                        type="submit"
                        value="S'inscrire"
                        disabled={!this.isNotVoid()} 
                        onClick={(event) => this.isCgsChecked(event)} />
                      <div className="clr"></div>
                    </div>
                  </div>
                </form>
                {
                  this.state.providerOptionsValue && this.state.providerOptionsValue !== "Restaurant" && this.state.providerOptionsValue !== "Société" && this.state.codeHotel &&
                  <div className="list-prestation">
                    <h3>Les prestations indépendantes disponibles dans cet hôtel</h3>
                    <Slider {...settings} className="roomservice-list">
                    {
                      categories && categories.map(category =>{
                        return (
                          <div className="roomservice-item" key={category.id}>
                            <div className="roomservice-img">
                              {
                                (category.image) 
                                ? <img src={category.image} title={category.name} alt={category.name} className="roomserviceImg-img"/>
                                : ""
                              }
                            </div>
                            <div className="roomservice-title">{category.name}</div>
                          </div>
                        )
                      })
                    }
                    </Slider>                    
                  </div>
                }
                <div className="clr"></div>
              </div>
            </div>
            <div className="wrapper-modal">
              <Modal
                visible={this.state.visible}
                width="400"
                /* height="40%" */
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}
              >
                <div className="content-modal">
                  <div className="head-modal">
                    <button className="close-modal" type="button" onClick={() => this.closeModal()}><i className="material-icons icons-close">close</i></button>
                    <h1>{this.state.titleShow}</h1>
                  </div>
                  <div className="main-modal">
                    <p>{this.state.messageShow}</p>
                    <div className="cnt-Btn align-right">
                      {/* <Link className="btn-cancel" onClick={() => this.closeModal()}>OK</Link> */}
                      <button className="button" type="button" onClick={() => this.state.titleShow.includes("enregistrée") ? this.redirectSuccess() : this.setState({ isStripeConditionChecked: true, visible: false })}>Ok</button>
                      <div className="clr"></div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </LoadingOverlay>
      </div >
    )
  }
}
export default reduxConnect(Inscription);

import constants from '../constants/etablissement';
import serviceApp from '../../applicatif/etablissement';


export const getIdentityEtablissement = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getIdentityEtablissement()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_IDENTITÉ_ETABLISSEMENT,
        payload,
      })
    }
  }
}

export const updateIdentityEtablissement = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateIdentityEtablissement(data)
    callBack && callBack(payload,"update")
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.UPDATE_IDENTITÉ_ETABLISSEMENT,
        payload: data,
      })
    }
  }
}

export const getInfoEtablissement = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getInfoEtablissement()
    callBack && callBack(payload, "getInfoEtablissement")
    if (payload) {
      return dispatch({
        type: constants.GET_INFO_ETABLISSEMENT,
        payload,
      })
    }
  }
}

export const createEtablissement = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.createEtablissement(data)
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.CREATE_ETABLISSEMENT,
        payload,
      })
    }
  }
}


export const updateInfoEtablissement = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateInfoEtablissement(data)
    callBack && callBack(payload, "updateInfoEtablissement")
    if (payload === 200 || payload === 201 || payload === 204) {
      return dispatch({
        type: constants.UPDATE_INFO_ETABLISSEMENT,
        payload: data,
      })
    }
  }
}

export const getProgressionEtablissement = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getProgressionEtablissement()
    callBack && callBack(payload, "progression")
    if (payload) {
      return dispatch({
        type: constants.GET_PROGRESSION_ETABLISSEMENT,
        payload,
      })
    }
  }
}

export const getLegalInformation = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getLegalInformation()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_LEGAL_INFO,
        payload,
      })
    }
  }
}

export const sendLegalInformation = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.sendLegalInformation(data)
    callBack && callBack(payload,"update")
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.SEND_LEGAL_INFO,
        payload: data,
      })
    }
  }
}

export const getOpeningTime = (id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getOpeningTime(id)
    callBack && callBack(payload, "getOpeningTime")
    if (payload) {
      return dispatch({
        type: constants.GET_OPENING_TIME,
        payload,
      })
    }
  }
}

export const createOpeningTime = (data, id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.createOpeningTime(data, id)
    callBack && callBack(payload, "createOpeningTime")
    if (payload) {
      return dispatch({
        type: constants.CREATE_OPENING_TIME,
        payload,
      })
    }
  }
}

export const updateOpeningTime = (data, id, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateOpeningTime(data, id)
    callBack && callBack(payload, "updateOpeningTime");
    if (payload && (payload.status === 200 || payload.status === 201 || payload.status === 204)) {
      return dispatch({
        type: constants.UPDATE_OPENING_TIME,
        payload: data,
      })
    }
  }
}

export const removeOpeningTime = (id, idSeller, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.removeOpeningTime(id, idSeller);
    callBack && callBack(payload, "removeOpeningTime")
    if (payload && (payload.status === 204 || payload.status === 200)) {
      return dispatch({
        type: constants.REMOVE_OPENING_TIME,
        payload: id,
      })
    }
  }
}

import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import { getSellerType } from '../../../data/constants/seller.type';


class HotelController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      isLoading: false,
      queryPartner: {
        itemPerPage: 5,
        page: 1,
      },
      queryDmd1:{
        itemPerPage: 5,
        page: 1,
        status: 1,
      },
      queryDmd5: {
        itemPerPage: 5,
        page: 1,
        status: 5,
      },
    };
  }

  componentDidMount() {
    // this.setState({ isLoading: true })
    // this.getAllData()
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
  }

  getAllData = async () => {
    const date = new Date();
    const queryIncome = `?start=${moment(date).format('YYYY-MM-DD')}&end=${moment(date).format('YYYY-MM-DD')}`
    const sellerType = getSellerType(this.props.commons.infoLogin);
    if (sellerType !== 3) {
      await this.props.getHotelPartner(this.state.queryPartner, this.callBack);
      await this.props.getRequetePartenariat(this.state.queryDmd1,this.callBack);
      await this.props.getRequetePartenariat(this.state.queryDmd5,this.callBack);
      await this.props.getInfoDashboard(queryIncome, this.callBack);
    }
    await this.props.getAllActivityArea(this.callBack);
    await this.props.getSellerActivityArea(this.callBack);
    
    this.setState({ isLoading: false })
  }

  callBack = (data, type) => {
    
  }
  setLoadingTrue = () => {
    this.setState({ isLoading: true })
  }

  setLoadingFalse = () => {
    this.setState({ isLoading: false })
  }
  expandCollapse = (event, index, item) => {
    const element = document.getElementsByClassName('accordion-item')[index];
    if (element && element.lastChild.clientHeight === 0) {
      event && event.preventDefault();
      element.firstChild.click();
    }
  }
  renderDmdPartenariat = (tabId) => {
    this.setState({ isLoading: true })
    this.getAllData()
    if (tabId === "dmdPartenariat") {
      const { listPartnershipRequest_1, listPartnershipRequest_5 } = this.props.hotels;
      if (listPartnershipRequest_1 && "total" in listPartnershipRequest_1 && listPartnershipRequest_1.total > 0) {
        this.expandCollapse(null, 1)
      }
      if (listPartnershipRequest_5 && "total" in listPartnershipRequest_5 && listPartnershipRequest_5.total > 0) {
        this.expandCollapse(null, 0)
      }
    }
  }
}

export default HotelController;

//
// Redux connection
//

HotelController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/etablissement';

const initialSate = {
  infoIdentiteEtablissement: {},
  infoEtablissement: {},
  infoProgressionEtablissement: {},
  legalInfoEtablissement: {
    numSiret: "",
    numTva: "",
    status: "",
    url: "",
  },
  listOpeningTime: [],
  listSellerActivityArea: [],
}
const etablissement = (state = initialSate, action) => {
  switch (action.type) {
    case constants.GET_IDENTITÉ_ETABLISSEMENT:
      return {
        ...state,
        infoIdentiteEtablissement: action.payload,
      }
    case constants.UPDATE_IDENTITÉ_ETABLISSEMENT:
      return {
        ...state,
        infoIdentiteEtablissement: action.payload,
      }
    case constants.GET_INFO_ETABLISSEMENT:
      return {
        ...state,
        infoEtablissement: action.payload,
      }
    case constants.CREATE_ETABLISSEMENT:
      return {
        ...state,
        infoEtablissement: action.payload,
      }
    case constants.GET_LEGAL_INFO:
      return {
        ...state,
        legalInfoEtablissement: action.payload,
      }
    case constants.GET_PROGRESSION_ETABLISSEMENT:
      return {
        ...state,
        infoProgressionEtablissement: action.payload,
      }
    case constants.UPDATE_INFO_ETABLISSEMENT:
      
      return {
        ...state,
        infoEtablissement: action.payload,
      }
    case constants.SEND_LEGAL_INFO:
      return {
        ...state,
        legalInfoEtablissement: action.payload,
      }
    case constants.GET_OPENING_TIME:
      return {
        ...state,
        listOpeningTime: action.payload,
      }
    case constants.CREATE_OPENING_TIME:
      return {
        ...state,
        listOpeningTime: [...state.listOpeningTime, action.payload.data],
      }
    case constants.UPDATE_OPENING_TIME:
      const response = action.payload;
      const data = {
        dayEnd: response.day_end,
        dayStart: response.day_start,
        id: response.id,
        timeEnd: response.timeEnd,
        timeStart: response.timeStart,
      }
      return {
        ...state,
        listOpeningTime: [...state.listOpeningTime.map(i => {
          if (i.id === data.id) {
            return data
          }
          else {
            return i
          }
        })]
      }
    case constants.REMOVE_OPENING_TIME:
      return {
        ...state,
        listOpeningTime: [...state.listOpeningTime.filter(i => i.id !== action.payload)]
      }
    case constants.LOGOUT:
      return initialSate;

    default:
      return state;
  }

}

export default etablissement;
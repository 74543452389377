import etablissementBdl from '../bdl/etablissement';

export default {
    getIdentityEtablissement: () => etablissementBdl.getIdentityEtablissement(),
    updateIdentityEtablissement: (data) => etablissementBdl.updateIdentityEtablissement(data),
    getInfoEtablissement: () => etablissementBdl.getInfoEtablissement(),
    updateInfoEtablissement: (data) => etablissementBdl.updateInfoEtablissement(data),
    createEtablissement: (data) => etablissementBdl.createEtablissement(data),
    getProgressionEtablissement: () => etablissementBdl.getProgressionEtablissement(),
    getLegalInformation: () => etablissementBdl.getLegalInformation(),
    sendLegalInformation: (data) => etablissementBdl.sendLegalInformation(data),
    getOpeningTime: (id) => etablissementBdl.getOpeningTime(id),
    createOpeningTime: (data,id) => etablissementBdl.createOpeningTime(data,id),
    updateOpeningTime: (data,id) => etablissementBdl.updateOpeningTime(data,id),
    removeOpeningTime: (id,idSeller) => etablissementBdl.removeOpeningTime(id,idSeller),


}
import React from 'react';
import { Link } from 'react-router-dom';
import Rating from 'react-rating';
import LoadingOverlay from 'react-loading-overlay';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';


import DashboardController, { reduxConnect } from './controller';
// import '../../../index.css';
import 'react-dropdown/style.css';
import InstitutImg from './img/avatar.png';

import SelectionDate from './../../components/dashboard/selectionDate';
import ListProduits from './../../components/dashboard/listProduits';
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import PopupHandler from "./../../components/popup/popupHandler";



const localizer = momentLocalizer(moment);
class Dashboard extends DashboardController {

  render() {
    const { bankInfoFile, bankInfo } = this.props.bank;
    const { infoSellerIndependant } = this.props.sellers;
    const { infoEtablissement, listOpeningTime } = this.props.etablissement;
    const { infoAccountSeller } = this.props.sellers
    const { listAllEvent } = this.props.agenda;
    const { infoLogin } = this.props.commons;
    const { infoDashboardAll, infoDashboard} = this.props.dashboard;
    const nbOrderPending = (infoDashboardAll && infoDashboardAll.data && parseInt(infoDashboardAll.data.nbOrderPending)) || 0
    const isIndependant = (infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT'));
    const isAgency = (infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_AGENCE'));
    const isRestaurant =infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_RESTAURENT');
    const { infoProgressionEtablissement } = this.props.etablissement
    const jour = [
      { id: 1, label: 'Lundi' },
      { id: 2, label: 'Mardi' },
      { id: 3, label: 'Mercredi' },
      { id: 4, label: 'jeudi' },
      { id: 5, label: 'Vendredi' },
      { id: 6, label: 'Samedi' },
      { id: 7, label: 'Dimanche' },
    ];
    return (

      <div className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Tableau de bord</h1>
            </div>
          </div>
          {
            !this.getStep5Finished() && !this.getHeadbangClosed() && !this.getStepsLoading() &&
            <div className={`bandeaux-step ${this.getStep1Finished() ? 'started': ''}`}> {/* ajouter started si l'étape 1 est terminé, remplacer par completed si c'est complété à 100% */}
              <div className="wrapper position-static">
                <h2>Suivre mon inscription</h2>
                <ul className={`bandeauxStep-list${isAgency ? ' agency' : ''}`}>
                  <li className={`bandeauxStep-item ${this.getStep1Finished() ? 'step-completed': 'to-complete'}`}> {/* remplacer to-complete par step-completed si terminé */}
                    <span onClick={this.goToStep1} className="bandeauxStep-label clickable">Données de l’établissement</span>                    
                    <span onClick={this.goToStep1} className="bandeauxStep-number clickable"><span>1</span></span>
                  </li>
                  <li className={`bandeauxStep-item ${this.getStep1Finished() ? (this.getStep2Finished() ? 'step-completed' : 'to-complete'): ''}`}> {/* si précédent terminé ajouter to-complete */}
                    <span onClick={this.goToStep2} className="bandeauxStep-label clickable">Ma boutique</span>                     
                    <span onClick={this.goToStep2} className="bandeauxStep-number clickable"><span>2</span></span>
                  </li>
                  <li className={`bandeauxStep-item ${this.getStep2Finished() ? (this.getStep3Finished() ? 'step-completed' : 'to-complete'): ''}`}>
                    <span onClick={this.goToStep3} className="bandeauxStep-label clickable">Informations légales et bancaires</span>                     
                    <span onClick={this.goToStep3} className="bandeauxStep-number clickable"><span>3</span></span>
                  </li>
                  {
                    !isAgency &&
                    <React.Fragment>
                      <li className={`bandeauxStep-item ${this.getStep3Finished() ? (this.getStep4Finished() ? 'step-completed' : 'to-complete'): ''}`}>
                        <span onClick={this.goToStep4} className="bandeauxStep-label clickable">Mes produits</span>                     
                        <span onClick={this.goToStep4} className="bandeauxStep-number clickable"><span>4</span></span>
                      </li>
                    
                      <li className={`bandeauxStep-item ${isAgency ? (this.getStep3Finished() && 'to-complete') || '' : (this.getStep4Finished() && 'to-complete') || ''}`}>
                        <span onClick={this.goToStep5} className="bandeauxStep-label clickable">Partenariats</span>                     
                        <span onClick={this.goToStep5} className="bandeauxStep-number clickable"><span>{isAgency ? 4 : 5}</span></span>
                      </li>
                    </React.Fragment>
                  }
                </ul>
              </div>
              <div className="bandeaux-progress"></div>
            </div>
          }
          {
            this.getStep5Finished() && !this.getHeadbangClosed() && !this.getStepsLoading() &&
            <div className="bandeaux-step completed">
              <div className="wrapper position-static">
                <div className="text-completed"> 
                  <h1>Félicitations, votre inscription est terminée</h1>
                  <span className="material-icons bandeaux-close" onClick={this.hideCongratulation}>close</span>
                </div>
              </div>
            </div>
          }
          
          <div className={`top-main ${isAgency ? 'agency': ''}`}>
            <div className="item">
              <div className="itemTop">
                <span className="titleItem">Mes revenus</span>
                <span className="price">{this.state.income ? parseFloat(this.state.income).toFixed(2) : 0}€</span>
                <SelectionDate
                  infoDashboard={infoDashboard} 
                  onDateSelected = {this.onDateSelected}
                />
              </div>
            </div>
            {
              !isAgency &&
              <React.Fragment>
                <div className="item">
                  <div className="itemTop">
                    <span className="titleItem">Prestations de mon établissement</span>
                  </div>
                  <div className="infosDash"><span className="nbr">{this.state.prestation ? this.state.prestation : 0}</span><Link to="/etablissement-institut/Prestations" className="linkplus">Voir plus</Link></div>
                </div>
                <div className="item">
                  <div className="itemTop">
                    <span className="titleItem">Hôtels partenaires</span>
                  </div>
                  <div className="infosDash"><span className="nbr">{this.state.hotel ? this.state.hotel : 0}</span><Link to="/hotel/hotelPartenaires" className="linkplus">Voir plus</Link></div>
                </div>
                <div className="item">
                  <div className="itemTop">
                    <span className="titleItem">Demandes de <br />partenariat</span>
                  </div>
                  <div className="infosDash"><span className="nbr">{this.state.partenershipRequest ? this.state.partenershipRequest : 0}</span><Link to="/hotel/dmdPartenariat" className="linkplus">Voir plus</Link></div>
                </div>
              </React.Fragment>
            }
            <div className="item">
              <div className="itemTop">
                <span className="titleItem">Appréciations</span>
                <div className="ratings">
                  <Rating
                    readonly
                    placeholderRating={this.state.review && this.state.review.value ? this.state.review.value : 0}
                    emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune star-empty">star</i></span>}
                    placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune">star</i></span>}
                  />
                </div>
              </div>
              <div className="infosDash"><span className="nbr">{this.state.review && this.state.review.nombre ? this.state.review.nombre : 0} avis</span>
                {/* <Link to="/etablissement-institut/Etablissement" className="linkplus">Voir plus</Link> */}
                <Link to={{ pathname: '/etablissement-institut/Etablissement', state: { defaultOpen: [2] } }} className="linkplus">Voir plus</Link>

              </div>
            </div>
          </div>


          {/* {((this.state.identityFile && this.state.identityFile === 'ko') || (this.state.kbisFile && this.state.kbisFile === 'ko')) && */}
          {/* {((bankInfoFile && bankInfoFile.identityFile && bankInfoFile.identityFile === 'ko') || (bankInfoFile && bankInfoFile.kbisFile && bankInfoFile.kbisFile === 'ko') || (bankInfoFile && Object.entries(bankInfoFile).length === 0)) && */}
          {
            (infoProgressionEtablissement.stateOfProgress !== 100 || (!isRestaurant && (!bankInfo || !bankInfo.identityFile || !bankInfo.kbisFile || bankInfo.status !== "verified"))) &&
            <div className="infosError">
              <div className="wrapper clr">
                <span>
                  <span className="dashicons dashicons-star-filled">
                    <i className="material-icons arrow-down">error</i>
                  </span>Des informations sont manquantes/incorrectes pour la vérification de votre profil.
                    </span>
                <Link to={{ pathname: '/gestionadministration', state: { defaultOpen: isIndependant ? [2] : [3],  activeTab: isIndependant ? 2 : 3 } }}>Voir</Link>
              </div>
            </div>
          }
          <div>
            {this.state.uploadError && PopupHandler.showError("L'upload a échoué.")}
            {this.state.uploadError === false && PopupHandler.showSuccess("Succès de l'upload.")}
          </div>
          <div className="main main-dashboard">
            <div className="wrapper clr">
              {
                this.getHeadbangClosed() && !this.getStepsLoading() &&
                <div className="complement">
                  <h2>Complétez votre profil <span>- {this.state.stateOfProgress ? this.state.stateOfProgress : 0}% complété</span></h2>
                  <div className="block-import">
                    {
                      bankInfoFile.identityFile && bankInfoFile.identityFile === 'ko' && (<div className="item-import" onClick={this.uploadFileIdentity}>
                        <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-perm_identity">perm_identity</i></span>Importer un justificatif d’identité
                        <input
                          type="file"
                          hidden
                          id="fileIdentity"
                          style={{ display: "none" }}
                          onChange={(event) => this.sendIdentityFile(event)}
                          name="fileIdentity"
                        />
                      </div>)
                    }
                    {
                      bankInfoFile.kbisFile && bankInfoFile.kbisFile === 'ko' && (<div className="item-import" onClick={this.uploadFileKbis}>
                        <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-description">description</i></span>Importer une photocopie de votre Kbis
                        <input
                          type="file"
                          hidden
                          id="fileKbis"
                          onChange={(event) => this.sendKbisFile(event)}
                          style={{ display: "none" }}
                          name="fileKbis"
                        />
                      </div>)
                    }

                  </div>
                  <div className="cnt-Btn">
                    <Link to="/gestionadministration" className="button">Voir mon profil</Link>
                  </div>
                </div>
              }
              <div className="row detail-commande">
                <div className="col col-6">
                  <h2>{`Vous ${nbOrderPending ? "" : "n'"}avez ${nbOrderPending ? nbOrderPending:  "aucune"} commande(s) en attente`}</h2>
                  <div className="cnt-Btn">
                    <Link to={{pathname: '/commande', state: {status:'pending' , direction:'asc'}}} className="button">Voir mes commandes</Link>
                  </div>
                </div>
                <div className="col col-6">
                  <h2>Une indisponibilité prochainement ?</h2>
                  <div className="cnt-Btn">
                    <Link to="/mon-agenda" className="button">Notifier dans mon agenda</Link>
                  </div>
                </div>
              </div>
              <div className="row mainBody">
                <div className="col col-3">
                  <h2>{
                    isIndependant ?
                      "Ma fiche" : "Mon établissement"}</h2>
                  <div className="det-etablissement">
                    <div className="img-etablissement">
                      {
                        isIndependant ?
                          <img src={infoSellerIndependant && Object.entries(infoSellerIndependant).length !== 0  && infoSellerIndependant.logo && infoSellerIndependant.logo.data
                            ?
                            (infoSellerIndependant.logo.data.includes("data:image/"))
                              ?
                              infoSellerIndependant.logo.data
                              : `data:image/jpeg;base64,${infoSellerIndependant && infoSellerIndependant.logo && infoSellerIndependant.logo.data}`
                            :
                            infoSellerIndependant && Object.entries(infoSellerIndependant).length !== 0 && infoSellerIndependant.logo && infoSellerIndependant.logo.url
                              ?
                              infoSellerIndependant.logo.url
                              : InstitutImg
                          } alt="Institut" />
                          :
                          <img src={infoEtablissement.logo && infoEtablissement.logo.data ? (infoEtablissement.logo.data.includes("data:image/")) ? infoEtablissement.logo.data : `data:image/jpeg;base64,${infoEtablissement && infoEtablissement.logo && infoEtablissement.logo.data}` : infoEtablissement && infoEtablissement.logo && infoEtablissement.logo.url ? infoEtablissement.logo.url : InstitutImg} alt="Institut" />
                      }
                    </div>
                    <div className="infos-etablissement">
                      <h3 className="title-et">{
                        isIndependant ? infoSellerIndependant && infoSellerIndependant.firstname ? `${infoSellerIndependant.firstname} ${infoSellerIndependant.lastname}` : "" : infoEtablissement && infoEtablissement.name ? infoEtablissement.name : ""} </h3>
                      {/* <p>Institut beauté</p> */}
                      <p>
                        {
                          isIndependant ? "" : infoEtablissement && infoEtablissement.address ? `${infoEtablissement.address.streetAddress}, ${infoEtablissement.address.zipCode} ${infoEtablissement.address.city}` : ""
                        }
                      </p>
                    </div>
                  </div>
                  <div className="contact-etablissement">
                    <p>
                      {infoAccountSeller && infoAccountSeller.email} <br />{infoAccountSeller && infoAccountSeller.phone}</p>
                  </div>
                  {
                    isIndependant ?
                      ""
                      :
                      (listOpeningTime && listOpeningTime.length > 0 &&
                        <div className="horaire-etablissement">
                          <p className="title-horaire">Horaires d’ouverture</p>
                          {listOpeningTime && listOpeningTime.length > 0 && listOpeningTime.map(item =>
                            <p key={`${item.id}-opening`}> {`Du ${jour.find(i => { return i.id.toString() === item.dayStart.toString() }).label} au ${jour.find(i => { return i.id.toString() === item.dayEnd.toString() }).label} de ${item.timeStart} a ${item.timeEnd}`}</p>)
                          }

                          {/* <p>Du mercredi au dimanche de 12:00 à 14:00 et de 19:00 à 23:00.</p> */}
                        </div>
                      )
                  }

                  <div className="cnt-Btn">
                    <Link to="/etablissement-institut/Etablissement" className="button">Modifier</Link>
                  </div>
                </div>
                <div className="col col-6">
                  <h2>Mes revenus</h2>
                  <div className="cnt-graph">
                    <div id="chartdiv" style={{ width: "100%", height: "300px" }}></div>
                  </div>
                  {/* <div className="cnt-Btn align-right">
                    <Link to="#" className="button">Voir mes revenus</Link>
                  </div> */}
                </div>
                <div className="col col-3">
                  <h2>Mon agenda</h2>
                  <div className="cnt-agenda">
                    <div>
                      <Calendar
                        localizer={localizer}
                        events={listAllEvent.map(i => {
                          i.start = moment(i.start).format('YYYY-MM-DD');
                          i.end = moment(i.end).format('YYYY-MM-DD');
                          return i
                        })}
                        views={['month']}
                        style={{ height: 240 }}
                      />
                    </div>
                  </div>
                  <div className="cnt-Btn align-right">
                    <Link to="/mon-agenda" className="button">Voir mon agenda</Link>
                  </div>
                </div>
              </div>
              {
                infoLogin && infoLogin.roles && !infoLogin.roles.includes('ROLE_SELLER_AGENCE') &&
                <div className="cnt-produit">
                  <h2>Mes produits</h2>
                  <ListProduits listProduit={this.state.listPrestation && this.state.listPrestation.data ? this.state.listPrestation.data : []} onchange={this.onChange} />
                  <div className="cnt-Btn align-right">
                    <Link to="/etablissement-institut/Prestations" className="button">Voir mes produits</Link>
                    <div className="clr"></div>
                  </div>
                </div>
              }
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(Dashboard);

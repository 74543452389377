import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class ResultatRecherche extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
}

export default ResultatRecherche;

ResultatRecherche.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
import constants from '../constants/commons';

const initialSate = {
	listCategories: [],
	allKeywords: [],
	token : '',
	infoLogin : [],
	hotelCategories: [],
	allDeliveryPlaces: [],
	expiresDate: false,
}
const  copyArray= (array)=>{
	if (!array || array.length === 0) {
		return []
	}
	return JSON.parse(JSON.stringify(array)); 
}

const commons = (state = initialSate, action) => {
	switch (action.type) {
		case constants.GET_CATEGORIES:
			return {
				...state,
				listCategories: action.payload,
			}
		case constants.GET_HOTEL_CATEGORIES:
			return {
				...state,
				hotelCategories: (action.payload && action.payload.data) || [],
			}
		case constants.GET_KEYWORDS:
			return {
				...state,
				allKeywords: action.payload,
			}
		case constants.GET_TOKEN:
			return {
				...state,
				infoLogin : action.payload
			}
		case constants.GET_ALL_DELIVERY_PLACES:
			return {
				...state,
				allDeliveryPlaces : action.payload
			}
		case constants.ADD_DELIVERY_PLACE:
			const id = Object.keys(action.payload)[0];
			const allDeliveryPlaces = copyArray(state.allDeliveryPlaces).map(deliveryPlace =>{
				deliveryPlace.type_traject && deliveryPlace.type_traject.length > 0 && deliveryPlace.type_traject.forEach(typeTraject=>{
					typeTraject && typeTraject.traject && typeTraject.traject.length > 0 &&  typeTraject.traject.forEach(traject=>{
						if (parseInt(traject.place_id) === parseInt(id)) {
							traject.id_service = action.payload[id];
							traject.added = 1;
						}
					})
				})
				return deliveryPlace;
			})
			return {
				...state,
				allDeliveryPlaces
			}
		case constants.REMOVE_DELIVERY_PLACE:
			const idRemoved = action.payload;
			const deliveryPlaces = copyArray(state.allDeliveryPlaces).map(deliveryPlace =>{
				deliveryPlace.type_traject && deliveryPlace.type_traject.length > 0 && deliveryPlace.type_traject.forEach(typeTraject=>{
					typeTraject && typeTraject.traject && typeTraject.traject.length > 0 &&  typeTraject.traject.forEach(traject=>{
						if (parseInt(traject.place_id) === parseInt(idRemoved)) {
							traject.id_service = null;
							traject.added = 0;
						}
					})
				})
				return deliveryPlace;
			})
			return {
				...state,
				allDeliveryPlaces: deliveryPlaces
			}
		case constants.LOGOUT :     
			return initialSate;
		case constants.EXPIRES_AT:
			return {
				...state,
				expiresDate: action.payload,
			};

		default:
			return state;
	}

}

export default commons;
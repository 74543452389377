import { get, put, post } from '.';
import { url } from '../redux/constants/sellers'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;
}

const testVoidResponse = (response) => {
  const data  = (response && response.status) ? response : (response && response.response) || null;
  return data;
}

// const testResponseData = (response) => {
//     if (!response || !response.data || !response.data.data) return false;
//     return response.data.data;
// }

export default {
  inscription: async (data) => {
    const response = await post(url.sellerRegister, data);
    return response
  },

  getSellerContact: async () => {
    const response = await get(url.sellerContact);
    return testResponse(response)
  },

  updateSellerContact: async (data) => {
    const response = await put(url.sellerContact, data)
    return testVoidResponse(response)
  },

  getSellerInfoAccount: async () => {
    const response = await get(url.sellerAccount);
    return testResponse(response)
  },

  updateSellerInfoAccount: async (data) => {
    const response = await put(url.sellerAccount, data)
    return testVoidResponse(response)
  },
  getSellerId: async () => {
    const response = await get(url.sellerId);
    return testResponse(response)
  },
  getDataGraphe: async () => {
    const response = await get(url.graphe);
    return testResponse(response)
  },
  getSellerInfoIndependant: async () => {
    const response = await get(url.sellerIndependant);
    return testResponse(response)
  },

  updateSellerInfoIndependant: async (data) => {
    const response = await put(url.sellerIndependant, data)
    return testVoidResponse(response)
  },

  getSellerAgence: async () => {
    const response = await get(url.sellerAgence);
    return testResponse(response)
  },

  getRestaurantPriceRange: async () => {
    const response = await get(url.restaurantPriceRange);
    return testResponse(response)
  },
  getRestaurantRestrictionTable: async () => {
    const response = await get(url.restaurantRestrictionTable);
    return testResponse(response)
  },
  getAgenceSellers: async () => {
    const response = await get(url.agenceSellers);
    return testResponse(response)
  },

}
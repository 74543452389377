import hotelsBdl from '../bdl/hotels';

export default {
    getHotelPartner: (data) => hotelsBdl.getHotelPartner(data),
    getRequetePartenariat: (data) => hotelsBdl.getRequetePartenariat(data),
    getContactHotel : () => hotelsBdl.getContactHotel(),
    UpdateContactHotel : (data) => hotelsBdl.UpdateContactHotel(data),
    getAllHotel : (data) => hotelsBdl.getAllHotel(data),
    requestPartenership : (data) => hotelsBdl.requestPartenership(data),
    acceptPartenership : (data,id) => hotelsBdl.acceptPartenership(data,id),
    endPartenership : (id) => hotelsBdl.endPartenership(id),
    denyPartenership : (data) => hotelsBdl.denyPartenership(data),
    getHotelFilter : (data) => hotelsBdl.getHotelFilter(data),
    getDefaultCommission : (codeHotel) => hotelsBdl.getDefaultCommission(codeHotel),
    getPricePerCuterly : (codeHotel) => hotelsBdl.getPricePerCuterly(codeHotel),

}

import constants from '../constants/sellers';
import serviceApp from '../../applicatif/sellers';

export const inscription = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.inscription(data)
    if (payload) {
      callBack && callBack(payload, "inscription")
    }
  }
}

export const getSellerContact = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerContact()
    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_SELLER_CONTACT,
        payload,
      })
    }
  }
}

export const updateSellerContact = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateSellerContact(data)
    callBack && callBack(payload, "update")
    if (payload === 200 || payload === 201 || payload === 204) {
      return dispatch({
        type: constants.UPDATE_SELLER_CONTACT,
        payload : data,
      })
    }
  }
}

export const getSellerInfoAccount = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerInfoAccount()
    callBack && callBack(payload)

    return dispatch({
      type: constants.GET_SELLER_INFO_ACCOUNT,
      payload,
    })
  }
}

export const updateSellerInfoAccount = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateSellerInfoAccount(data)
    callBack && callBack(payload, 'updateSellerInfoAccount')
    if (payload) {
      return dispatch({
        type: constants.UPDATE_SELLER_INFO_ACCOUNT,
        payload,
      })
    }
  }
}

export const getSellerId = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerId()
    callBack && callBack(payload)
    if (payload) {
      localStorage.setItem("idSeller", payload.id);
      return dispatch({
        type: constants.GET_ID_SELLER,
        payload,
      })
    }
  }
}

export const getDataGraphe = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getDataGraphe()
    // const dataGraphe = [
    // 	{ category: "Janvier", value: 0 },
    // 	{ category: "Février", value: 0 },
    // 	{ category: "Mars", value: 0 },
    // 	{ category: "Avril", value: 0 },
    // 	{ category: "Mai", value: 0 },
    // 	{ category: "Juin", value: 1000 },
    // 	{ category: "Juillet", value: 0 },
    // 	{ category: "Aout", value: 0 },
    // 	{ category: "Septembre", value: 0 },
    // 	{ category: "Octobre", value: 0 },
    // 	{ category: "Novembre", value: 0 },
    // 	{ category: "Décembre", value: 0 },]

    callBack && callBack(payload)
    if (payload) {
      return dispatch({
        type: constants.GET_DATA_GRAPHE,
        payload,
      })
    }
  }
}

export const getSellerInfoIndependant = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerInfoIndependant()
    callBack && callBack(payload)

    return dispatch({
      type: constants.GET_SELLER_INFO_INDEPENDANT,
      payload,
    })
  }
}

export const updateSellerInfoIndependant = (data, callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.updateSellerInfoIndependant(data);
    callBack && callBack(payload, 'updateSellerInfoIndependant')
    if (payload) {
      return dispatch({
        type: constants.UPDATE_SELLER_INFO_INDEPENDANT,
        payload,
      })
    }
  }
}

export const getSellerAgence = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getSellerAgence()
    callBack && callBack(payload, "getAgence")
    if (payload) {
      return dispatch({
        type: constants.GET_SELLER_AGENCE,
        payload,
      })
    }
    else {
      return dispatch({
        type: constants.GET_SELLER_AGENCE,
        payload: [],
      })
    }
  }
}

export const getRestaurantPriceRange = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getRestaurantPriceRange()
    callBack && callBack(payload, "getPriceRange")
    if (payload) {
      return dispatch({
        type: constants.GET_RESTAURANT_PRICE_RANGE,
        payload,
      })
    }
  }
}

export const getRestaurantRestrictionTable = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getRestaurantRestrictionTable()
    callBack && callBack(payload, "getRestrictionTable")
    if (payload) {
      return dispatch({
        type: constants.GET_RESTAURANT_RESTRICTION_TABLE,
        payload,
      })
    }
  }
}

export const getAgenceSellers = (callBack) => {
  return async (dispatch) => {
    const payload = await serviceApp.getAgenceSellers()
    callBack && callBack(payload, "getAgenceSellers")
    if (payload) {
      return dispatch({
        type: constants.GET_AGENCE_SELLERS,
        payload,
      })
    }
  }
}
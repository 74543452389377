import constants from '../constants/activityArea';

const initialSate = {
  listAllActivityArea: [],
  listSellerActivityArea: [],
}
const activityArea = (state = initialSate, action) => {
  switch (action.type) {
    case constants.RECEIVE_ALL_ACTIVITY_AREA:
      return {
        ...state,
        listAllActivityArea: action.payload,
      }
    case constants.RECEIVE_ACTIVITY_AREA:
      return {
        ...state,
        listSellerActivityArea: action.payload,
      }
    case constants.ADD_ACTIVITY_AREA:
      return {
        ...state,
        listSellerActivityArea: [...state.listSellerActivityArea, action.payload]
      }
    case constants.DELETE_ACTIVITY_AREA:
      return {
        ...state,
        listSellerActivityArea: [...state.listSellerActivityArea.filter(i => i.zoneId !== action.payload)]
      }
    case constants.LOGOUT:
      return initialSate;
    default:
      return state;
  }

}

export default activityArea;
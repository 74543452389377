import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import { REDIRECT_LOGIN } from '../../../services/redux/constants/commons';
import queryString from 'query-string';


class InscriptionController extends Component {

  constructor(props) {
    super(props);
    const parametersValues = queryString.parse(props.location.search);
    const isInvitation = parametersValues && parametersValues.email;
    this.pageRef = createRef();
    this.state = {
      providerOptions: ['Agence', 'Société', 'Indépendant', 'Restaurant'],
      providerOptionsValue: "Agence",
      textProvider: "",
      userName: "",
      userFirstName: "",
      password: '',
      userEmail: isInvitation ? parametersValues.email : "",
      allowSmsNotif: false,
      isChecked: false,
      currentCountry: "FR",
      currentUserNumber: "",
      currentUserNumberError: "default",
      errors: {
        userProvider: '',
        userName: 'default',
        userFirstName: 'default',
        userEmail: isInvitation ? '' : 'default',
        currentUserNumber: 'default',
        currentUserNumberError: 'default',
        descriptionOnResult: 'default',
        averagePrice: 'default',
        nombreConvives: 'default',
        priceRange: 'default',
        establishmentName: 'default',
      },
      errorCheckRequired: '',
      errorCheckStripeRequired: '',

      visible: false,
      agence: null,
      agenceName: "",
      titleShow: "",
      messageShow: "",
      priceRange: null,
      isStripeConditionChecked: false,
      restrictionTable: null,
      descriptionOnResult: "",
      nombreConvives: "",
      averagePrice: "",
      parametersValues,
      isInvitation,
      codeHotel: ( parametersValues && parametersValues.codeHotel) || "",
    };
  }

  componentDidMount (){
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    if (this.state.codeHotel) {
      this.setState({ isLoading: true });
      this.props.getHotelCategories(this.state.codeHotel, this.callBack)
    }
  }

  openModal = (title, message) => {
    this.setState({
      visible: true,
      titleShow: title,
      messageShow: message,
    });
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
  }
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  showCondition = () => {
    this.setState({
      visible: true,
      titleShow: "Condition",
      messageShow: "En fournissant votre IBAN et en confirmant un paiement, vous autorisez (A) Rocketship Inc et Stripe, notre fournisseur de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque de débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être réclamé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité."
    })

  }

  isNotVoid = () => {
    if (this.state.providerOptionsValue === "Restaurant") {
      return this.state.errors.userProvider === ""
        && this.state.errors.establishmentName === ""
        && this.state.errors.userName === ""
        && this.state.errors.userFirstName === ""
        && this.state.errors.userEmail === ""
        && this.state.errors.currentUserNumberError === ""
        && this.state.priceRange !== null
        && this.state.restrictionTable !== null
        && this.state.descriptionOnResult !== ""
        && this.state.nombreConvives !== ""
        && this.state.averagePrice !== ""
    }
    else {
      return this.state.errors.userProvider === ""
        && (this.state.providerOptionsValue === "Indépendant" || this.state.errors.establishmentName === "")
        && this.state.errors.userName === ""
        && this.state.errors.userFirstName === ""
        && this.state.errors.userEmail === ""
        && this.state.errors.currentUserNumberError === ""
    }
  }

  isCgsChecked = (event) => {
    if (this.state.providerOptionsValue === "Restaurant") {
      this.isCgsRequired(event);
      this.isStripeRequired(event);
    }else{
      this.isCgsRequired(event);
    }
  }

  isCgsRequired = (event) => {
    if (!this.state.isChecked) {
      this.setState({ errorCheckRequired: "Veuillez cocher pour continuer" });
      event.preventDefault();
    }else{
      this.setState({ errorCheckRequired: '' });
    }
  }

  isStripeRequired = (event) => {
    if (!this.state.isStripeConditionChecked) {
      this.setState({ errorCheckStripeRequired: "Veuillez cocher pour continuer" });
      event.preventDefault();
    }else{
      this.setState({ errorCheckStripeRequired: '' });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.isNotVoid()) {
      this.setState({ isLoading: true })
      const { parametersValues } = this.state;
      const { userName, providerOptionsValue, currentUserNumber, allowSmsNotif, userEmail, userFirstName, establishmentName } = this.state;
      const data = {
        establishment_name: establishmentName,
        email: userEmail,
        type: parseInt(this.renderSwitch(providerOptionsValue)),
        firstName: userFirstName,
        lastName: userName,
        phone: currentUserNumber,
        smsAuthorization: allowSmsNotif === 'true' ? true : false,
        password: "Azeérty6ç&10",
        agence: this.state.agence ? parseInt(this.state.agence) : null,
        codeHotel: parametersValues && parametersValues.codeHotel ? parametersValues.codeHotel : null,
        key :  parametersValues && parametersValues.key ? parametersValues.key : null
      }
      if (providerOptionsValue === "Restaurant" && this.state.priceRange !== null) {
        data.priceRange = this.state.priceRange
        data.dateMandate = new Date();
        data.nombreConvives = parseInt(this.state.nombreConvives);
        data.restrictionTable = this.state.restrictionTable;
        data.averagePrice = parseFloat(this.state.averagePrice)
        data.descriptionOnResult = this.state.descriptionOnResult;

      }
      this.props.inscription(data, this.callBack);
    }
  }

  callBack = (data, type) => {
    this.setState({ isLoading: false });
    if (type && type === "getAgence") {

    }else if (type && type === "inscription") {
      if (data && data.error && data.error.response && data.error.response.data && data.error.response.data.message) {
        let message = data.error.response.data.message === "The email address is already in use by another account." ? "L'adresse e-mail est déjà utilisée par un autre compte." : data.error.response.data.message;
        this.openModal("Erreur lors de l'inscription", message)
      }else if (data && data.error && data.error.toString().includes('code 400')) {
        this.openModal("Erreur lors de l'inscription", data.error.response.data.detail)
      }
      else if (data && data.error && data.error.toString().includes('Network Error'))
        this.openModal("Problème de connexion", "Vérifier la connectivité internet")
      else if ((data && data.status && data.status === 201) || (data && data.status && data.status === 200) || (data && data.status && data.status === 204)) {
        this.openModal("Inscription enregistrée", "Vous allez recevoir un mail afin de créer votre mot de passe")
      }
    }
  }
  redirectSuccess = () => {
    const { history } = this.props;
    if (history) history.push(REDIRECT_LOGIN);
    window.location.href = REDIRECT_LOGIN;
  }

  renderSwitch = (param) => {
    switch (param) {
      case 'Agence':
        this.setState({ textProvider: "Texte pour agence" })
        return 3;
      case 'Société':
        this.setState({ textProvider: "Texte pour société" })
        return 2;
      case 'Restaurant':
        this.setState({ textProvider: "Texte pour restaurant" })
        return 5;
      case 'Indépendant':
        this.setState({ textProvider: "Texte pour independant", })
        return 1;
      default:
        return 1;
    }
  }

  getPriceRange = () => {
    this.setState({ isLoading: true });
    this.props.getRestaurantPriceRange(this.callBack)
  }
  getrestriction = () => {
    this.setState({ isLoading: true });
    this.props.getRestaurantRestrictionTable(this.callBack)
  }
  toogleChange = (event) => {
    this.setState({ isChecked: event.target.checked });
    if (event.target.checked) {
      this.setState({ errorCheckRequired: '' });
    }
  }
  toogleStripeChange = (event) => {
    this.setState({ isStripeConditionChecked: event.target.checked });
    if (event.target.checked) {
      this.setState({ errorCheckStripeRequired: '' });
    }
  }

  updateState = (event) => {
    let name = event.target.name;
    if ((name === "userName" && event.target.value.length < 30)) {
      this.setState({ [name]: event.target.value });
    } else if (name === "userFirstName" && event.target.value.length < 30) {
      this.setState({ [name]: event.target.value });
    }
    else if (name === "averagePrice") {
      this.setState({ [name]: parseFloat(event.target.value, 10) });
    }
    else if (name === "nombreConvives") {
      this.setState({ [name]: parseInt(event.target.value) });
    }
    else if (name !== "userName" && name !== "userFirstName") {
      this.setState({ [name]: event.target.value });
    }
    this.handleUserInput(event)
  }

  updateStateNumber = (value) => {
    this.handleNumberInput(value)
    this.setState({ currentUserNumber: value });
    // this.handleNumberInput(value);
  }

  handleProviderOptionsChange = (value) => {
    this.renderSwitch(value.value)
    let errors = this.state.errors;
    if (!(this.state.providerOptions.includes(value.value))) {
      this.setState({ errors, 'userProvider': "" });
    } else {
      this.setState({ errors, 'userProvider': "Valeur non disponible" });
    }
    if (value.value === "Restaurant") {
      this.getPriceRange();
      this.getrestriction();
    }
    else if (value.value === "Indépendant") {
      this.setState({ isLoading: true });
      this.props.getSellerAgence(this.callBack)
    } 
    this.setState({ providerOptionsValue: value.value });
  }

  handleAgenceChange = (value, list) => {
    this.setState({ agence: list ? list.find(i => i.name === value.value).id : null });
  }

  handleUserSelect = (value) => {
    if (value.value === "FR") {
      this.setState({ currentCountry: value.value, currentUserNumber: "+336" });
      return
    }
    this.setState({ currentCountry: value.value, currentUserNumber: "" });
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'establishmentName':
        errors.establishmentName = this.props.errors.nameErrors(value);
        break;
      case 'userName':
        errors.userName = this.props.errors.nameErrors(value);
        break;
      case 'userFirstName':
        errors.userFirstName = this.props.errors.nameErrors(value);
        break;
      case 'userEmail':
        errors.userEmail = this.props.errors.emailErrors(value);
        break;
      case 'currentUserNumber':
        errors.currentUserNumber = this.numberErrors(this.state.currentUserNumber);
        break;
      case 'descriptionOnResult':
        errors.descriptionOnResult = this.props.errors.extraLongDescriptionErrors(value);
        break;
      case 'nombreConvives':
        errors.nombreConvives = this.props.errors.numberIntegerErrors(value);
        break;
      case 'averagePrice':
        errors.averagePrice = this.props.errors.numberErrors(value);
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  handleNumberInput = (value) => {
    if (typeof value === "string") {
      let errors = this.state.errors;
      errors.currentUserNumberError = this.numberErrors(value)
      this.setState({ errors });
    }
    //this.setState({currentUserNumberError : this.numberErrors(value)});
  }
  setRestrictionTable = () => {
    const { listRestaurantRestrictionTable } = this.props.sellers;
    return listRestaurantRestrictionTable && this.state.restrictionTable !== null && listRestaurantRestrictionTable.find(i => i.value === parseInt(this.state.restrictionTable)).label

  }
  numberErrors = (value) => {
    if (typeof value === "string") {
      if (isValidPhoneNumber(value)) {
        if (this.state.currentCountry === "FR" && (value.charAt(3) === "0")) {
          return "0 apres 33 est invalide";
        }
        else {
          return "";
        }
      }
      else {
        return "Numero invalide";
      }
    }
    else {
      return "Numero invalide";
    }


    // if (isValidPhoneNumber(this.state.currentUserNumber)) {
    //   return "";
    // } else {
    //   return "Numero invalide";
    // }
  }

}

export default InscriptionController;

//
// Redux connection
//

InscriptionController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
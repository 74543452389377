import React from 'react';

import EmailController, { reduxConnect } from './controller';

import logo from './img/logo.png';
import logoFooter from './img/logofooter.png';
import bandeaux from './img/bandeaux.png';
import telephone from './img/tel.png';
import email from './img/mail.png';

class Email extends EmailController {
    render() {
        
        return (
            <div className="page-mail">
                <div className="top-mail" style={{background:'#000000', width: '100%', height: '40px' }}></div>
                <div className="logo-mail" style={{background:'#000000', width: '100%', height: '40px', textAlign: 'center' }}>
                    <img src={logo} alt="logo" style={{maxWidth: '400px', height: '100%'}} />
                </div>
                <div className="bandeau-mail" style={{width: '100%', height: '80px', textAlign: 'center' }}>
                    <img src={bandeaux} alt="logo" style={{width: '100%', height: '100%'}}/>
                </div>
                <div className="content-mail" style={{maxWidth: '980px', width: '100%', margin: 'auto'}}>
                    <div className="content-title" style={{textAlign: 'center'}}>
                        <h1 style={{color: '#c9ab71', padding:'40px', borderBottom:'solid 1px #000'}}>Merci pour votre inscription</h1>
                    </div>
                    <div className="content-main" style={{textAlign: 'left', padding: '40px'}}>
                        <p>Félicitations cher partenaire !</p>
                        <p>Votre inscription est désormais validée.</p>
                        <p>Votre compte est actif et vous pouvez dès maintenant répondre à une commande.</p>
                        <p>Vos données personnelles :</p>
                        <div className="infos-pers" style={{textAlign: 'center', padding: '40px', fontWeight: 'bold'}}>
                            <p style={{fontWeight: 'bold'}}><span style={{color: '#c9ab71', fontWeight: 'bold'}}>Identifiant :</span> indep.freelance@yopmail.com</p>
                            <p style={{fontWeight: 'bold'}}><span style={{color: '#c9ab71', fontWeight: 'bold'}}>Mot de passe :</span> XXXXXXXXX</p>
                        </div>
                        <p>Nous vous remercions pour votre confiance. A très bientôt.</p>
                    </div>
                </div>
                <div className="footer-mail" style={{maxWidth: '980px', width: '100%', margin: 'auto', textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold'}}>Pour toute question, merci de contacter l’hôtel au : </p>
                    <table style={{border: '0', width: '450px', margin: 'auto'}}>
                        <tr>
                            <td style={{border: '0'}}><a href="tel:0181702880" style={{color: '#c9ab71', fontWeight: 'bold'}}><img src={telephone} style={{display:'inline-block', verticalAlign:'middle', width:'20px', marginRight: '10px'}} alt="tel"/> 01 81 70 28 80</a></td>
                            <td style={{border: '0'}}><a href="mailto:serviceclient@hotel.com" style={{color: '#c9ab71', fontWeight: 'bold'}}><img src={email} style={{display:'inline-block', verticalAlign:'middle', width:'20px', marginRight: '10px'}} alt="tel"/> serviceclient@hotel.com</a></td>
                        </tr>
                    </table>
                    <div className="bottom"  style={{borderTop: 'solid 1px #e0e0e0', padding: '40px', marginTop: '40px', textAlign: 'center'}}>
                        <p style={{fontSize: '20px'}}>Nous vous remercions et à très bientôt ! </p>
                        <p>Un service proposé par <img src={logoFooter} alt="logo" style={{maxWidth: '100px', height: 'auto', display:'inline-block', verticalAlign:'middle'}} /> </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default reduxConnect(Email);
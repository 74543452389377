import React from 'react';
import { Link } from 'react-router-dom';
import Currency from 'react-currency-formatter';
import moment from 'moment';

import DetailCommandeController, { reduxConnect } from './controller';
import LoadingOverlay from 'react-loading-overlay';

import Headers from "../../../components/headers/headers";
import Footer from "../../../components/footer/footer";
import { ORDER_STATUS } from "../../../../data/constants/order.status";

class DetailCommande extends DetailCommandeController {
  render() {
        const order = this.state.order || {};
        const customer = order.customer || {};
        const seller = order.seller || {};
        const items = order.description || [];
        const currencyCode = order.currency_code  || 'EUR';
        const paymentMethod = (order.payment_info && order.payment_info.payement_methode) || "";

    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>
                <Link to={{ pathname: "/commande" }}><span className="back"><i className="material-icons icon-back">arrow_back</i></span></Link> Votre commande</h1>
            </div>
          </div>
          <div className="main-commande">
            <div className="wrapper clr">
              <div className="mainBody">
                <div className="infos-commande">
                  <div className="head-det-commande">
                    <p className="nameClient"><span className="ico"><i className="material-icons icon-person">person_outline</i></span> {seller.seller_name || ""} <span className="statut">{ORDER_STATUS[order.status]}</span></p>
                    <p className="adressClient">{seller.seller_address || ""}</p>
                    <p className="telClient">{seller.seller_phone || ""}</p>
                    <p className="date-commande"><span className="ico"><i className="material-icons icon-person">date_range</i></span>{order.date ? moment(order.date).format('DD/MM/YYYY HH:mm') : ""} </p>
                    <p className="num-commande">{"Commande n° "} {order.order_number || ""} </p>
                    <p className="transaction-Commande">{"Transaction n° "} {(order.payment_info && order.payment_info.transaction) || ""}</p>
                  </div>
                  <div className="row">
                    <div className="col col-6 info-client-commande">
                      <h4 className="titre">Adresse de facturation</h4>
                      <p className="nameClient">{customer.customer_name || ""}</p>
                      <p className="mailClient">{customer.customer_email || ""}</p>
                      <p className="adressClient">{`${customer.street || ""}, ${customer.postcode || ""} ${customer.region || ""} ${customer.city || ""}`}</p>
                      <p className="telClient">{customer.telephone || ""}</p>
                    </div>
                    <div className="col col-6 info-client-commande">
                      <h4 className="titre">Mode de paiement</h4>
                      <p className="mode-paiment"><span className="ico"><i className="material-icons icon-card">credit_card</i></span>{paymentMethod}</p>
                    </div>
                  </div>
                </div>
                <div className="recap-commande">
                  <div className="titre-recap-commande">
                    Recapitulatif de la commande
                                </div>
                  <div className="table table-commande">
                    <div className="table-responsive scrollbar">
                      <table>
                        <thead>
                          <tr>
                            <th>Nom produit</th>
                            <th className="th-date">Date de la prestation</th>
                            <th className="th-qtt">Quantité</th>
                            <th className="th-montant">Prix</th>
                            <th className="th-reponse">Montant</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items && items.map(item =>(
                            <tr>
                                <td><span className="name-prestation">{item.prestation}</span><p>{item.type_prestation}</p></td>
                                <td>{item.date_start_prestation ? moment(item.date_start_prestation).format('DD/MM/YYYY hh:mm') : ""}</td>
                                <td>{(item.qty_invoiced && parseFloat(item.qty_invoiced) > 0 && parseFloat(item.qty_invoiced).toFixed(2)) || 1}</td>
                                <td> 
                                    <Currency
                                        quantity={item.price ? parseFloat(item.price) : 0}
                                        currency={currencyCode}
                                    />
                                </td>
                                <td className="align-right"> 
                                    <Currency
                                        quantity={item.row_total ? parseFloat(item.row_total) : 0}
                                        currency={currencyCode}
                                    />
                                </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6">&nbsp;</div>
                    <div className="col col-6 table table-commande">
                      <div className="table-responsive scrollbar">
                        <table>
                          <tbody>
                          <tr>
                              <td className="font-bold">Sous-total </td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={order.subtotal ? parseFloat(order.subtotal) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">Montant HT</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={order.price_HT ? parseFloat(order.price_HT) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">TVA</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={order.tax_amount ? parseFloat(order.tax_amount) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">Taux TVA</td>
                              <td className="align-right">{(order.taux_tva && parseFloat(order.taux_tva).toFixed(2)) || 0} %</td>
                          </tr>
                          <tr>
                              <td className="font-bold">Montant TTC</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={order.price_TTC ? parseFloat(order.price_TTC) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr>
                              <td className="font-bold">Remise</td>
                              <td className="align-right">{(order.discount_amount && parseFloat(order.discount_amount).toFixed(2)) || 0} %</td>
                          </tr>
                          <tr>
                              <td className="font-bold">Montant remboursé</td>
                              <td className="align-right"> 
                                  <Currency
                                      quantity={order.total_refunded ? parseFloat(order.total_refunded) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                          <tr className="total-commande">
                              <td className="font-bold">Total</td>
                              <td className="align-right font-bold">
                                  <Currency
                                      quantity={order.total ? parseFloat(order.total) : 0}
                                      currency={currencyCode}
                                  />
                              </td>
                          </tr>
                      </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cnt-Btn align-right">
                      <button 
                          disabled={order.status !== 'complete'} 
                          className="button btn-facture" 
                          onClick={()=>{this.props.history.push(`/commande/facture/${this.state.id}/seller`)}}
                      >
                          {"Votre facture"}
                      </button>
                      <button 
                        disabled={!this.state.review } 
                        className="button btn-facture" 
                        onClick={()=>{this.props.history.push(`/commande/avis-commande/${this.state.id}`)}}
                      >
                        {"Avis sur la commande"}
                      </button>
                      <div className="clr"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}
export default reduxConnect(DetailCommande);
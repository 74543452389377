import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

import mapStateToProps from '../../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../../services/redux/mapDispatchToProps';


class DetailCommandeController extends Component {
    constructor(props) {
        super(props);
        this.pageRef = createRef();
        this.state = {
            order: {},
            id: this.props.match.params.id,
            review: null,
            nbWS: 0,
        }
    }

    componentDidMount() {
        if (this.pageRef && this.pageRef.current) {
            this.pageRef.current.scrollIntoView();
        }
        this.setState({
            isLoading: true,
            nbWS: 0
        });
        this.props.getOrderDetails(this.state.id, this.callback);
        this.props.getOrderReview(this.state.id ,this.callback);
    }

    callback = (data, type) => {
        const nbWS = this.state.nbWS + 1
        if(nbWS === 2){
            this.setState({
                isLoading: false
            });
        }
        this.setState({
            nbWS
        });
        if (type === "orderDetails") {
            const order =  data.data || {}
            this.setState({
                order
            });            
        }else if (type ==="getOrderReview") {
            this.setState({
                review: (data.data && data.data.length > 0 && data.data[0]) || null,
            });
        }
    }
}

export default DetailCommandeController;



DetailCommandeController.propTypes = {
  
};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
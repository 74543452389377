import React, { Component } from "react";
import  {hidePopUp} from '../popup/popup';

 
class Comment extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            comment: "",
        };
    }

    handleCommentChange = event =>{
        const comment = event.target.value;
        this.setState({
            comment
        })
    }

    onValidate =()=>{
        this.props.onValidate(this.state.comment);
        hidePopUp();
    }
   
    render() {
        return (
            <div className="main-modal">                
                <div className="line-input">
                    <label>{"Commentaire"}</label>
                    <div className="cnt-input">
                        <textarea onChange={this.handleCommentChange} value={this.state.comment}></textarea>
                    </div>
                </div>
                <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={hidePopUp}>{"Annuler"}</button>
                    <button 
                        className="button" 
                        disabled={!this.state.comment}
                        onClick={this.onValidate}
                    >
                        {"Confirmer"}
                    </button>
                </div>
            </div>
        );
    }
}


export default Comment;

import React from 'react';
import RemboursementController from './controller';

class Remboursement extends RemboursementController {

  render() {
    return (        
        <div className="main-modal scrollbar" style={{width:"370px"}}>
            <form onSubmit={(event) => this.handleSubmit(event)}>
              {/* <div className="line-input">
                <label>Méthode de paiement</label>
                <div className="btn-check">
                  <label className="check">
                    <input
                      type="radio"
                      checked={this.state.acceptCard === true ? "checked" : ""}
                      name="acceptCard"
                      onChange={() => this.updateRadioState()}
                      value={this.state.acceptCard}
                    />
                    <span className="radiobtn"></span> Carte Bancaire
                                    </label>
                </div>
              </div> */}
              <div className="line-input montant-rembourse">
                <label>Montant du remboursement</label>
                <div className="cnt-input">
                  <input
                    type="text"
                    className="input-nom"
                    value={this.state.amount}
                    onChange={(event) => this.updateState(event)}
                    name="amount"
                  />
                  <span className="edit"><i className="material-icons icon-edit">edit</i></span>
                  {
                    this.state.errors.amount && this.state.errors.amount !== "default" &&
                    <span className='error'>{this.state.errors.amount}</span>
                  }
                </div>
              </div>
              {/* <div className="line-input">
                <label>Commentaire</label>
                <div className="cnt-input">
                  <textarea
                    value={this.state.comment}
                    onChange={(event) => this.updateState(event)}
                    name="comment"
                  >
                  </textarea>
                  {
                    this.state.errors.comment && this.state.errors.comment !== "default" &&
                    <span className='error'>{this.state.errors.comment}</span>
                  }
                </div>
              </div> */}
              <div className="cnt-Btn align-right">
                <input
                  className={`button  ${this.isButtonValid() ? '' : 'buttonIsInvalid'}`}
                  type="submit"
                  disabled={!this.isButtonValid()}
                  value={"Rembourser"}
                />
              </div>
            </form>
        </div>
    )
  }
}

export default Remboursement;
const INFO_DASHBOARD = 'INFO_DASHBOARD';
const INFO_DASHBOARD_ALL = 'INFO_DASHBOARD_ALL';
const STEPS_FINISHED = 'STEPS_FINISHED';
export const LOGOUT = 'LOGOUT';

export const url = {
    infoDashboard: '/seller/home/data',
    infoDashboardAll:'/dashboard/establishment-infos',
    steps: '/dashboard/steps'
  };
export default {
    INFO_DASHBOARD,
    INFO_DASHBOARD_ALL,
    STEPS_FINISHED,
    LOGOUT,
}
import React from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import 'react-dropdown/style.css';
import { HashLink } from "react-router-hash-link";
import LoadingOverlay from 'react-loading-overlay';
import InformationSiege from '../informationSiege';
import InformationBancaire from '../informationBancaire';
import ContactHotel from './../contactHotel'
import GestionAdminController, { reduxConnect } from './controller';
import InfoJuridique from './../infoJuridique';
import Headers from "./../../../components/headers/headers";
import Footer from "./../../../components/footer/footer";

class GestionAdministration extends GestionAdminController {

  render() {
    const { bankInfo } = this.props.bank;
    const { infoLogin } = this.props.commons;
    const isIndependant =infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    const isRestaurant =infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_RESTAURENT');
    const { infoProgressionEtablissement } = this.props.etablissement
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Gestion administration</h1>
            </div>
          </div>
          {
            (infoProgressionEtablissement.stateOfProgress !== 100 || (!isRestaurant && (!bankInfo || !bankInfo.identityFile || !bankInfo.kbisFile || bankInfo.status !== "verified"))) &&
            <div className="infosError">
              <div className="wrapper clr">
                <span><span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">error</i></span>Des informations sont manquantes/incorrectes pour la vérification de votre profil.</span>
                <HashLink
                  data-url={"#bankStatus"}
                  smooth
                  onClick={(e) => { this.expandCollapse(null, isIndependant ? [2] : [3], isIndependant ? 2 : 3) }}
                  to={"#bankStatus"}
                >
                  voir
                </HashLink>
                {/* <button onClick ={(e)=>{this.expandCollapse(e, 1)}} >Voir</button> */}
              </div>
            </div>
          }
          <div className="main monHotel">
            {
              this.getHeadbangClosed() &&
              <div className="wrapper clr">
                <div className="row mainBody">
                  <div className="col col-12">
                    <div className="title_progress">Votre profil est complété à {this.state.stateOfProgress ? this.state.stateOfProgress : 0}%</div>
                    <div className="progress">
                      <Progress percent={this.state.stateOfProgress ? this.state.stateOfProgress : 0} status={this.state.completionStatus} theme={{ toValidate: { color: '#fbc630' }, success: { color: '#94c535' } }} />
                    </div>
                    <div className="infos-detail"><span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">error_outline</i></span> Attention, certaines fonctionnalités ne sont pas disponibles si des informations obligatoires ne sont pas renseignées.</div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            }
            <div className="wrapper clr cnt-gestion content-accordeon">
              <Accordion atomic={false}>
                {infoLogin && infoLogin.roles && !infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? <AccordionItem title="Siège social de l’établissement">
                  <InformationSiege
                    errors={this.props.errors}
                    setLoadingTrue={this.setLoadingTrue}
                    setLoadingFalse={this.setLoadingFalse}
                  />
                </AccordionItem> : <Accordion title="test"></Accordion>

                }
                <AccordionItem title={`Contact de l’${infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT') ? "indépendant" : "établissement"}`}>
                  <ContactHotel
                    errors={this.props.errors}
                    setLoadingTrue={this.setLoadingTrue}
                    setLoadingFalse={this.setLoadingFalse}
                  />
                </AccordionItem>
                <AccordionItem title="Informations juridiques">
                  <InfoJuridique
                    errors={this.props.errors}
                    setLoadingTrue={this.setLoadingTrue}
                    setLoadingFalse={this.setLoadingFalse}
                  />
                </AccordionItem>
                <AccordionItem
                  title="Informations du compte bancaire"
                  open={1}>
                  <InformationBancaire
                    errors={this.props.errors}
                    setLoadingTrue={this.setLoadingTrue}
                    setLoadingFalse={this.setLoadingFalse}
                  />
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>
    )
  }
}

export default reduxConnect(GestionAdministration);


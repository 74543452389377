import React from "react";
import OptionsController, { reduxConnect } from './controller';

class Options extends OptionsController {
  renderOptions() {
    return this.state.options && this.state.options.map(option => {
      const optionValues = option.options_values || {}
      return (
        <div className="row option-item" key={option.option_id}>
          <div className="col col-12">
            <h1 className="title-option">{option.title}</h1>
          </div>
          <div className="col col-12">
            {
              optionValues && Object.keys(optionValues).map(key => {
                const optionValue = optionValues[key];
                return(
                  <div className="row option-bottom" key={`${option.option_id}_${optionValue.value_id}`}>
                    <div className="col col-6 line-input">
                      <label>&nbsp;</label>
                      <label className="checkbox-label">
                        <input type="checkbox"
                          data-id = {optionValue.value_id}
                          data-option = {option.option_id}
                          checked={this.state.addedOptions.includes(parseInt(optionValue.value_id))}
                          onChange={this.handleAddOption}
                        />
                        <span className="check"></span>
                        <b>{optionValue.description || optionValue.value || ''}</b>
                      </label>
                    </div>
                    <div className="col col-6 line-input">
                      <label>Prix de cession</label>
                      <p>
                        {`${(optionValue.price_cession && parseFloat(optionValue.price_cession).toFixed(2)) || 0}€`}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      );
    });
  }

  renderDeliveryPlaces() {
    const {deliveryPlaces} = this.state; 
    return deliveryPlaces && deliveryPlaces.length > 0 && deliveryPlaces.map(deliveryPlace =>{
      return(
        <div className="vtc" key={deliveryPlace.hotel_id}>
          <h4 className="title-option">{`${deliveryPlace.hotel_name}`}</h4>
          <ul className="tripList" >
          {
            deliveryPlace.type_traject && deliveryPlace.type_traject.length > 0 && deliveryPlace.type_traject.map(typeTraject=>{
              return typeTraject && typeTraject.traject && typeTraject.traject.length > 0 &&  typeTraject.traject.map(traject=>{
                return (
                  <li className="tripList-item row" key={traject.id}>
                      <div className="actions">
                        <label className="checkbox-label">
                          <input 
                            type="checkbox"
                            data-id = {traject.place_id}
                            data-hotel = {deliveryPlace.hotel_id}
                            data-service = {traject.id_service}
                            checked={(traject.added && parseInt(traject.added))}
                            onChange={this.handleAddDeliveryPlace}
                          />
                          <span className="check"></span>
                        </label>
                      </div>
                      <div className="col col-7">
                          <label>Lieu</label>
                          <p>{traject.name}</p>
                      </div>
                      <div className="col col-5">
                          <label>Prix de cession</label>
                          <p>
                              {`${(traject.price_cession && parseFloat(traject.price_cession).toFixed(2)) || 0}€`}
                          </p>
                      </div>
                  </li>
                )
              })
            })
          }
          </ul>
        </div> 
      )
    })
  }
  
  render() {
    const {product} = this.state;
    const {deliveryPlaces} = this.state; 
    return (
      <div className="col col-7" id="cont" name="scroll-to-anchor">
        <div className="cnt-right">
          <div className="blc-presta-top">
            <div className="cntDetCategory-flex">
              <h1 className="title-product">{product.name}</h1>
            </div>
            <div className="blc-top-product mb-3">
              <div
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-12 line-input">
            {
              !(this.props.category && this.props.category.flag && this.props.category.flag === 'vtc' && deliveryPlaces && deliveryPlaces.length > 0) &&
              <p className="price-cession opacity-1">
                <label>Prix de cession :</label> <strong>{product.price_cession ? parseFloat(product.price_cession).toFixed(2) : 0}€</strong>
              </p>
            }
            </div>
          </div>
          {
            this.props.category && this.props.category.flag && this.props.category.flag === 'vtc' && deliveryPlaces && deliveryPlaces.length > 0 &&
            <React.Fragment>
              <h5 className="title-tripOption">Liste des lieux</h5>
              {this.renderDeliveryPlaces()}
            </React.Fragment>
          }
          <div className="option">
            {
              this.state.options && this.state.options.length > 0 && 
              <React.Fragment>
                <h5 className="title-tripOption">Liste des options</h5>
                {this.renderOptions()}
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }
}
export default reduxConnect(Options);

export const GET_HOTEL_PARTENER = 'GET_HOTEL_PARTENER';
export const GET_HOTEL_PARTENER_ALL = 'GET_HOTEL_PARTENER_ALL';
export const GET_PARTENERSHIP_REQUEST_1 = 'GET_PARTENERSHIP_REQUEST_1';
export const GET_PARTENERSHIP_REQUEST_5 = 'GET_PARTENERSHIP_REQUEST_5';
export const SEARCH_HOTEL_PARTENER = 'SEARCH_HOTEL_PARTENER';
export const GET_ALL_HOTEL = 'GET_ALL_HOTEL';
export const GET_HOTEL_FILTER = 'GET_HOTEL_FILTER';
export const REQUEST_PARTENERSHIP = 'REQUEST_PARTENERSHIP';
export const END_PARTENERSHIP = 'END_PARTENERSHIP';
export const ACCEPT_PARTENERSHIP = 'ACCEPT_PARTENERSHIP';
export const DENY_PARTENERSHIP = 'DENY_PARTENERSHIP';
export const LOGOUT = 'LOGOUT';
export const GET_DEFAULT_COMMISSION = 'GET_DEFAULT_COMMISSION';
export const GET_PRICE_PER_CUTERLY = 'GET_PRICE_PER_CUTERLY';

export const url = {
    hotelPartener : '/hotel/partner',
    hotelPartenershipAction : '/partnership',
    requetePartenariat : '/partnership',
    allHotel : '/hotel/filter',
    hotelFilter : 'hotel/filter',
    requestPartenership : '/partnership/request',
    hotel : '/hotel',
    hotelDefaultCommission: '/getDefaultCommission',
    hotelPricePerCuterly: '/hotel/getPricePerCuterly'
  };

export default {
    GET_HOTEL_PARTENER,
    GET_HOTEL_PARTENER_ALL,
    GET_ALL_HOTEL,
    GET_HOTEL_FILTER,
    GET_PARTENERSHIP_REQUEST_1,
    GET_PARTENERSHIP_REQUEST_5,
    SEARCH_HOTEL_PARTENER,
    REQUEST_PARTENERSHIP,
    ACCEPT_PARTENERSHIP,
    END_PARTENERSHIP,
    DENY_PARTENERSHIP,
    LOGOUT,
    GET_DEFAULT_COMMISSION,
    GET_PRICE_PER_CUTERLY
}
import { get, put, remove } from '.';
import { url } from '../redux/constants/language'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;

}

export default {
  getAllLanguage: async () => {
    const response = await get(url.language);
    return testResponse(response);
  },

  getSellerLanguage: async (data) => {
    const response = await get(url.sellerLanguage);
    return testResponse(response);
  },

  UpdateSellerLanguage: async (data) => {
    const response = await put(url.sellerLanguage, data);
    return response;
  },

  removeSellerLanguage: async (id) => {
    const response = await remove(`${url.sellerLanguage}/${id}`)
    return response;
  }
}

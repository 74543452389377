import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import ShowMore from 'react-show-more';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import CommandeController, { reduxConnect } from './controller';
import Headers from "./../../components/headers/headers";
import Footer from "./../../components/footer/footer";
import LoadingOverlay from 'react-loading-overlay';
import Pagination from "./../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../data/constants/page.size";
import moment from 'moment'
import 'moment/locale/fr';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import PopupHandler from "./../../components/popup/popupHandler";
import { ORDER_STATUS } from "../../../data/constants/order.status";



class Commande extends CommandeController {
  renderPagination = () => {
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={this.state.data && this.state.data.nbPage ? this.state.data.nbPage * this.state.data.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={this.state.data && this.state.data.itemPerPage ? this.state.data.itemPerPage : 5}
            activePage={this.state.data && this.state.data.page ? this.state.data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  renderRefuse() {
    const Minute = () => {
      let minu = [];
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          i = `0${i}`
        }
        else {
          i = `${i}`
        }
        minu = [...minu, i]
      }
      return minu
    }
      ;
    const heureSelect = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    return (
      <div>
        <h2>Votre contre-proposition d’horaire</h2>
        <div className="line-input">
          <label>Date</label>
          <DatePickerInput
            disabled={this.state.disabled}
            displayFormat='DD/MM/YYYY'
            returnFormat='YYYY-MM-DD'
            value={this.state.newDate ? new Date(this.state.newDate) : null}
            className='my-react-component'
            onChange={(jsDate, dateString) => this.setState({ newDate: dateString })}
            showOnInputClick
            placeholder='Séléctionner'
            locale='fr'
            minDate={new Date()}
            onClear={() => this.setState({ newDate: null })}
            readOnly={true}
          />
        </div>
        <div className="line-input">
          <label>Heure</label>
          <div className="line-dure">
            <div className="select-down heure-prestation">
              <Dropdown
                className="MyclassName"
                options={heureSelect}
                defaultOption={0}
                name="heurePrestation"
                onChange={(event) => this.sethour(event, "heureSend")}
                value={this.state.heureSend}
                placeholder={0}
                controlClassName=''
                arrowClassName='arrow-down'
                arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
              />
            </div>
            <div className="txt">heure(s)</div>
            <div className="select-down minute-prestation">
              <Dropdown
                className="MyclassName"
                options={Minute()}
                defaultOption={0}
                onChange={(event) => this.sethour(event, "minuteSend")}
                value={this.state.minuteSend}
                placeholder={0}
                controlClassName=''
                arrowClassName='arrow-down'
                arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
              />
            </div>
            <div className="txt">minute(s)</div>
          </div>
          {/* <div className="line-dure">
            <div className="select-down select-heure">
              <Dropdown
                className="MyclassName"
                options={heureSelect}
                defaultOption={heureSelect[0]}
                onChange={(event)=> this.sethour(event,"heureSend")}
                value={this.state.heureSend}
                controlClassName=''
                name="heures"
                arrowClassName='arrow-down'
                arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
              />
            </div>
            <div className="txt-h">h</div>
            <div className="select-down select-minute">
              <Dropdown
                className="MyclassName"
                options={Minute()}
                onChange={(event)=>this.sethour(event,"minuteSend")}
                value={this.state.minuteSend}
                name="minutes"
                controlClassName=''
                arrowClassName='arrow-down'
                arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
              />
            </div>
            <div className="txt-h">min</div>
          </div> */}
        </div>
      </div>

    );
  }

  render() {
    const reponse = [
      'Accepter', 'Refuser'
    ];
    const hotelOptions = () => {
      if (this.state.listHotelPartner && "data" in this.state.listHotelPartner && Array.isArray(this.state.listHotelPartner.data)) {
        return [{value: "", label: "Tous"}, ...this.state.listHotelPartner.data.map(i => ({value: i.code , label: i.name}))];
      }
      else {
        return [];
      }
    }
    const {statusOptions} = this.state
    const listOrders = this.state.listOrderSale || [];
    const { infoLogin } = this.props.commons;
    const { agenceSellers } = this.props.sellers;
    const isIndependant = infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_INDEPENDANT');
    return (
      <div ref={this.pageRef} className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
          <Headers />
          <div className="bandeaux">
            <div className="wrapper clr">
              <h1>Gestion des commandes</h1>
            </div>
          </div>
          <div>
            {this.state.responseError && PopupHandler.showError("L'envoi de reponse a échoué.")}
            {this.state.responseError === false && PopupHandler.showSuccess("La reponse a bien été envoyée.")}
            {this.state.exportError && PopupHandler.showError("L'export a échoué.")}
            {this.state.exportError === false && PopupHandler.showSuccess("Succès de l'export.")}
            {this.state.downloadError && PopupHandler.showError("Le téléchargement de la facture a échoué.")}
            {this.state.downloadError === false && PopupHandler.showSuccess("Succès du téléchargement de la facture.")}
            {this.state.refundError && PopupHandler.showError("Le remboursement de la commande a échoué.")}
            {this.state.refundError === false && PopupHandler.showSuccess("Succès du remboursement de la commande.")}
          </div>
          <div className="main-commande">
            <div className="wrapper clr">
              {
                infoLogin && infoLogin.roles && infoLogin.roles.includes('ROLE_SELLER_AGENCE') &&
                <div className="cnt-sidebar choix-seller">
                  <div className="title-sidebar">Choix du prestataire</div>
                  <div className="main-sidebar">
                    <select
                      value={this.state.query.seller}
                      onChange={this.handleSellerChange}
                      name="seller"
                    >
                      <option value="" key="0">Séléctionner un prestataire</option>
                      { agenceSellers && agenceSellers.map(seller =>
                        <option value={seller.id} key={seller.id}>{`${seller.firstname || ''} ${seller.lastname || ''}`}</option>
                      )}
                    </select>
                  </div>
                </div> 
              }
              <div className="cnt-filtre">
                <div className="block-search-filter">
                  <div>
                    <h3>N°Commande / Client</h3>
                    <input
                      type="text"
                      value={this.state.query.filter.search}
                      className="input-search"
                      placeholder="Rechercher…"
                      onChange={this.handleFilterChange}
                      onKeyDown={this.handleSearchKeyDown}
                      name="search"
                    />
                  </div>
                  <div>
                    <h3>Période</h3>
                    <div className="cnt-periode">
                      <div className="cnt-periode-item">
                        <div className="txt">Depuis</div>
                        <DatePickerInput
                          disabled={this.state.disabled}
                          displayFormat='DD/MM/YYYY'
                          returnFormat='YYYY-MM-DD'
                          value={this.state.query.start ? new Date(this.state.query.start) : null}
                          className='my-react-component'
                          onChange={(jsDate, dateString) => this.handleDateChange(dateString, 'start')}
                          showOnInputClick
                          placeholder='Séléctionner'
                          locale='fr'
                          maxDate={this.state.lastTime}
                          onClear={() => this.handleDateChange(null,'start')}
                          name="start"
                        />
                      </div>
                      <div className="cnt-periode-item">
                        <div className="txt txt-center">Jusqu’à</div>
                        <DatePickerInput
                          disabled={this.state.disabled}
                          displayFormat='DD/MM/YYYY'
                          returnFormat='YYYY-MM-DD'
                          value={this.state.query.end ? new Date(this.state.query.end) : null}
                          className='my-react-component'
                          onChange={(jsDate, dateString) => this.handleDateChange(dateString, 'end')}
                          showOnInputClick
                          placeholder='Séléctionner'
                          locale='fr'
                          minDate={this.state.firstTime}
                          onClear={() => this.handleDateChange(null,'end')}
                          name="end"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Hôtel associé</h3>
                    <div className="select-down">
                      <Dropdown
                        className="MyclassName"
                        options={hotelOptions()}
                        onChange={value=>this.handleDropdownChange(value, 'hotel')}
                        value={this.state.query.filter.hotel}
                        placeholder="Tous"
                        controlClassName='myControlClassName'
                        arrowClassName='arrow-down'
                        arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                        arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                      />
                    </div>
                  </div>
                  <div>
                    <h3>Statut</h3>
                    <div className="select-down">
                      <Dropdown
                        className="MyclassName"
                        options={statusOptions}
                        onChange={value=>this.handleDropdownChange(value, 'status')}
                        value={this.state.query.filter.status}
                        placeholder="Tous"
                        controlClassName='myControlClassName'
                        arrowClassName='arrow-down'
                        arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                        arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                      />
                    </div>
                  </div>
                  <div className="cnt-btn">
                    <input type="button" className="btnSearch" value="Rechercher" onClick={this.search}/>
                  </div>
                </div>
              </div>

              <div className="table table-commande">
                <div className="table-responsive scrollbar">
                  <table>
                    <thead>
                      <tr>
                        <th className="th-check">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="check-all"
                              checked={this.state.allChecked}
                              onChange={(event) => this.toogleChangeAll(event)}
                            />
                            <span className="check">
                            </span>
                          </label>
                        </th>
                        <th>Prestation</th>
                        <th>Client</th>
                        <th className="th-date">
                          Date/Heure prestation
                          <span className="filtre clickable" onClick={this.handleSortChange}>
                            <i className="material-icons">
                              {this.state.prestationDateOrder.icon}
                            </i>
                          </span>
                        </th>
                        <th className="th-hotel">Hôtel associé</th>
                        <th className="th-montant">Montant perçu</th>
                        <th className="th-reponse">Votre réponse</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        listOrders && Array.isArray(listOrders) && listOrders.map((value,index) =>
                          <React.Fragment key={`${index}-commande`}>
                            <tr>
                              <td className="cnt-check no-border-bottom">
                                <label className="checkbox-label">
                                  <input type="checkbox"
                                    checked={listOrders[index].checked}
                                    onChange={(event) => this.toogleChange(event,index)}
                                    disabled={listOrders[index].status && listOrders[index].status === "canceled" ? true : false}
                                  />
                                  <span className="check"></span>
                                </label>
                              </td>
                              <td className="no-border-bottom">
                                <Link to={`/commande/detail/${value.id}`}>
                                  <span className="name-prestation">
                                    {value.product}
                                  </span>
                                </Link>
                                <p>{value.categoryProduct}</p>
                                <p>Commande n°{value.numCommand}<br />Transaction n°{value.numTransaction}</p>
                              </td>
                              <td className="no-border-bottom"><span className="nameclient">{value.client && value.client.name}</span><br />{value.client && value.client.phone}<br />{value.client && value.client.email}</td>
                              <td className="no-border-bottom">{value.datePrestation}</td>
                              <td className="no-border-bottom">{value.hotel}</td>
                              <td className="no-border-bottom">{value.price} €</td>
                              <td className="no-border-bottom">{ORDER_STATUS[value.status]}</td>
                              {
                                this.renderSwitch(value, index)
                              }
                            </tr>
                            <tr className="row-furtherInformation">
                              <td className="no-padding"></td>
                              <td colSpan="7" className="td-furtherInformation no-padding">
                                {
                                  value.additionalInformation &&
                                  <div className="wrapper-furtherInformation">
                                    <div className="furtherInformation">
                                      <ShowMore
                                        lines={1}
                                        more='Lire la suite'
                                        less='Réduire'
                                        anchorClass='action-furtherInformation'
                                      >
                                          {value.additionalInformation}
                                      </ShowMore>
                                    </div>                  
                                  </div>
                                }
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      }
                    </tbody>
                  </table>
                </div>
                <div>{this.state.data && this.state.data.total > 0 && this.renderPagination()}</div>
                <div className="cnt-Btn">
                  <button
                    onClick={this.exportToCsv}
                    className={this.state.canExport ? "button" : "button btn-export-csv"}
                    disabled={this.state.canExport ? false : true}
                  >
                    Exporter les commandes en CSV
                  </button>
                  <button
                    onClick={this.generateInvoice}
                    className={this.state.canDownloadInvoice ? "button" : "button btn-export-csv"}
                    disabled={this.state.canDownloadInvoice ? false : true}
                  >Télécharger la facture</button>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="wrapper-modal">
                <Modal visible={this.state.responseModal} width="370" /* height={`${(this.state.statusResponse === "Refuser" && this.state.contreProposition === true) ? "70%" : ((this.state.statusResponse === "" && this.state.contreProposition === false) || (!this.state.statusResponse === "Accepter" && this.state.contreProposition === false)) ? "60%" : "87%"}`} */ effect="fadeInUp" onClickAway={() => this.closeModal('responseModal')}>
                  <div className="content-modal modal-reponse">
                    <div className="head-modal">
                      <button className="close-modal" onClick={() => this.closeModal('responseModal')}><i className="material-icons icons-close">close</i></button>
                      <h1>{listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].product}</h1>
                      {/* <p>{listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].duration} min</p> */}
                      <p>{listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].categoryProduct}</p>
                    </div>
                    <div className="main-modal scrollbar">
                      <p><span className="title-infos-commande">Hôtel associé</span>{listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].hotel}</p>
                      <p><span className="title-infos-commande">Client</span>{listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].client.name}</p>
                      <p>
                        <span className="title-infos-commande">
                          Date/Heure de la prestation
                        </span>
                        {(listOrders && listOrders[this.state.orderIdToAnswer] && listOrders[this.state.orderIdToAnswer].datePrestation && listOrders[this.state.orderIdToAnswer].datePrestation.replace(" ", " à ")) || ""}
                      </p>
                      <div className="line-input">
                        <label>Votre réponse</label>
                        <div className="select-down select-reponse">
                          <Dropdown
                            className="MyclassName"
                            options={reponse}
                            onChange={(event) => this.selectResponse(event)}
                            value={this.state.statusResponse}
                            name="statusResponse"
                            placeholder={"Select"}
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                      {
                        !isIndependant && this.state.statusResponse && this.state.statusResponse === "Refuser" &&
                        <div className="line-input">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="check-all"
                              checked={this.state.contreProposition}
                              onChange={(event) => this.toogleProposeChange(event, "contreProposition")}
                              name="contreProposition"
                            />
                            <span className="check"></span>
                            Proposer une autre date/horaire pour cette prestation
                          </label>
                        </div>
                      }
                      {
                        !isIndependant && this.state.statusResponse && this.state.contreProposition &&
                        this.renderRefuse()
                      }
                      <div className="cnt-Btn align-right">
                        <button className="btn-cancel" onClick={() => this.closeModal("responseModal")}>Annuler</button>
                        <button
                          className={`button ${this.isReponseValid() ? '' : 'buttonIsInvalid'}`}
                          disabled={!this.isReponseValid}
                          onClick={(event) => this.responseSubmit(listOrders[this.state.orderIdToAnswer].id)}
                        >
                          Envoyer votre réponse
                        </button>
                        <div className="clr"></div></div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="wrapper-modal">
                <Modal visible={this.state.cancelModal} width="370" effect="fadeInDown" onClickAway={() => this.closeModal('cancelModal')}>
                  <div className="content-modal">
                    <div className="head-modal">
                      <button type="button" className="close-modal" onClick={() => this.closeModal('cancelModal')}><i className="material-icons icons-close">close</i></button>
                      <h1>Confirmation</h1>
                    </div>
                    <div className="main-modal">
                      <p>Voulez-vous vraiment annuler cette commande ?</p>
                      <div className="cnt-Btn align-right">
                        <button className="btn-cancel" onClick={() => this.closeModal('cancelModal')}>Annuler</button>
                        <button 
                          className="button"
                          onClick={() => this.cancelSubmit(listOrders[this.state.orderIdToAnswer].id)}
                          >Valider</button>
                        <div className="clr"></div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <Footer />
        </LoadingOverlay>
      </div>

    )
  }
}



export default reduxConnect(Commande);

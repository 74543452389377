import React from 'react';
import ItemProduct from './itemProduit'

class listProduits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="table table-produit scrollbar">
        <table>
          <thead>
            <tr>
              <th>Prestation</th>
              <th>Prix prestation</th>
              <th>Nombre de ventes <span className="filtre"><i className="material-icons">arrow_drop_down</i></span></th>
              <th>Masquer du catalogue</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.listProduit && Array.isArray(this.props.listProduit) && this.props.listProduit.length > 0 && this.props.listProduit.map(item => <ItemProduct item={item} key={item.id} onchange={this.props.onchange} />).sort((a, b) => b.nbrVentes - a.nbrVentes)
            }
          </tbody>
        </table>
      </div>
    );
  }
}
export default listProduits;
import { getAllActivityArea, getSellerActivityArea, createActivityArea, addSellerActivityArea, deleteSellerActivityArea } from './actions/activityArea'
import { getBankInfo, sendBankInfo, uploadBankFile, verifyBankFile, } from './actions/bank'
import { getOrderSale, getNextOrderSale, acceptSaleOrder, denySaleOrder, cancelSaleOrder, exportOrder, getOrderDetails, getOrderBill, downloadOrderBill, refundOrder } from './actions/commandes'
import { getAllCategory, getAllKeywords, login, setUserPassword, forgetPassword, isLogout, checkToken, getHotelCategories, addOption, getAllDeliveryPlaces, addDeliveryPlace, removeDeliveryPlace, setExpireDate } from './actions/commons'
import { getInfoDashboard, getAllInfoDashboard, getSteps, addSteps} from './actions/dashboard'
import {
  getIdentityEtablissement,
  updateIdentityEtablissement,
  getInfoEtablissement,
  createEtablissement,
  updateInfoEtablissement,
  getProgressionEtablissement,
  getLegalInformation,
  sendLegalInformation,
  getOpeningTime,
  createOpeningTime,
  updateOpeningTime,
  removeOpeningTime,
} from './actions/etablissement'
import { getHotelPartner, getRequetePartenariat, getAllHotel, requestPartenership, acceptPartenership, denyPartenership, endPartenership, getDefaultCommission, getPricePerCuterly } from './actions/hotels'
import { getAllLanguage, getSellerLanguage, UpdateSellerLanguage, removeSellerLanguage } from './actions/language'
import { getOrderNotice, getReviews, getOrderReview, getProductReview, reportReview, validateReview } from './actions/notice'
import { createPrestation, updatePrestation, getPrestation, removePrestation, updateFullPrestation, getPrestationIndependant, addPrestationIndependant } from './actions/prestation'
import { inscription, getSellerContact, updateSellerContact, getSellerInfoAccount, updateSellerInfoAccount, getSellerId, getDataGraphe, getSellerInfoIndependant, updateSellerInfoIndependant, getSellerAgence, getRestaurantPriceRange, getRestaurantRestrictionTable, getAgenceSellers } from './actions/sellers'
import { getAllEvent, addEvent, searchEvent, removeEvent, getGoogleAccessToken, getStateSync, syncCalendar, unSyncCalendar, exportEvent } from './actions/agenda'

const mapDispatchToProps = (dispatch) => ({

  /// props to use for ActivityArea///////
  getAllActivityArea: (payload, cb) => dispatch(getAllActivityArea(payload, cb)),
  getSellerActivityArea: (payload, cb) => dispatch(getSellerActivityArea(payload, cb)),
  createActivityArea: (payload, cb) => dispatch(createActivityArea(payload, cb)),
  deleteSellerActivityArea: (payload, cb) => dispatch(deleteSellerActivityArea(payload, cb)),
  addSellerActivityArea: (payload, cb) => dispatch(addSellerActivityArea(payload, cb)),


  /// props to use for bank///////
  verifyBankFile: (payload, cb) => dispatch(verifyBankFile(payload, cb)),
  getBankInfo: (payload, cb) => dispatch(getBankInfo(payload, cb)),
  sendBankInfo: (payload, cb) => dispatch(sendBankInfo(payload, cb)),
  uploadBankFile: (payload, cb) => dispatch(uploadBankFile(payload, cb)),

  /// props to use in commandes///////
  getOrderSale: (payload, id, cb) => dispatch(getOrderSale(payload, id, cb)),
  getNextOrderSale: (payload, cb) => dispatch(getNextOrderSale(payload, cb)),
  acceptSaleOrder : (payload, cb) => dispatch(acceptSaleOrder(payload, cb)),
  denySaleOrder : (payload, cb) => dispatch(denySaleOrder(payload, cb)),
  cancelSaleOrder : (payload, cb) => dispatch(cancelSaleOrder(payload, cb)),
  exportOrder : (payload, id, cb) => dispatch(exportOrder(payload, id, cb)),
  getOrderDetails : (payload, cb) => dispatch(getOrderDetails(payload, cb)),
  getOrderBill : (payload, idSeller, cb) => dispatch(getOrderBill(payload, idSeller, cb)),
  downloadOrderBill : (payload, idSeller, cb) => dispatch(downloadOrderBill(payload, idSeller, cb)),
  refundOrder : (payload, cb) => dispatch(refundOrder(payload, cb)),

  /// props to use in commons///////
  getAllCategory: (payload, cb) => dispatch(getAllCategory(payload, cb)),
  getAllKeywords: (payload, cb) => dispatch(getAllKeywords(payload, cb)),
  login: (payload, cb) => dispatch(login(payload, cb)),
  setUserPassword: (payload,token,cb) => dispatch(setUserPassword(payload,token,cb)),
  forgetPassword: (payload, cb) => dispatch(forgetPassword(payload, cb)),
  isLogout: (payload, cb) => dispatch(isLogout(payload, cb)),
  checkToken: (payload, cb) => dispatch(checkToken(payload, cb)),
  getHotelCategories: (payload, cb) => dispatch(getHotelCategories(payload, cb)),
  addOption: (idPrestation, options, cb) => dispatch(addOption(idPrestation, options, cb)),
  getAllDeliveryPlaces: (cb) => dispatch(getAllDeliveryPlaces(cb)),
  addDeliveryPlace: (payload, cb) => dispatch(addDeliveryPlace(payload, cb)),
  removeDeliveryPlace: (id, serviceId, cb) => dispatch(removeDeliveryPlace(id, serviceId, cb)),
  setExpireDate: (data, cb) => dispatch(setExpireDate(data, cb)),


  /// props to use in dashboard///////
  getInfoDashboard: (payload, cb) => dispatch(getInfoDashboard(payload, cb)),
  getAllInfoDashboard: (payload, cb) => dispatch(getAllInfoDashboard(payload, cb)),
  getSteps: (cb) => dispatch(getSteps(cb)),
  addSteps: (payload, cb) => dispatch(addSteps(payload, cb)),

  /// props to use for etablissement///////
  getIdentityEtablissement: (payload, cb) => dispatch(getIdentityEtablissement(payload, cb)),
  updateIdentityEtablissement: (payload, cb) => dispatch(updateIdentityEtablissement(payload, cb)),
  getInfoEtablissement: (payload, cb) => dispatch(getInfoEtablissement(payload, cb)),
  createEtablissement: (payload, cb) => dispatch(createEtablissement(payload, cb)),
  updateInfoEtablissement: (payload, cb) => dispatch(updateInfoEtablissement(payload, cb)),
  getProgressionEtablissement: (payload, cb) => dispatch(getProgressionEtablissement(payload, cb)),
  getLegalInformation: (payload, cb) => dispatch(getLegalInformation(payload, cb)),
  sendLegalInformation: (payload, cb) => dispatch(sendLegalInformation(payload, cb)),
  getOpeningTime: (payload, cb) => dispatch(getOpeningTime(payload, cb)),
  createOpeningTime: (payload, id, cb) => dispatch(createOpeningTime(payload, id, cb)),
  updateOpeningTime: (payload, id, cb) => dispatch(updateOpeningTime(payload, id, cb)),
  removeOpeningTime: (payload, id, cb) => dispatch(removeOpeningTime(payload, id, cb)),

  /// props to use in hotels///////
  getHotelPartner: (payload, cb) => dispatch(getHotelPartner(payload, cb)),
  getRequetePartenariat: (payload, cb) => dispatch(getRequetePartenariat(payload, cb)),
  getAllHotel: (payload, cb) => dispatch(getAllHotel(payload, cb)),
  requestPartenership : (payload, cb) => dispatch(requestPartenership(payload, cb)),
  acceptPartenership : (payload,id,cb) => dispatch(acceptPartenership(payload,id,cb)),
  denyPartenership : (payload, cb) => dispatch(denyPartenership(payload, cb)),
  endPartenership : (payload, cb) => dispatch(endPartenership(payload, cb)),
  getDefaultCommission : (payload, cb) => dispatch(getDefaultCommission(payload, cb)),
  getPricePerCuterly : (payload, cb) => dispatch(getPricePerCuterly(payload, cb)),




  /// props to use for language///////
  getAllLanguage: (payload, cb) => dispatch(getAllLanguage(payload, cb)),
  getSellerLanguage: (payload, cb) => dispatch(getSellerLanguage(payload, cb)),
  UpdateSellerLanguage: (payload, cb) => dispatch(UpdateSellerLanguage(payload, cb)),
  removeSellerLanguage: (payload, cb) => dispatch(removeSellerLanguage(payload, cb)),

  /// props to use in notice///////
  getOrderNotice: (payload, cb) => dispatch(getOrderNotice(payload, cb)),
  getReviews: (saleOrder, productId, query, cb) => dispatch(getReviews(saleOrder, productId, query, cb)),
  getOrderReview: (payload, cb) => dispatch(getOrderReview(payload, cb)),
  getProductReview: (payload, cb) => dispatch(getProductReview(payload, cb)),
  reportReview: (payload, cb) => dispatch(reportReview(payload, cb)),
  validateReview: (payload, cb) => dispatch(validateReview(payload, cb)),

  /// props to use for prestation///////
  createPrestation: (payload, id, cb) => dispatch(createPrestation(payload, id, cb)),
  updatePrestation: (payload, cb) => dispatch(updatePrestation(payload, cb)),
  getPrestation: (payload, cb) => dispatch(getPrestation(payload, cb)),
  removePrestation: (payload, cb) => dispatch(removePrestation(payload, cb)),
  updateFullPrestation: (payload, id1, id2, cb) => dispatch(updateFullPrestation(payload, id1, id2, cb)),
  getPrestationIndependant: (payload, cb) => dispatch(getPrestationIndependant(payload, cb)),
  addPrestationIndependant : (payload, cb) => dispatch(addPrestationIndependant(payload, cb)),


  /// props to use for sellers///////
  inscription: (payload, cb) => dispatch(inscription(payload, cb)),
  getSellerContact: (payload, cb) => dispatch(getSellerContact(payload, cb)),
  updateSellerContact: (payload, cb) => dispatch(updateSellerContact(payload, cb)),
  getSellerInfoAccount: (payload, cb) => dispatch(getSellerInfoAccount(payload, cb)),
  updateSellerInfoAccount: (payload, cb) => dispatch(updateSellerInfoAccount(payload, cb)),
  getSellerId: (payload, cb) => dispatch(getSellerId(payload, cb)),
  getDataGraphe: (payload, cb) => dispatch(getDataGraphe(payload, cb)),
  getSellerInfoIndependant: (payload, cb) => dispatch(getSellerInfoIndependant(payload, cb)),
  updateSellerInfoIndependant: (payload, cb) => dispatch(updateSellerInfoIndependant(payload, cb)),
  getSellerAgence: (payload, cb) => dispatch(getSellerAgence(payload, cb)),
  getRestaurantPriceRange : (payload, cb) => dispatch(getRestaurantPriceRange(payload, cb)),
  getRestaurantRestrictionTable : (payload ,cb) => dispatch(getRestaurantRestrictionTable(payload, cb)),
  getAgenceSellers: (payload, cb) => dispatch(getAgenceSellers(payload, cb)),

  /// props to use in Agenda///////
  getAllEvent: (payload, cb) => dispatch(getAllEvent(payload, cb)),
  addEvent : (payload,cb) => dispatch(addEvent(payload, cb)),
  searchEvent: (payload, cb) => dispatch(searchEvent(payload, cb)),
  removeEvent: (payload, cb) => dispatch(removeEvent(payload, cb)),
  getGoogleAccessToken : (payload, cb) => dispatch(getGoogleAccessToken(payload, cb)),
  getStateSync: (payload, cb) => dispatch(getStateSync(payload, cb)),
  syncCalendar: (payload, cb) => dispatch(syncCalendar(payload, cb)),
  unSyncCalendar: (payload, cb) => dispatch(unSyncCalendar(payload, cb)),
  exportEvent : (payload,cb) => dispatch(exportEvent(payload, cb)),

});


export default mapDispatchToProps;
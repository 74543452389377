const mapStateToProps = (state) => ({
  activityArea: state.activityArea,
  bank : state.bank,
  commandes : state.commandes,
  commons : state.commons,
  dashboard : state.dashboard,
  etablissement : state.etablissement,
  hotels : state.hotels,
  language : state.language,
  notice : state.notice,
  prestation : state.prestation,
  sellers : state.sellers,
  agenda : state.agenda,
});
  
export default mapStateToProps;

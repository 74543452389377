import React from "react";
import { Link } from 'react-router-dom';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import InstitutImg from './../img/avatar.png';
import StarRatings from 'react-star-ratings';
import commentaireController, { reduxConnect } from './controller';
import PopupHandler from "../../../components/popup/popupHandler";
import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";



class Commentaire extends commentaireController {

  renderGlobal() {
    const { infoDashboard} = this.props.dashboard;
    const rating=(infoDashboard && infoDashboard.review && parseFloat(infoDashboard.review.value)) || 0;
    return (
      <div className="top-comm">
        <div className="cnt-photo">
          <div className="blc-photo">
          <img src={(this.props.logo && this.props.logo.url) || InstitutImg} alt="Institut" />
          </div>
          <div className="blc-edit">
            <p className="note-rate">Note moyenne</p>
            <p className="name-comm">{this.props.name ? this.props.name : "Nom non definit"}</p>
            <div className="ratings pt-0">
              <StarRatings
                rating={rating}
                starRatedColor='rgb(255, 181, 0)'
                starEmptyColor='rgb(255, 236, 191)'
                numberOfStars={5}
                name='rating'
                starDimension='16px'
                starSpacing='2px'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPagination = () => {
    const data = this.props.notice.reviews || {};
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={data && data.total ? data.total : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={(data && data.itemPerPage) || PAGE_SIZE_LIST[0]}
            activePage={data && data.page ? data.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  render() {
    const reviews = (this.props.notice.reviews && this.props.notice.reviews.data) || [];
    const filterReponses = [
      {value: "0", label: 'Toutes les réponses'},
      {value: "1", label: 'Publiée'},
      {value: "2", label: 'Signalée'},
      {value: "3", label: 'Pas de réponse'},
    ];
    return (
      <div className="cnt-tab">
        <div>
            {this.state.reportError && PopupHandler.showError("Le signalement de l'avis a échoué.")}
            {this.state.reportError === false && PopupHandler.showSuccess("L'avis a été signalé avec succès.")}
            {this.state.validateError && PopupHandler.showError("La validation de l'avis a échoué.")}
            {this.state.validateError === false && PopupHandler.showSuccess("L'avis a été validé avec succès.")}
          </div>
        {this.renderGlobal()}
        <div className="cnt-comm">
          <div className="table table-commande">
            <div className="table-responsive scrollbar wrapper-table-commentaire">
              <table className="table-commentaire">
                <thead>
                  <tr>
                    <td colSpan="2" className="evaluation">
                      <Link to="#">Évaluation <span className="arrow"><i className="material-icons icon-drop-up">arrow_drop_up</i></span></Link>
                    </td>
                    <td colSpan="3" className="pl-0">
                      <div className="filter-reponse">
                        <div className="select-down">
                          <Dropdown
                            className="MyclassName"
                            options={filterReponses}
                            onChange={this.onStatusChanged}
                            value={this.state.statusValue}
                            placeholder="Toutes les réponses"
                            controlClassName='myControlClassName'
                            arrowClassName='arrow-down'
                            arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                            arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    reviews.map((value) =>
                      <tr key={`${value.id}-commentaire`}>
                        <td className="blc-product">
                          <div className="name-Product">{value.productName}</div>
                          <div className="ratings pt-0">
                            <StarRatings
                              rating={value.ratingSummary ? parseFloat(value.ratingSummary)/20 : 0}
                              starRatedColor='rgb(255, 181, 0)'
                              starEmptyColor='rgb(255, 236, 191)'
                              numberOfStars={5}
                              name='rating'
                              starDimension='16px'
                              starSpacing='2px'
                            />
                          </div>
                        </td>
                        <td className="blc-comment">
                          <div className="name-comment">{value.nickname}</div>
                          <div className="corp-comment">{value.detail || ""}</div>
                        </td>
                        <td className="blc-status-comment">
                          <div className="name-status">Statut réponse</div>
                          <div className="corp-status">{value.status === 1 || (value.status === 2 && value.hasReported) ? "Réponse publiée": "Pas de réponse"}</div>
                        </td>
                        <td className="blc-date-comment">
                          <p>{value.date}</p>
                        </td>
                        <td className="blc-actio-comment">
                          {/* <span className="arrow">
                            <i className="material-icons icon-chat clickable">chat</i>
                          </span> */}
                          {
                            !value.hasReported && value.status === 2 &&
                            <React.Fragment>
                              <span className="arrow">
                                <i 
                                  className="material-icons icon-warning clickable"
                                  data-id ={value.id}
                                  onClick={this.onReportClicked}
                                >
                                  warning
                                </i>
                              </span>
                              <span className="arrow">
                                <i 
                                  data-id ={value.id}
                                  onClick={this.onValidateClicked}
                                  className="material-icons icon-edit clickable"
                                >
                                  edit
                                </i>
                              </span>
                            </React.Fragment>
                          }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>{reviews && reviews.length > 0 && this.renderPagination()}</div>
      </div>
    )
  }
}
export default reduxConnect(Commentaire);

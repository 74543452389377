import constants from '../constants/commandes';

const initialSate = {
	listOrderSale: [],
	nextSaleOrder: {},
}
const commandes = (state = initialSate, action) => {
	switch (action.type) {
		case constants.GET_SALE_ORDER:
			return {
				...state,
				listOrderSale: action.payload,
			}
		case constants.GET_NEXT_SALE_ORDER:
			return {
				...state,
				nextSaleOrder: action.payload,
			}
		case constants.LOGOUT:
			return initialSate;

		default:
			return state;
	}

}

export default commandes;
import {isValidIBAN, isValidBIC} from "ibantools";
import {isSIRET} from "siret";
import { checkVAT, france } from 'jsvat';

export default class Errors {
  emailErrors(value) {
    const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length === 0) {
      return "Ce champ est obligatoire";
    } else if (mailReg.test(value)) {
      return "";
    } else {
      return "Email Invalide";
    }
  }

  passwordErrors(value) {
    //const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*â§!€£éÀ\\-\\_ÁÂÃ\\,\\;\\.\\ÄÅàáâã\\=äåÒÓ\\+ÔÕ\\?ÖØ+òóô\\:õöøÈÉÊ\\/ËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûü\\)ÿÑñ\\(ëÇçÌÍÎ])(?=.{6,})');
    const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9áàâäãéèêëíìîïóòôöõúùûüýÿÁÀÂÄÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝç])(?=.{6,})');
    if (!value) {
      return "Ce champ est obligatoire";
    }
    else if (!regex.test(value)) {
      return "6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial";
    } else {
      return "";
    }
  }

  nameErrors(value) {
    // const specials=/[*|\".,?§ù%£^¨\-_=!+123456789:<>[/\]{}`\\()';@&$]/;
    const specials = /[*|".,?§ù%£^¨\-_=!+:<>[/\]{}`\\()';@&$]/;
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 30)
      return 'Ce champ ne doit pas contenir plus de 30 caractères';
    else if (specials.test(value))
      return 'Ce champ ne doit pas contenir de caractère spéciaux';      
    else
      return "";
  }

  nameWithSpecialCharsErrors(value) {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 30)
      return 'Ce champ ne doit pas contenir plus de 30 caractères';      
    else
      return "";
  }

  textErrors(value) {
    if (!value){
      return "Ce champ est obligatoire";
    } else if (value.length > 100) {
      return 'Ce champ ne doit pas contenir plus de 100 caractères';
    }else {
      return "";
    }
  }

  descriptionErrors(value) {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 50) {
      return 'Ce champ ne doit pas contenir plus de 50 caractères';
    }else {
      return "";
    }
  }

  longDescriptionErrors(value) {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 500) {
      return 'Ce champ ne doit pas contenir plus de 500 caractères';
    }else {
      return "";
    }
  }

  extraLongDescriptionErrors(value) {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 2000) {
      return 'Ce champ ne doit pas contenir plus de 2000 caractères';
    }else {
      return "";
    }
  }

  codePostalError(value) {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (value.length > 30) {
      return 'Ce champ ne doit pas contenir plus de 100 caractères';
    }else {
      return "";
    }
  }

  numberErrors(value) {
    if (!value) {
      return "Ce champ est obligatoire";
    }else if (isNaN(`${value}`.replace(',','.'))) {
      return 'Montant invalide';
    } else {
      return "";
    }
  }

  numberIntegerErrors = (value) => {
    if (!value){
      return "Ce champ est obligatoire";
    }else if (isNaN(value) ) {
      return 'Nombre invalide';
    }
    else if ( !Number.isInteger(value / 1)) {
      return 'Nombre invalide';
    }else {
      return "";
    }
  }

  confirmNewsPassErrors(password, confirmPassword) {
    if (confirmPassword.length === 0) {
      return "Ce champ est obligatoire";
    } else if (password !== confirmPassword) {
      return "Les mots de passe ne sont pas indentiques";
    } else {
      return "";
    }
  }

  // passwordErrors (password) {
  //     const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})');
  //     if (password.length == 0) {
  //         return "Ce champ est obligatoire";
  //     } else if (!regex.test(password)){
  //         return "6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial";
  //     } else {
  //         return "";
  //     }
  // }

  notEmptyInput(value) {
    if (value == null) {
      return "Ce champ est obligatoire";
    } else if (value.length === 0) {
      return "Ce champ est obligatoire";
    } else {
      return "";
    }
  }

  ibanValid(value){
    if (value && !isValidIBAN(value)) {
      return "Numéro de l’IBAN invalide";
    }
    return ""; 
  }

  bicValid(value){
    if (value && !isValidBIC(value)) {
      return "Numéro du BIC invalide";
    }
    return ""; 
  }

  siretValid(value){
    if (value && !isSIRET(value)) {
      return "SIRET invalide";
    }
    return ""; 
  }

  tvaValid(value){
    if (value) {
      const checkedVat = checkVAT(value, [france]);
      if (!checkedVat || !checkedVat.isValid) {
          return "Numéro de TVA invalide";
      }
    }
    return ""; 
  }

}

import prestationBdl from '../bdl/prestation';

export default {
    createPrestation: (data,id) => prestationBdl.createPrestation(data,id),
    updatePrestation: (data,id) => prestationBdl.updatePrestation(data,id),
    updateFullPrestation: (data,idSeller,idPrestation) => prestationBdl.updateFullPrestation(data,idSeller,idPrestation),
    getPrestation: (data) => prestationBdl.getPrestation(data),
    removePrestation: (id) =>prestationBdl.removePrestation(id),
    getPrestationIndependant: () => prestationBdl.getPrestationIndependant(),
    addPrestationIndependant : (data) => prestationBdl.addPrestationIndependant(data),
}

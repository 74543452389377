import commonsBdl from '../bdl/commons';

export default {
    getAllCategory : () => commonsBdl.getAllCategory(),
    getAllKeywords : () => commonsBdl.getAllKeywords(),
    login : (data) => commonsBdl.login(data),
    setUserPassword : (data,token) => commonsBdl.setUserPassword(data,token),
    forgetPassword : (data) => commonsBdl.forgetPassword(data),
    checkToken : (data) => commonsBdl.checkToken(data),
    getHotelCategories : (codeHotel) => commonsBdl.getHotelCategories(codeHotel),
    addOption : (idPrestation, options) => commonsBdl.addOption(idPrestation, options),
    getAllDeliveryPlaces : () => commonsBdl.getAllDeliveryPlaces(),
    addDeliveryPlace : (data) => commonsBdl.addDeliveryPlace(data),
}
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';

class LandingPageController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
    };
  }
  
  componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
  }
}

export default LandingPageController;


export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
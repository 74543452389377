import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import mapStateToProps from '../../../services/redux/mapStateToProps';
import mapDispatchToProps from '../../../services/redux/mapDispatchToProps';
import moment from 'moment';
import fileDownload from 'js-file-download';

class AgendaController extends Component {
  constructor(props) {
    super(props);
    this.pageRef = createRef();
    this.state = {
      isLoading: false,
      isSearch: false,
      visible: false,
      visibleE: false,
      listAllEvent: [],
      event: {
        start: "string",
        end: "string",
        googleCalendarId: "string",
        title: "string",
        description: "string",
      },
      startEventAdd: "",
      endEventAdd: "",
      titleEventAdd: "",
      errorsEvent: {
        start: "default",
        end: "default",
        title: "default",
      },
      eventSelected: null,
      search: "",
      searchText: "",
      code: null,
      selectAgenda: false,
      visibleCommon: false,
      visibleSelectCalendar: false,
      titleCommon: null,
      messageCommon: null,
      titleSubmitCommon: null,
      urlRedirect: null,
      selectedCalendar: "",
      dateStart: null,
      dateEnd: null,
      selectedSeller: '',
      // visibleSelectCalendar : false,
    };
  }

  componentDidMount() {
    if (this.pageRef && this.pageRef.current) {
      this.pageRef.current.scrollIntoView();
    }
    this.setState({ isLoading: true })
    this.onNavigate(new Date(), "month");
    const parametersValues = queryString.parse(this.props.location.search);
    if (parametersValues && parametersValues.code) {
      this.startSync(parametersValues.code, this.callback)
    }
    else {
      this.getAllData()
      // const { listAllEvent } = this.props.agenda
      // if (listAllEvent) {
      //   this.setState({
      //     listAllEvent,
      //   });
      // }
    }
  }
  getAllData = async () => {
    await this.props.getAllEvent(null,this.callback);
    await this.props.getStateSync(this.callback);
    await this.props.getNextOrderSale(this.callback)
    if (localStorage.getItem('type_compte') && localStorage.getItem('type_compte').includes('ROLE_SELLER_AGENCE')) {
      await this.props.getAgenceSellers(this.callback);
    }
    this.setState({ isLoading: false })
  }

  getSellerEvent = () => {
    this.setState({ isLoading: true })
    this.props.getAllEvent(this.state.selectedSeller,this.callback);
  }
  startSync = (code) => {
    this.setState({ isLoading: true })
    let data = {}
    if (code === null) {
      data = {
        urlRedirect: window.location.href
      }
    }
    else if (code) {
      data = {
        code: code
      }
    }
    this.props.getGoogleAccessToken(data, this.callback)
  }

  componentDidUpdate(prevProps) {
    // const prevListAllEvent = prevProps.agenda.listAllEvent;
    // const propsListAllEvent = this.props.agenda.listAllEvent;

    // if (!prevListAllEvent) {
    //   return;
    // }
    // if (prevListAllEvent !== propsListAllEvent) {
    //   this.setState({
    //     listAllEvent: propsListAllEvent
    //   });
    // }
  }

  isValidAdd = () => {
    const {
      startEventAdd,
      endEventAdd,
      titleEventAdd,
    } = this.state;
    return startEventAdd !== "" && endEventAdd !== "" && titleEventAdd !== ""
  }

  addEvent = (event) => {
    const data = {
      start: this.state.startEventAdd,
      end: this.state.endEventAdd,
      title: this.state.titleEventAdd,
    }
    this.setState({ 
      isLoading: true,
      selectedSeller: ''
    });
    this.props.addEvent(data, this.callback)
  }

  callback = (data, type) => {
    this.setState({
      isLoading: false,
      startEventAdd: null,
      endEventAdd: null,
      titleEventAdd: "",
      searchText: "",
      visibleSelectCalendar: false,
      selectedCalendar: "",
    });

    if (type === 'addEvent') {
      let addEventError = data && data.status === 201 ? false : true;
      this.setState({
        addEventError,
      })
    }else if (type === 'syncCalendar') {
      let syncError = data && data.status === 200 ? false : true;
      this.setState({
        syncError,
      })
    }else if (type === 'unSyncCalendar') {
      let unSyncError = data && data.status === 200 ? false : true;
      this.setState({
        unSyncError,
      })
    }else if (type === 'removeEvent') {
      let removeEventError = data && data.status === 200 ? false : true;
      this.setState({
        removeEventError,
      })
    }else if (type && type === "getAccess") {
      if (data && data.status === 200) {
        if (data && data.data && data.data.auth_url) {
          this.setState({ urlRedirect: data.data.auth_url });
          this.openModalCommon("Authentification", "Voulez-vous vous connecter avec un compte google pour acceder a votre agenda?", "OK")
        }
        else if (data && data.data && data.data.length > 0) {
          this.openModalSelectCalendar()
        }
      }
    } else if (type && type === "search") {
      this.setState({ isSearch: true })
    }
    else if (type && type === "syncCalendar") {
      let syncError = data && data.status === 200 ? false : true;
      this.setState({
        selectedSeller: '',
        syncError
      })
      this.props.getAllEvent(null,this.callback);
      this.props.getStateSync(this.callback)
      this.setState({ isLoading: false })
    }
    else if (type && type === "unSyncCalendar") {
      let unSyncError = data && data.status === 200 ? false : true;
      this.setState({
        unSyncError,
      })
    }
    else if (type === "exportAgenda") {
      let exportError = data && data.status === 200 ? false : true;
      this.setState({
        exportError,
      })
      if (data && data.data){
        this.downloadIcsFile(data.data);
      }
    }

  }
  
  // downloadIcsFile = (data) => {
  //   const blob = new Blob([`${data}`], { type: 'text/calendar' });
  //   fileDownload(blob, "ClickbuttlerCalendar.ics")
  // }

   downloadIcsFile = (data) => {
    const blob = new Blob([`${data}`], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    var hiddenIFrameID = 'hiddenDownloader',
        iframe = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
    }
    iframe.src = url;
  }

  selectCalendar = (event) => {
    this.setState({ selectedCalendar: event.label, });
  }
  submitLogin = () => {
    this.setState({
      visibleCommon: false,
      titleCommon: null,
      messageCommon: null,
      titleSubmitCommon: null
    })
    window.open(this.state.urlRedirect, "_self");
  }

  removeEvent = () => {
    this.setState({ isLoading: true })
    this.closeModal()
    this.props.removeEvent(this.state.eventSelected.id, this.callback)
  }

  onNavigate = (date, view, action) => {
    let start, end;
    if (view === 'month') {
      start = moment(date).startOf('month')._d
      end = moment(date).endOf('month')._d
    }
    this.setState({ dateStart: start, dateEnd: end })
  }

  setSync = () => {
    const selected = this.props.agenda.listCalendarGoogle && this.props.agenda.listCalendarGoogle.filter(item => item.summary === this.state.selectedCalendar);
    this.setState({ isLoading: true })
    this.props.syncCalendar(selected[0].id, this.callback)
  }

  setUnSync = () => {
    this.setState({ isLoading: true })
    this.props.unSyncCalendar(this.callback)
  }
  isExportValid = () => {

    let { listAllEvent } = this.props.agenda;
    let start = new Date(this.state.dateStart);
    let end = new Date(this.state.dateEnd);
    listAllEvent = listAllEvent.filter(item => {
      let date = new Date(item.start);
      return date >= start && date <= end;
    })
    return listAllEvent.length > 0
  }

  exportAgenda = () => {
    const data = {
      start: this.state.dateStart,
      end: this.state.dateEnd,
    }
    this.setState({ isLoading: true })
    this.props.exportEvent(data, this.callback)

  }
  isSearchValid = () => {
    const searchText = this.state.searchText
    return searchText !== ""
  }

  searchEventSubmit = () => {
    if (this.state.searchText !== "") {
      this.setState({ isLoading: true })
      const data={
        name: this.state.searchText,
        sellerId: this.state.selectedSeller
      }
      this.props.searchEvent(data, this.callback);
    }
  }

  handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.searchEventSubmit()
    }
  }

  updateState(event) {
    let name = event.target.name;
    this.setState({ [name]: event.target.value, });
  }
  
  openModal = (event) => {
    event = {
      ...event,
      start: moment(new Date(event.start)).format('DD/MM/YYYY'),
      end: moment(new Date(event.end)).format('DD/MM/YYYY')
    }
    if (!this.state.selectedSeller) {
      this.setState({
        visible: true,
        eventSelected: event
      });
    }
    
  }

  openModalCommon = (titleCommon, messageCommon, titleSubmitCommon) => {
    this.setState({
      visibleCommon: true,
      titleCommon,
      messageCommon,
      titleSubmitCommon,
    });
  }
  closeModalCommon = () => {
    this.setState({
      visibleCommon: false,
      titleCommon: null,
      messageCommon: null,
      titleSubmitCommon: null,
    });
  }
  openModalSelectCalendar = () => {
    this.setState({
      visibleSelectCalendar: true,
    });
  }
  closeModalSelectCalendar = () => {
    this.setState({
      visibleSelectCalendar: false,
    });
  }

  closeModal = () => {
    this.setState({
      visible: false,
      eventSelected: null
    });
  }
  setDisabledDate = (list, type) => {
    list = list ? list.map((item, index) => { return { "from": new Date(item.start), "to": new Date(item.end) } }) : [];
    if (type === "start") {
      list = this.state.endEventAdd !== null ? [...list, { "after": new Date(this.state.endEventAdd) }] : list;
    }
    else if (type === "end") {
      list = this.state.startEventAdd !== null ? [...list, { "before": new Date(this.state.startEventAdd) }] : list;
    }
    return list
  }

  handleSellerChange = (event) => {
    const selectedSeller = event.target.value;
    this.setState({
      selectedSeller
    },this.getSellerEvent);
  };

}

export default AgendaController;

AgendaController.propTypes = {

};

export const reduxConnect = component => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(component));
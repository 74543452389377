import React from 'react';
import StarRatings from 'react-star-ratings';
import 'react-web-tabs/dist/react-web-tabs.css';

import ClientController, { reduxConnect } from './controller';
import InstitutImg from './../img/avatar.png';
import Pagination from "./../../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "./../../../../data/constants/page.size";
import LoadingOverlay from 'react-loading-overlay';
import { getSellerType } from '../../../../data/constants/seller.type';

class Client extends ClientController {
  renderHour() {
    const jour = [
      { id: 1, label: 'Lundi' },
      { id: 2, label: 'Mardi' },
      { id: 3, label: 'Mercredi' },
      { id: 4, label: 'jeudi' },
      { id: 5, label: 'Vendredi' },
      { id: 6, label: 'Samedi' },
      { id: 7, label: 'Dimanche' },
    ];
    const { listOpeningTime } = this.props.etablissement;
    return (
      listOpeningTime && listOpeningTime.map((value, index) =>
        <p key={`${index}-time`}>{`Du ${value.dayStart ? jour.find(i => { return i.id === value.dayStart }).label : ""} au ${value.dayEnd ? jour.find(i => { return i.id === value.dayEnd }).label : ""} de ${value.timeStart} à ${value.timeEnd}`}</p>
      )
    )
  }

  renderPagination = () => {
    const {listPrestation} = this.props.prestation;
    return (
      <div className="pagination">
        <div>
          <Pagination
            totalElements={listPrestation && listPrestation.nbPage ? listPrestation.nbPage * listPrestation.itemPerPage : 1}
            changePageCallback={pageNumber =>
              this.handlePaginationChange(pageNumber)
            }
            itemsPerPage={listPrestation && listPrestation.itemPerPage ? listPrestation.itemPerPage : 5}
            activePage={listPrestation && listPrestation.page ? listPrestation.page : 1}
          />
        </div>
        <div>
          {"Nombre par page  "}
          <select
            value={(this.state.query && this.state.query.itemPerPage) || PAGE_SIZE_LIST[0]}
            onChange={(value) => this.handleItemsPerPageChange(value)}

          >
            {PAGE_SIZE_LIST.map(size =>
              <option key={`item-${size}`} value={size}>{size}</option>
            )}
          </select>
        </div>
      </div>
    );
  };

  render() {
    const { infoEtablissement } = this.props.etablissement;
    const listPrestation = (this.props.prestation.listPrestation && this.props.prestation.listPrestation.data) || [];
    const nbPrestation = (this.props.prestation.listPrestation && this.props.prestation.listPrestation.total) || 0;
    const sellerType = getSellerType(this.props.commons.infoLogin);
    return (
      <div className="page">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Chargement'
        >
        <div className="main-catalogue">
          <div className="wrapper cnt-tabs">
            <div className="content-client">
              <div className="banner-client" style={{backgroundImage: `url(${infoEtablissement && infoEtablissement.logo && infoEtablissement.logo.data && !infoEtablissement.logo.url ? (infoEtablissement.logo.data.includes("data:image/")) ? infoEtablissement.logo.data : `data:image/jpeg;base64,${infoEtablissement.logo.data}` : infoEtablissement.logo && infoEtablissement.logo.url ? infoEtablissement.logo.url : InstitutImg})`}}>
              </div>
              <div className="info-etablissement">
                <h2 className="name-etablissement">{infoEtablissement && infoEtablissement.name}</h2>
                <div className="ratings">
                  <StarRatings
                    rating={0}
                    starRatedColor='rgb(255, 181, 0)'
                    starEmptyColor='rgb(255, 236, 191)'
                    numberOfStars={5}
                    name='rating'
                    starDimension='20px'
                    starSpacing='0px'
                  />
                </div>
                <span className="nbr-avis">0 avis</span>
                <div className="adress-etablissement">
                  {infoEtablissement && infoEtablissement.address && Object.values(infoEtablissement.address).join(", ")} <span className="cordonne"><span className="arrow"><i className="material-icons arrow-room">room</i></span> à 0m</span></div>
                <div className="heure-etablissement">
                  <p className="title-heure">Horaires d’ouverture</p>
                  {this.renderHour()}
                </div>
                <div className="detail-etablissement">
                  <p>{infoEtablissement && infoEtablissement.description}</p>
                </div>
                <div className="liste-product">
                  {
                    listPrestation && Array.isArray(listPrestation) && listPrestation.map((value) =>
                      <div className="line-product" key={`${value.id}-client`}>
                        <div className="infos-product">
                          <h3>{value.name}</h3>
                          <p>{`${value.duration || 0} min`}</p>
                          {value && value.prestationOffert &&  
                          <p>
                            <span className="arrow">
                              <i className="material-icons arrow-redeem">redeem</i>
                            </span> {value.prestationOffert}
                          </p>
                          }
                          <p>+ détails</p>
                        </div>
                        <div className="action-product">

                          <p className="price">{value.reduction ? (value.price * (100 - value.reduction) / 100) : value.price}€</p>
                          { value && value.reduction ? <p className="price-rong">{value.price || 0}€</p> : "" } 
                          <div className="cnt-btn">
                            <button className="button">Réserver</button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                {nbPrestation > 0 && sellerType !== 1 && this.renderPagination()}
              </div>
            </div>
          </div>
        </div>
        </LoadingOverlay>
      </div>
    )
  }
}
export default reduxConnect(Client);

import { get, put } from '.';
import { url } from '../redux/constants/dashboard'

const testResponse = (response) => {
  if (!response || !response.data) return false;
  return response.data;
}

const testVoidResponse = (response) => {
  const data  = (response && response.status) ? response : (response && response.response) || null;
  return data;
}

export default {
  getInfoDashboard: async (query) => {
    const response = await get(`${url.infoDashboard}${query || ''}`);
    return testResponse(response)
  },
  
  getAllInfoDashboard: async () => {
    const response = await get(url.infoDashboardAll);
    return testVoidResponse(response)
  },

  getSteps: async () => {
    const response = await get(url.steps);
    return testResponse(response)
  },

  addSteps: async (data) => {
    const response = await put(url.steps, data);
    return testVoidResponse(response)
  }
}